import React, { LabelHTMLAttributes } from 'react';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { Customisation } from 'src/components/BundleProduct/types';
import { ArtworkItem as ArtworkItemProps } from 'src/components/Artwork/artwork.types';
import { camelizeKeys } from 'humps';
import { WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface } from 'swagger/ts/Definitions';

import classes from './ArtworkItem.scss';

type ArtworkGridItemProps = {
    imageId?: string;
    textImage?: React.ReactNode;
    logoImage?: React.ReactNode;
    icon?: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    artwork: ArtworkItemProps;
    freeCustomisation?: boolean;
    selectedArtwork?: string;
    computedArtwork?: WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface;
} & LabelHTMLAttributes<any>;

export const ArtworkItem: React.FC<ArtworkGridItemProps> = ({
    textImage,
    logoImage,
    imageId,
    handleCustomisationSet,
    artwork,
    freeCustomisation,
    selectedArtwork,
    computedArtwork,
}) => {
    return (
        <div className={classes.container}>
            <input
                className={classes.input}
                type="radio"
                checked={selectedArtwork == artwork.id}
                name="artwork"
                id={artwork.id}
                onChange={(e) => {
                    e.preventDefault();
                    handleCustomisationSet({
                        selectedArtwork: artwork.id,
                        selectedArtworkExisting: JSON.stringify(computedArtwork),
                        selectedType: 'logo',
                        selectedOptions: {
                            ...camelizeKeys(artwork),
                            isFree: Boolean(freeCustomisation),
                        },
                        isArtworkTemporary: Boolean(computedArtwork?.id),
                    });
                }}
            />

            <label className={classes.label} htmlFor={artwork.id}>
                <div className={classes.wrapper}>
                    {artwork.application_type === ApplicationType.Logo ? (
                        <a href={artwork.image} target="_blank" rel="noreferrer">
                            <img src={require('../../../../../static/icons/circle-check.png')} alt="check circle" />{' '}
                            {imageId}
                        </a>
                    ) : (
                        <span>Artwork</span>
                    )}
                </div>

                <div className={classes.image}>
                    {artwork.application_type === ApplicationType.Logo && (
                        <div className={classes.logoImage}>{logoImage}</div>
                    )}
                    {artwork.application_type === ApplicationType.Text && (
                        <div className={classes.textImage}>{textImage}</div>
                    )}
                </div>
                <span className={classes.title}>{artwork.title ? artwork.title : ''}</span>
                <img
                    className={classes.checkIcon}
                    src={require('../../../../svg/check-circle.svg')}
                    alt="check circle"
                />
            </label>
        </div>
    );
};
