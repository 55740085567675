import classes from 'src/components/BasketShared/BasketShared.scss';
import { Svg } from 'src/components/Svg/Svg';
import React from 'react';
import { Button } from 'src/components/Button/Button';

type Props = {
    onCancel();
    recipientEmail: string;
    isSharedEmail: boolean;
};

export const ShareFormSuccess: React.FC<Props> = ({ onCancel, recipientEmail, isSharedEmail = false }) => (
    <div className={classes.container} data-test-id="ShareBasketFormSuccess">
        <h2 className={[classes.title, classes.titleSuccess].join(' ')}>
            <Svg name="check" /> Basket Shared
        </h2>
        <p>
            Thank you! You have shared your basket with: <b>{recipientEmail}</b>
        </p>
        <p>
            {isSharedEmail ? (
                <small>
                    They will receive an email shortly with a link that will remain live for 5 days. After this time,
                    the basket will be deleted.
                </small>
            ) : (
                <small>
                    They will recieve an email shortly with a link that will remain live for 5 days, after this time it
                    will be deleted.
                </small>
            )}
        </p>
        <Button onClick={onCancel} variant="light">
            Continue Shopping
        </Button>
    </div>
);
