import React from 'react';
import { BasketItemType } from 'src/components/Basket/BasketContext';

import {
    BasketItemCustomisation,
    CustomisationWithIdAndLocation,
    Targets,
} from '../CustomisationPage/feature/customisation.types';

import { ItemRowCheckout } from './ItemCustomisations';
import classes from './ItemCustomisations.scss';

type Props = {
    groupedCustomisation: BasketItemCustomisation[];
    itemId: string;
    isPending: boolean;
    allowActions?: boolean;
    item: BasketItemType;
    canCopyCustomisation?: (customisationId: number, itemId: string) => boolean;
    getCopyParams?: (itemId: string, customisation: CustomisationWithIdAndLocation) => Targets;
};

export const ItemCustomisation: React.FC<Props> = ({
    groupedCustomisation,
    itemId,
    isPending,
    allowActions,
    item,
    canCopyCustomisation,
    getCopyParams,
}) => (
    <li className={classes.customisationsItem}>
        <div className={classes.customisationsActionsLeftAndItemRowWrapper}>
            <ItemRowCheckout
                items={groupedCustomisation}
                firstItem={groupedCustomisation[0]}
                itemId={itemId}
                getCopyParams={getCopyParams}
                isPending={isPending}
                groupedCustomisation={groupedCustomisation}
                item={item}
                canCopyCustomisation={canCopyCustomisation}
                allowActions={allowActions}
            />
        </div>
    </li>
);
