import React, { useEffect, useMemo } from 'react';
import { useCartItemApi, UseCartItemApi } from '@wearejh/m2-pwa-cart-gql/lib/hooks/useCartItemApi';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import { BasketItemCustomisation, ProductLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { getCart_getCart } from 'src/queries/__generated__/getCart';
import { CartItemType } from 'src/util/enums';
import { getBasketItems } from 'src/components/Basket/utils/getBasketItems';
import Bugsnag from '@bugsnag/js';

import { useCustomisations } from './hooks/useCustomisations';
import { Step } from './feature/basket.types';

export type BasketItemType = {
    cartItem: any;
    customisations: BasketItemCustomisation[];
    availableLocations: ProductLocation[];
    customisationsCost: number;
    csr_discount: number;
};

export type BasketClearCartFn = (object: {
    context: {
        headers: {
            Authorization: string;
        };
    };
    variables: {
        cartId: string;
    };
}) => void;

export type BasketMergeCartsMutation = (object: {
    context: {
        headers: {
            Authorization: string;
        };
    };
    variables: {
        source_cart_id: string;
        destination_cart_id: string;
    };
}) => void;

export type AddProductToCartMutation = (object: {
    context: {
        headers: {
            Authorization: string;
        };
    };
    variables: {
        input: {
            cart_id: string;
            cart_items: CartItems[];
        };
    };
}) => void;

type CartItems = {
    data: {
        quantity: number;
        sku: string;
        tax_free: boolean;
    };
    parent_sku: string;
    variant_sku: string;
};

export const defaultContext = {
    isPending: false,
    messages: [],
    itemMessages: [],
    basketItems: [],
    cartId: null,
    itemsPending: [],
    cartItemPricesInc: new Map(),
    totals: null,
    deleteItem: (_params) => {
        Bugsnag.notify('deleteItem not implemented');
    },
    updateItems: (_params) => {
        Bugsnag.notify('updateItem not implemented');
    },
    clearItemMessages: () => {
        Bugsnag.notify('clearItemMessages not implemented');
    },
    sumTotalCost: 0,

    setupCostAfterDiscount: 0,
    setupCost: 0,
    setupCostDiscount: 0,
};

export const BasketContext = React.createContext<{
    isPending: boolean;
    basketItems: BasketItemType[];
    cartId: string | null;
    itemsPending: string[];
    cartItemPricesInc: Map<string, number>;
    messages: Message[];
    totals: UseCartItemApi['totals'];
    itemMessages: Message[];
    deleteItem: UseCartItemApi['deleteItem'];
    updateItems: UseCartItemApi['updateItems'];
    clearItemMessages: UseCartItemApi['clearItemMessages'];
    sumTotalCost: number;

    setupCostAfterDiscount: number;
    setupCost: number;
    setupCostDiscount: number;
}>(defaultContext);

export const BasketContextProvider: React.FC = ({ children }) => {
    const { loadStep, isPending, customisations, itemsPending } = useCustomisations();
    const {
        deleteItem,
        items: cartItems,
        isPending: cartIsPending,
        updateItems,
        itemMessages,
        messages,
        clearItemMessages,
        totals,
        raw,
    } = useCartItemApi<getCart_getCart>();

    const cartItemsWithNoVirtualItem = cartItems.filter(
        (item) => String(item.__typename) !== CartItemType.VirtualCartItem,
    );

    const setupCost = raw?.items?.filter((el) => el?.product.sku === 'setup_charge')[0]?.prices?.price.value || 0;
    // SETUP COST DISCOUNT
    const setupCostDiscount =
        raw?.items
            ?.filter((el) => {
                return el?.prices?.discounts !== null && el?.product.sku === 'setup_charge';
            })
            .map((el) => el?.prices?.discounts)[0]
            ?.map((el) => el?.amount.value)[0] || 0;
    // TOTAL SETUP COST
    const setupCostAfterDiscount = setupCost - setupCostDiscount;

    // ************
    // CUSTOMISATION COSTS
    const costs = useMemo(() => {
        const cost = raw?.items?.filter(
            (item) => (item?.__typename === 'ConfigurableCartItem' || item?.__typename === 'BundleCartItem') && item,
        );

        return cost?.reduce((acc, el) => {
            if (el) {
                acc[el.id] = el.customisation_application_cost;
            }
            return acc;
        }, {});
    }, [raw]);

    const totalCost = costs ? Object.values(costs)?.map((c: any) => c?.cost) : [];

    // TOTAL CUSTOMISATION COST
    const sumTotalCost = totalCost?.reduce(add, 0);

    const cartItemPricesInc = useMemo(() => {
        return new Map(
            raw?.items?.map((item) => {
                if (!item || !item.prices?.price) {
                    throw new Error('Missing item.prices.price on cart item');
                }
                return [item.id!, item.prices.price_incl_tax.value!];
            }),
        );
    }, [raw]);

    const basketItems = getBasketItems(cartItemsWithNoVirtualItem, customisations, raw);

    useEffect(() => {
        loadStep(Step.List);
    }, [loadStep]);

    const api = {
        isPending: isPending || cartIsPending,
        itemsPending,
        deleteItem,
        updateItems,
        itemMessages,
        clearItemMessages,
        messages,
        basketItems,
        totals,
        cartItemPricesInc,
        cartId: raw?.id || null,
        sumTotalCost,
        setupCostAfterDiscount,
        setupCost,
        setupCostDiscount,
    };

    return <BasketContext.Provider value={api}>{children}</BasketContext.Provider>;
};

const add = (accumulator, a) => {
    let nr = a;
    if (!nr) {
        nr = 0;
    }
    return accumulator + nr;
};
