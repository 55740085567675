import { registerResponseEpic } from './epics/registerResponse.epic';

export function registerCustomerResponse() {
    return {
        epics: [registerResponseEpic],
        reducers: {
            customerResponses: (state = {}) => state,
        },
        name: 'customerResponses',
    };
}
