import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/useSetResolved';
import { useCallback, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import orderStatusQuery from 'src/queries/orderStatus.graphql';
import { useDeps } from 'src/hooks/useDeps';

import { TrackingStates } from '../utils/orderTrackingStaticValues';
import { OrderStatus } from '../utils/orderTrackingTypes';

export const useOrderTrackingDetails = (footprint, orderInfo) => {
    useSetResolved();
    const [trackingState, setTrackingState] = useState<TrackingStates>(TrackingStates.SEARCH);
    const [orderId, setOrderId] = useState<string>('');
    const [statusFromDPD, setStatusFromDPD] = useState<string | null>(null);

    const [invoiceStatusesFromDPD, setInvoiceStatusesFromDPD] = useState<Array<string | null>>([]);
    const [getOrderStatus, { loading, data }] = useLazyQuery<
        { orderStatus: OrderStatus },
        { orderId: string; postcode: string }
    >(orderStatusQuery, {
        onCompleted: async () => {
            setTrackingState(TrackingStates.FOUND);
        },
        onError: () => setTrackingState(TrackingStates.NOT_FOUND),
        ssr: useDeps().env.SSR_GQL,
    });

    const items = data?.orderStatus?.items || [];

    useEffect(() => {
        setStatusFromDPD(null);
        setTrackingState(TrackingStates.SEARCH);
    }, [footprint]);

    const isLiveChatEnabled = typeof window !== 'undefined' ? window['LC_API']?.agents_are_available?.() : false;

    const formSubmitHandler = useCallback(
        (event: { orderId: string; postcode: string }) => {
            const newOrderId = event.orderId.toUpperCase();
            const newPostcode = event.postcode.toUpperCase();
            setOrderId(newOrderId);
            getOrderStatus({ variables: { orderId: newOrderId, postcode: newPostcode } });
        },
        [getOrderStatus],
    );

    useEffect(() => {
        if (orderInfo) formSubmitHandler(orderInfo);
    }, [formSubmitHandler, orderInfo]);

    const backButtonClickHandler = () => {
        setOrderId('');
        setStatusFromDPD(null);
        setTrackingState(TrackingStates.RETRY);
    };

    const openChatClickHandler = () => {
        if (typeof window !== undefined) {
            window['LC_API']?.open_chat_window();
        }
    };

    return {
        loading,
        trackingState,
        formSubmitHandler,
        backButtonClickHandler,
        invoiceStatusesFromDPD,
        orderId,
        statusFromDPD,
        data,
        isLiveChatEnabled,
        openChatClickHandler,
        items,
    };
};
