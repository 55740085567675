import { useQuery } from '@apollo/react-hooks';
import { Fields } from 'src/components/CustomisationPage/feature/customisation.types';
import { createDebug } from 'src/util/debug';
import Bugsnag from '@bugsnag/js';

import { fontPreview } from '../../../queries/__generated__/fontPreview';
import fontPreviewQuery from '../../../queries/fontPreview.graphql';

const debug = createDebug('fontPreview');

export type Font = {
    preview: string;
    id: string;
    name: string;
    size: number;
};

export type Colour = {
    fg_colour: string;
    id: string;
    name: string;
};

const EMPTY_ARR = [];

export function queryToFonts(q?: fontPreview): Font[] {
    return (q?.fontPreviewFonts as Font[]) || EMPTY_ARR;
}

export function queryToColours(q?: fontPreview): Colour[] {
    return (q?.fontPreviewColours as Colour[]) || EMPTY_ARR;
}

export function useFontPreview(): { pending: boolean; fonts: Font[]; colours: Colour[] } {
    const { data, loading, error } = useQuery(fontPreviewQuery, { ssr: false });
    if (error) {
        Bugsnag.notify('Could not fetch font information');
    }
    return {
        pending: loading,
        fonts: queryToFonts(data),
        colours: queryToColours(data),
    };
}

export function formValuesToString(values: { [index: string]: string | undefined }, fonts?: Font[]): string {
    const params = new URLSearchParams();

    // line 1
    if (!values[Fields.textLineOne]) {
        params.set(Fields.textLineOne, 'Preview');
    } else {
        params.set(Fields.textLineOne, values[Fields.textLineOne]!);
    }
    // line 2, optional
    if (values[Fields.textLineTwo]) {
        params.set(Fields.textLineTwo, values[Fields.textLineTwo]!);
    }
    // line 3, optional
    if (values[Fields.textLineThree]) {
        params.set(Fields.textLineThree, values[Fields.textLineThree]!);
    }
    // font, required
    if (values[Fields.font]) {
        params.set('fontName', values[Fields.font]!);
    } else if (fonts) {
        params.set('fontName', fonts[0].name);
    } else {
        debug('Missing font from form values');
    }
    if (values[Fields.color]) {
        params.set('fontColour', values[Fields.color]!);
    } else {
        debug('Missing color from form values');
    }
    return params.toString();
}
