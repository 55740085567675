import React from 'react';

import { SvgIconNames } from '../Svg/icons-types';
import { Svg } from '../Svg/Svg';

import classes from './InlineIcons.scss';

type InlineIconsProps = {
    icons: SvgIconNames[];
};

export const InlineIcons: React.FC<InlineIconsProps> = ({ icons }) => (
    <div className={classes.customisationIcons}>
        {icons.map((icon) => (
            <Svg name={icon} key={icon} />
        ))}
    </div>
);
