import React, { useState } from 'react';
import { CustomerOrderLoading } from 'src/components/Orders/CustomerOrderLoading';
import { useQuery } from '@apollo/react-hooks';
import customer from 'src/queries/customerOrders.graphql';
import { CustomerOrders } from 'src/components/Orders/CustomerOrders';
import { camelizeKeys } from 'humps';

type Props = {
    sliced?: boolean;
};

const NUMBER_OF_FIRST_DISPLAYED_ORDERS = 5;

export const CustomerOrdersRoot: React.FC<Props> = ({ sliced }) => {
    const [page, setPage] = useState(1);

    if (typeof window !== 'undefined') {
        const pageFromLink = Number(window.location.search.match(/([\d]+)/g)?.[0]);
        if (pageFromLink) {
            setPage(pageFromLink);
        }
    }

    return <CustomerOrdersWithPage page={page} sliced={sliced} />;
};

const CustomerOrdersWithPage: React.FC<{ page: number; sliced?: boolean }> = ({ page, sliced = false }) => {
    const [numberOfResultsPerPage, setNumberOfResultsPerPage] = useState(10);
    const [filterResults, setFilterResults] = useState('');

    const { data, loading } = useQuery(customer, {
        variables: {
            pageSize: sliced ? NUMBER_OF_FIRST_DISPLAYED_ORDERS : numberOfResultsPerPage,
            currentPage: sliced ? 1 : page,
            orderFilters: {
                khaos_id: {
                    match: filterResults,
                },
            },
            sort: { sort_field: 'CREATED_AT', sort_direction: 'DESC' },
        },
    });

    if (!data || loading) return <CustomerOrderLoading />;

    const orders = data.customer.orders.items;
    const totalCount = data.customer.orders.total_count;

    if (!Boolean(orders)) return <h3 className="expaneded">You do not have any orders yet</h3>;

    const camelizedOrders = camelizeKeys(orders);

    return (
        <CustomerOrders
            orders={camelizedOrders}
            showPagination={!sliced}
            page={page}
            totalCount={totalCount}
            numberOfResultsPerPage={numberOfResultsPerPage}
            setNumberOfResultsPerPage={setNumberOfResultsPerPage}
            setFilterResults={setFilterResults}
            filterResults={filterResults}
        />
    );
};
