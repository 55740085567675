import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { UserActions } from '@wearejh/m2-pwa-user/lib/index';
import { ignoreElements, tap } from 'rxjs/operators';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';

export function gtmLogin(action$: Observable<any>, _state$: Observable<any>): Observable<never> {
    return action$.pipe(
        ofType<UserActions>('User.SignInSuccess'),
        tap(() => {
            fireGtm({
                event: 'login',
                method: 'Username/Password',
            });
        }),
        ignoreElements(),
    );
}
