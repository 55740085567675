import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';

export const getMethodNumber = (methodName: string) => {
    switch (methodName) {
        case 'embroidery':
            return ApplicationMethod.Embroidery;

        case 'print':
            return ApplicationMethod.Print;
        default:
            return ApplicationMethod.None;
    }
};
