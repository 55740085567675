import React from 'react';
import { formatDate } from 'src/util/formatDate';
import { useQuery } from '@apollo/react-hooks';
import expectedDeliveryDate from 'src/queries/expectedDeliveryDate.graphql';
import { parse } from 'date-fns';

import classes from './ItemDetails.scss';

type Props = {
    id: string;
};

export const ExpectedDeliveryDate: React.FC<Props> = ({ id }) => {
    const { data } = useQuery(expectedDeliveryDate, {
        variables: {
            orderFilters: {
                number: { eq: id },
            },
        },
        fetchPolicy: 'network-only',
    });

    if (
        !data?.customer?.orders?.items ||
        data?.customer?.orders?.items.length === 0 ||
        !data.customer?.orders?.items?.[0]?.khaos_delivery_date
    ) {
        return null;
    }

    return (
        <div className={classes.estimatedShippingDate}>
            Est. dispatch date:{' '}
            {formatDate(
                String(parse(data.customer.orders.items[0].khaos_delivery_date, 'yyyy-MM-dd HH:mm:ss', new Date())),
            )}
        </div>
    );
};
