import { AppendState } from '@wearejh/m2-pwa-engine/lib/router/router.register';
import { shallowEqual, useSelector } from 'react-redux';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';

const EMPTY_ARR = [];
const selector = (s: AppendState) => s.router.location.state?.messages || EMPTY_ARR;

export function useRouterMessages(): Message[] {
    return useSelector(selector, shallowEqual);
}
