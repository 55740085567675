import React, { useCallback, useContext } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { Fields } from '@wearejh/m2-pwa-user/lib/user.types';
import { forgotPasswordRegister } from '@wearejh/m2-pwa-password/lib/forgotPassword/forgotPassword.register';
import { useForgotPassword } from '@wearejh/m2-pwa-password/lib/forgotPassword/hooks/useForgotPassword';
import { successMessage, useLazyRegister } from '@wearejh/m2-pwa-engine/lib';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import { SkeletonBlock } from 'src/elements/Skeletons/SkeletonBlock';
import Bugsnag from '@bugsnag/js';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { Input } from '../Forms/Input';
import { basicEmailValidation } from '../../util/forms';
import { Actions } from '../Forms/Actions';
import { ModalFooter } from '../Modal/ModalFooter';
import { Modal } from '../Modal/Modal';
import { Fieldset } from '../Forms/Fieldset';
import { Messages } from '../Messages/Messages';
import { Block } from '../Helpers/Block';
import { Button } from '../Button/Button';

export type ForgotModalProps = {
    close();
};

export const ForgotContext = React.createContext({
    close: () => {
        Bugsnag.notify('close not implemented');
    },
});

export const ForgotModal: React.FC<ForgotModalProps> = (props) => {
    return (
        <Modal type="popup" isOpen={true} onDismiss={props.close}>
            <ModalHeader title="Forgot Your Password?" icon="account" onDismiss={props.close} />
            <ModalBody>
                <ForgotContext.Provider value={{ close: props.close }}>
                    <FormInnerConnected close={props.close} />
                </ForgotContext.Provider>
            </ModalBody>
            <ModalFooter onDismiss={props.close} />
        </Modal>
    );
};

export default ForgotModal;

type ForgotProps = {
    messages: Message[];
    isPending: boolean;
    isSuccess: boolean;
    isError: boolean;
    submit(email: string): void;
};

export const Forgot: React.FC<ForgotProps> = React.memo((props) => {
    const { submit, messages, isSuccess, isPending, isError } = props;
    const { close } = useContext(ForgotContext);
    const onSubmit = useCallback(
        (values) => {
            submit(values.email);
        },
        [submit],
    );
    return (
        <>
            <p>Please enter your email address below to receive a password reset link.</p>
            {isError && messages.length > 0 && (
                <Block>
                    <Messages messages={messages} />
                </Block>
            )}
            {isSuccess && (
                <>
                    <Block>
                        <Messages messages={[successMessage('Success - Check your emails for further instructions')]} />
                    </Block>
                    <Block>
                        <Button onClick={close}>Continue</Button>
                    </Block>
                </>
            )}
            {!isSuccess && (
                <Form onSubmit={onSubmit} data-test-id="ForgotForm">
                    <Fieldset disabled={isPending}>
                        <Input
                            label="Email"
                            field={Fields.email}
                            id={`modal-${Fields.email}`}
                            validate={basicEmailValidation()}
                            required={true}
                        />
                        <Actions primaryText="Submit" />
                    </Fieldset>
                </Form>
            )}
        </>
    );
});

export const ForgotApi: React.FC = () => {
    const { messages, isSuccess, isPending, isError, submit } = useForgotPassword();
    return <Forgot messages={messages} isSuccess={isSuccess} isPending={isPending} isError={isError} submit={submit} />;
};

export const FormInnerConnected: React.FC<{ close() }> = () => {
    const ready = useLazyRegister(forgotPasswordRegister, true, 'FormInnerConnected');
    if (!ready)
        return (
            <>
                <SkeletonBlock style={{ height: '20px', marginBottom: '20px' }} />
                <SkeletonBlock style={{ height: '20px', marginBottom: '20px' }} />
                <SkeletonBlock style={{ height: '20px', marginBottom: '20px' }} />
            </>
        );
    return <ForgotApi />;
};
