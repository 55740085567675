import React from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'src/components/Layout';
import { PageTitle } from 'src/components/Layout/PageTitle';

import { FormInnerConnected, ForgotContext } from '../SignIn/ForgotModal';
import { useDeps } from '../../hooks/useDeps';

type Props = Record<string, unknown>;

export const Forgot: React.FC<Props> = () => {
    const { push } = useDeps();
    const dispatch = useDispatch();
    const close = () => {
        dispatch(push('/'));
    };

    return (
        <Container variant="modal-narrow">
            <PageTitle title="Forgot Your Password?" />
            <div className="card">
                <ForgotContext.Provider value={{ close }}>
                    <FormInnerConnected close={close} />
                </ForgotContext.Provider>
            </div>
        </Container>
    );
};
