import { copyCustomisationEpic } from 'src/components/Basket/feature/epics/copyCustomisation.epic';
import { couponRegister } from 'src/components/Basket/coupon/coupon.register';
import { scrollTopAfterCoupon } from 'src/components/Basket/feature/epics/scrollTopAfterCoupon.epic';
import { gtmRemoveFromCart } from 'src/features/analytics/epics/gtmRemoveFromCart';
import { registerLogrocket } from 'src/features/logrocket/logrocket.register';
import { gtmBasket } from 'src/features/analytics/epics/gtmBasket';

import { basketReducer } from './basket.reducer';
import { loadStepBasket } from './epics/loadStepBasket.epic';
import { basketRefreshEpic, basketRefreshOnCurrencyChange } from './epics/basketRefresh.epic';
import { removeCustomisationEpic } from './epics/removeCustomisation.epic';

export function basketRegister() {
    return [
        {
            epics: [
                loadStepBasket,
                basketRefreshOnCurrencyChange,
                basketRefreshEpic,
                removeCustomisationEpic,
                copyCustomisationEpic,
                scrollTopAfterCoupon,
                gtmRemoveFromCart,
                gtmBasket,
            ],
            reducers: {
                basket: basketReducer,
            },
            name: 'basket',
        },
        couponRegister(),
        registerLogrocket(),
    ];
}
