import React from 'react';
import { CustomerAccountPage } from 'src/components/Customer/CustomerAccountPage';
import { DefaultMeta } from 'src/components/AppShell/Head';
import meta from 'src/components/Customer/customer.json';
import { BasketContextProvider } from 'src/components/Basket/BasketContext';
import { BasketCspContextProvider } from 'src/components/Basket/BasketCspContext';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { basketRegister } from 'src/components/Basket/feature/basket.register';

import { CustomerOrdersRoot } from './CustomerOrdersRoot';

type Props = { globalCurrentPage?: number; setGlobalCurrentPage?: (page: number) => void };

export const MyOrders: React.FC<Props> = ({ globalCurrentPage, setGlobalCurrentPage }) => {
    const ready = useLazyRegister(basketRegister, true, 'Basket');
    if (!ready) return null;

    return (
        <>
            <DefaultMeta data={meta.helmet.order} />
            <BasketContextProvider>
                <BasketCspContextProvider>
                    <CustomerOrdersRoot />
                </BasketCspContextProvider>
            </BasketContextProvider>
        </>
    );
};

export const Orders: React.FC<Props> = ({ globalCurrentPage, setGlobalCurrentPage }) => {
    return (
        <CustomerAccountPage
            name="Orders"
            child={<MyOrders globalCurrentPage={globalCurrentPage} setGlobalCurrentPage={setGlobalCurrentPage} />}
        />
    );
};
