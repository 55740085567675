import React from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { ModalHeader } from 'src/components/ProductDetail/ModalHeader';
import { useOrderTrackingDetails } from 'src/components/Orders/hooks/useOrderTrackingDetails';
import { StaticSpinner } from 'src/components/Layout/Spinner';
import { DefaultMeta } from 'src/components/AppShell/Head';
import meta from 'src/components/Orders/orderTracking.json';
import { ContactSection } from 'src/components/Orders/ContactSection';
import { TrackingNotFound } from 'src/components/Orders/TrackingNotFound';
import { ModalTrackingFound } from 'src/components/Orders/ModalTrackingFound';

import { TrackingStates } from './utils/orderTrackingStaticValues';
import classes from './OrderTracking.scss';

type Props = {
    footprint: number;
    orderInfo?: { orderId: string; postcode: string };
    onDismiss: () => void;
    isModalOpen: boolean;
};

export const ModalOrderTracking: React.FC<Props> = ({ footprint, onDismiss, isModalOpen, orderInfo }) => {
    const {
        loading,
        trackingState,
        orderId,
        data,
        isLiveChatEnabled,
        openChatClickHandler,
        invoiceStatusesFromDPD,
        statusFromDPD,
    } = useOrderTrackingDetails(footprint, orderInfo);

    return (
        <Modal type="transparent" onDismiss={onDismiss} isOpen={isModalOpen}>
            <div className={classes.container}>
                <ModalHeader iconSrc="../../../../static/icons/aim.svg" title="Track Order" closeModal={onDismiss} />
                <div className={classes.orderTracking}>
                    <DefaultMeta data={meta.helmet} />
                    {loading && <StaticSpinner />}

                    {data && trackingState !== TrackingStates.NOT_FOUND && (
                        <div className={classes.orderTrackingCard}>
                            <ModalTrackingFound
                                data={data}
                                orderId={orderId}
                                invoiceStatusesFromDPD={invoiceStatusesFromDPD}
                                statusFromDPD={statusFromDPD}
                            />
                            <ContactSection
                                isLiveChatEnabled={isLiveChatEnabled}
                                openChatClickHandler={openChatClickHandler}
                            />
                        </div>
                    )}
                    {trackingState === TrackingStates.NOT_FOUND && (
                        <div className={classes.orderTrackingCard}>
                            <TrackingNotFound />
                            <ContactSection
                                isLiveChatEnabled={isLiveChatEnabled}
                                openChatClickHandler={openChatClickHandler}
                            />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    );
};
