import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "";
export const method = "POST";
export const operationId = "workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1UpdateCustomisationsPost";
export const path = "/V1/customisations/mine/updateItemCustomisations";
export interface Body {
    itemCustomisations: Definitions.WorkwearExpressCustomisationPlatformFrontendDataItemCustomisationInterface[];
}
export type Response = Response200 | Response401 | ResponseDefault;
export type Response200 = void;
export type Response401 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "postJSON">;
export function execute(body: Body, effects: MinimalEffects): Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, undefined), body, effects.restHeaders({})) as any;
}
