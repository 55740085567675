import classes from 'src/components/BasketShared/BasketShared.scss';
import { Svg } from 'src/components/Svg/Svg';
import { Fieldset } from 'src/components/Forms/Fieldset';
import { Input } from 'src/components/Forms/Input';
import { basicEmailValidation, basicPresenceValidation, telephoneValidation } from 'src/util/forms';
import { TextArea } from 'src/components/Forms/TextArea';
import React, { Dispatch, SetStateAction } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { Button } from 'src/components/Button/Button';
import { Actions } from 'src/components/Forms/Actions';
import { MessageType } from '@wearejh/m2-pwa-engine/lib/types';
import { Messages } from 'src/components/Messages/Messages';
import { BasketShareMachineValues } from 'src/components/BasketShared/ShareForm';
import { formTypes } from 'src/components/BasketShared/ShareForm';
import classnames from 'classnames';

export enum UserKind {
    Csp = 'Csp',
    Customer = 'Customer',
}

type Props = {
    onSubmit: (values: BasketShareMachineValues, isSharedEmail?: boolean) => void;
    isPending: boolean;
    errors: string | null;
    userKind: UserKind;
    setFormType: Dispatch<SetStateAction<formTypes>>;
    initialValue?: string;
};

export const ShareBasketViaEmailForm: React.FC<Props> = ({
    onSubmit,
    isPending,
    errors,
    userKind,
    setFormType,
    initialValue,
}) => (
    <div className={classes.container}>
        <Content />
        <Form
            data-test-id="ShareBasketForm"
            onSubmit={(values) => {
                onSubmit(values, true);
            }}
            initialValues={initialValue ? { collaboratorEmail: initialValue } : undefined}
        >
            {errors && (
                <div style={{ marginBottom: '20px' }}>
                    <Messages messages={[{ type: MessageType.Error, text: errors }]} />
                </div>
            )}
            <Inner isPending={isPending} userKind={userKind} isSharedEmail={Boolean(initialValue)} />
            <Actions primaryText="Send Link" direction="reverse">
                <Button onClick={() => setFormType(formTypes.chooseForm)} variant="reset">
                    <b>Back</b>
                </Button>
            </Actions>
        </Form>
    </div>
);

const labels: { [K in UserKind]: Record<string, string> } = {
    [UserKind.Customer]: {
        name: 'Your Name',
        email: 'Your Email Address',
        recipient_email: `Recipient's Email Address`,
    },
    [UserKind.Csp]: {
        name: 'Customer Name',
        mobile_number: 'Customer Mobile Number',
        recipient_email: `Customer Email Address`,
    },
};

type InnerProps = {
    userKind: UserKind;
    isPending: boolean;
    isSharedEmail?: boolean;
};

const Inner: React.FC<InnerProps> = ({ isPending, userKind, isSharedEmail = false }) => (
    <Fieldset disabled={isPending}>
        <Input
            label={labels[userKind].name}
            field="name"
            id="name"
            validate={basicPresenceValidation()}
            required={true}
        />
        {userKind === UserKind.Customer && (
            <Input
                label={labels[userKind].email}
                field="creatorEmail"
                id="creatorEmail"
                validate={basicEmailValidation()}
                required={true}
                placeholder="Email Address"
            />
        )}
        <div
            className={classnames(classes.collaboratorEmail, {
                [classes.hide]: isSharedEmail,
            })}
        >
            <Input
                label={labels[userKind].recipient_email}
                field="collaboratorEmail"
                id="collaboratorEmail"
                validate={basicEmailValidation()}
                required={true}
                placeholder="Email Address"
                disabled={isSharedEmail}
            />
        </div>
        {userKind === UserKind.Csp && (
            <Input
                label={labels[userKind].mobile_number}
                field="mobileNumber"
                id="mobileNumber"
                type="number"
                validate={telephoneValidation()}
                required={true}
            />
        )}
        <TextArea
            label="Message"
            field="message"
            id="message"
            placeholder="Optional"
            validate={basicPresenceValidation()}
            required={true}
        />
    </Fieldset>
);

const Content = () => (
    <>
        <p>
            <b>Email a link to your recipient and they will receive a copy of your basket.</b>
        </p>
        <ul className={classes.list}>
            <li className={classes.listItem}>
                <Svg name="alert" />
                <span>
                    The recipient’s basket will be a copy of yours and any changes made will only appear on their
                    basket.
                </span>
            </li>
            <li className={classes.listItem}>
                <Svg name="alert" />
                <span>The recipient will be able to view and use any customisations your have added.</span>
            </li>
            <li className={classes.listItem}>
                <Svg name="alert" /> <span>The basket link will remain active for 5 days.</span>
            </li>
        </ul>
    </>
);
