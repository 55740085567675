import React from 'react';
import { UserKind } from 'src/components/BasketShared/ShareBasketViaEmailForm';
import { useCspLoggedInToken } from 'src/components/CustomerServicePortal/hooks/useCspLogin';

export const defaultContext = {
    userKind: UserKind.Customer,
    token: null,
};

export const BasketCspContext = React.createContext<{
    userKind: UserKind;
    token: string | null;
}>(defaultContext);

export const BasketCspContextProvider: React.FC = (props) => {
    const token = useCspLoggedInToken();
    const api = {
        userKind: token ? UserKind.Csp : UserKind.Customer,
        token,
    };
    return <BasketCspContext.Provider value={api}>{props.children}</BasketCspContext.Provider>;
};
