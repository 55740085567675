import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "Returns information for the cart for a specified customer.";
export const method = "GET";
export const operationId = "quoteCartManagementV1GetCartForCustomerGet";
export const path = "/V1/carts/mine";
export type Response = Response200 | Response400 | Response401 | ResponseDefault;
export type Response200 = Definitions.QuoteDataCartInterface;
export type RespKeys = (keyof Definitions.QuoteDataCartInterface)[];
export type Response400 = Definitions.ErrorResponse;
export type Response401 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "getJSON">;
export function execute<T extends RespKeys | undefined>(effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.QuoteDataCartInterface, T[number]>> : Observable<Response200> {
    return effects.getJSON(effects.apiUrl(operationId)(path, undefined, fields), effects.restHeaders({})) as any;
}
