import React, { useMemo } from 'react';
import { useCountries } from '@wearejh/m2-pwa-addresses/lib/hooks/useCountries';
import { Fields } from '@wearejh/m2-pwa-addresses/lib/addresses.types';
import { Select as RxSelect } from '@wearejh/rx-form';
import { ErrorFor } from 'src/components/Forms/ErrorFor';

type Props = {
    onCountryChange(value);
    noLabel?: boolean;
    showFields?: boolean;
    onChange?: () => void;
};

export const CountryField: React.FC<Props> = ({ onCountryChange, noLabel = false, showFields, onChange }) => {
    const { countries } = useCountries();
    const options = useMemo(
        () =>
            countries.map((c) => ({
                label: c.full_name_english,
                value: c.id,
            })),
        [countries],
    );

    if (!showFields) {
        return null;
    }

    const handleChange = (value: string) => {
        onCountryChange(value);
        onChange?.();
    };

    return (
        <>
            {!noLabel && <label htmlFor={Fields.country_id}>Country</label>}
            <RxSelect
                field={Fields.country_id}
                id={Fields.country_id}
                options={options}
                onChange={(e) => handleChange(e.target.value)}
            />
            <ErrorFor field={Fields.country_id} />
        </>
    );
};
