import React, { HTMLAttributes } from 'react';

import classes from './SkeletonLine.scss';

type SkeletonLineProps = {
    width?: number;
} & HTMLAttributes<any>;

export const SkeletonLine: React.FC<SkeletonLineProps> = (props) => {
    return (
        <span
            className={classes.skeletonLine}
            {...props}
            style={(props.width && { width: `${props.width}em` }) || undefined}
        />
    );
};
