import React from 'react';
import { useDeps } from 'src/hooks/useDeps';
import { useDispatch } from 'react-redux';
import { Modal } from 'src/components/Modal/Modal';
import { useFormik } from 'formik';
import { TwoCol } from 'src/components/Layout';
import { BasketCopy } from 'src/components/Basket/BasketCopy';
import { handleCreateCustomisation } from 'src/components/Basket/utils/handleCreateCustomisation';
import { BasketCopySummary } from 'src/components/Basket/BasketCopySummary';
import { ModalHeader } from 'src/components/ProductDetail/ModalHeader';

import { CustomisationFull, Targets } from '../CustomisationPage/feature/customisation.types';

import classes from './CopyCustomisationModal.scss';

type Props = {
    isOpenModal: boolean;
    closeModal: () => void;
    customisation: CustomisationFull;
    locationNames: string[];
} & Targets;

export const CopyCustomisationModal: React.FC<Props> = ({
    isOpenModal,
    closeModal,
    customisation,
    validTargets,
    invalidTargets,
    locationNames,
}) => {
    const deps = useDeps();
    const dispatch = useDispatch();

    const formik = useFormik<string[]>({
        initialValues: [],
        onSubmit: (value) => {
            handleCreateCustomisation(
                { ...customisation, location_names: locationNames },
                value,
                deps,
                dispatch,
                false,
            );
            closeModal();
        },
    });

    const handleItemCheck = (itemId: string) => {
        const values = [...formik.values];
        if (values.includes(itemId)) {
            formik.setValues(values.filter((value) => value !== itemId));
        } else {
            values.push(itemId);
            formik.setValues(values);
        }
    };
    return (
        <Modal className={classes.modal} type="wide" isOpen={isOpenModal}>
            <form onSubmit={formik.handleSubmit}>
                <ModalHeader title="Copy logo to another garment" closeModal={closeModal} titleBig />
                <div className={classes.container}>
                    <TwoCol
                        main={
                            <BasketCopy
                                customisation={customisation}
                                locationNames={locationNames}
                                validTargets={validTargets}
                                invalidTargets={invalidTargets}
                                handleItemCheck={handleItemCheck}
                            />
                        }
                        right={<BasketCopySummary closeModal={closeModal} disabled={!Boolean(formik.values.length)} />}
                    />
                </div>
            </form>
        </Modal>
    );
};
