import { BasketItemCustomisation } from 'src/components/CustomisationPage/feature/customisation.types';
import { BasketCustomisationsMapping } from 'src/components/Basket/feature/basket.reducer';
import { CartItem } from '@wearejh/m2-pwa-cart-gql';
import { imagePath } from 'src/util/formatUrl';
import { CartItemType } from 'src/util/enums';

import { CartItemImages, EMPTY_IMAGE, getImageForItem } from './itemImagesFromQuery';
export interface ItemCustomisationMap {
    [index: number]: BasketItemCustomisation[];
}

/**
 * This represents a line-item, such as White / Small x 20
 * Which is a configuration.
 */
export interface ProductSummaryChild {
    labels: string[];
    qty: number;
}

/**
 * A tiny representaion of a product - mostly used
 * in summary lists such as checkout/basket
 */
export interface ProductSummary {
    name: string;
    image: string;
    sku: string;
    items: ProductSummaryChild[];
}

/**
 * Because some customisations are applied in multiple place
 * this will handle re-grouping them for easier output
 * @param items
 */
export function groupById(items: BasketItemCustomisation[]): ItemCustomisationMap {
    const group: { [index: number]: BasketItemCustomisation[] } = {};
    items.forEach((item) => {
        if (!group[item.id]) group[item.id] = [];
        group[item.id].push(item);
    });
    return group;
}

export function hasCustomisations(customisations: BasketCustomisationsMapping): boolean {
    return Object.keys(customisations).some((key) => customisations[key]?.customisations.length > 0);
}

export type CartItemWithCustomisations = {
    cartItem: CartItem;
    customisations: BasketItemCustomisation[];
};
export function addCustomisationsAndImagesToCartItems(
    cartItems: CartItem[],
    customisations: BasketCustomisationsMapping,
    images: CartItemImages,
): CartItemWithCustomisations[] {
    const cartItemsWithNoVirutalItem = cartItems.filter(
        (item) => String(item.__typename) !== CartItemType.VirtualCartItem,
    );
    return cartItemsWithNoVirutalItem.map((cartItem) => {
        const match = customisations[cartItem.id];
        const nextCartItem = {
            ...cartItem,
            product: {
                ...cartItem.product,
                small_image: images.get(cartItem.id) || EMPTY_IMAGE,
            },
        };
        if (match) {
            return {
                cartItem: nextCartItem,
                customisations: match.customisations,
            };
        }
        return {
            cartItem: nextCartItem,
            customisations: [],
        };
    });
}

export function cartItemToProductSummary(cartItem: CartItem): ProductSummary {
    const image = getImageForItem(cartItem as any);
    return {
        items:
            cartItem.__typename === 'ConfigurableCartItem' || cartItem.__typename === 'BundleCartItem'
                ? [
                      {
                          qty: cartItem.quantity,
                          labels:
                              cartItem.__typename === 'ConfigurableCartItem'
                                  ? cartItem.configurable_options.map((x) => x.value_label)
                                  : [],
                      },
                  ]
                : [],
        name: cartItem.product.name,
        image: imagePath(image.url),
        sku: cartItem.product.sku,
    };
}
