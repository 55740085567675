import React from 'react';
import { Link } from 'react-router-dom';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';

import { stripDomain } from '../../util/sanitizeLink';

export interface OrderDetailInfoAddress {
    name?: string;
    url?: string;
    firstname: string;
    lastname: string;
    company?: string;
    street?: string[];
    address_line1?: string;
    address_line2?: string;
    address_line3?: string;
    city: string;
    postcode: string;
    region?: string;
    telephone: string;
    email?: string;
}

type OrderDetailAddressProps = {
    address: OrderDetailInfoAddress;
    loading?: boolean;
};

export const OrderDetailAddress: React.FC<OrderDetailAddressProps> = (props) => {
    if (props.loading)
        return (
            <address>
                <SkeletonLine width={5} />
                <br />
                <SkeletonLine width={7} />
                <br />
                <SkeletonLine width={6} />
                <br />
                <br />
                <SkeletonLine width={9} />
            </address>
        );

    return (
        <address>
            {props.address.name && props.address.url && (
                <>
                    <Link to={stripDomain(props.address.url)}>{props.address.name}</Link> <br />
                </>
            )}
            {props.address.firstname && props.address.firstname}{' '}
            {props.address.lastname && (
                <>
                    {props.address.lastname}
                    <br />
                </>
            )}
            {props.address.company && (
                <>
                    {props.address.company} <br />
                </>
            )}
            {props.address.street &&
                props.address.street.map((item, index) => {
                    return (
                        <React.Fragment key={item}>
                            <span key={index}>{item}</span>
                            <br />
                        </React.Fragment>
                    );
                })}
            {props.address.city && (
                <>
                    {props.address.city} <br />
                </>
            )}
            {props.address.address_line1 && (
                <>
                    {props.address.address_line1} <br />
                </>
            )}
            {props.address.address_line2 && (
                <>
                    {props.address.address_line2} <br />
                </>
            )}
            {props.address.address_line3 && (
                <>
                    {props.address.address_line3} <br />
                </>
            )}
            {props.address.region && (
                <>
                    {props.address.region} <br />
                </>
            )}
            {props.address.postcode && (
                <>
                    {props.address.postcode} <br />
                </>
            )}
            {props.address.telephone && (
                <>
                    <br />
                    {props.address.telephone}
                </>
            )}
        </address>
    );
};
