import React from 'react';
import { OrdersConnected } from 'src/components/Orders/OrdersConnected';
import { CustomerAccountPage } from 'src/components/Customer/CustomerAccountPage';
import { DefaultMeta } from 'src/components/AppShell/Head';
import meta from 'src/components/Customer/customer.json';
import { Basket } from 'src/components/SavedBasket/Basket';
import { BasketContextProvider } from 'src/components/Basket/BasketContext';
import { BasketCspContextProvider } from 'src/components/Basket/BasketCspContext';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { basketRegister } from 'src/components/Basket/feature/basket.register';

import classes from './SavedBasket.scss';

export const MySavedBasket: React.FC = React.memo(() => {
    const ready = useLazyRegister(basketRegister, true, 'Basket');
    if (!ready) return null;
    return (
        <>
            <DefaultMeta data={meta.helmet.order} />
            <div className={classes.container}>
                <h2>Saved Basket</h2>
                <BasketContextProvider>
                    <BasketCspContextProvider>
                        <Basket />
                    </BasketCspContextProvider>
                </BasketContextProvider>
            </div>
        </>
    );
});

export const SavedBasket: React.FC = () => {
    return <CustomerAccountPage name="Basket" child={<OrdersConnected component={MySavedBasket} />} />;
};
