import React from 'react';
import classnames from 'classnames';
import { pageRange } from 'src/components/Orders/utils/pageRange';
import { Link } from 'react-router-dom';

import classes from './CustomPagination.scss';

type Props = {
    numberOfResults: number;
    currentPage: number;
    numberOfResultsPerPage: number;
    setNumberOfResultsPerPage?: (numberOfResultsPerPage: number) => void;
    host: string;
};

export const CustomPagination: React.FC<Props> = ({
    numberOfResults,
    currentPage,
    numberOfResultsPerPage,
    host,
    setNumberOfResultsPerPage,
}) => {
    const numberOfPages = Math.ceil(numberOfResults / numberOfResultsPerPage);
    const pages = Array.from(Array(numberOfPages).keys()).slice(
        pageRange(currentPage, numberOfPages).start - 1,
        pageRange(currentPage, numberOfPages).end,
    );

    return (
        <div className={classes.pagination}>
            {setNumberOfResultsPerPage && <div className={classes.emptyContainer} />}
            <div className={classes.paginationActions}>
                {currentPage === 1 ? (
                    <span className={classes.disabled}>Previous</span>
                ) : (
                    <Link to={`/${host}?page=${currentPage - 1}`} className={classes.link}>
                        Previous
                    </Link>
                )}
                {pages.map((value, key) => (
                    <Link
                        key={key}
                        to={`/${host}?page=${value + 1}`}
                        className={classnames(classes.pageNumber, {
                            [classes.active]: currentPage - 1 === value,
                        })}
                    >
                        {value + 1}
                    </Link>
                ))}
                {currentPage === numberOfPages ? (
                    <span className={classes.disabled}>Next</span>
                ) : (
                    <Link to={`/${host}?page=${currentPage + 1}`} className={classes.link}>
                        Next
                    </Link>
                )}
            </div>
            {setNumberOfResultsPerPage && (
                <form className={classes.selectResults}>
                    <select
                        value={numberOfResultsPerPage}
                        onChange={(value) => {
                            setNumberOfResultsPerPage(Number(value.target.value));
                        }}
                    >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                    </select>
                </form>
            )}
        </div>
    );
};
