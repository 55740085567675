import { getCart_getCart } from 'src/queries/__generated__/getCart';

export type CsrItemDiscounts = Map<string, number>;
export const CSR_DISCOUNT = new Map<string, number>();

/**
 * Extract a POSSIBLE CSR discount - ensure it's always a number
 * @param raw
 */
export function csrItemDiscount(raw: getCart_getCart | null): CsrItemDiscounts {
    if (!raw) return CSR_DISCOUNT;
    if (!raw?.items) return CSR_DISCOUNT;
    if (raw.items.length === 0) return CSR_DISCOUNT;
    return new Map(
        raw.items.map((item) => {
            if (!item) throw new Error('null found in cart item');
            return [item!.id, item.csr_discount ?? 0];
        }),
    );
}
