import React from 'react';
import { WorkwearExpressArtworkDataArtworkApprovalCompositeInterface } from 'swagger/ts/Definitions';
import {
    applicationMethodFromInt,
    ApplicationType,
} from 'src/components/CustomisationPage/feature/customisation.types';
import classes from 'src/components/Orders/CustomerOrders.scss';
import { Status } from 'src/components/Orders/Status';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useDeps } from 'src/hooks/useDeps';
import { ArtworkStatus } from 'src/components/Orders/utils/order.utils';

import { CustomisationSummary } from '../CustomisationPage/CustomisationSummary';

type Props = {
    artwork_approvals: any;
    loading?: boolean;
};

export const OrderApprovals: React.FC<Props> = (props) => {
    const buttonText = {
        [ArtworkStatus.ReviewPending]: 'Artwork approval required',
        [ArtworkStatus.RequestPending]: 'Change request pending',
        [ArtworkStatus.ArtworkApproved]: 'Artwork Approved',
        [ArtworkStatus.Processing]: 'View Artwork',
    };
    const { paths } = useDeps();
    return (
        <div>
            <h2>Your Artwork</h2>
            <p className={classes.intro}>
                Your artwork must be approved before your order can be produced and delivered.
            </p>
            <table className={classes.root}>
                <tbody>
                    {cleanArtworks(props.artwork_approvals).map((item) => {
                        return (
                            <>
                                <tr className={classes.order} data-artworkStatus={item.status}>
                                    <td>
                                        <CustomisationSummary
                                            customisation={item.artwork}
                                            locationNames={item.location_name}
                                        />
                                    </td>
                                    <td>
                                        <div className={classes.status}>
                                            <Status status={item.status} />
                                        </div>
                                    </td>
                                    <td>
                                        <div className={classes.actions}>
                                            <Link
                                                to={`${paths.customer.orders}/${item.orderId}/artwork/${item.id}`}
                                                data-artworkStatus={item.status}
                                                className={classnames({
                                                    ['button']: true,
                                                    ['button--light']: true,
                                                    [classes.orderLink]: true,
                                                })}
                                            >
                                                {buttonText[item.status]}
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                                <tr className={classes.spacer}>
                                    <td colSpan={3}></td>
                                </tr>
                            </>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export const getPendingStatusName = (status: string) => {
    switch (status) {
        case 'proof_sent':
        case 'chase':
            return ArtworkStatus.ReviewPending;
        case 'reproof':
            return ArtworkStatus.RequestPending;
        case 'approved':
            return ArtworkStatus.ArtworkApproved;
        default:
            return ArtworkStatus.Processing;
    }
};

export function cleanArtworks(artworks: WorkwearExpressArtworkDataArtworkApprovalCompositeInterface[]) {
    return artworks.map((artwork) => {
        const status = getPendingStatusName(artwork.status);
        return {
            status: status,
            location_name: [artwork.location_name],
            id: artwork.id,
            orderId: artwork.order_id,
            artwork: {
                application_method: applicationMethodFromInt(artwork.type),
                application_type: artwork.artwork.image ? ApplicationType.Logo : ApplicationType.Text,
                textLineOne: artwork.artwork.text_line_one || '',
                textLineTwo: artwork.artwork.text_line_two || '',
                textLineThree: artwork.artwork.text_line_three || '',
                color: artwork.artwork.color || '',
                font: artwork.artwork.font || '',
                image: artwork.artwork.image,
                customisationName: artwork.artwork.customisation_name || '',
                specialInstructions: artwork.artwork.special_instructions || '',
                isContactRequested: artwork.artwork.is_contact_requested,
            },
            comments: artwork.comments || [],
            samples: artwork.samples || [],
            status_update_allowed: artwork.status_update_allowed,
        };
    });
}
