import React, { LabelHTMLAttributes } from 'react';
import { Checkbox } from '@wearejh/rx-form/lib/RxCheckbox';
import { Radio } from '@wearejh/rx-form/lib/RxRadioGroup';
import { CustomisationLocation } from 'src/components/CustomisationPage/CustomisationLocation';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import classnames from 'classnames';

import classes from './CustomCheckbox.scss';

type Props = {
    id: string;
    field: string;
    title: string;
    methodName?: any;
    availableMethods?: ApplicationMethod;
    image?: string;
    isBundle?: boolean;
    isDisabled?: boolean;
    fieldIndex?: number;
    oneOptionSelect?: boolean;
} & LabelHTMLAttributes<any>;

export const CustomCheckbox: React.FC<Props> = ({
    fieldIndex,
    id,
    field,
    title,
    availableMethods,
    image,
    isBundle,
    isDisabled,
    oneOptionSelect,
    methodName,
    ...rest
}) => {
    return (
        <div
            className={classnames(classes.container2, {
                [classes.notBundleContainer]: !isBundle,
            })}
        >
            {isBundle && <Radio id={fieldIndex ? fieldIndex + id : id} value={id} className={classes.input} />}
            {!isBundle && isDisabled && <p className={classes.locationDisabled}>Location used in other logo</p>}
            {!isBundle && !isDisabled && (
                <>
                    {oneOptionSelect ? (
                        <Radio id={fieldIndex ? fieldIndex + id : id} value={id} className={classes.inputHidden} />
                    ) : (
                        <Checkbox id={id} field={field} className={classes.inputHidden} disabled={isDisabled} />
                    )}
                    <img src="../../../static/icons/ok.png" className={classes.checkmark}></img>
                </>
            )}

            <label
                className={classes.label}
                htmlFor={fieldIndex ? fieldIndex + id : id}
                {...rest}
                data-status={isDisabled}
            >
                <CustomisationLocation
                    image={image}
                    title={title}
                    availableMethods={availableMethods}
                    isBundle={isBundle}
                    isDisabled={isDisabled}
                    methodName={methodName}
                />
            </label>
        </div>
    );
};
