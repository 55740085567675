import React from 'react';
import { CustomerOrder } from 'src/components/Orders/CustomerOrder';
import { Searcher } from 'src/components/Orders/Searcher';
import { Order } from 'src/features/app/orders.register';
import { CustomPagination } from 'src/components/CustomPagination/CustomPagination';

import classes from './CustomerOrders.scss';

type Props = {
    orders: Order[];
    showPagination?: boolean;
    page: number;
    totalCount: number;
    numberOfResultsPerPage: number;
    setNumberOfResultsPerPage: (numberOfResultsPerPage: number) => void;
    setFilterResults: (filterResults: string) => void;
    filterResults: string;
};

export const CustomerOrders: React.FC<Props> = ({
    orders,
    showPagination,
    page,
    totalCount,
    numberOfResultsPerPage,
    setNumberOfResultsPerPage,
    setFilterResults,
    filterResults,
}) => (
    <div className={classes.wrapper}>
        <div className={classes.header}>
            <p className={classes.title}>My Orders</p>
            {showPagination && (
                <div className={classes.searcher}>
                    <Searcher
                        onSubmit={(searchResults) => setFilterResults(searchResults)}
                        filterResults={filterResults}
                    />
                </div>
            )}
        </div>
        <div className={classes.orders}>
            <div className={classes.ordersHeader}>
                <span>Date placed</span>
                <span>Type</span>
                <span>Order ID</span>
                <span>Items</span>
                <span>Total</span>
                <span></span>
            </div>
            {orders?.map((order) => (
                <CustomerOrder key={order.number} {...order} />
            ))}
        </div>
        {showPagination && (
            <CustomPagination
                numberOfResults={totalCount}
                currentPage={page}
                numberOfResultsPerPage={numberOfResultsPerPage}
                setNumberOfResultsPerPage={setNumberOfResultsPerPage}
                host="customer/orders"
            />
        )}
    </div>
);
