import { asyncScheduler, concat, Observable, of, scheduled } from 'rxjs';
import { ofType } from 'redux-observable';
import { mapTo, mergeMap } from 'rxjs/operators';
import { createStateFromCustomisation } from 'src/components/CustomisationPage/feature/customisation.types';

import { Actions, CustomisationMsg, TypeMap } from '../customisation.actions';
import { Deps } from '../../../../types/global-types';

export function artworkSubmitNew(action$: Observable<any>, _state$: Observable<any>, deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.ArtworkSubmitNew']>('Customisation.ArtworkSubmitNew'),
        mapTo(deps.push(deps.paths.customisation.type)),
    );
}

export function artworkSubmitExisting(
    action$: Observable<any>,
    _state$: Observable<any>,
    _deps: Deps,
): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.ArtworkSubmitExisting']>('Customisation.ArtworkSubmitExisting'),
        mergeMap(({ payload }) => {
            const nextPayload = createStateFromCustomisation(payload);
            return concat(
                of(CustomisationMsg('Customisation.FromArtwork', nextPayload)),
                scheduled(of(CustomisationMsg('Customisation.CustomisationSubmit', true)), asyncScheduler),
            );
        }),
    );
}
