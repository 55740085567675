import React from 'react';

import { ModalHeader } from '../../ProductDetail/ModalHeader';

import { CustomisationModalMenu } from './CustomisationModalMenu';
import classes from './MultipleStepCustomisation.scss';

type Props = {
    image: string;
    closeModal: () => void;
    titleModal: string;
    isFinalStep?: boolean;
    stepName: string;
};

export const CustomisationModalWrapper: React.FC<Props> = ({
    children,
    image,
    closeModal,
    titleModal,
    isFinalStep,
    stepName,
}) => (
    <div className={classes.customisationModalWrapper}>
        <ModalHeader imageUrl={image} title={titleModal} titleMobile="Add logo" closeModal={closeModal} />
        <div className={classes.customisationBody}>
            <CustomisationModalMenu name={stepName} isFinalStep={isFinalStep} titleModal={titleModal} />
            {children}
        </div>
    </div>
);
