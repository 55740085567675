import { useCallback, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { AppendState as UserAppendState } from '@wearejh/m2-pwa-user';
import { AppendState, EditMsg, EditParams } from '@wearejh/m2-pwa-user-edit/lib/user-edit.register';

export interface UseEditUserApi {
    edit(values: EditParams);
    reset();
    status: DataStatus;
    messages: Message[];
    data: EditParams;
}

const selector = (s: AppendState) => s['user-edit'];
const dataSelector = (s: AppendState<UserAppendState>) => {
    return {
        firstname: s.user.data?.firstname,
        lastname: s.user.data?.lastname,
        email: s.user.data?.email,
        extensionAttributes: s.user.data?.extension_attributes,
    };
};

export function useEditUser(): UseEditUserApi {
    const dispatch = useDispatch();
    const { status, messages } = useSelector(selector, shallowEqual);
    const { firstname, lastname, email, extensionAttributes } = useSelector(dataSelector, shallowEqual);
    const data = useMemo(() => ({ firstname, lastname, email, extensionAttributes }), [
        email,
        firstname,
        lastname,
        extensionAttributes,
    ]);
    const edit = useCallback(
        (values: EditParams) => {
            dispatch(EditMsg('Edit.Submit', values));
        },
        [dispatch],
    );
    useMemo(() => ({ firstname, lastname, email }), [email, firstname, lastname]);
    const reset = useCallback(() => {
        dispatch(EditMsg('Edit.Reset'));
    }, [dispatch]);
    return useMemo(() => {
        return {
            edit,
            messages,
            data,
            status,
            reset,
        };
    }, [data, edit, messages, reset, status]);
}
