import { Observable, concat, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, concatMap, mergeMap, pluck, withLatestFrom } from 'rxjs/operators';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { CartVariant } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { execute as saveGuestCustomisation } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1SaveGuestCustomisationsPost';
import { execute as saveAccountCustomisation } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1SaveCustomisationsPost';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { Deps } from 'src/types/global-types';
import { extractError } from 'src/util/errors';
import { execute as accountUpdate } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1UpdateCustomisationsPost';
import { execute as guestUpdate } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1UpdateGuestCustomisationsPost';

import { Actions, TypeMap, CustomisationMsg, AppendState, EntryKind } from '../customisation.actions';

export function customisationSubmit(action$: Observable<any>, state$: Observable<any>, deps: Deps) {
    const customisation$ = state$.pipe(pluck<AppendState, 'customisation'>('customisation'));

    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.CustomisationSubmit']>('Customisation.CustomisationSubmit'),
        withLatestFrom(customisation$, cartVariantAndId(state$)),
        concatMap(([{}, customisation, [variant, cartId]]) => {
            const customisationPayload = [
                {
                    itemIds: customisation.item_ids,
                    locationNames: customisation.locationNames,
                    customisation: {
                        customisationName: customisation.customisationName || '',
                        image: customisation.image || '',
                        type: customisation.application_method,
                        isContactRequested: customisation.isContactRequested,
                        specialInstructions: customisation.specialInstructions || '',
                        textLineOne: customisation.textLineOne || '',
                        textLineTwo: customisation.textLineTwo || '',
                        textLineThree: customisation.textLineThree || '',
                        color: customisation.color || '',
                        font: customisation.font || '',
                        id: customisation.id ?? undefined,
                        artworkId: customisation.artwork_id ?? undefined,
                        is_artwork_missing: customisation.is_artwork_missing ?? false,
                        is_free: customisation.is_free ?? false,
                    },
                },
            ];

            const ajax$ =
                customisation.entry_kind === EntryKind.New
                    ? getNewAjax(variant, String(cartId), customisationPayload, deps)
                    : getAjaxEdit(variant, String(cartId), customisationPayload, deps);

            return concat(
                of(CustomisationMsg('Customisation.SetStatus', DataStatus.Pending)),
                ajax$.pipe(
                    mergeMap((_resp) => {
                        return concat(
                            of(
                                CustomisationMsg('Customisation.CustomisationSuccess', true),
                                deps.push(deps.paths.basket.basket),
                            ),
                        );
                    }),
                    catchError((err) => {
                        return of(CustomisationMsg('Customisation.CustomisationError', extractError(err)));
                    }),
                ),
            );
        }),
    );
}

export function getNewAjax(cartVariant: CartVariant, cartId: string, payload, deps: Deps) {
    const ajax$ =
        cartVariant === CartVariant.Guest
            ? saveGuestCustomisation({ cartId: String(cartId) }, { itemCustomisations: payload } as any, deps)
            : saveAccountCustomisation(
                  {
                      itemCustomisations: payload as any,
                  },
                  deps,
              );
    return ajax$;
}

function getAjaxEdit(cartVariant: CartVariant, cartId: string, payload, deps: Deps) {
    const ajax$ =
        cartVariant === CartVariant.Guest
            ? guestUpdate({ cartId }, { itemCustomisations: payload }, deps)
            : accountUpdate({ itemCustomisations: payload }, deps);
    return ajax$;
}
