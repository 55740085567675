import React, { useEffect } from 'react';
import { Dropdown, Option } from 'src/components/Forms/Dropdown';
import { Fields } from 'src/components/CustomisationPage/feature/customisation.types';
import { Colour, Font, useFontPreview } from 'src/components/CustomisationPage/utils/fontPreview';
import { Input } from 'src/components/Forms/Input';
import { basicPresenceValidation } from 'src/util/forms';
import { Fieldset } from 'src/components/Forms/Fieldset';
import { Skeleton } from 'src/components/Common/Skeleton';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';
import { Customisation, SelectedOptions } from 'src/components/BundleProduct/types';

import { TextPreview } from './TextPreview';
import { AdditionalNotes } from './AdditionalNotes';
import classes from './SelectText.scss';

type SelectTextProps = {
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedOptions: SelectedOptions;
};

export const SelectText: React.FC<SelectTextProps> = ({
    backgroundStyles,
    handleCustomisationSet,
    selectedOptions,
}) => {
    const { fonts, pending, colours } = useFontPreview();

    if (pending) return <Skeleton />;

    return (
        <FontPreview
            fonts={fonts}
            colours={colours}
            pending={pending}
            backgroundStyles={backgroundStyles}
            handleCustomisationSet={handleCustomisationSet}
            selectedOptions={selectedOptions}
        />
    );
};

type FontPreviewProps = {
    fonts: Font[];
    colours: Colour[];
    pending: boolean;
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedOptions: SelectedOptions;
};

const FontPreview: React.FC<FontPreviewProps> = ({
    fonts,
    colours,
    backgroundStyles,
    handleCustomisationSet,
    selectedOptions,
}) => {
    const fontOptions: Option[] = fonts.map((f) => ({
        image: f.preview,
        name: f.name,
        value: f.name,
    }));
    const colourOptions: Option[] = colours.map((c) => ({
        name: c.name,
        value: c.name,
        swatch: '#' + c.fg_colour,
    }));

    useEffect(() => {
        if ('textLineOne' in selectedOptions) {
            handleCustomisationSet({
                selectedOptions: {
                    ...selectedOptions,
                    font: fonts[0].name,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if ('textLineOne' in selectedOptions) {
        const validFont = fonts.find((f) => f.name === selectedOptions?.font);
        const validColor = colours.find((f) => f.name === selectedOptions?.color);

        return (
            <Fieldset>
                <div className={classes.wrapper} style={backgroundStyles}>
                    <Input
                        label="Text Line 1"
                        field={Fields.textLineOne}
                        id={Fields.textLineOne}
                        validate={basicPresenceValidation()}
                        required
                        placeholder="e.g Workwear Express"
                        onChange={(e) => {
                            handleCustomisationSet({
                                selectedOptions: {
                                    ...selectedOptions,
                                    textLineOne: e.target.value,
                                },
                            });
                        }}
                    />
                    <Input
                        label="Text Line 2"
                        field={Fields.textLineTwo}
                        id={Fields.textLineTwo}
                        optional
                        onChange={(e) => {
                            handleCustomisationSet({
                                selectedOptions: {
                                    ...selectedOptions,
                                    textLineTwo: e.target.value,
                                },
                            });
                        }}
                    />
                    <Input
                        label="Text Line 3"
                        field={Fields.textLineThree}
                        id={Fields.textLineThree}
                        optional
                        onChange={(e) => {
                            handleCustomisationSet({
                                selectedOptions: {
                                    ...selectedOptions,
                                    textLineThree: e.target.value,
                                },
                            });
                        }}
                    />
                    <div className={classes.action}>
                        <div className={classes.dropdown}>
                            <Dropdown
                                options={fontOptions}
                                label="Font"
                                field={Fields.font}
                                onChange={(value) => {
                                    handleCustomisationSet({
                                        selectedOptions: {
                                            ...selectedOptions,
                                            font: value,
                                        },
                                    });
                                }}
                                initialValue={validFont ? validFont.name : fonts[0].name}
                                showOnlyImage
                            />
                        </div>
                        <div className={classes.dropdown}>
                            <Dropdown
                                options={colourOptions}
                                label="Colour"
                                field={Fields.color}
                                onChange={(value) => {
                                    handleCustomisationSet({
                                        selectedOptions: {
                                            ...selectedOptions,
                                            color: value,
                                        },
                                    });
                                }}
                                initialValue={validColor?.name || 'White'}
                            />
                        </div>
                    </div>
                </div>
                <div className={classes.wrapper} style={backgroundStyles}>
                    <TextPreview selectedOptions={selectedOptions} fonts={fonts} />
                </div>
                <AdditionalNotes
                    backgroundStyles={backgroundStyles}
                    handleCustomisationSet={handleCustomisationSet}
                    selectedOptions={selectedOptions}
                />
            </Fieldset>
        );
    } else return null;
};
