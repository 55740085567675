import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { StatusMessageSkeleton, StatusMessageMobileSkeleton } from 'src/elements/Skeletons/Skeletons';

import { STEPS_ARRAY, WidgetSteps, WidgetStepsNoLogo, ORDER_STATUSES } from './utils/OrderStatusWidget';
import classes from './OrderStatusWidget.scss';

type Props = {
    orderStatus?: string;
    setOrderShipped: React.Dispatch<React.SetStateAction<boolean>>;
    setIsArtworkEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
    hasArtwork?: boolean;
    statusMessage?: string;
    loading?: boolean;
};

const setSteps = (status, hasArtwork) => {
    if (status && !hasArtwork)
        return STEPS_ARRAY.filter((step) => step !== 'Ticking Along' && step !== 'Applying Logos');
    if (status && hasArtwork) return STEPS_ARRAY.filter((step) => step !== 'Ticking Along');

    if (!status && !hasArtwork) return STEPS_ARRAY.filter((step) => step !== 'Applying Logos');

    return STEPS_ARRAY;
};

const pickStepNr = (status: string | null, setStepNr: React.Dispatch<React.SetStateAction<number>>, widgetSteps) => {
    if (!status) return setStepNr(widgetSteps.ProcessingOrder);
    if (ORDER_STATUSES.processingOrder.includes(status)) setStepNr(widgetSteps.ProcessingOrder);
    if (ORDER_STATUSES.awaitingStock.includes(status)) setStepNr(widgetSteps.AwaitingStock);
    if (ORDER_STATUSES.gettingSetUp.includes(status)) setStepNr(widgetSteps.GettingSetUp);
    if (ORDER_STATUSES.embroidering.includes(status)) setStepNr(widgetSteps.Embroidering);
    if (ORDER_STATUSES.packingOrder.includes(status)) setStepNr(widgetSteps.PackingOrder);
    if (ORDER_STATUSES.orderShipped.includes(status)) setStepNr(widgetSteps.OrderShipped);
};

export const OrderStatusWidget: React.FC<Props> = ({
    orderStatus,
    setOrderShipped,
    setIsArtworkEnabled,
    hasArtwork,
    statusMessage,
    loading = false,
}) => {
    const status = orderStatus ? orderStatus?.toLowerCase() : null;
    const [stepNr, setStepNr] = useState(0);
    const steps = setSteps(status, hasArtwork);
    const widgetSteps = hasArtwork ? WidgetSteps : WidgetStepsNoLogo;
    const [isOpen, setIsOpen] = useState(true);

    useEffect(() => {
        pickStepNr(status, setStepNr, widgetSteps);
    }, [status, widgetSteps]);

    useEffect(() => {
        if (
            (hasArtwork && status && stepNr > widgetSteps.ProcessingOrder) ||
            (hasArtwork && status && ORDER_STATUSES.artworkApproval.includes(status))
        ) {
            setIsArtworkEnabled?.(true);
        }

        if (stepNr === widgetSteps.OrderShipped) setOrderShipped(true);
    }, [
        hasArtwork,
        setIsArtworkEnabled,
        setOrderShipped,
        status,
        stepNr,
        widgetSteps.OrderShipped,
        widgetSteps.ProcessingOrder,
    ]);

    const allStepsAreDone =
        steps.filter((step, index) => Boolean(index < stepNr || stepNr === widgetSteps.OrderShipped)).length ===
        steps.length;

    return (
        <>
            <div className={classes.widgetContainer}>
                {steps.map((step, index) => {
                    const isActive = index === stepNr && stepNr !== widgetSteps.OrderShipped;
                    const isDone = index < stepNr || stepNr === widgetSteps.OrderShipped;
                    const lastAndDone = allStepsAreDone && index === steps.length - 1;

                    return (
                        <div
                            key={index}
                            className={classnames(classes.step, {
                                [classes.stepActive]: isActive,
                                [classes.isOpen]: Boolean(
                                    isOpen && (statusMessage || loading) && (isActive || lastAndDone),
                                ),
                            })}
                        >
                            <div className={classes.stepImageContainer}>
                                {!isDone && (
                                    <div
                                        className={classnames(classes.stepNr, {
                                            [classes.stepNrActive]: isActive,
                                        })}
                                    >
                                        {index + 1}
                                    </div>
                                )}
                                {isDone && <img className={classes.stepImage} src="../../../static/icons/ok.svg" />}
                            </div>
                            <span>{step}</span>
                            <div className={classes.statusMessageContainerMobile}>
                                {Boolean(isActive || lastAndDone) && (
                                    <>
                                        {loading && <StatusMessageMobileSkeleton />}
                                        {Boolean(statusMessage && !loading) && (
                                            <>
                                                <div
                                                    className={classnames(classes.arrow, {
                                                        [classes.isOpen]: isOpen,
                                                    })}
                                                    onClick={() => setIsOpen(!isOpen)}
                                                >
                                                    <img src={require('../../../static/img/orange-arrow-down.png')} />
                                                </div>
                                                <div className={classes.mobileStatusMessage}>{statusMessage}</div>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    );
                })}
            </div>
            <div
                className={classnames(classes.statusMessageContainer, {
                    [classes.empty]: Boolean(!loading && !statusMessage),
                })}
            >
                {loading && <StatusMessageSkeleton />}
                {Boolean(statusMessage && !loading) && (
                    <div className={classes.statusMessage}>
                        <h3>Order Activity</h3>
                        <div className={classes.status}>
                            <img
                                className={classes.orangeButton}
                                src={require('../../../static/icons/v-right_orange.svg')}
                            />
                            <p>{statusMessage}</p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
