import { ActionMap, createMsg } from 'action-typed';
import { WorkwearExpressArtworkDataArtworkApprovalCompositeInterface } from 'swagger/ts/Definitions';
import { DataStatus, Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';
import { getArtworkApprovalEpic } from 'src/components/ArtworkApproval/feature/epics/getArtworkApprovalEpic.epic';
import { getArtworkGuestApprovalEpic } from 'src/components/ArtworkApproval/feature/epics/getArtworkGuestApprovalEpic.epic';

export type Messages = {
    ['Artwork.SetStatus']: DataStatus;
    ['Artwork.Fetch']: { artworkApprovalId: number };
    ['ArtworkGuest.Fetch']: { token: string };
    ['Artwork.Success']: WorkwearExpressArtworkDataArtworkApprovalCompositeInterface;
    ['Artwork.Error']: string;
};

export type ArtworkState = {
    status: DataStatus;
    artworkData: WorkwearExpressArtworkDataArtworkApprovalCompositeInterface;
    messages: Message[];
};

const initialState = {
    status: DataStatus.Idle,
    messages: [],
    artworkData: {
        id: 0,
        status: 'Pending',
        location_name: '',
        type: 0,
        order_id: 0,
        comments: [],
        artwork: {
            id: 0,
            customisation_name: '',
            image: '',
            is_contact_requested: false,
            special_instructions: '',
            text_line_one: '',
            text_line_two: '',
            text_line_three: '',
            color: '',
            font: '',
        },
        status_update_allowed: false,
        samples: [''],
    },
};

export function artworkApprovalRegister() {
    return {
        epics: [getArtworkApprovalEpic, getArtworkGuestApprovalEpic],
        reducers: {
            artworkApproval: function (state: ArtworkState = initialState, action: Actions): ArtworkState {
                switch (action.type) {
                    case 'Artwork.SetStatus': {
                        return {
                            ...state,
                            status: action.payload,
                        };
                    }
                    case 'Artwork.Fetch': {
                        return {
                            ...state,
                            messages: [],
                            status: DataStatus.Pending,
                        };
                    }
                    case 'ArtworkGuest.Fetch': {
                        return {
                            ...state,
                            messages: [],
                            status: DataStatus.Pending,
                        };
                    }
                    case 'Artwork.Success': {
                        return {
                            ...state,
                            artworkData: action.payload,
                            status: DataStatus.Success,
                        };
                    }
                    case 'Artwork.Error': {
                        return {
                            ...state,
                            messages: [{ type: MessageType.Error, text: action.payload }],
                            status: DataStatus.Error,
                        };
                    }
                    default:
                        return state;
                }
            },
        },
        name: 'artworkApproval',
    };
}

export const Msg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
export type AppendState<T> = T & { artworkApproval: ArtworkState };
