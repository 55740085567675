import React from 'react';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { Fieldset } from 'src/components/Forms/Fieldset';
import classnames from 'classnames';
import { MethodOption } from 'src/components/CustomisationPage/steps/SelectMethod/MethodOption';
import { Customisation } from 'src/components/BundleProduct/types';

import classes from './SelectMethod.scss';

type SelectMethodProps = {
    recommendedApplication?: ApplicationMethod;
    selectedMethod?: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
};
export const SelectMethod: React.FC<SelectMethodProps> = ({
    recommendedApplication,
    selectedMethod,
    handleCustomisationSet,
}) => {
    const methods = ['embroidery', 'print'];

    return (
        <Fieldset>
            <div className={classnames(classes.wrapper)}>
                {methods.map((method) => (
                    <MethodOption
                        key={method}
                        method={method}
                        selectedMethod={selectedMethod}
                        handleCustomisationSet={handleCustomisationSet}
                        recommendedApplication={recommendedApplication}
                    />
                ))}
            </div>
        </Fieldset>
    );
};
