import React, { useState } from 'react';
import classnames from 'classnames';
import { ProductInformations } from 'src/components/Orders/OrderDetailItem';
import { useDispatch, shallowEqual, useSelector } from 'react-redux';
import { bundleRegister } from 'src/components/BundleProduct/feature/bundle.register';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { useDeps } from 'src/hooks/useDeps';
import { useMutation } from '@apollo/react-hooks';
import addConfigurable from 'src/queries/addConfigurable.graphql';
import addSimpleProductsToCart from 'src/queries/addSimpleProductsToCart.graphql';
import { Modal } from 'src/components/Modal/Modal';
import { CloseButton } from 'src/components/BundleProduct/CloseButton';
import { AppendBasketState } from 'src/components/Basket/feature/basket.actions';
import { formatDate } from 'src/util/formatDate';
import { parse } from 'date-fns';
import { Price } from 'src/components/Helpers/Price';
import { Item } from 'src/components/ItemDetailsFromMagento/ItemDetailsFromMagento';
import { reorderProduct } from 'src/components/Orders/utils/reorderProduct';
import classes from 'src/components/Orders/ItemDetails.scss';

const selector = (s: AppendBasketState) => s.basket;

type Props = {
    item: Item;
    product: ProductInformations;
    cartId?: string | null;
    treatment: string;
    shippingDate?: string;
    isOfflineOrder?: boolean;
};

export const OrderItem: React.FC<Props> = ({
    item,
    product,
    cartId,
    treatment,
    shippingDate,
    isOfflineOrder = false,
}) => {
    const dispatch = useDispatch();
    const deps = useDeps();
    const [error, setError] = useState<string | null>(null);
    const { parsedCardId } = useSelector(selector, shallowEqual);

    useLazyRegister(bundleRegister, true, 'BundleSelectionProvider');
    const [addConfigurableMutation] = useMutation(addConfigurable);
    const [addSimpleProductsToCartMutation] = useMutation(addSimpleProductsToCart);

    const taxFree = Boolean(item.additionalData && Boolean(JSON.parse(item.additionalData)?.tax_free));

    const addToCart = () => {
        cartId &&
            reorderProduct(
                item as any,
                cartId,
                product.sku,
                taxFree,
                deps,
                dispatch,
                addConfigurableMutation,
                addSimpleProductsToCartMutation,
                setError,
                parsedCardId,
            );
    };

    const productInfo1 = item.meta[1]?.attributeValue;
    const productInfo2 = item.meta[0]?.attributeValue;

    const productImage = item.productThumbnail !== '' ? item.productThumbnail : '../../../static/img/placeholder.jpg';

    return (
        <div className={classes.itemContainer}>
            <div className={classes.item}>
                <div className={classes.itemDataContainer}>
                    <div className={classes.itemInfoContainer}>
                        <div className={classes.itemImage}>
                            <img src={productImage} alt={productImage} />
                            <span className={classes.imageQty}>{item.qtyOrdered}</span>
                        </div>
                        <div className={classes.itemInfo}>
                            <p className={classes.itemName}>{item.name}</p>
                            <p className={classes.itemDetails}>
                                {item.productType !== 'wearer_bundle' && item.productType !== 'bundle' && (
                                    <>
                                        <span>{item.sku}</span>
                                        <span>
                                            {productInfo2 && productInfo2}
                                            {productInfo1 && `, ${productInfo1}`}
                                        </span>

                                        {!isOfflineOrder && (
                                            <span
                                                style={{ background: item.meta[0]?.attributeValue }}
                                                className={classes.colorSwatch}
                                                data-testid="colorSwatch"
                                            />
                                        )}
                                    </>
                                )}
                            </p>
                        </div>
                    </div>
                    {item.price && (
                        <>
                            <p className={classes.itemPriceContainer}>
                                <Price price={item.price} priceIncl={item.price} className={classes.singleItemPrice} />
                                <span>each (exc. VAT)</span>
                            </p>
                            {item.qtyOrdered && (
                                <p className={classnames(classes.itemPriceContainer, classes.totalItemPriceContainer)}>
                                    <Price
                                        price={item.price * item.qtyOrdered}
                                        priceIncl={item.price * item.qtyOrdered}
                                        className={classes.totalItemPrice}
                                    />
                                    <span className={classes.totalItemPriceText}>Total</span>
                                </p>
                            )}
                        </>
                    )}
                </div>
                {(shippingDate || treatment === 'on') && (
                    <div className={classes.additionalOptions}>
                        {shippingDate && (
                            <span className={classes.trackingInfo}>
                                <img className={classes.icon} src="../../../static/icons/approve.png" />
                                <p>Shipped: {formatDate(String(parse(shippingDate, 'dd/MM/yy', new Date())))}</p>
                            </span>
                        )}
                        {treatment === 'on' && (
                            <div className={classes.orderDetailButtons}>
                                <button
                                    className={classes.reorder}
                                    type="button"
                                    disabled={!Boolean(product)}
                                    onClick={addToCart}
                                >
                                    <img className={classes.icon} src="../../../static/icons/reorder-orange.svg" />
                                    Reorder
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Modal className={classes.errorModal} type="popup" isOpen={Boolean(error)} onDismiss={() => setError(null)}>
                <div className={classes.modalHeader}>
                    <CloseButton onClick={() => setError(null)} background="gray" />
                </div>
                <div className={classes.errorText}>{error}</div>
            </Modal>
        </div>
    );
};
