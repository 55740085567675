export const STEPS_ARRAY = [
    'Ticking Along',
    'Processing Order',
    'Awaiting Stock',
    'Getting Set Up',
    'Applying Logos',
    'Packing Order',
    'Order Shipped',
];

export const ORDER_STATUSES = {
    processingOrder: ['processed', 'artwork new', 'artwork ready to approve'],
    awaitingStock: ['orders - awaiting stock'],
    gettingSetUp: ['picked'],
    embroidering: ['artwork ready', 'artwork processing'],
    packingOrder: ['shipping'],
    orderShipped: ['complete', 'part shipped'],
    artworkApproval: ['artwork ready to approve'],
};

export enum WidgetSteps {
    ProcessingOrder = 0,
    AwaitingStock = 1,
    GettingSetUp = 2,
    Embroidering = 3,
    PackingOrder = 4,
    OrderShipped = 5,
}

export enum WidgetStepsNoLogo {
    ProcessingOrder = 0,
    AwaitingStock = 1,
    GettingSetUp = 2,
    PackingOrder = 3,
    OrderShipped = 4,
}
