import React from 'react';
import {
    generateLogoPreview,
    generateTextPreview,
    getAllImages,
    getLastSample,
} from 'src/components/ArtworkApproval/utils/utils';
import classes from 'src/components/ArtworkApproval/ArtworkApproval.scss';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { maybePdf } from 'src/util/formatUrl';
import { Artwork } from 'src/components/ArtworkApproval/ArtworkApprovalContext';

export const ProofGallery: React.FC<{ artwork: Artwork }> = ({ artwork }) => (
    <>
        {getAllImages(artwork.samples).map((image, index) => (
            <div key={index}>
                <span>
                    <b>{`${index + 1} of ${getAllImages(artwork.samples).length} `}</b>
                </span>
                <figure className={classes.figure}>
                    {artwork.artwork.application_type === ApplicationType.Text &&
                        generateTextPreview(artwork.artwork, getLastSample(artwork.samples))}
                    {artwork.artwork.application_type === ApplicationType.Logo &&
                        generateLogoPreview(maybePdf(image), image)}
                </figure>
            </div>
        ))}
    </>
);
