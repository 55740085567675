import React from 'react';

type Props = {
    nameOfNotAvailableMethod: string;
    nameOfSelectedMethod: string;
    className: string;
    someLocationHasBothPositions?: boolean;
};

export const AutomaticallySelectedMethod: React.FC<Props> = ({
    nameOfNotAvailableMethod,
    nameOfSelectedMethod,
    className,
    someLocationHasBothPositions,
}) => {
    return (
        <span className={className}>
            <b>{nameOfNotAvailableMethod}</b> isn’t available in this position on this garment.
            {!someLocationHasBothPositions && (
                <span>
                    {' '}
                    We’ve automatically selected <b>{nameOfSelectedMethod}</b> for you to ensure you get the best
                    application possible.
                </span>
            )}
        </span>
    );
};
