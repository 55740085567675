import React from 'react';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { SelectMethod } from 'src/components/CustomisationPage/steps/SelectMethod/SelectMethod';
import { Customisation } from 'src/components/BundleProduct/types';

import { CustomisationModalWrapper } from './CustomisationModalWrapper';

type Props = {
    closeModal: () => void;
    image: string;
    recommendedApplication?: ApplicationMethod;
    selectedMethod?: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
};

export const CustomisationStepMethodFieldset: React.FC<Props> = ({
    closeModal,
    image,
    recommendedApplication,
    selectedMethod,
    handleCustomisationSet,
}) => (
    <CustomisationModalWrapper
        image={image}
        titleModal="2. Choose application method"
        closeModal={closeModal}
        stepName="Application"
    >
        <SelectMethod
            recommendedApplication={recommendedApplication}
            selectedMethod={selectedMethod}
            handleCustomisationSet={handleCustomisationSet}
        />
    </CustomisationModalWrapper>
);
