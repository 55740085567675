import React from 'react';
import { Price } from 'src/components/Helpers/Price';
import { groupById } from 'src/components/Basket/utils/basket-customisations';
import { ItemCustomisation } from 'src/components/Basket/ItemCustomisation';
import { BasketItemType } from 'src/components/Basket/BasketContext';

import classes from './RemoveItemBtn.scss';

type Props = {
    item: BasketItemType;
};

export const BasketItemCustomisation: React.FC<Props> = ({ item }) => {
    const groupedCustomisations = groupById(item.customisations);

    if (item.customisations.length === 0) {
        return null;
    }

    return (
        <ul className={classes.customisations}>
            <div />
            <div>
                <h4 className={classes.customisationsTitle}>Logos applied to this item</h4>
                {Object.keys(groupedCustomisations).map((id) => (
                    <ItemCustomisation
                        key={id}
                        groupedCustomisation={groupedCustomisations[id]}
                        itemId={item.cartItem.id}
                        isPending={false}
                        item={item}
                    />
                ))}
            </div>
            {item.customisationsCost > 0 && (
                <>
                    <div />
                    <div className={classes.customisationsCost}>
                        <div>
                            Customisation cost:
                            <span className={classes.customisationsPrice}>
                                <Price price={item.customisationsCost} priceIncl={item.customisationsCost} />
                            </span>
                        </div>
                    </div>
                </>
            )}
        </ul>
    );
};
