import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "";
export const method = "POST";
export const operationId = "workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1DeleteGuestCustomisationsPost";
export const path = "/V1/customisations/{cartId}/removeItemCustomisations";
export interface PathParams {
    cartId: string;
}
export interface Body {
    removeCustomisations: Definitions.WorkwearExpressCustomisationPlatformFrontendDataRemoveCustomisationInterface[];
}
export type Response = Response200 | ResponseDefault;
export type Response200 = void;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "postJSON">;
export function execute(pathParams: PathParams, body: Body, effects: MinimalEffects): Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, pathParams), body, effects.restHeaders({})) as any;
}
