import { parseAndFormatDate } from 'src/util/formatDate';

const emptyAddress = {
    firstname: '',
    lastname: '',
    city: '',
    postcode: '',
    telephone: '',
};

type Order = {
    incrementId: string;
    orderNumber: string;
    createdAt: string;
    grandTotal: number;
    status: string;
    khaosId: string;
    totalQtyOrdered: number;
    billingAddress: {
        countryCode: string;
        city: string;
        firstname: string;
        lastname: string;
        postcode: string;
        street: string[];
        telephone: string;
    };
    shippingAddress: {
        countryCode: string;
        city: string;
        firstname: string;
        lastname: string;
        postcode: string;
        street: string[];
        telephone: string;
    };
    items: {
        productType: string;
        selectedOptions: {
            label: string;
            value: string;
        }[];
        productImage: string;
        productUrlKey: string;
        productSku: string;
        productName: string;
        productSalePrice: {
            value: number;
        };
        quantityOrdered: number;
    }[];
    total: {
        baseGrandTotal: {
            value: number;
        };
        grandTotal: {
            value: number;
        };
    };
    orderDate: string;
    paymentMethods: {
        type: string;
        name: string;
        additionalData: {
            name: string;
            value: string;
        };
    };
    extensionAttributes: {
        maskedQuoteId: string;
        shippingDate: string;
        lastShipDate: string;
        artworkApprovals: {
            id: number;
        }[];
    };
    ccDetails: {
        ccBrand: string;
        ccLast4: string;
    };
    khaosStatus: string;
};

export const processOrderGraphqlMagento = (order: Order, statusMessage?: string) => {
    const shippingAddress = order.shippingAddress;
    const billingAddress = (order.billingAddress && order.billingAddress) || emptyAddress;

    const OrderDetailItems = order.items
        .filter((item) => item.productType !== 'customisation')
        .map((item) => {
            const options = item.selectedOptions || [];
            const thumbnail = item.productImage || '';
            const link = item.productUrlKey;
            const meta =
                item.productType === 'configurable' && options.length > 0
                    ? [{ attributeLabel: options[0].label, attributeValue: options[0].value }]
                    : [
                          {
                              attributeLabel: item.productSku,
                              attributeValue: item.productSku,
                          },
                      ];

            return {
                sku: item.productSku,
                name: item.productName || '',
                meta,
                price: item.productSalePrice.value || 0,
                qtyOrdered: item.quantityOrdered || 0,
                link: link || '',
                productThumbnail: thumbnail,
                productType: item.productType,
            };
        });

    const grandTotal = order.total.baseGrandTotal.value || order.total.grandTotal.value || 0;

    const IdToUse = order?.khaosId ? order?.khaosId : 'ID pending';

    const orderDetail = {
        orderNumber: IdToUse,
        placed: parseAndFormatDate(order.createdAt || ''),
        total: grandTotal,
        status: order.khaosStatus,
        statusMessage: statusMessage || '',
        estimatedShippingDate: order.extensionAttributes?.shippingDate,
        shippingDate:
            order.extensionAttributes?.lastShipDate && parseAndFormatDate(order.extensionAttributes?.lastShipDate),
        maskedQuoteId: order.extensionAttributes?.maskedQuoteId,
    };

    return {
        orderDetails: orderDetail,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        orderDetailItems: OrderDetailItems,
        payment: {
            cardNumber: order.ccDetails?.ccLast4,
            cardBrand: order.ccDetails?.ccBrand,
        },
        artworkApprovals: order.extensionAttributes?.artworkApprovals,
    };
};
