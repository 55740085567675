import React from 'react';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';
import { Customisation } from 'src/components/BundleProduct/types';
import { Fieldset } from 'src/components/Forms/Fieldset';

import { ApplicationType } from './ApplicationType';
import classes from './SelectType.scss';

type SelectTypeProps = {
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedType?: 'logo' | 'text';
};
export const SelectType: React.FC<SelectTypeProps> = ({ backgroundStyles, handleCustomisationSet, selectedType }) => {
    return (
        <Fieldset>
            <div className={classes.wrapper} style={backgroundStyles}>
                <ApplicationType
                    id="logo"
                    icon="logo-new"
                    title="Logo"
                    selectedType={selectedType}
                    handleCustomisationSet={handleCustomisationSet}
                />
                <ApplicationType
                    id="text"
                    icon="text-new"
                    title="Text"
                    selectedType={selectedType}
                    handleCustomisationSet={handleCustomisationSet}
                />
            </div>
        </Fieldset>
    );
};
