import React, { useCallback, useState } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { CustomerDataAddressInterface } from 'swagger/ts/Definitions';
import { formToCustomerAddress } from '@wearejh/m2-pwa-user/lib/utils/formToCustomerAddress';
import { useEditAddresses } from '@wearejh/m2-pwa-addresses/lib';
import { Actions } from 'src/components/Forms/Actions';
import Bugsnag from '@bugsnag/js';
import classnames from 'classnames';

import { AddressFields } from './AddressFields';
import classes from './PopupNewAddress.scss';

type PopupNewAddressProps = {
    onSave(address: CustomerDataAddressInterface);
    onClose();
    isOpen: boolean;
    title?: string;
    ctaText?: string;
    initialValues?: Partial<CustomerDataAddressInterface>;
    initiallyOpen?: boolean;
    isModal?: boolean;
    showFields?: boolean;
    setShowFields?: (state: boolean) => void;
    saveInAddressbook?: (state: boolean) => void;
};

export const PopupNewAddress: React.FC<PopupNewAddressProps> = ({
    onSave,
    isOpen,
    initialValues,
    showFields,
    setShowFields,
    saveInAddressbook,
}) => {
    const [isActionButtonBlocked, setIsActionButtonBlocked] = useState(Boolean(initialValues));
    const initial = initialValues || {};
    const { isPending } = useEditAddresses();

    /**
     * Construct initial values for the inline form
     */
    const initialAddressValues = {
        address: {
            /**
             * Setting a sane default here for country_id, but allowing it to be overriden
             */
            country_id: 'GB',
            ...initial,
            region: (initial.region && initial.region) || undefined,
            street1: Array.isArray(initial.street) && initial.street[0],
            street2: Array.isArray(initial.street) && initial.street[1],
            firstname: initial.firstname || undefined,
            lastname: initial.lastname || undefined,
        },
    };

    /**
     * Handle the form submission.
     */
    const onSubmit = useCallback(
        (values) => {
            saveInAddressbook?.(true);
            if (!values.address) {
                Bugsnag.notify('Missing values.address');
                return;
            }

            const output = formToCustomerAddress(values.address);

            if (!output) {
                Bugsnag.notify('Could not convert form values into a customer address', (event) => {
                    event.addMetadata('output', { output: output });
                });
                return;
            }

            onSave(output);
        },
        [onSave, saveInAddressbook],
    );

    if (!isOpen) return null;

    return (
        <Form initialValues={initialAddressValues} onSubmit={onSubmit} data-test-id="NewAddressForm">
            <fieldset
                disabled={isPending}
                className={classnames({
                    [classes.topSpace]: isOpen,
                })}
            >
                <AddressFields
                    showFields={showFields}
                    setshowFields={setShowFields}
                    onChange={isActionButtonBlocked ? () => setIsActionButtonBlocked(false) : undefined}
                />
                <Actions primaryText="Save Address" direction="reverse" primaryDisabled={isActionButtonBlocked} />
            </fieldset>
        </Form>
    );
};
