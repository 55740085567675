import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { errorMessage, successMessage } from 'src/util/state-helpers';
import { useDeps } from 'src/hooks/useDeps';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { useCallback, useEffect, useReducer } from 'react';
import { concat, EMPTY, of, Subject, timer } from 'rxjs';
import { catchError, filter, ignoreElements, mergeMap, switchMap, tap } from 'rxjs/operators';
import { execute } from 'swagger/ts/WorkwearExpressArtworkCustomerArtworkApprovalRepositoryV1AddCommentPost';
import { execute as executeGuest } from 'swagger/ts/WorkwearExpressArtworkCustomerArtworkApprovalRepositoryV1AddCommentForGuestPost';
import { extractErrorAndCode } from 'src/util/errors';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

export type RequestState = {
    status: DataStatus;
    messages: Message[];
};

const initialState: RequestState = {
    status: DataStatus.Idle,
    messages: [],
};

type RequestMsg =
    | {
          kind: 'submit';
          body: {
              id: number | string;
              status: string;
              comment: string;
          };
      }
    | {
          kind: 'error';
          message: string;
      }
    | {
          kind: 'success';
          message: string;
      };

function requestReducer(state = initialState, action: RequestMsg): RequestState {
    switch (action.kind) {
        case 'submit': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'error': {
            return {
                ...state,
                status: DataStatus.Error,
                messages: [errorMessage(action.message)],
            };
        }
        case 'success': {
            return {
                ...state,
                status: DataStatus.Success,
                messages: [successMessage(action.message)],
            };
        }
        default:
            return state;
    }
}

export function useArtworkRequest() {
    const deps = useDeps();
    const reduxDispatch = useDispatch();
    const [state, dispatch] = useReducer(requestReducer, initialState);

    const mirror$ = useConstant(() => new Subject<RequestMsg>());
    const { id } = useParams() as Record<string, any>;

    useEffect(() => {
        const sub = mirror$
            .pipe(
                filter((x) => x.kind === 'submit'),
                switchMap((msg) => {
                    if (msg.kind !== 'submit') {
                        return EMPTY;
                    }
                    const ajax$ = execute(
                        {
                            artworkApprovalId: Number(msg.body.id),
                        },
                        {
                            status: msg.body.status,
                            comment: msg.body.comment,
                        },
                        deps,
                    );

                    return ajax$.pipe(
                        mergeMap(() => {
                            return concat(
                                of({ kind: 'success', message: 'Sent!' }),
                                timer(2000).pipe(
                                    tap(() => {
                                        reduxDispatch(deps.replace(`${deps.paths.customer.orders}/${id}`));
                                    }),
                                    ignoreElements(),
                                ),
                            );
                        }),
                        catchError((err) => {
                            const { error } = extractErrorAndCode(err);
                            return of({ kind: 'error', message: error });
                        }),
                    );
                }),
                tap((msg) => dispatch(msg as RequestMsg)),
            )
            .subscribe();
        return () => {
            sub.unsubscribe();
        };
    }, [deps, id, mirror$, reduxDispatch]);

    type RequestSubmit = {
        id: number | string;
        status: string;
        comment: string;
    };

    const submit = useCallback(
        (body: RequestSubmit) => {
            const msg = { kind: 'submit' as const, body };
            dispatch(msg);
            mirror$.next(msg);
        },
        [mirror$],
    );

    return {
        state,
        submit,
    };
}

export function useArtworkGuestRequest() {
    const deps = useDeps();
    const reduxDispatch = useDispatch();
    const [state, dispatch] = useReducer(requestReducer, initialState);

    const mirror$ = useConstant(() => new Subject<RequestMsg>());

    useEffect(() => {
        const sub = mirror$
            .pipe(
                filter((x) => x.kind === 'submit'),
                switchMap((msg) => {
                    if (msg.kind !== 'submit') {
                        return EMPTY;
                    }

                    const ajax$ = executeGuest(
                        {
                            token: String(msg.body.id),
                        },
                        {
                            status: msg.body.status,
                            comment: msg.body.comment,
                        },
                        deps,
                    );

                    return ajax$.pipe(
                        mergeMap(() => {
                            return concat(
                                of({ kind: 'success', message: 'Sent!' }),
                                timer(2000).pipe(
                                    tap(() => {
                                        reduxDispatch(deps.replace('/'));
                                    }),
                                    ignoreElements(),
                                ),
                            );
                        }),
                        catchError((err) => {
                            const { error } = extractErrorAndCode(err);
                            return of({ kind: 'error', message: error });
                        }),
                    );
                }),
                tap((msg) => dispatch(msg as RequestMsg)),
            )
            .subscribe();
        return () => {
            sub.unsubscribe();
        };
    }, [deps, mirror$, reduxDispatch]);

    type RequestSubmit = {
        id: number | string;
        status: string;
        comment: string;
    };

    const submit = useCallback(
        (body: RequestSubmit) => {
            const msg = { kind: 'submit' as const, body };
            dispatch(msg);
            mirror$.next(msg);
        },
        [mirror$],
    );

    return {
        state,
        submit,
    };
}
