import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { UserMsg } from '@wearejh/m2-pwa-user/lib/user.actions';
import { AppendState, UserSession } from '@wearejh/m2-pwa-user/lib/user.reducer';
import { RegisterParams } from 'src/features/user/lib/user.action';

const dataSelector = (s: AppendState) => {
    return {
        firstname: s.user.data?.firstname,
        lastname: s.user.data?.lastname,
        email: s.user.data?.email,
    };
};

const userSelector = (s: AppendState) => s.user;

export type UseCustomerStatusAPI = {
    isSignedIn: boolean;
    isPending: boolean;
    isIdle: boolean;
    firstname: string | undefined;
    lastname: string | undefined;
    email: string | undefined;
    signOut(event?: any);
    signIn(params: { username: string; password: string; allowRedirects?: boolean });
    register(params: RegisterParams);
    messages: Message[];
    createMessages: Message[];
};

export function useCustomerStatus(): UseCustomerStatusAPI {
    const dispatch = useDispatch();
    const { createMessages, messages, session, status } = useSelector(userSelector, shallowEqual);
    const isSignedIn = session === UserSession.SignedIn;
    const isPending = status === DataStatus.Pending;
    const isIdle = status === DataStatus.Idle;
    const { firstname, lastname, email } = useSelector(dataSelector, shallowEqual);
    const signOut = useCallback(
        (e) => {
            if (e && e.preventDefault) {
                e.preventDefault();
            }
            dispatch(UserMsg('User.SignOut'));
        },
        [dispatch],
    );
    const signIn = useCallback(
        (values: { username: string; password: string; allowRedirects?: boolean }) => {
            dispatch(UserMsg('User.SignIn', { allowRedirects: true, ...values }));
        },
        [dispatch],
    );
    const register = useCallback(
        (values: RegisterParams) => {
            dispatch(UserMsg('User.Register', values));
        },
        [dispatch],
    );
    return {
        isPending,
        isIdle,
        isSignedIn,
        firstname,
        lastname,
        email,
        signOut,
        signIn,
        register,
        messages,
        createMessages,
    };
}
