import { useContext, useEffect, useMemo } from 'react';
import { useVatCausesRender } from 'src/components/AppShell/VatProvider';
import { Fields } from 'src/components/CustomisationPage/feature/customisation.types';
import { usePriceString } from 'src/components/Helpers/Price';
import { RxFormContext } from '@wearejh/rx-form/lib/Context';
import { useFormApi } from '@wearejh/rx-form/lib/hooks/useFormApi';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { ARTWORK_FORM_PREFIX } from 'src/components/BundleProduct/bundle-product.types';
import { useFieldValue } from '@wearejh/rx-form/lib/hooks/useFieldValue';
import { useCustomerStatus } from '@wearejh/m2-pwa-user/lib/hooks/useCustomerStatus';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { artworkGridRegister } from 'src/components/Artwork/feature/artwork.register';
import { ArtworkMsg } from 'src/components/Artwork/feature/artwork.register';
import { customisationToArtwork } from 'src/components/Artwork/utils/artwork';
import { cartIdSelector } from 'src/components/Artwork/ArtworkSelect';
import { ConfigContext } from 'src/components/AppShell/ConfigProvider';

export const useArtwork = (artworkId?: number) => {
    useLazyRegister(artworkGridRegister, true, 'ArtworkGridWrapper');
    const { vat } = useVatCausesRender();
    const { setupCharge } = useContext(ConfigContext);
    const price = usePriceString(vat ? setupCharge.price_incl : setupCharge.price, vat);
    const { customisation } = useSelector(cartIdSelector, shallowEqual);
    const selectedLocationApplicationMethod = useFieldValue(
        `${artworkId}.${ARTWORK_FORM_PREFIX}${Fields.applicationMethod}`,
    );
    const applicationMethod = customisation?.application_method || selectedLocationApplicationMethod;
    const { artworkGrid } = useSelector(cartIdSelector, shallowEqual);
    const isPending = artworkGrid.status === 'Pending';
    const { customisations = [] } = artworkGrid || {};
    const { isSignedIn } = useCustomerStatus();
    const dispatch = useDispatch();
    const { getValueStream } = useContext(RxFormContext);
    const api = useFormApi();

    useEffect(() => {
        dispatch(ArtworkMsg('ArtworkGrid.Fetch'));
    }, [dispatch, isSignedIn]);

    const uniqueImages: string[] = [];

    const deduplicatedCustomisations = customisations.filter((customisation) => {
        if (customisation.image && uniqueImages.includes(customisation.image)) return false;

        uniqueImages.push(customisation.image);

        return true;
    });

    const processed = useMemo(() => {
        return deduplicatedCustomisations.slice().reverse().map(customisationToArtwork);
    }, [deduplicatedCustomisations]);

    return {
        isPending,
        deduplicatedCustomisations,
        artworkGrid,
        applicationMethod,
        processed,
        getValueStream,
        api,
        price,
    };
};
