import React from 'react';
import { ProductLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';
import { CustomisationLocation } from 'src/components/CustomisationPage/CustomisationLocation';
import { Customisation } from 'src/components/BundleProduct/types';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';

import classes from './SelectLocation.scss';

type SelectLocationProps = {
    locations: ProductLocation[];
    selectedPositions?: string[];
    alreadySelectedPositions?: string[];
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    multiplePositions?: boolean;
};
export const SelectLocation: React.FC<SelectLocationProps> = ({
    locations,
    selectedPositions,
    alreadySelectedPositions,
    backgroundStyles,
    handleCustomisationSet,
    multiplePositions,
}) => {
    const methodKey = Boolean(locations[0].type) ? 'type' : 'method';

    return (
        <div className={classes.positionBackground} style={backgroundStyles}>
            <div className={classes.content}>
                {locations.map((location) => {
                    const disabled = alreadySelectedPositions?.includes(location.name);

                    return (
                        <div key={location.name} className={classes.container}>
                            {disabled && <p className={classes.locationDisabled}>Location used in other logo</p>}
                            <input
                                className={classes.inputHidden}
                                type={multiplePositions ? 'checkbox' : 'radio'}
                                checked={selectedPositions?.includes(location.name)}
                                disabled={disabled}
                                name="location"
                                id={location.name}
                                onChange={(e) => {
                                    e.preventDefault();
                                    if (!selectedPositions?.includes(location.name)) {
                                        const computedSelectedPositions = selectedPositions && [...selectedPositions];
                                        computedSelectedPositions?.push(location.name);
                                        if (location[methodKey] !== ApplicationMethod.Both) {
                                            handleCustomisationSet({
                                                selectedPositions: multiplePositions
                                                    ? computedSelectedPositions
                                                    : [location.name],
                                                availableMethodNumber: location[methodKey],
                                                selectedMethod: String(location[methodKey]),
                                            });
                                        } else {
                                            handleCustomisationSet({
                                                selectedPositions: multiplePositions
                                                    ? computedSelectedPositions
                                                    : [location.name],
                                                availableMethodNumber: location[methodKey],
                                            });
                                        }
                                    } else if (multiplePositions) {
                                        handleCustomisationSet({
                                            selectedPositions: selectedPositions.filter(
                                                (position) => position !== location.name,
                                            ),
                                        });
                                    }
                                }}
                            />
                            <img src="../../../../static/icons/ok.png" className={classes.checkmark} />
                            <label htmlFor={location.name} className={classes.label}>
                                <CustomisationLocation
                                    image={location.image_url}
                                    title={location.name}
                                    availableMethods={location[methodKey]}
                                    isDisabled={disabled}
                                />
                            </label>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
