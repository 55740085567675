import React from 'react';
import { SelectArtwork } from 'src/components/CustomisationPage/steps/SelectArtwork/SelectArtwork';
import { Customisation } from 'src/components/BundleProduct/types';
import { AutomaticallySelectedMethod } from 'src/components/BundleProduct/AutomaticallySelectedMethod';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';

import classes from './MultipleStepCustomisation.scss';
import { CustomisationModalWrapper } from './CustomisationModalWrapper';

type Props = {
    closeModal: () => void;
    onNextButtonClick: () => void;
    image: string;
    applicationMethod?: string;
    isFinalStep: boolean;
    freeCustomisation?: boolean;
    oneMethodAvailable: boolean;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedArtwork?: string;
    selectedMethod?: string;
    someLocationHasBothPositions?: boolean;
    productHasOnlyOnePosition?: boolean;
};

export const CustomisationStepArtworkSelect: React.FC<Props> = ({
    closeModal,
    onNextButtonClick,
    image,
    applicationMethod,
    isFinalStep,
    freeCustomisation,
    oneMethodAvailable,
    handleCustomisationSet,
    selectedArtwork,
    selectedMethod,
    someLocationHasBothPositions,
    productHasOnlyOnePosition,
}) => {
    const backgroundStyles = {
        background: 'none',
        boxShadow: '0 0 4px rgb(35 31 32 / 40%)',
        borderRadius: 0,
        'font-size': '12px',
    };

    return (
        <CustomisationModalWrapper
            image={image}
            titleModal="3. Choose artwork"
            closeModal={closeModal}
            isFinalStep={isFinalStep}
            stepName="Choose Artwork"
        >
            {oneMethodAvailable && !productHasOnlyOnePosition && (
                <AutomaticallySelectedMethod
                    nameOfNotAvailableMethod={
                        Number(applicationMethod) === ApplicationMethod.Print ? 'Embroidery' : 'Print'
                    }
                    nameOfSelectedMethod={
                        Number(applicationMethod) === ApplicationMethod.Print ? 'Print' : 'Embroidery'
                    }
                    className={classes.additionalInfo}
                    someLocationHasBothPositions={someLocationHasBothPositions}
                />
            )}
            <SelectArtwork
                selectedApplicationMethod={applicationMethod ? parseInt(applicationMethod) : undefined}
                onNextButtonClick={onNextButtonClick}
                handleCustomisationSet={handleCustomisationSet}
                freeCustomisation={freeCustomisation}
                selectedArtwork={selectedArtwork}
                selectedMethod={selectedMethod}
                backgroundStyles={backgroundStyles}
            />
        </CustomisationModalWrapper>
    );
};
