import { useDeps } from 'src/hooks/useDeps';
import { SvgIconNames } from 'src/components/Svg/icons-types.d';

export const useNavLinks = () => {
    const { paths } = useDeps();

    return [
        {
            title: 'My Orders',
            mobileTitle: 'My Orders',
            path: paths.customer.orders,
            icon: 'orders',
        },
        {
            title: 'Saved Basket',
            mobileTitle: 'Basket',
            path: paths.customer.basket,
            icon: 'baskets',
        },
        {
            title: 'Profile',
            mobileTitle: 'Profile',
            path: paths.customer.accountSettings,
            icon: 'profile',
        },
        {
            title: 'Addresses',
            mobileTitle: 'Address',
            path: paths.customer.addressSettings,
            icon: 'address',
        },
        {
            title: 'Password',
            mobileTitle: 'Password',
            path: paths.customer.changePassword,
            icon: 'password',
        },
    ] as { title: string; mobileTitle: string; path: string; icon: SvgIconNames }[];
};
