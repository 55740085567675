import React, { useCallback, useState } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { useSearch } from '@wearejh/m2-pwa-engine/lib/hooks/useSearch';
import { useNewPassword } from '@wearejh/m2-pwa-password/lib/newPassword/hooks/useNewPassword';
import { useDispatch } from 'react-redux';
import { DataStatus, Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';
import { NewPasswordData } from '@wearejh/m2-pwa-password/lib/newPassword/newPassword.types';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';
import { Container } from 'src/components/Layout';
import { PageTitle } from 'src/components/Layout/PageTitle';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';

import { Button } from '../Button/Button';
import { successMessage } from '../../util/state-helpers';
import { ErrorComponent } from '../Error/ErrorComponent';
import { useDeps } from '../../hooks/useDeps';
import { PasswordConfirm, PW_FIELD, PW_EMAIL } from '../SignIn/PasswordConfirm';
import { Block } from '../Helpers/Block';
import { Messages } from '../Messages/Messages';

interface Props {
    token: string;
    status: DataStatus;
    messages: Message[];
    isPending: boolean;
    isError: boolean;
    isSuccess: boolean;
    submit(params: NewPasswordData): void;
}

export const ResetPassword: React.FC<Props> = React.memo((props) => {
    const { push, text, paths } = useDeps();
    const { token, submit, isPending } = props;
    const dispatch = useDispatch();

    const [error, setError] = useState<Message | undefined>(undefined);

    const onSubmit = useCallback(
        (vals) => {
            resetPassword(token, vals[PW_FIELD], vals[PW_EMAIL]);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submit, token],
    );

    const resetPassword = async (token: string, newPassword: string, email: string) => {
        try {
            await axios({
                method: 'POST',
                url: `/rest/V1/customers/resetPassword`,
                data: {
                    email,
                    newPassword: newPassword,
                    resetToken: token!, // guarded below
                },
            });
        } catch (err: any) {
            Bugsnag.notify(err);
            setError({ type: MessageType.Error, text: String(err.response.data.message) });
            return null;
        }

        dispatch(push(paths.customer.login, { messages: [successMessage(text['user.resetPassword.success'])] }));
    };

    return (
        <Container variant="modal-narrow">
            <PageTitle title="Set a New Password" />
            {error && (
                <Block>
                    <Messages messages={[error]} />
                </Block>
            )}
            <Form onSubmit={onSubmit} className="card">
                <fieldset disabled={isPending}>
                    <PasswordConfirm />
                    <Button type="submit">{isPending ? 'Wait...' : 'Submit'}</Button>
                </fieldset>
            </Form>
        </Container>
    );
});

export const ResetPasswordConnected: React.FC = () => {
    useSetResolved();
    const search = useSearch();
    const params = new URLSearchParams(search);
    const token = params.get('token');
    const api = useNewPassword();

    /**
     * Prevent the form from show
     */
    if (!token) {
        return <ErrorComponent message="Page has expired" />;
    }

    return <ResetPassword token={token} {...api} />;
};
