import { editUserResponsesEpic } from './epics/editUserResponses.epic';

export function editUserResponsesRegister() {
    return {
        epics: [editUserResponsesEpic],
        reducers: {
            editUserResponses: (state = {}) => state,
        },
        name: 'editUserResponses',
    };
}
