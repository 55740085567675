import { parseAndFormatDate } from 'src/util/formatDate';

const emptyAddress = {
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    firstName: '',
    lastName: '',
    postcode: '',
    town: '',
};

type Order = {
    khaosInvoices: {
        customer: {
            addresses: Addresses[];
        };
        items: {
            stockCode: string;
            stockDesc: string;
            unitPriceNet: number;
            invoiceQty: number;
            image: string;
            extendedDesc: string;
        }[];
        code: string;
    }[];
    priceTotal: string;
    datePlaced: string;
    itemsTotal: string;
};

export type Addresses = {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressType: 'shipping' | 'billing';
    firstName: string;
    lastName: string;
    postcode: string;
    town: string;
};

type CustomerOrderData = {
    ccDetails: {
        ccBrand: string;
        ccLast4: string;
    };
    khaosStatus: string;
    paymentMethods: {
        type: string;
        name: string;
        additionalData: {
            name: string;
            value: string;
        };
    };
    extensionAttributes: {
        maskedQuoteId: string;
        shippingDate: string;
        lastShipDate: string;
    };
};

export const processOrderGraphql = (order: Order, id: string) => {
    const khaosInvoices = order.khaosInvoices;

    const invoices = khaosInvoices.map((invoice) => {
        const customer = invoice.customer;
        const items = invoice.items;

        const shippingAddress = customer.addresses.find((address) => address.addressType === 'shipping') || {
            ...emptyAddress,
            addressType: 'shipping',
        };
        const billingAddress = customer.addresses.find((address) => address.addressType === 'billing') || {
            ...emptyAddress,
            addressType: 'billing',
        };

        const OrderDetailItems = items.map((item) => ({
            sku: item.stockCode,
            name: item.stockDesc || '',
            price: item.unitPriceNet || 0,
            qtyOrdered: item.invoiceQty || 0,
            productThumbnail: item.image || '',
            extendedDesc: item.extendedDesc || '',
        }));

        return {
            shippingAddress: shippingAddress,
            billingAddress: billingAddress,
            orderDetailItems: OrderDetailItems,
            code: invoice.code,
        };
    });

    const grandTotal = order.priceTotal || '0';

    const orderDetails = {
        orderNumber: id,
        placed: parseAndFormatDate(order.datePlaced || ''),
        total: grandTotal,
        itemsTotal: order.itemsTotal,
    };

    return {
        invoices,
        orderDetails,
    };
};

export const processAdditionalOrderGraphql = (customerOrderData: CustomerOrderData) => {
    const additionalOrderData = {
        status: customerOrderData.khaosStatus,
        estimatedShippingDate: customerOrderData.extensionAttributes?.shippingDate,
        shippingDate:
            customerOrderData.extensionAttributes?.lastShipDate &&
            parseAndFormatDate(customerOrderData.extensionAttributes?.lastShipDate),
        maskedQuoteId: customerOrderData.extensionAttributes?.maskedQuoteId,
        cardNumber: customerOrderData.ccDetails?.ccLast4,
        cardBrand: customerOrderData.ccDetails?.ccBrand,
    };

    return additionalOrderData;
};
