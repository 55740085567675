import LogRocket from 'logrocket';
import { defer, EMPTY, merge, Observable, of } from 'rxjs';
import {
    catchError,
    filter,
    ignoreElements,
    map,
    mergeMap,
    pluck,
    startWith,
    switchMap,
    take,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { Deps, StoreState } from 'src/types/global-types';
import { UserState } from 'src/util/test-utils';
import { ofType } from 'redux-observable';
import { Actions } from '@wearejh/m2-pwa-user/lib/index';
import { LOCATION_CHANGE, Actions as RouterActions, TypeMap } from '@wearejh/m2-pwa-engine/lib/router/router.register';
import ApolloClient from 'apollo-client';
import { logrocket } from 'src/queries/__generated__/logrocket';

import logrocketQuery from '../../queries/logrocket.graphql';

let started = false;
const NAME = 'logrocket';

export function registerLogrocket() {
    return {
        name: NAME,
        epics: [setupLogrocket],
        reducers: {
            [NAME]: (state = {}) => state,
        },
    };
}

/**
 * Use the GQL client cache to retrieve the logrocket enabled flag
 * @param deps
 */
function isEnabled(deps: Deps): Observable<boolean> {
    return defer(() =>
        (deps.client as ApolloClient<any>).query<logrocket>({
            query: logrocketQuery,
        }),
    ).pipe(
        map((resp) => Boolean(resp.data.storeConfig?.logrocket_general_enabled === '1')),
        catchError((_e) => of(false)),
    );
}

function init(deps: Deps) {
    LogRocket.init(deps.env.LOGROCKET_ID, {
        release: deps.env.VERSION,
        network: {
            requestSanitizer: (request) => {
                /**
                 * Prevent user/pass being sent/stored in LogRocket
                 */
                if (request.url.toLowerCase().indexOf('customer/token') !== -1) {
                    request.body = undefined;
                }

                /**
                 * Prevent user tokens being sent
                 */
                if (request.headers['authorization']) {
                    request.headers['authorization'] = '';
                }
                return request;
            },
        },
    });
}

/**
 * If enabled, init with the ID from the env var.
 * Then take the user data and try to identify the session
 * @param _action$
 * @param state$
 * @param deps
 */
export function setupLogrocket(action$: Observable<any>, state$: Observable<StoreState>, deps: Deps): Observable<any> {
    if (started) {
        return EMPTY;
    }

    started = true;

    const includeList = ['/checkout', '/basket', '/customisation'];

    return action$.pipe(
        ofType<RouterActions, TypeMap[typeof LOCATION_CHANGE]>(LOCATION_CHANGE),
        map((action) => action.payload.location.pathname),
        startWith(window.location.pathname),
        filter((pathname) => includeList.some((l) => pathname.indexOf(l) === 0)),
        take(1),
        switchMap(() => isEnabled(deps)),
        filter((enabled) => enabled),
        tap(() => init(deps)),
        mergeMap(() => {
            /**
             * Take the changes in user data + current cart
             * @param userData
             * @param cart
             */
            const identify = (userData: UserState['data']) => {
                const user = {
                    name: [userData.firstname, userData.lastname].join(' '),
                    email: userData.email!,
                };
                LogRocket.identify(String(userData.id), user);
            };

            const current$ = state$.pipe(
                pluck('user', 'data'),
                filter((x) => x !== null),
                take(1),
                tap((data) => identify(data)),
            );

            const signIn$ = action$.pipe(
                ofType<Actions>('User.SignInSuccess', 'User.Persist'),
                withLatestFrom(state$.pipe(pluck('user', 'data'))),
                tap(([, data]) => identify(data)),
            );

            const logger$ = action$.pipe(tap((x) => LogRocket.log(x)));

            return merge(current$, signIn$, logger$);
        }),
        ignoreElements(),
        catchError((e) => {
            console.error('could not start logrocket', e);
            return EMPTY;
        }),
    );
}
