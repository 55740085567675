import React from 'react';
import { CustomerDataAddressInterface } from 'swagger/ts/Definitions';
import { useAddresses } from '@wearejh/m2-pwa-user/lib/hooks/useAddresses';
import { useEditAddresses, UseEditAddressesApi } from '@wearejh/m2-pwa-addresses/lib/hooks/useEditAddresses';
import { CustomerAccountPage } from 'src/components/Customer/CustomerAccountPage';
import { DefaultMeta } from 'src/components/AppShell/Head';

import { Button } from '../Button/Button';
import { TwoUp } from '../Layout/TwoUp';
import meta from '../Customer/customer.json';

import { AddressHeading } from './AddressHeading';
import classes from './AddressBook.scss';
import { OtherAddresses } from './OtherAddresses';
import { AccountAddressPopup } from './AccountAddressPopup';
import { useEditAddressesPopup } from './hooks/useEditAddressesPopup';

export type Props = {
    addresses: CustomerDataAddressInterface[];
    shippingAddress: CustomerDataAddressInterface | undefined;
    billingAddress: CustomerDataAddressInterface | undefined;
    otherAddresses: CustomerDataAddressInterface[];
    edit: UseEditAddressesApi;
};

export const AddressBook: React.FC<Props> = React.memo((props) => {
    const { addresses, shippingAddress, billingAddress, otherAddresses, edit } = props;
    const {
        operation,
        deleteAddress,
        isPending,
        selected,
        closeModal,
        onSave,
        newAddress,
        openEditAddress,
        isOpen,
    } = useEditAddressesPopup(addresses, edit);

    return (
        <>
            <DefaultMeta data={meta.helmet.addressBook} />
            {addresses.length === 0 && <p data-test-id="NoAddressMessage">You don't have any addresses saved yet</p>}
            {shippingAddress && billingAddress && (
                <TwoUp
                    className={classes.defaultAddresses}
                    data-test-id="DefaultAddresses"
                    left={
                        <AddressHeading
                            title="Default Billing Address"
                            address={billingAddress}
                            edit={openEditAddress}
                        />
                    }
                    right={
                        <AddressHeading
                            title="Default Delivery Address"
                            address={shippingAddress}
                            edit={openEditAddress}
                        />
                    }
                />
            )}
            {otherAddresses.length > 0 && (
                <OtherAddresses addresses={otherAddresses} edit={openEditAddress} delete={deleteAddress} />
            )}
            <div className={classes.cta}>
                <Button variant="primary" disabled={isPending} onClick={newAddress} data-test-id="AddNewAddress">
                    Add a new Address
                </Button>
            </div>
            {isOpen && (
                <AccountAddressPopup
                    initialValues={selected}
                    open={true}
                    operation={operation}
                    close={closeModal}
                    onSave={onSave}
                />
            )}
        </>
    );
});

export const AddressBookConnected: React.FC = () => {
    const api = useAddresses();
    const edit = useEditAddresses();
    return <CustomerAccountPage name="Addresses" child={<AddressBook {...api} edit={edit} />} />;
};
