import React, { useState } from 'react';
import { Button } from 'src/components/Button/Button';
import { ArtworkStatus } from 'src/components/Orders/utils/order.utils';
import { Artwork, ArtworkValues } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { Modal } from 'src/components/Modal/Modal';
import { ModalHeader } from 'src/components/Modal/ModalHeader';
import { ModalBody } from 'src/components/Modal/ModalBody';
import { Messages } from 'src/components/Messages/Messages';
import { RequestState } from 'src/components/ArtworkApproval/hooks/useArtworkRequest';

import { ArtworkApprovalRequest } from './ArtworkApprovalRequest';
import classes from './ArtworkApproval.scss';

type Props = {
    artwork: Artwork;
    onSubmit: (values: ArtworkValues, status: string) => void;
    onGuestSubmit?: (values: ArtworkValues, status: string) => void;
    isGuest?: boolean;
    state?: RequestState;
    setIsPendingAction: (isPendingAction: boolean) => void;
    isPendingAction: boolean;
};

export const ArtworkApprovalActions: React.FC<Props> = ({
    artwork,
    onSubmit,
    state,
    onGuestSubmit,
    isGuest,
    setIsPendingAction,
    isPendingAction,
}) => {
    const { status_update_allowed, status } = artwork;
    const submitAction = isGuest && onGuestSubmit ? onGuestSubmit : onSubmit;
    const samplesPdfs = artwork.samples.filter((x) => {
        if (x.match(/\.pdf$/)) {
            return x;
        }
    });

    const pdfDownload = samplesPdfs.length > 0 ? samplesPdfs[samplesPdfs.length - 1] : '';
    const [showForm, setShowForm] = useState(false);

    return (
        <div className={classes.actions}>
            {pdfDownload && (
                <div className={classes.downloadPdf}>
                    <p>
                        <a href={pdfDownload} className="button button--light button--width-full" download>
                            Download Proof (PDF)
                        </a>
                    </p>
                </div>
            )}
            <div>
                {status !== ArtworkStatus.ArtworkApproved && (
                    <div className={classes.actionsSubmit}>
                        {!showForm && (
                            <>
                                <h3 className={classes.actionsHeader}>Artwork Approval</h3>
                                <p>
                                    Please make sure you review <b>all</b> artwork proofs provided before approving
                                </p>
                                <p>We are not responsible for errors once approval for production has been given.</p>
                                {status === ArtworkStatus.ReviewPending && (
                                    <Button
                                        width="full"
                                        onClick={() => {
                                            submitAction(
                                                {
                                                    comment: '',
                                                },
                                                'approved',
                                            );
                                            setIsPendingAction(true);
                                        }}
                                        disabled={isPendingAction}
                                    >
                                        Approve artwork
                                    </Button>
                                )}
                                {status_update_allowed && (
                                    <Button
                                        width="full"
                                        variant="bold"
                                        onClick={() => setShowForm(true)}
                                        disabled={isPendingAction}
                                    >
                                        Request changes
                                    </Button>
                                )}
                            </>
                        )}

                        {showForm && (
                            <>
                                <ArtworkApprovalRequest onSubmit={(values, status) => submitAction(values, status)} />
                                <Button width="full" variant="light" onClick={() => setShowForm(false)}>
                                    Cancel
                                </Button>
                            </>
                        )}
                    </div>
                )}

                {state?.status === DataStatus.Success && (
                    <Modal type="popup" isOpen={true} onDismiss={() => console.warn('dismiss not implemented')}>
                        <ModalHeader title="Success" icon="check" type="success" />
                        <ModalBody>
                            {state.messages.length > 0 && (
                                <div className={classes.actionsFormMsg}>
                                    <Messages messages={state.messages} />
                                </div>
                            )}
                        </ModalBody>
                    </Modal>
                )}
            </div>
        </div>
    );
};
