import { merge, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { TypeMap, UserActions } from '@wearejh/m2-pwa-user/lib';
import { mapTo } from 'rxjs/operators';
import { scrollToSelector } from '@wearejh/m2-pwa-engine/lib/runtime/utils/scrollTop';

import { Deps } from '../../../../types/global-types';

/**
 * This epic is here to allow responses to the registeration functions
 * @param action$
 * @param _
 * @param deps
 */
export function registerResponseEpic(action$: Observable<any>, _, deps: Deps) {
    /**
     * Convert User.RegisterError messages into a scroll-top event
     */
    const errors$ = action$.pipe(
        ofType<UserActions, TypeMap['User.RegisterError']>('User.RegisterError'),
        mapTo(scrollToSelector(`[id="Register"]`)),
    );

    const success$ = action$.pipe(
        ofType<UserActions, TypeMap['User.RegisterSuccess']>('User.RegisterSuccess'),
        mapTo(deps.push(deps.paths.customer.account)),
    );

    /**
     * Convert registration events into a marker that showed we signed
     */
    return merge(errors$, success$);
}
