import React, { useState } from 'react';
import { AsyncSwitch, DelayedRedirect } from '@wearejh/m2-pwa-engine/lib/router';
import { Route } from 'react-router';
import { Dashboard } from 'src/components/Customer/Dashboard';
import { Orders } from 'src/components/Orders/Orders';
import ArtworkApprovalProvider from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import { SavedBasket } from 'src/components/SavedBasket/SavedBasket';
import { Order } from 'src/components/Orders/ItemDetails';

import { useDeps } from '../../hooks/useDeps';
import { AddressBookConnected } from '../AddressBook/AddressBook';
import { OrderDetail } from '../Orders/OrderDetail';
import { ArtworkApprovalConnected } from '../ArtworkApproval/ArtworkApproval';

import { AccountInformationPageConnected } from './AccountInformationPage';
import { ChangePasswordPageConnected } from './ChangePasswordPage';

export const AccountRouter: React.FC = React.memo(() => {
    const { paths } = useDeps();
    const [globalCurrentPage, setGlobalCurrentPage] = useState(1);

    return (
        <AsyncSwitch base="/customer">
            <Route exact path={paths.customer.account}>
                <Dashboard />
            </Route>
            <Route exact path={paths.customer.orders}>
                <Orders globalCurrentPage={globalCurrentPage} setGlobalCurrentPage={setGlobalCurrentPage} />
            </Route>
            <Route exact path={`${paths.customer.orders}/:id`}>
                <Order />
            </Route>
            <Route exact path={paths.customer.order}>
                <OrderDetail />
            </Route>
            <Route exact path={paths.customer.artwork}>
                <ArtworkApprovalProvider>
                    <ArtworkApprovalConnected />
                </ArtworkApprovalProvider>
            </Route>
            <Route exact path={paths.customer.addressSettings}>
                <AddressBookConnected />
            </Route>
            <Route exact path={paths.customer.accountSettings}>
                <AccountInformationPageConnected />
            </Route>
            <Route exact path={paths.customer.changePassword}>
                <ChangePasswordPageConnected />
            </Route>
            <Route exact path={paths.customer.basket}>
                <SavedBasket />
            </Route>
            <DelayedRedirect to={paths.customer.account} />
        </AsyncSwitch>
    );
});
