import React from 'react';
import classnames from 'classnames';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';
import { Status } from 'src/components/Orders/Status';

import { Price } from '../Helpers/Price';

export type OrderDetailProps = {
    orderNumber: string;
    placed: string;
    total: string | number;
    status: string;
    loading?: boolean;
    className?: string;
};

export const OrderDetails: React.FC<OrderDetailProps> = React.memo((props) => {
    return (
        <table
            className={classnames({
                ['tablePlain']: true,
                [props.className || '']: true,
            })}
        >
            <tr>
                <td>Order:</td>
                <td>
                    {props.loading && <SkeletonLine width={6} />} {!props.loading && props.orderNumber}
                </td>
            </tr>
            <tr>
                <td>Placed</td>
                <td>
                    {props.loading && <SkeletonLine width={5} />} {!props.loading && props.placed}
                </td>
            </tr>
            <tr>
                <td>Total:</td>
                <td>
                    {props.loading && <SkeletonLine width={4} />}
                    {!props.loading && <Price price={props.total} priceIncl={props.total} />}
                </td>
            </tr>
            <tr>
                <td>Status</td>
                <td>
                    {props.loading && <SkeletonLine width={8} />} {!props.loading && <Status status={props.status} />}
                </td>
            </tr>
        </table>
    );
});
