import React, { useEffect, useState, Dispatch } from 'react';
import { Artwork, ArtworkValues } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import { ArtworkSummary } from 'src/components/Orders/ArtworkSummary';
import { Button } from 'src/components/Button/Button';
import { OrderItemArtworkReviewModal } from 'src/components/Orders/OrderItemArtworkReviewModal';
import { getPendingStatusName, cleanArtworks } from 'src/components/Orders/OrderApprovals';
import axios from 'axios';
import { Spinner } from 'src/components/Layout/Spinner';
import { ArtworkStatus } from 'src/components/Orders/utils/order.utils';
import { useDeps } from 'src/hooks/useDeps';
import Bugsnag from '@bugsnag/js';

import classes from './OrderItemArtwork.scss';

type Props = {
    artworkId: number;
    onSubmit: (id: number, values: ArtworkValues, status: string, deps) => void;
};

const fetchArtworkData = async (
    id: number,
    setArtwork: Dispatch<React.SetStateAction<Artwork | null>>,
    setStatus: Dispatch<React.SetStateAction<ArtworkStatus | undefined>>,
    deps,
    setPending: (state: boolean) => void,
) => {
    if (!id) return null;

    try {
        setPending(true);
        const { data } = await axios.get(`/rest/V1/artwork_approval/${id}`, {
            headers: { Authorization: deps.restHeaders({}).Authorization },
        });
        const artwork = cleanArtworks([data])[0];

        if (artwork) {
            setPending(false);
            setArtwork(artwork);
            setStatus(artwork.status);
        }
    } catch (err) {
        Bugsnag.notify(err);
    }
};

export const OrderItemArtwork: React.FC<Props> = ({ artworkId, onSubmit }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pending, setPending] = useState(false);
    const [status, setStatus] = useState<ArtworkStatus>();
    const [artwork, setArtwork] = useState<Artwork | null>(null);

    const deps = useDeps();

    useEffect(() => {
        fetchArtworkData(artworkId, setArtwork, setStatus, deps, setPending);
    }, [artworkId, deps]);

    if (pending) return <Spinner />;

    if (!artwork) return null;

    const handleSubmit = (values: ArtworkValues, status: string) => {
        onSubmit(artworkId, values, status, deps);
        setStatus(getPendingStatusName(status));
        setIsModalOpen(false);
    };

    return (
        <div className={classes.itemArtworkContainer}>
            <ArtworkSummary artwork={artwork} status={status} />
            {status === 'ReviewPending' && (
                <Button type="button" variant="primary" onClick={() => setIsModalOpen(true)}>
                    View Proof
                </Button>
            )}
            <OrderItemArtworkReviewModal
                artwork={artwork}
                isOpen={isModalOpen}
                onDismiss={setIsModalOpen}
                onSubmit={handleSubmit}
            />
        </div>
    );
};
