export enum BundleCustomisationOptions {
    Required = 'Required',
    Optional = 'Optional',
    PlainOnly = 'Plain only',
}

export enum BackendCustomisationOptions {
    Required = '12721',
    Optional = '12722',
    PlainOnly = '12723',
}
