import React from 'react';
import { ProductLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { Customisation } from 'src/components/BundleProduct/types';
import { SelectLocation } from 'src/components/CustomisationPage/steps/SelectLocation/SelectLocation';

import { CustomisationModalWrapper } from './CustomisationModalWrapper';

type Props = {
    locations: ProductLocation[];
    selectedPositions?: string[];
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    closeModal: () => void;
    image: string;
    alreadySelectedPositions?: string[];
    multiplePositions?: boolean;
};

export const CustomisationStepPositionSelect: React.FC<Props> = ({
    closeModal,
    locations,
    image,
    alreadySelectedPositions,
    handleCustomisationSet,
    selectedPositions,
    multiplePositions,
}) => {
    const backgroundStyles = {
        background: 'none',
        padding: '15px 0px 25px 0px',
    };

    return (
        <CustomisationModalWrapper
            image={image}
            titleModal="1. Choose logo position"
            closeModal={closeModal}
            stepName="Position"
        >
            <SelectLocation
                locations={locations}
                alreadySelectedPositions={alreadySelectedPositions}
                backgroundStyles={backgroundStyles}
                selectedPositions={selectedPositions}
                handleCustomisationSet={handleCustomisationSet}
                multiplePositions={multiplePositions}
            />
        </CustomisationModalWrapper>
    );
};
