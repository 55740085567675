import React from 'react';
import { Link } from 'react-router-dom';
import { SkeletonBlock } from 'src/components/Helpers/SkeletonBlock';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';
import {
    CatalogDataProductOptionInterface,
    WorkwearExpressCustomisationPlatformFrontendDataSoldCustomisationInterface,
} from 'swagger/ts/Definitions';

import classes from './OrderDetailItem.scss';

export interface OrderDetailItem {
    sku: string;
    name?: string;
    meta: ItemMeta[];
    qty_ordered?: number;
    comment?: string;
    link: string;
    product_thumbnail: string;
    printing?: ItemPrinting[];
    price?: number;
    product_type?: string;
    additional_data?: string;
    quote_id?: string;
    product_option?: CatalogDataProductOptionInterface;
    customisations?: WorkwearExpressCustomisationPlatformFrontendDataSoldCustomisationInterface[];
}

export type ProductInformations = {
    sku: string;
    stock_status: string;
    url_key: string;
};

type ItemMeta = {
    attribute_label: string;
    attribute_value: string;
};

type ItemPrinting = {
    method: string;
    location: string;
    name: string;
};

export const OrderDetailItemRow: React.FC<OrderDetailItem> = (props) => {
    return (
        <li className={classes.root}>
            <div className={classes.title}>
                <img className={classes.image} src={props.product_thumbnail} alt={props.name} />
                <Link to={`/${props.link}`}>{props.name}</Link>
            </div>
            <div className={classes.meta}>
                <table className="tablePlain">
                    {Array.isArray(props.meta) &&
                        props.meta.map((meta) => {
                            return (
                                <tr key={meta.attribute_label}>
                                    <td>{meta.attribute_label}:</td>
                                    <td>{meta.attribute_value}</td>
                                </tr>
                            );
                        })}
                    <tr>
                        <td>Qty:</td>
                        <td>{props.qty_ordered}</td>
                    </tr>
                </table>
            </div>
        </li>
    );
};

export const OrderDetailItemRowLoading: React.FC = () => {
    return (
        <li className={classes.root}>
            <div className={classes.title}>
                <SkeletonBlock className={classes.image} style={{ height: '60px', minWidth: '60px' }} />
                <Link to="#">
                    <SkeletonLine width={3} /> <SkeletonLine width={6} /> <SkeletonLine width={2} />{' '}
                    <SkeletonLine width={3} /> <SkeletonLine width={6} />
                </Link>
            </div>
            <div className={classes.meta}>
                <table className="tablePlain">
                    <tr>
                        <td>
                            <SkeletonLine width={3} />
                        </td>
                        <td>
                            <SkeletonLine width={5} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SkeletonLine width={3} />
                        </td>
                        <td>
                            <SkeletonLine width={4} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SkeletonLine width={2} />
                        </td>
                        <td>
                            <SkeletonLine width={2} />
                        </td>
                    </tr>
                </table>
            </div>
            <div className={classes.meta}>
                <table className="tablePlain">
                    <tr>
                        <td>
                            <SkeletonLine width={3} />
                        </td>
                        <td>
                            <SkeletonLine width={5} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SkeletonLine width={3} />
                        </td>
                        <td>
                            <SkeletonLine width={4} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SkeletonLine width={2} />
                        </td>
                        <td>
                            <SkeletonLine width={2} />
                        </td>
                    </tr>
                </table>
            </div>
        </li>
    );
};
