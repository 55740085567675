import React from 'react';
import { InlineIcons } from 'src/components/Artwork/InlineIcons';
import { displayTypeNames, getIcons } from 'src/components/Artwork/utils/artwork';
import { ApplicationType, ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { useFontPreview } from 'src/components/CustomisationPage/utils/fontPreview';
import { TextPreview } from 'src/components/CustomisationPage/steps/ConfigureCustomisation/TextPreview';

import classes from './CustomisationBasketSummary.scss';

export type BasketCustomisation = {
    line1: string;
    line2: string;
    line3: string;
    font: string;
    colour: string;
    isContactRequested: boolean;
    isArtworkMissing: boolean;
    method: ApplicationMethod;
    positions: string[];
    details: string;
    type: ApplicationType;
};

type Props = {
    customisation: BasketCustomisation;
};

export const CustomisationBasketSummary: React.FC<Props> = ({ customisation }) => {
    const {
        method,
        type,
        isContactRequested,
        line1,
        line2,
        line3,
        font,
        colour,
        isArtworkMissing,
        positions,
    } = customisation;

    const { fonts } = useFontPreview();

    const selectedOptions = Boolean(colour && font && line1) && {
        color: colour,
        font,
        textLineOne: line1,
        textLineTwo: line2,
        textLineThree: line3,
    };

    return (
        <div className={classes.customisationSummary}>
            {selectedOptions && <TextPreview selectedOptions={selectedOptions} fonts={fonts} noTitle />}
            <div className={classes.info}>
                <span className={classes.application}>
                    <InlineIcons icons={getIcons(method, type)} />
                    <b>{displayTypeNames(method, type, isContactRequested, isArtworkMissing || false)}</b>
                </span>
                <span className={classes.meta}>
                    <span className={classes.locations}>
                        Locations: <b>{positions.join(', ')}</b>
                    </span>
                </span>
            </div>
        </div>
    );
};
