import React, { useState, useContext, useEffect } from 'react';
import { getCardBrand } from 'src/components/Orders/utils/getCardBrand';
import { formatDate } from 'src/util/formatDate';
import { Price } from 'src/components/Helpers/Price';
import { ModalOrderTracking } from 'src/components/Orders/ModalOrderTracking';
import { useMutation, useQuery } from '@apollo/react-hooks';
import mergeCarts from 'src/queries/mergeCarts.graphql';
import { useDeps } from 'src/hooks/useDeps';
import { useDispatch } from 'react-redux';
import { BasketContext } from 'src/components/Basket/BasketContext';
import { Modal } from 'src/components/Modal/Modal';
import { CloseButton } from 'src/components/BundleProduct/CloseButton';
import { ItemDetailsLoading } from 'src/components/Orders/ItemDetailsLoading';
import { useTreatments } from '@splitsoftware/splitio-react';
import ReactTooltip from 'react-tooltip';
import { Button } from 'src/components/Button/Button';
import { ModalHeader } from 'src/components/ProductDetail/ModalHeader';
import customerOrder from 'src/queries/customerOrderAdditionalData.graphql';
import { camelizeKeys } from 'humps';
import { OrderStatusWidget } from 'src/components/Orders/OrderStatusWidget';
import { useHistory } from 'react-router-dom';
import { ExpectedDeliveryDate } from 'src/components/Orders/ExpectedDeliveryDate';
import { OrderItem } from 'src/components/Orders/OrderItem';
import customerInvoice from 'src/queries/customerInvoice.graphql';
import {
    processAdditionalOrderGraphql,
    Addresses,
    processOrderGraphql,
} from 'src/components/Orders/utils/processOrderGraphql';
import { parse } from 'date-fns';
import { parseCustomisationData } from 'src/components/CustomisationBasketSummary/utils/parseCustomisationData';
import {
    CustomisationBasketSummary,
    BasketCustomisation,
} from 'src/components/CustomisationBasketSummary/CustomisationBasketSummary';
import { mergeCarts as mergeCartsUtil } from 'src/components/Orders/utils/mergeCarts';
import classes from 'src/components/Orders/ItemDetails.scss';
import classnames from 'classnames';
import statusMessageFromKhaos from 'src/queries/statusMessageFromKhaos.graphql';

export type Item = {
    sku: string;
    name: string;
    price: number;
    qtyOrdered: number;
    productThumbnail: string;
    extendedDesc: string;
};

type Props = {
    order: {
        invoices: {
            shippingAddress: Addresses;
            billingAddress: Addresses;
            orderDetailItems: Item[];
            code: string;
        }[];
        orderDetails: {
            orderNumber: string;
            placed: string;
            total: string;
            itemsTotal: string;
        };
    };
    incrementId: string;
    soNumber: string;
};

const AddressContent = (address: Addresses) => (
    <span className={classes.address}>
        <p>
            <span>{address.firstName} </span>
            <span>{address.lastName}</span>
        </p>
        <p className={classes.address}>
            <span>{address?.addressLine2}</span>
            <span>{address?.addressLine1}</span>
            <span>{address?.addressLine3}</span>
            <span>{address?.town}</span>
            <span>{address?.postcode}</span>
        </p>
    </span>
);

export const ItemDetailsFromKhaos: React.FC<{ id: string }> = ({ id }) => {
    const { data } = useQuery(customerInvoice, {
        variables: {
            id,
        },
        fetchPolicy: 'network-only',
    });

    if (!data?.customerInvoice) {
        return <ItemDetailsLoading />;
    }

    const processedOrderData = processOrderGraphql(camelizeKeys(data.customerInvoice), id);
    if (!processedOrderData.invoices) return null;

    return (
        <ItemDetailsContent
            order={processedOrderData}
            incrementId={data.customerInvoice?.khaos_invoices[0]?.increment_id || ''}
            soNumber={id}
        />
    );
};

export const ItemDetailsContent: React.FC<Props> = ({ order, incrementId, soNumber }) => {
    const { cartId } = useContext(BasketContext);
    const deps = useDeps();
    const dispatch = useDispatch();
    const [mergeCartsMutation, { data: mergeCartsData }] = useMutation(mergeCarts);

    const history = useHistory();
    const goToPreviousPath = () => {
        history.goBack();
    };

    const { data: statusMessageData, loading } = useQuery(statusMessageFromKhaos, {
        variables: {
            id: soNumber,
        },
        fetchPolicy: 'network-only',
    });

    const statusMessages = camelizeKeys(statusMessageData?.customerInvoice?.khaos_invoices);

    const { data } = useQuery(customerOrder, {
        variables: {
            orderFilters: {
                number: { eq: incrementId },
            },
        },
        fetchPolicy: 'network-only',
    });

    const customerOrderData = camelizeKeys(data?.customer?.orders?.items);

    const additionalOrderData = Boolean(customerOrderData && customerOrderData.length > 0)
        ? processAdditionalOrderGraphql(customerOrderData[0])
        : undefined;

    const { invoices, orderDetails } = order;
    const { billingAddress, shippingAddress } = invoices[0];

    const [isViewTrackingModalOpen, setIsViewTrackingModalOpen] = useState(false);
    const [isArtworksModal, setIsArtworksModal] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [orderShipped, setOrderShipped] = useState(false);
    const [enableArtworks, setEnableArtworks] = useState<BasketCustomisation[]>([]);

    const hasArtwork =
        invoices.findIndex(
            (invoice) =>
                invoice.orderDetailItems.findIndex(
                    (item) => item.sku === 'CUSTOM_GENERAL' && Number(item.qtyOrdered) !== 0,
                ) > 0,
        ) > 0;

    const featureName = 'Account_Reorder';
    const treatments = useTreatments([featureName]);

    const { treatment } = treatments[featureName];

    const isMaskedQuoteId = Boolean(additionalOrderData?.maskedQuoteId);

    useEffect(() => {
        if (!Boolean(mergeCartsData)) return;

        setError(mergeCartsData.addToCartFromOrder.errors.join('\r\n'));
    }, [mergeCartsData]);

    const handleReorder = () => {
        if (!isMaskedQuoteId) {
            setError(`Can't find basket ID`);
        }

        mergeCartsUtil(
            mergeCartsMutation,
            additionalOrderData?.maskedQuoteId || '',
            cartId || '',
            deps,
            dispatch,
            setError,
        );
    };

    const isOfflineOrder = false;

    const ArtworkModal = ({ isOpen, onDismiss }) => (
        <Modal isOpen={isOpen} type="popup" onDismiss={onDismiss} className={classes.artworkModal}>
            <ModalHeader title="Artwork Approval" closeModal={onDismiss} />
            <div className={classes.artworksContainer}>
                <div className={classes.artworksContainer}>
                    {enableArtworks.map((artwork, key) => (
                        <CustomisationBasketSummary key={key} customisation={artwork} />
                    ))}
                </div>
            </div>
        </Modal>
    );

    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <div className={classes.orderTitle}>
                        <Button onClick={goToPreviousPath}>
                            <img className={classes.icon} src="../../../static/icons/arrow-orange-left-thin.svg" />
                        </Button>
                        <p className={classes.title}>
                            <span className={classes.titleOrder}>Order </span>
                            {orderDetails.orderNumber}
                        </p>
                    </div>
                    {orderShipped ? (
                        <div className={classes.trackingInfoButtonContainer}>
                            <button
                                className={classes.trackingInfoButtonDesktop}
                                type="button"
                                onClick={() => setIsViewTrackingModalOpen(true)}
                            >
                                View Tracking Info
                                <img className={classes.icon} src="../../../static/icons/v-right_orange.svg" />
                            </button>
                        </div>
                    ) : additionalOrderData && additionalOrderData?.estimatedShippingDate ? (
                        <div className={classes.estimatedShippingDate}>
                            Est. dispatch date:{' '}
                            {formatDate(
                                String(parse(additionalOrderData.estimatedShippingDate, 'dd/MM/yyyy', new Date())),
                            )}
                        </div>
                    ) : (
                        <ExpectedDeliveryDate id={incrementId} />
                    )}
                </div>
                <div className={classes.orderHeaderDesktop}>
                    <div className={classes.orderHeaderTitles}>
                        <span>Date placed</span>
                        <span>Order ID</span>
                        <span>Items</span>
                        <span>Type</span>
                        <span>Total</span>
                        {additionalOrderData?.cardNumber && <span>Method</span>}
                    </div>
                    <div className={classes.orderHeaderInfo}>
                        <span>{formatDate(String(parse(orderDetails.placed, 'dd/MM/yy', new Date())))}</span>
                        <span>{orderDetails.orderNumber}</span>
                        <span>{orderDetails.itemsTotal}</span>
                        <span className={classes.type}>
                            <img
                                src={`../../../static/icons/${
                                    isOfflineOrder ? 'offline-orders.svg' : 'online-orders.svg'
                                }`}
                                alt="online-orders"
                                data-tip={isOfflineOrder ? 'Offline order' : 'Online order'}
                            />
                            <ReactTooltip type="dark" effect="solid" />
                        </span>
                        <Price
                            price={orderDetails.total}
                            priceIncl={orderDetails.total}
                            className={classes.headerPrice}
                        />

                        {additionalOrderData?.cardNumber && (
                            <span className={classes.payment}>
                                <img
                                    className={classes.iconBrand}
                                    src={getCardBrand(additionalOrderData?.cardBrand || '')}
                                    alt={additionalOrderData?.cardBrand}
                                />
                                <span>{additionalOrderData.cardNumber}</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className={classes.orderHeaderMobile}>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Date placed</span>
                        <span>{formatDate(String(parse(orderDetails.placed, 'dd/MM/yy', new Date())))}</span>
                    </div>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Items</span>
                        <span>{orderDetails.itemsTotal}</span>
                    </div>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Type</span>
                        <span className={classes.type}>
                            <img
                                src={`../../../static/icons/${
                                    isOfflineOrder ? 'offline-orders.svg' : 'online-orders.svg'
                                }`}
                                alt="online-orders"
                                data-tip={isOfflineOrder ? 'Offline order' : 'Online order'}
                            />
                            <ReactTooltip type="dark" effect="solid" />
                        </span>
                    </div>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Total</span>
                        <Price
                            price={orderDetails.total}
                            priceIncl={orderDetails.total}
                            className={classes.headerPrice}
                        />
                    </div>
                    {additionalOrderData?.cardNumber && (
                        <span className={classes.payment}>
                            <img
                                className={classes.iconBrand}
                                src={getCardBrand(additionalOrderData?.cardBrand || '')}
                                alt={additionalOrderData?.cardBrand}
                            />
                            <span>{additionalOrderData.cardNumber}</span>
                        </span>
                    )}
                </div>
                <div className={classnames(classes.itemsContainer, classes.itemsInvoices)}>
                    <div>
                        {invoices.map((invoice, key) => {
                            const artworks: BasketCustomisation[] = [];

                            const statusMessage = statusMessages
                                ? statusMessages.find((statusMessage) => statusMessage.code === invoice.code)
                                : '';

                            return (
                                <div className={classes.invoice} key={key}>
                                    <h2 className={classes.invoiceNr}>{invoice.code}</h2>

                                    {invoice.orderDetailItems.map((item, key) => {
                                        if (
                                            item.sku !== 'default_customisation' &&
                                            item.sku !== 'setup_charge' &&
                                            item.sku !== 'CUSTOM_GENERAL' &&
                                            Number(item.qtyOrdered) !== 0 &&
                                            Number(item.price) !== 0
                                        )
                                            return <OrderItem key={key} item={item} />;
                                    })}

                                    {invoice.orderDetailItems.map((item, key) => {
                                        if (item.sku === 'CUSTOM_GENERAL' && Number(item.qtyOrdered) !== 0) {
                                            const parsedData = parseCustomisationData(item.extendedDesc);
                                            artworks.push(parsedData);
                                        }
                                    })}

                                    {artworks.length > 0 && (
                                        <div className={classes.buttonsContainer}>
                                            <Button
                                                className={classes.button}
                                                type="button"
                                                onClick={() => {
                                                    setEnableArtworks(artworks);
                                                    setIsArtworksModal(true);
                                                }}
                                            >
                                                Check artwork
                                            </Button>
                                            {treatment === 'on' && isMaskedQuoteId && (
                                                <Button
                                                    className={classes.button}
                                                    type="button"
                                                    onClick={handleReorder}
                                                >
                                                    Reorder
                                                </Button>
                                            )}
                                        </div>
                                    )}

                                    <OrderStatusWidget
                                        orderStatus={statusMessage.status}
                                        setOrderShipped={setOrderShipped}
                                        hasArtwork={hasArtwork}
                                        statusMessage={statusMessage.statusMessage}
                                        loading={loading}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className={classes.details}>
                    <div className={classes.billing}>
                        <p>Billing Address</p>
                        {AddressContent(billingAddress)}
                    </div>
                    <div className={classes.delivery}>
                        <p>Delivery Address</p>
                        {AddressContent(shippingAddress)}
                    </div>
                </div>
            </div>
            <ArtworkModal isOpen={isArtworksModal} onDismiss={() => setIsArtworksModal(false)} />
            <ModalOrderTracking
                footprint={Math.round(Math.random() * 1e6)}
                onDismiss={() => setIsViewTrackingModalOpen(false)}
                isModalOpen={isViewTrackingModalOpen}
                orderInfo={{ orderId: orderDetails.orderNumber, postcode: shippingAddress?.postcode || '' }}
            />
            <Modal className={classes.errorModal} type="popup" isOpen={Boolean(error)} onDismiss={() => setError(null)}>
                <div className={classes.modalHeader}>
                    <CloseButton onClick={() => setError(null)} background="gray" />
                </div>
                <div className={classes.errorText}>
                    <p>Some items cannot be added:</p>
                    <p>{error}</p>
                </div>
            </Modal>
        </>
    );
};
