import React from 'react';
import { Artwork } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import { ArtworkSummary } from 'src/components/Orders/ArtworkSummary';

import { ArtworkApprovalCommentsWithoutPopup } from './ArtworkApprovalComments';
import classes from './ArtworkApproval.scss';

type Props = {
    artwork: Artwork;
};

export const ArtworkApprovalSummary: React.FC<Props> = ({ artwork }) => (
    <div className={classes.wrapper}>
        <div className={classes.summary} data-status={artwork.status}>
            <small className={classes.summaryHeader} data-status={artwork.status}>
                Review and approve artwork
            </small>
            <ArtworkSummary artwork={artwork} />
        </div>
        <ArtworkApprovalCommentsWithoutPopup comments={artwork.comments} />
    </div>
);
