import Axios from 'axios';
import { CartId, Deps } from 'src/types/global-types';

const handleRequest = async (data, deps, url) => {
    const headers = {
        Accept: 'application/json',
        authorization: deps.restHeaders({}).Authorization,
    };

    return Axios({
        method: 'POST',
        url,
        headers,
        data,
    });
};

export const newCustomisationUser = async (data, deps: Deps) => {
    const operationId =
        'workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1SaveCustomisationsPost';
    const path = '/V1/customisations/mine/itemCustomisations';
    const url = deps.apiUrl(operationId)(path, undefined);

    return handleRequest(data, deps, url);
};

export const newCustomisationGuest = async (data, deps: Deps, cartId: CartId) => {
    const operationId =
        'workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1SaveGuestCustomisationsPost';
    const path = '/V1/customisations/{cartId}/itemCustomisations';
    const url = deps.apiUrl(operationId)(path, cartId);

    return handleRequest(data, deps, url);
};

export const editCustomisationUser = async (data, deps: Deps) => {
    const operationId =
        'workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1UpdateCustomisationsPost';
    const path = '/V1/customisations/mine/updateItemCustomisations';
    const url = deps.apiUrl(operationId)(path, undefined);

    return handleRequest(data, deps, url);
};

export const editCustomisationGuest = async (data, deps: Deps, cartId: CartId) => {
    const operationId =
        'workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1UpdateGuestCustomisationsPost';
    const path = '/V1/customisations/{cartId}/updateItemCustomisations';
    const url = deps.apiUrl(operationId)(path, cartId);

    return handleRequest(data, deps, url);
};

export const removeCustomisationUser = async (data, deps: Deps) => {
    const operationId =
        'workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1DeleteCustomisationsPost';
    const path = '/V1/customisations/mine/removeItemCustomisations';
    const url = deps.apiUrl(operationId)(path, undefined);

    return handleRequest(data, deps, url);
};

export const removeCustomisationGuest = async (data, deps: Deps, cartId: CartId) => {
    const operationId =
        'workwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1DeleteGuestCustomisationsPost';
    const path = '/V1/customisations/{cartId}/removeItemCustomisations';
    const url = deps.apiUrl(operationId)(path, cartId);

    return handleRequest(data, deps, url);
};
