import React from 'react';
import { Container } from 'src/components/Layout';
import { PageTitle } from 'src/components/Layout/PageTitle';

import { MagicLinkInner } from '../SignIn/MagicLink';

export const MagicLinkPage: React.FC = React.memo(() => {
    return (
        <Container variant="modal-narrow">
            <PageTitle title="Customer Passwordless Login" />
            <div className="card">
                <MagicLinkInner />
            </div>
        </Container>
    );
});
