import React from 'react';
import { Text } from '@wearejh/rx-form';
import { ListboxButton, ListboxInput, ListboxList, ListboxOption, ListboxPopover } from '@reach/listbox';
import toString from 'lodash/toString';

import classes from './Dropdown.scss';

export interface Option {
    name: string;
    value: string;
    image?: string;
    swatch?: string;
    price?: string;
    subtitle?: string;
}

type Props = {
    label: string;
    options: Option[];
    field: string;
    onChange(...value: any);
    initialValue?: any;
    currentValue?: string | number;
    showOnlyImage?: boolean;
};

export const Dropdown: React.FC<Props> = ({
    label,
    options,
    field,
    onChange,
    currentValue,
    initialValue,
    showOnlyImage,
}) => {
    let computedCurrentValue = initialValue;
    if (typeof currentValue !== undefined) computedCurrentValue = toString(currentValue);

    const swatchesForValues = options.reduce((acc, item) => {
        return {
            ...acc,
            [item.value]: item.swatch,
        };
    }, {});
    const imagesForValues = options.reduce((acc, item) => {
        return {
            ...acc,
            [item.value]: item.image,
        };
    }, {});
    const priceForValues = options.reduce((acc, item) => {
        return {
            ...acc,
            [item.value]: item.price,
        };
    }, {});
    const subtitleForValues = options.reduce((acc, item) => {
        return {
            ...acc,
            [item.value]: item.subtitle,
        };
    }, {});

    return (
        <>
            <label>{label}</label>
            <ListboxInput
                onChange={onChange}
                value={initialValue ? undefined : computedCurrentValue}
                defaultValue={initialValue || computedCurrentValue}
                disabled={options.length <= 1}
            >
                {({ value, valueLabel }) => {
                    const showSwatch = swatchesForValues[`${value}`];
                    const showImg = imagesForValues[`${value}`];

                    return (
                        <div className={classes.dropdownContainer}>
                            <ListboxButton arrow={options.length > 1 && <div />}>
                                <span data-value={value} style={{ display: 'flex' }}>
                                    {showSwatch && (
                                        <span
                                            data-testid={`swatch-${value}`}
                                            style={{
                                                display: 'inline-block',
                                                borderRadius: '50%',
                                                width: '14px',
                                                height: '14px',
                                                marginRight: '14px',
                                                marginTop: '2px',
                                                backgroundColor: swatchesForValues[`${value}`],
                                            }}
                                        />
                                    )}
                                    <div className="dropdownValueContainer">
                                        {showImg && (
                                            <img
                                                data-testid={`image-${value}`}
                                                className={showOnlyImage ? classes.fullImage : classes.imageSwatch}
                                                src={imagesForValues[`${value}`]}
                                                alt=""
                                            />
                                        )}
                                        {!showOnlyImage && (
                                            <>
                                                <span className={classes.dropdownTitle}>
                                                    {valueLabel}
                                                    <br />
                                                    {subtitleForValues[`${value}`] && (
                                                        <b>{subtitleForValues[`${value}`]}</b>
                                                    )}
                                                </span>
                                                {priceForValues[`${value}`] && (
                                                    <span className="dropdownValuePrice">
                                                        {priceForValues[`${value}`]}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </span>
                            </ListboxButton>
                            {options.length > 0 && (
                                <ListboxPopover portal={false}>
                                    <ListboxList>
                                        {options.map((option) => (
                                            <ListboxOption key={option.value} value={option.value} label={option.name}>
                                                {option.image && (
                                                    <>
                                                        <img
                                                            data-testid={`image-${option.name}`}
                                                            className={
                                                                showOnlyImage ? classes.fullImage : classes.imageSwatch
                                                            }
                                                            src={option.image}
                                                            alt=""
                                                        />

                                                        {!showOnlyImage && (
                                                            <span className={classes.optionName}>{option.name}</span>
                                                        )}
                                                    </>
                                                )}
                                                {option.swatch && (
                                                    <>
                                                        <span
                                                            data-testid={`swatch-${option.name}`}
                                                            style={{
                                                                display: 'inline-block',
                                                                borderRadius: '50%',
                                                                width: '14px',
                                                                height: '14px',
                                                                marginRight: '14px',
                                                                marginTop: '2px',
                                                                backgroundColor: option.swatch,
                                                            }}
                                                        />
                                                        <span className={classes.optionName}>{option.name}</span>
                                                    </>
                                                )}
                                                {!option.image && !option.swatch && (
                                                    <div className="dropdownValueContainer">
                                                        <span>
                                                            <span className={classes.optionName}>{option.name}</span>
                                                            <br />
                                                            {option.subtitle && <b>{option.subtitle}</b>}
                                                        </span>
                                                        {option.price && (
                                                            <span className="dropdownValuePrice">{option.price}</span>
                                                        )}
                                                    </div>
                                                )}
                                            </ListboxOption>
                                        ))}
                                    </ListboxList>
                                </ListboxPopover>
                            )}
                        </div>
                    );
                }}
            </ListboxInput>
            <Text field={field} hidden />
        </>
    );
};
