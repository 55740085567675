import React from 'react';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';

import classes from './CustomerOrderLoading.scss';

export const CustomerOrderLoading: React.FC = React.memo(() => (
    <div className={classes.wrapper}>
        <div className={classes.header}></div>
        {[...Array(4).keys()].map((key) => (
            <div className={classes.row} key={key}>
                <SkeletonLine width={4} />
                <SkeletonLine width={4} />
                <SkeletonLine width={4} />
                <SkeletonLine width={4} />
                <SkeletonLine width={4} />
                <SkeletonLine width={4} />
            </div>
        ))}
    </div>
));
