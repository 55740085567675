import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import {
    AppendState,
    artworkApprovalRegister,
    Msg,
} from 'src/components/ArtworkApproval/feature/artworkApproval.register';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    RequestState,
    useArtworkGuestRequest,
    useArtworkRequest,
} from 'src/components/ArtworkApproval/hooks/useArtworkRequest';
import { StoreState } from 'src/types/global-types';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { ArtworkApprovalSkeleton } from 'src/components/ArtworkApproval/ArtworkApprovalSkeleton';
import { cleanArtworks } from 'src/components/Orders/OrderApprovals';
import { FromCustomisation } from 'src/components/CustomisationPage/feature/customisation.actions';
import { ApplicationMethod, ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { ArtworkStatus } from 'src/components/Orders/utils/order.utils';
import { WorkwearExpressArtworkDataArtworkApprovalCommentInterface } from 'swagger/ts/Definitions';
import { useSearch } from 'src/RootComponents/CatalogSearch/CatalogSearchRoot';
import Bugsnag from '@bugsnag/js';

export interface ApprovalValueAPi {
    id: number | string;
    status: string;
    comment: string;
}

export interface ArtworkValues {
    comment: string;
}

export interface Artwork {
    status?: ArtworkStatus;
    location_name: string[];
    id: number;
    orderId: number;
    artwork: FromCustomisation;
    comments: WorkwearExpressArtworkDataArtworkApprovalCommentInterface[];
    samples: string[];
    status_update_allowed: boolean;
}

export const ArtworkApprovalContext = React.createContext<{
    isGuest: boolean;
    ready: boolean;
    artwork: Artwork;
    isPending: boolean;
    onSubmit(values, status);
    onGuestSubmit(values, status);
    state: RequestState;
}>({
    isGuest: false,
    ready: false,
    artwork: {
        status: ArtworkStatus.ReviewPending,
        location_name: [''],
        id: 0,
        orderId: 0,
        artwork: {
            application_method: ApplicationMethod.Embroidery,
            application_type: ApplicationType.Logo,
            textLineOne: '',
            textLineTwo: '',
            textLineThree: '',
            color: '',
            font: '',
            image: '',
            customisationName: '',
            specialInstructions: '',
            isContactRequested: false,
        },
        comments: [],
        samples: [],
        status_update_allowed: false,
    },
    isPending: false,
    onSubmit: () => {
        Bugsnag.notify(
            'onSubmit not defined, did you wrap your components like this: <ArtworkApprovalProvider>...</ArtworkApprovalProvider>',
        );
    },
    onGuestSubmit: () => {
        Bugsnag.notify(
            'onGuestSubmit not defined, did you wrap your components like this: <ArtworkApprovalProvider>...</ArtworkApprovalProvider>',
        );
    },
    state: {
        status: DataStatus.Idle,
        messages: [],
    },
});

function stateSelector(state: AppendState<StoreState>) {
    return {
        artworkApproval: state.artworkApproval,
        isPending: state.artworkApproval
            ? state.artworkApproval.status === DataStatus.Idle || state.artworkApproval.status === DataStatus.Pending
            : true,
    };
}

export const ArtworkApprovalProvider: React.FC = React.memo((props) => {
    // if logged in the id will be apart of the url
    const { artworkId } = useParams() as Record<string, any>;

    // if directed from email the token will be apart of the url
    const search = useSearch();
    const [token, setToken] = useState('');

    const ready = useLazyRegister(artworkApprovalRegister, true, 'ArtworkApprovalProvider');
    const dispatch = useDispatch();

    const { state, submit } = useArtworkRequest();
    const { state: guestState, submit: guestSubmit } = useArtworkGuestRequest();

    const reduxState = useSelector(stateSelector, shallowEqual);

    useEffect(() => {
        if (artworkId) {
            dispatch(Msg('Artwork.Fetch', { artworkApprovalId: Number(artworkId) }));
            return;
        }
        const params = new URLSearchParams(search);
        const token = params.get('token');

        if (!token) {
            Bugsnag.notify('Guest artwork approval screen was loaded, but the token was missing', (event) => {
                event.addMetadata('token', { token: token });
            });
        } else {
            setToken(token);
            dispatch(Msg('ArtworkGuest.Fetch', { token: token }));
        }
    }, [dispatch, artworkId, search]);

    function setSubmitValues(values: ArtworkValues, status: string) {
        const payload = {
            id: artworkId,
            status: status,
            comment: values.comment,
        } as ApprovalValueAPi;
        submit(payload);
    }

    function setGuestSubmitValues(values: ArtworkValues, status: string) {
        const payload = {
            id: token,
            status: status,
            comment: values.comment,
        } as ApprovalValueAPi;

        guestSubmit(payload);
    }

    if (!ready) return <ArtworkApprovalSkeleton />;

    const artwork = cleanArtworks([reduxState.artworkApproval.artworkData]);
    const first = artwork[0];

    return (
        <ArtworkApprovalContext.Provider
            value={{
                isGuest: !artworkId,
                ready: ready,
                artwork: first,
                isPending: reduxState.isPending,
                onSubmit: (values: ArtworkValues, status: string) => setSubmitValues(values, status),
                onGuestSubmit: (values: ArtworkValues, status: string) => setGuestSubmitValues(values, status),
                state: state || guestState,
            }}
        >
            {props.children}
        </ArtworkApprovalContext.Provider>
    );
});

export default ArtworkApprovalProvider;
