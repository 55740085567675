import { AjaxError } from 'rxjs/ajax';
import { ErrorErrorsItem } from '@wearejh/swagger-rxjs/lib/Definitions';
import dlv from 'dlv';

import { text } from './text';

export type FormKeysType = { [index: string]: { key: string; value: string } };

export function replaceMessage(message, params = [], formKeys?: FormKeysType) {
    let index = 0;
    return message.replace(/%[a-zA-Z0-9]/g, function () {
        const val = (formKeys && formKeys[params[index]] && formKeys[params[index]].value) || params[index];
        index += 1;
        return val;
    });
}

function replaceMessageObject(message, params = {}, formKeys?: FormKeysType) {
    return Object.keys(params).reduce((acc, key) => {
        return acc.replace(
            new RegExp(`%${key}`, 'g'),
            (formKeys && formKeys[params[key]] && formKeys[params[key]].value) || params[key],
        );
    }, message);
}

function stripGenericAjax(input) {
    if (input.match(/ajax error (\d){3}/)) {
        return text('generic.error');
    }
    return input;
}

export function extractError(err: AjaxError, formKeys?: FormKeysType): string {
    const message = dlv(err, 'response.message') || dlv(err, 'message', 'Rest API error');
    const params = dlv(err, 'response.parameters');
    const output = (() => {
        if (params) {
            if (Array.isArray(params)) {
                return replaceMessage(message, params as any, formKeys);
            } else {
                return replaceMessageObject(message, params as any, formKeys);
            }
        }
        return message;
    })();

    const transforms = [stripGenericAjax];

    return transforms.reduce((acc, item) => item(acc), output);
}

export function extractErrorAndCode(
    err: AjaxError,
): { error: string; code?: number; status: number; errors: ErrorErrorsItem[] } {
    const error = extractError(err);
    const code = dlv(err, 'response.code');
    const errors = (err.response && err.response.errors) || [];
    if (error && code) {
        return { error, code, status: err.status, errors };
    }
    return { error, status: err.status, errors };
}
