import { CartItem } from '@wearejh/m2-pwa-cart-gql';
import { EMPTY_IMAGE, itemImagesFromQuery } from 'src/components/Basket/utils/itemImagesFromQuery';
import { csrItemDiscount } from 'src/components/Basket/utils/csr-item-discounts';
import { BasketCustomisationsMapping } from 'src/components/Basket/feature/basket.reducer';
import { getCart_getCart } from 'src/queries/__generated__/getCart';

export const getBasketItems = (
    cartItemsWithNoVirtualItem: CartItem[],
    customisations: BasketCustomisationsMapping | null,
    raw: getCart_getCart | null,
) => {
    const customisationCostsArray = raw?.items?.filter((item) => item?.product.sku === 'default_customisation');
    const images = itemImagesFromQuery(raw);
    const discounts = csrItemDiscount(raw);

    return cartItemsWithNoVirtualItem.map((cartItem: CartItem) => {
        const match = customisations?.[cartItem.id];

        const calculatedCustomisationCost =
            customisationCostsArray?.filter((cost) => cost?.product.name?.includes(cartItem.id))[0]?.prices?.row_total
                .value || 0;

        let customisationDiscount = 0;
        const productsWithDiscounts = raw?.items?.filter((el) => el?.prices?.discounts);

        productsWithDiscounts?.map((product) => {
            if (product?.product?.name?.includes(cartItem.id)) {
                customisationDiscount = product?.prices?.discounts?.[0]?.amount.value || 0;
            }
        });

        const bundleOptions = raw?.items?.filter((el) => el?.id === cartItem.id)[0];

        const nextCartItem = {
            ...cartItem,
            bundle_options: cartItem.__typename === 'BundleCartItem' && bundleOptions?.bundle_options,
            product: {
                ...cartItem.product,
                small_image: images.get(cartItem.id) || EMPTY_IMAGE,
            },
            customisationDiscount,
        };

        if (match) {
            return {
                cartItem: nextCartItem,
                customisations: match.customisations,
                availableLocations: match.availableLocations,
                customisationsCost: calculatedCustomisationCost,
                csr_discount: discounts.get(cartItem.id) ?? 0,
            };
        }
        return {
            cartItem: nextCartItem,
            customisations: [],
            availableLocations: [],
            customisationsCost: calculatedCustomisationCost,
            csr_discount: 0,
        };
    });
};
