import React from 'react';
import { SkeletonBlock } from 'src/components/Helpers/SkeletonBlock';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';

import classes from './ArtworkApproval.scss';
export function ArtworkApprovalSkeleton() {
    return (
        <div className={classes.root}>
            <div className={classes.summary}>
                <div className={classes.summaryContent}>
                    <SkeletonLine />
                    <SkeletonLine />
                    <SkeletonLine />
                    <SkeletonLine width={10} />
                </div>
            </div>
            <div className={classes.proof}>
                <SkeletonLine width={10} />
                <p>
                    <SkeletonLine width={5} />
                </p>
                <SkeletonBlock className={classes.figure} />
            </div>
            <div className={classes.actions}>
                <div className={classes.actionsNotes}>
                    <SkeletonLine width={10} />
                    <SkeletonLine />
                </div>
                <div className={classes.actionsSubmit}>
                    <SkeletonLine width={10} />
                    <SkeletonLine />
                    <SkeletonLine />
                </div>
            </div>
        </div>
    );
}
