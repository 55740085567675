import React from 'react';
import { RxValidateFn } from '@wearejh/rx-form/lib/types';
import { RxTextArea } from '@wearejh/rx-form';

import { ErrorFor } from './ErrorFor';

type Props = {
    id: string;
    field: string;
    label: string;
    placeholder?: string;
    validate?: RxValidateFn;
    validateOnChange?: boolean;
    required?: boolean;
    onChange?: (...args: any[]) => void;
};

export const TextArea: React.FC<Props> = ({
    label,
    id,
    field,
    placeholder,
    validate,
    validateOnChange,
    required,
    onChange,
}) => {
    return (
        <>
            <label htmlFor={id}>
                {label}
                {required && ' *'}
            </label>
            <RxTextArea
                field={field}
                id={id}
                placeholder={placeholder}
                validate={validate}
                validateOnChange={validateOnChange}
                onChange={onChange}
            />
            <ErrorFor field={field} />
        </>
    );
};
