import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { errorMessage } from '@wearejh/m2-pwa-engine/lib/utils/general';
import {
    AvailableLocation,
    BasketItemCustomisation,
} from 'src/components/CustomisationPage/feature/customisation.types';

import { Actions } from './basket.actions';
import { Step } from './basket.types';

export type BasketCustomisationItem = {
    customisations: BasketItemCustomisation[];
    availableLocations: AvailableLocation[];
    setupCharge?: number;
};

export type BasketCustomisationsOnRefresh = {
    updatedAt: string;
    customisations: BasketCustomisationsMapping;
    parsedCardId: string;
};

export type BasketCustomisationsMapping = {
    [index: string]: BasketCustomisationItem;
};

export type BasketState = {
    status: DataStatus;
    messages: Message[];
    step: Step;
    customisations: BasketCustomisationsMapping;
    itemsPending: number[];
    updatedAt: string | null;
    parsedCardId: string | null;
};

const EMPTY_OJB = {};
export const initialState: BasketState = {
    status: DataStatus.Pending,
    messages: [],
    step: Step.List,
    customisations: EMPTY_OJB,
    itemsPending: [],
    updatedAt: null,
    parsedCardId: null,
};

export function basketReducer(state = initialState, action: Actions): BasketState {
    switch (action.type) {
        case 'Basket.LoadStep': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'Basket.Refresh': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'Basket.RefreshSuccess': {
            return {
                ...state,
                status: DataStatus.Success,
                customisations: action.payload.customisations,
                updatedAt: action.payload.updatedAt,
                parsedCardId: action.payload.parsedCardId,
                messages: [],
                itemsPending: [],
            };
        }
        case 'Basket.RefreshError': {
            return {
                ...state,
                status: DataStatus.Error,
                customisations: EMPTY_OJB,
                updatedAt: null,
                parsedCardId: null,
                messages: [errorMessage(action.payload)],
            };
        }
        case 'Basket.RemoveCustomisations': {
            const first = action.payload[0];
            return {
                ...state,
                itemsPending: [first.item_id],
                messages: [],
            };
        }
        case 'Basket.RemoveCustomisationsSuccess': {
            return {
                ...state,
                itemsPending: [],
            };
        }
        case 'Basket.RemoveCustomisationsError': {
            return {
                ...state,
                itemsPending: [],
                messages: [errorMessage(action.payload)],
            };
        }
        case 'Basket.CopyCustomisations': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'Basket.CopyCustomisationsSuccess': {
            return {
                ...state,
                status: DataStatus.Success,
                itemsPending: [],
            };
        }
        case 'Basket.CopyCustomisationsError': {
            return {
                ...state,
                itemsPending: [],
                messages: [errorMessage(action.payload)],
            };
        }
        default:
            return state;
    }
}
