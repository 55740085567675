import React from 'react';
import { useFieldError } from '@wearejh/rx-form/lib/hooks/useFieldError';
import { useSubmitCount } from '@wearejh/rx-form/lib/hooks/useSubmitCount';

import { Input } from '../Forms/Input';
import { validateConfirm, validatePassword, basicEmailValidation } from '../../util/forms';
import { Field } from '../Forms/Field';

import classes from './PasswordConfirm.scss';

type Props = {
    required?: boolean;
};

export const PW_FIELD = 'password';
export const PW_CONFIRM_FIELD = 'confirmation';
export const PW_EMAIL = 'email';

export const PasswordConfirm: React.FC<Props> = (props) => {
    const { required = true } = props;
    const passwordError = useFieldError(PW_FIELD);
    const confirmationError = useFieldError(PW_CONFIRM_FIELD);
    const submits = useSubmitCount();

    return (
        <>
            <Field>
                <Input
                    field={PW_EMAIL}
                    id={PW_EMAIL}
                    label="Confirm email"
                    type="text"
                    validate={basicEmailValidation()}
                    required={required}
                />
            </Field>
            <Field>
                <Input
                    field={PW_FIELD}
                    id={PW_FIELD}
                    label="New Password"
                    type="password"
                    validate={validatePassword}
                    notify={submits > 0 || passwordError || confirmationError ? [PW_CONFIRM_FIELD] : []}
                    required={required}
                />
            </Field>
            <span className={classes.formText}>
                <img src={require('../../../static/icons/exclamationMark.svg')} />
                Passwords must contain at least 8 characters, a combination of uppercase letters,
                <br />
                lowercase letters, numbers and symbols.
            </span>
            <Field>
                <Input
                    field={PW_CONFIRM_FIELD}
                    id={PW_CONFIRM_FIELD}
                    label="Confirm New Password"
                    type="password"
                    validate={validateConfirm}
                    notify={submits > 0 || passwordError || confirmationError ? [PW_FIELD] : []}
                    required={required}
                />
            </Field>
        </>
    );
};
