import React from 'react';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { Customisation, SelectedOptions } from 'src/components/BundleProduct/types';
import { ConfigureCustomisation } from 'src/components/CustomisationPage/steps/ConfigureCustomisation/ConfigureCustomisation';

import classes from './MultipleStepCustomisation.scss';
import { CustomisationModalWrapper } from './CustomisationModalWrapper';

type Props = {
    closeModal: () => void;
    selectedType?: Customisation['selectedType'];
    image: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedOptions: SelectedOptions;
};

export const CustomisationStepConfigureSelector: React.FC<Props> = ({
    closeModal,
    selectedType,
    image,
    handleCustomisationSet,
    selectedOptions,
}) => {
    const applicationType = selectedType === 'text' ? ApplicationType.Text : ApplicationType.Logo;

    const backgroundStyles = {
        backgroundColor: 'unset',
        boxShadow: '0 0 4px rgb(35 31 32 / 40%)',
    };

    return (
        <CustomisationModalWrapper
            image={image}
            titleModal="5. Choose logo artwork"
            closeModal={closeModal}
            stepName="Edit Artwork"
        >
            <div className={classes.configureSelector}>
                <ConfigureCustomisation
                    applicationType={applicationType}
                    backgroundStyles={backgroundStyles}
                    handleCustomisationSet={handleCustomisationSet}
                    selectedOptions={selectedOptions}
                />
            </div>
        </CustomisationModalWrapper>
    );
};
