import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSignoutCallback } from '@wearejh/m2-pwa-user/lib/hooks/useSignOut';
import { Button } from 'src/components/Button/Button';
import classnames from 'classnames';
import { paths } from 'src/config.json';
import { Svg } from 'src/components/Svg/Svg';

import { titleCaseString } from '../../util/general';

import { useNavLinks } from './hooks/useNavLinks';
import classes from './AccountNav.scss';

export const AccountNav: React.FC = React.memo(() => {
    const handleSignOut = useSignoutCallback();
    const links = useNavLinks();

    return (
        <nav data-test-id="SideNav">
            <ul className={classes.list}>
                {links.map((link) => {
                    let active = false;

                    if (typeof window !== 'undefined') {
                        const pathnames = window.location.pathname.split('/');
                        const isOrdersPath = Boolean(pathnames.find((pathname) => pathname === 'orders'));

                        active =
                            link.path === window.location.pathname ||
                            (window.location.pathname === paths.customer.account &&
                                link.path === paths.customer.orders) ||
                            (link.path === paths.customer.orders && isOrdersPath);
                    }

                    return (
                        <li
                            key={link.path}
                            className={classnames(classes.item, {
                                [classes.active]: active,
                            })}
                        >
                            <NavLink
                                className={classes.link}
                                activeClassName={classes.linkActive}
                                to={link.path}
                                data-test-id={titleCaseString(`${link.title} Button`)}
                            >
                                <div className={classes.itemContainer}>
                                    <Svg name={link.icon} color={active ? 'orange' : 'grey'} className={classes.icon} />
                                    <span className={classes.title}>{link.title}</span>
                                    <span className={classes.mobileTitle}>{link.mobileTitle}</span>
                                </div>
                            </NavLink>
                        </li>
                    );
                })}
            </ul>
            <Button onClick={handleSignOut} variant="transparent" className={classes.logOut}>
                <Svg name="logout" color="grey" className={classes.icon} />
                <span>Logout</span>
            </Button>
        </nav>
    );
});
