import React from 'react';
import { Svg } from 'src/components/Svg/Svg';
import classes from 'src/components/CustomisationPage/pages/Pages.scss';

import { SvgIconNames } from '../Svg/icons-types';

type ApplicationTypes = {
    isAvailableText?: boolean;
    types: SvgIconNames[];
};

export const ApplicationDesc: React.FC<ApplicationTypes> = ({ isAvailableText, types }) => (
    <>
        {types.map((type) => (
            <div key={type}>
                <Svg name={type} /> <span className={classes.capitalizedText}>{`${type} `} </span>
                {!isAvailableText && 'available'}
            </div>
        ))}
    </>
);
