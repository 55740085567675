import { ActionMap, createMsg } from 'action-typed';
import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { CustomisationFull } from 'src/components/CustomisationPage/feature/customisation.types';
import { errorMessage } from 'src/util/state-helpers';
import { artworkFetch } from 'src/components/Artwork/feature/epics/artworkFetch.epic';

export const NAME = 'artworkGrid';

export type Messages = {
    ['ArtworkGrid.Fetch']: undefined;
    ['ArtworkGrid.FetchSuccess']: CustomisationFull[];
    ['ArtworkGrid.FetchError']: string;
};

export type ArtworkGridState = {
    status: DataStatus;
    messages: Message[];
    customisations: CustomisationFull[];
};

const initialState = {
    status: DataStatus.Pending,
    messages: [],
    customisations: [],
};

export function artworkGridRegister() {
    return {
        epics: [artworkFetch],
        reducers: {
            [NAME]: function (state = initialState, action: ArtworkActions): ArtworkGridState {
                switch (action.type) {
                    case 'ArtworkGrid.Fetch': {
                        return {
                            ...state,
                            status: DataStatus.Pending,
                            messages: [],
                            customisations: [],
                        };
                    }
                    case 'ArtworkGrid.FetchSuccess': {
                        return {
                            ...state,
                            status: DataStatus.Success,
                            messages: [],
                            customisations: action.payload,
                        };
                    }
                    case 'ArtworkGrid.FetchError': {
                        return {
                            ...state,
                            status: DataStatus.Error,
                            messages: [errorMessage(action.payload)],
                            customisations: [],
                        };
                    }
                    default:
                        return state;
                }
            },
        },
        name: NAME,
    };
}

export const ArtworkMsg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type ArtworkActions = TypeMap[keyof TypeMap];
export type AppendState<T = Record<string, unknown>> = T & { artworkGrid: ArtworkGridState };
