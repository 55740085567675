import React, { useState, useContext } from 'react';
import { Price } from 'src/components/Helpers/Price';
import { QtyPicker } from 'src/components/ProductDetail/QtyPicker';
import { BasketContext } from 'src/components/Basket/BasketContext';
import { BasketItemType } from 'src/components/Basket/BasketContext';

import { BasketItemCustomisation } from './BasketItemCustomisation';
import { RemoveItemBtn } from './RemoveItemBtn';
import classes from './BasketItem.scss';

type Props = {
    item: BasketItemType;
};

export const BasketItem: React.FC<Props> = ({ item }) => {
    const { updateItems, deleteItem } = useContext(BasketContext);

    const currentQty = item.cartItem.quantity;

    const [itemQty, setItemQty] = useState(item.cartItem.quantity);
    const [isUpdateQtyOpen, setIsUpdateQtyOpen] = useState(false);

    const onUpdateQty = (quantity: number, id: string) => {
        if (quantity === 0) {
            deleteItem({ itemId: Number(id) });
        } else {
            updateItems([{ itemId: parseInt(id), qty: quantity }]);
        }
        setIsUpdateQtyOpen(false);
    };

    const updateQty = (quantity) => {
        setIsUpdateQtyOpen(true);
        setItemQty(quantity);
    };

    const resetQty = () => {
        setIsUpdateQtyOpen(false);
        setItemQty(currentQty);
    };

    return (
        <>
            <div className={classes.item}>
                <RemoveItemBtn deleteItem={() => deleteItem({ itemId: Number(item.cartItem.id)! })} />
                <div className={classes.itemImage}>
                    <img src={item.cartItem.product.small_image.url} alt={item.cartItem.product.small_image.label} />
                    <span className={classes.qtyCircle}>{item.cartItem.quantity}</span>
                </div>
                <div className={classes.details}>
                    <p className={classes.itemName}>{item.cartItem.product.name}</p>
                    <p className={classes.itemDetails}>
                        {[
                            item.cartItem.product.sku,
                            item.cartItem.configurable_options?.[1].value_label,
                            item.cartItem.configurable_options?.[0].value_label,
                        ]
                            .filter(Boolean)
                            .join(' - ')}
                        <span
                            style={{ background: item.cartItem.configurable_options?.[0].value_label }}
                            className={classes.colorSwatch}
                            data-testid="colorSwatch"
                        />
                    </p>
                </div>
                <div className={classes.actions}>
                    <RemoveItemBtn deleteItem={() => deleteItem({ itemId: Number(item.cartItem.id)! })} />
                    <div className={classes.qtyPicker}>
                        <div>
                            <QtyPicker
                                id={item.cartItem.id}
                                quantity={itemQty}
                                handleChange={(quantity) => updateQty(quantity)}
                                onDecreaseQuantity={(quantity) => quantity > 0 && updateQty(quantity - 1)}
                                onIncreaseQuantity={(quantity) => updateQty(quantity + 1)}
                            />
                        </div>
                        {isUpdateQtyOpen && (
                            <>
                                <button
                                    className={classes.updateBtn}
                                    type="button"
                                    onClick={() => onUpdateQty(itemQty, item.cartItem.id)}
                                >
                                    Update
                                </button>
                                <button className={classes.resetBtn} type="button" onClick={resetQty}>
                                    Reset to {currentQty}
                                </button>
                            </>
                        )}
                    </div>
                    <div className={classes.priceContainer}>
                        <span className={classes.price}>
                            <Price price={item.cartItem.prices.price} priceIncl={item.cartItem.prices.price} />
                        </span>
                        <span className={classes.priceInfo}>(exc. VAT)</span>
                    </div>
                </div>
            </div>
            <BasketItemCustomisation item={item} />
        </>
    );
};
