import { newPasswordRegister } from '@wearejh/m2-pwa-password/lib/newPassword/newPassword.register';
import { addressesRegister } from '@wearejh/m2-pwa-addresses/lib/addresses.register';
import { editRegister } from '@wearejh/m2-pwa-user-edit/lib/user-edit.register';
import { passwordRegister } from '@wearejh/m2-pwa-user-edit/lib/user-password.register';

import { registerCustomerResponse } from './customer-responses.register';
import { editUserResponsesRegister } from './edit-user-responses.register';

export function registerCustomer() {
    return [
        newPasswordRegister(),
        addressesRegister(),
        registerCustomerResponse(),

        /**
         * Editing customer information & Password
         */
        editRegister(),
        passwordRegister(),
        editUserResponsesRegister(),
    ];
}
