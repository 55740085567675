import { ApplicationType, ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';

export const parseCustomisationData = (input: string) => {
    const lines = input.split('\n');

    const positions: string[] = [];
    let details = '';
    let notes = {
        line1: '',
        line2: '',
        line3: '',
        font: '',
        colour: '',
        isContactRequested: false,
        isArtworkMissing: false,
    };

    lines.forEach((item) => {
        const parts = item.split(' - ');
        const type = parts[1].split('-')[1].trim();

        if (type === 'Position') {
            const positionValue = parts[2];
            if (positionValue) {
                positions.push(positionValue);
            }
        }

        if (type === 'Details') {
            const positionValue = parts[2];
            if (positionValue) {
                details = positionValue;
            }
        }

        if (type === 'Notes') {
            const positionValue = parts[2];
            const match = positionValue.match(
                /Text: Line 1: (.*?) Line 2: (.*?) Line 3: (.*?) Font: (.*?) Colour: (.*)/,
            );

            const isFilename = Boolean(positionValue.includes('Filename'));
            const isArtworkMissing = Boolean(details === 'As Previous' && isFilename);
            const isContactRequested = Boolean(details !== 'As Previous' && isFilename);

            if (match) {
                notes = {
                    line1: match[1].trim(),
                    line2: match[2].trim(),
                    line3: match[3].trim(),
                    font: match[4].trim(),
                    colour: match[5].trim(),
                    isContactRequested: false,
                    isArtworkMissing: false,
                };
            } else {
                notes = { ...notes, isContactRequested: isContactRequested, isArtworkMissing: isArtworkMissing };
            }
        }
    });

    const method = (lines[0].split(' - ')[0] as unknown) as ApplicationMethod;
    const type = (lines[0].split(' - ')[1].split(' ')[0] as unknown) as ApplicationType;

    const result = {
        method,
        positions,
        details,
        type,
        ...notes,
    };

    return result;
};
