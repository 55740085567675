import React from 'react';
import classnames from 'classnames';
import classes from 'src/components/Orders/OrderTracking.scss';

type Props = {
    isLiveChatEnabled: boolean;
    openChatClickHandler: () => void;
};

export const ContactSection: React.FC<Props> = ({ isLiveChatEnabled, openChatClickHandler }) => (
    <div className={classes.contactSection}>
        <span className={classes.contactSectionTitle}>Need to contact us?</span>
        <div
            className={classnames(classes.contactSectionCards, {
                [classes.isLiveChatEnabled]: isLiveChatEnabled,
            })}
        >
            {isLiveChatEnabled && (
                <div className={classes.contactSectionCard}>
                    <img
                        className={classes.chatIcon}
                        src={require('../../../static/img/conversation.svg')}
                        alt="liveChatIcon"
                    />
                    <span>Speak to us via Live Chat</span>
                    <button type="button" onClick={openChatClickHandler}>
                        Start live chat
                    </button>
                </div>
            )}
            <div className={classes.contactSectionCard}>
                <img
                    className={classes.phoneIcon}
                    src={require('../../../static/img/phone-call.svg')}
                    alt="phoneIcon"
                />
                <span>Give us a call</span>
                <a href="tel:08000285867">0800 028 5867</a>
            </div>
            <div className={classes.contactSectionCard}>
                <img className={classes.mailIcon} src={require('../../../static/img/envelope.svg')} alt="mailIcon" />
                <span>Send us a message</span>
                <a href="mailto:CustomerSupport@WorkwearExpress.com">Send message</a>
            </div>
        </div>
    </div>
);
