import { WorkwearExpressCustomisationPricingDataPriceEstimateInterface } from 'swagger/ts/Definitions';

export interface Application {
    qty: number;
    itemPrice: number;
    itemPriceIncl: number;
    totalPrice: number;
    totalPriceIncl: number;
}
export interface PriceSummary {
    applications: Application[];
    setupFee: number | undefined;
    productsTotalPrice: number;
    tax: number;
    total: number;
    basketItemCount: number;
}

export function estimateToSummary(estimate: RestEstimate, existing = false, basketItemCount = 1): PriceSummary {
    return {
        productsTotalPrice: estimate.products_total,
        applications: estimate.estimates.map((x) => {
            return {
                qty: x.qty,
                itemPrice: x.per_item_fee,
                itemPriceIncl: x.per_item_fee_incl,
                totalPrice: x.total,
                totalPriceIncl: x.total_incl,
            };
        }),
        setupFee: existing ? undefined : estimate.setup_charge,
        tax: estimate.tax,
        total: estimate.total,
        basketItemCount: basketItemCount,
    };
}

type RestEstimate = WorkwearExpressCustomisationPricingDataPriceEstimateInterface;
