import React from 'react';
import { CustomerDataAddressInterface, QuoteDataAddressInterface } from 'swagger/ts/Definitions';

type Props = {
    address: CustomerDataAddressInterface | QuoteDataAddressInterface;
};

export const Address: React.FC<Props> = (props) => {
    const { firstname, lastname, street, city, postcode, country_id, telephone } = props.address;
    return (
        <address>
            {firstname} {lastname}
            <br />
            {street && street[0]}
            <br />
            {street && street[1] && (
                <>
                    {street[1]}
                    <br />
                </>
            )}
            {city}
            <br />
            {postcode}
            <br />
            {country_id}
            <br />
            <br />
            T: {telephone}
        </address>
    );
};
