import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { BasketMergeCartsMutation } from 'src/components/Basket/BasketContext';
import Bugsnag from '@bugsnag/js';

export const mergeCarts = async (
    mergeCartsMutation: BasketMergeCartsMutation,
    sourceCartId: string,
    destinationCartId: string,
    deps,
    dispatch: React.Dispatch<any>,
    setError: (error: string) => void,
) => {
    try {
        await mergeCartsMutation({
            context: {
                headers: {
                    Authorization: `Bearer ${deps.restHeaders({}).Authorization}`,
                },
            },
            variables: { source_cart_id: sourceCartId, destination_cart_id: destinationCartId },
        });
        dispatch(CartMsg('Cart.Refresh', { force: true }));
    } catch (error) {
        Bugsnag.notify(error);
        setError(error?.graphQLErrors?.map((error) => error.message).join(' ,'));
    }
};
