import { camelizeKeys } from 'humps';
import { OrderDetailItem } from 'src/components/Orders/OrderDetailItem';
import { omit } from 'lodash';
import { CamelizedCustomisationObject } from 'swagger/ts/Definitions';

export const getBundlePayload = (item: OrderDetailItem, sku: string, parsedCardId: string) => {
    const bundle_options = item.product_option?.extension_attributes?.bundle_options?.map((option) => ({
        option_id: option.option_id,
        option_qty: 1,
        option_selections: option.option_selections,
        extension_attributes: {
            option_selections_qty:
                typeof option.option_qty !== 'number'
                    ? option.option_qty.map((qty, i) => ({
                          selection_id: option.option_selections[i],
                          selection_qty: qty,
                      }))
                    : [],
        },
    }));

    const bundled_customisation = item.customisations?.map((customisation) => {
        const customisationObject = camelizeKeys(customisation) as CamelizedCustomisationObject;

        return {
            location: customisation.location_name,
            customisation: {
                ...omit(customisationObject, ['locationName', 'locations', 'orderItemId', 'id']),
                isFree: false,
            },
        };
    });

    return {
        sku,
        quote_id: parsedCardId,
        qty: item.qty_ordered || 0,
        product_option: {
            extension_attributes: {
                bundle_options,
                bundled_customisation,
            },
        },
    };
};
