import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { OrderDetailItem } from 'src/components/Orders/OrderDetailItem';
import { AddProductToCartMutation } from 'src/components/Basket/BasketContext';
import Axios from 'axios';
import Bugsnag from '@bugsnag/js';

import { getBundlePayload } from './getBundlePayload';
import { getProductPayload } from './getProductPayload';

export const reorderProduct = async (
    item: OrderDetailItem,
    cartId: string,
    productSku: string,
    taxFree: boolean,
    deps,
    dispatch: React.Dispatch<any>,
    addConfigurableMutation: AddProductToCartMutation,
    addSimpleProductsToCartMutation: AddProductToCartMutation,
    setError: (error: string) => void,
    parsedCardId: string | null,
) => {
    const mutationBody = {
        context: {
            headers: {
                Authorization: `Bearer ${deps.restHeaders({}).Authorization}`,
            },
        },
        variables: {
            input: {
                cart_id: cartId,
                cart_items: getProductPayload(item, productSku, taxFree).items,
            },
        },
    };

    const cartRefresh = () => {
        dispatch(CartMsg('Cart.Refresh', { force: true }));
    };

    if (item.product_type === 'bundle' || item.product_type === 'wearer_bundle') {
        try {
            await Axios({
                method: 'POST',
                url: '/rest/V1/carts/mine/items',
                headers: { Authorization: `${deps.restHeaders({}).Authorization}` },
                data: { cartItem: getBundlePayload(item, productSku, parsedCardId || '') },
            });
            cartRefresh();
        } catch (error) {
            Bugsnag.notify(error);
            setError(error.response.data.message);
        }
    } else if (item.product_type === 'configurable' || item.product_type === 'simple') {
        try {
            item.product_type === 'configurable'
                ? await addConfigurableMutation(mutationBody)
                : await addSimpleProductsToCartMutation(mutationBody);
            cartRefresh();
        } catch (error) {
            Bugsnag.notify(error);
            setError(error?.graphQLErrors?.map((error) => error.message).join(' ,'));
        }
    }
};
