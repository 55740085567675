import React from 'react';
import classes from 'src/components/Artwork/SubmitNewCustomisation.scss';

type Props = {
    price: string;
    onNewCustomisationSubmit?: () => void;
    freeCustomisation?: boolean;
};

export const SubmitButton: React.FC<Props> = ({ price, onNewCustomisationSubmit, freeCustomisation }) => {
    const onClick = onNewCustomisationSubmit || undefined;
    return (
        <>
            <button className={classes.button} type={onNewCustomisationSubmit ? 'button' : 'submit'} onClick={onClick}>
                <img src={require('../../svg/upload.svg')} alt="upload" />
                <span>Add new logo</span>
            </button>
            {!freeCustomisation && (
                <>
                    <p className={classes.meta}>
                        <span>{price}</span> one-time new logo setup cost
                    </p>
                    <p className={classes.additionalNewIconInfo}>
                        This cost includes the full digitisation of your logo. Don't worry how it looks when it's
                        uploaded, we will send a proof before we begin production!
                    </p>
                </>
            )}
        </>
    );
};
