import React from 'react';
import { Price } from 'src/components/Helpers/Price';
import { parseISO, format } from 'date-fns';
import { Link } from 'react-router-dom';
import { Order } from 'src/features/app/orders.register';
import ReactTooltip from 'react-tooltip';
import classnames from 'classnames';

import classes from './CustomerOrders.scss';

type Props = Order & {
    shipped: boolean | null;
};

const REQUIRED_NUMBER_OF_WORDS = 2;

const OrderType = ({ trimedStatus, className, shipped }) => (
    <span className={className}>
        <img
            src={`../../../static/icons/${
                trimedStatus === '-' ? 'offline-orders.svg' : shipped ? 'online-orders-gray.svg' : 'online-orders.svg'
            }`}
            alt="online-orders"
            data-tip={trimedStatus === '-' ? 'Offline order' : 'Online order'}
        />
        <ReactTooltip type="dark" effect="solid" />
    </span>
);

export const CustomerOrder: React.FC<Props> = ({
    createdAt,
    khaosId,
    grandTotal,
    status,
    totalQtyOrdered = 0,
    items,
    shipped,
}) => {
    const maxLengthOfWords = 2;
    const computedStatus = status.replace(/_/g, ' ');
    const numberOfWordsInStatus = computedStatus.split(' ').length;
    const tooLongStatus = numberOfWordsInStatus > maxLengthOfWords;
    const trimedStatus = computedStatus.split(' ').slice(0, REQUIRED_NUMBER_OF_WORDS).join(' ');

    return (
        <div className={classes.customerOrder}>
            <div className={classes.orderInfo}>
                <div className={classes.img}>
                    <img src={items?.[0].productImage} alt="" />
                </div>
                <span
                    className={classnames(classes.date, {
                        [classes.orderShipped]: shipped,
                    })}
                >
                    <span className={classes.mobileTitle}>Date Placed</span>
                    <span>{format(parseISO(createdAt), 'do MMMM, yyyy')}</span>
                </span>
                <span
                    className={classnames(classes.typeContainer, {
                        [classes.orderShipped]: shipped,
                    })}
                >
                    <span className={classes.mobileTitle}>Type</span>
                    <OrderType trimedStatus={trimedStatus} className={classes.type} shipped={shipped} />
                </span>
                <span
                    className={classnames(classes.orderIdAndQty, {
                        [classes.orderShipped]: shipped,
                    })}
                >
                    <Link
                        to={`/customer/orders/${khaosId}`}
                        className={classnames(classes.order, {
                            [classes.orderShipped]: shipped,
                        })}
                    >
                        <span className={classes.mobileTitle}>Order ID</span>
                        <span className={classes.orderId}>{khaosId || 'pending'}</span>
                    </Link>
                    <span
                        className={classnames(classes.qty, {
                            [classes.orderShipped]: shipped,
                        })}
                    >
                        <span className={classes.mobileTitle}>Items</span>
                        {totalQtyOrdered}
                    </span>
                </span>
                <span
                    className={classnames(classes.price, {
                        [classes.orderShipped]: shipped,
                    })}
                >
                    <span className={classes.mobileTitle}>Total</span>
                    <Price
                        price={grandTotal}
                        priceIncl={grandTotal}
                        className={classnames({
                            [classes.priceShipped]: shipped,
                        })}
                    />
                </span>
                {tooLongStatus && <ReactTooltip type="dark" effect="solid" />}
                <Link
                    className={classnames(classes.manageOrderMobile, {
                        [classes.orderShipped]: shipped,
                    })}
                    to={`/customer/orders/${khaosId}`}
                >
                    <img className={classes.icon} src="../../../static/icons/v-right_gray.svg" />
                </Link>
            </div>
            <Link
                className={classnames(classes.manageOrder, {
                    [classes.orderShipped]: shipped,
                })}
                to={`/customer/orders/${khaosId}`}
            >
                <span className={classes.manageTitle}>Manage Order</span>
                {shipped ? (
                    <img className={classes.icon} src="../../../static/icons/v-right_gray.svg" />
                ) : (
                    <img className={classes.icon} src="../../../static/icons/v-right_orange.svg" />
                )}
            </Link>
        </div>
    );
};
