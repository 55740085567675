import { merge, Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { mapTo } from 'rxjs/operators';
import { scrollToSelector } from '@wearejh/m2-pwa-engine/lib/runtime/utils/scrollTop';
import { Actions, TypeMap } from '@wearejh/m2-pwa-user-edit/lib/user-edit.register';
import { Actions as PWActions, TypeMap as PWTypeMap } from '@wearejh/m2-pwa-user-edit/lib/user-password.register';

/**
 * This epic is here to allow responses to the user's information being edited
 * @param action$
 * @param _
 * @param deps
 */
export function editUserResponsesEpic(action$: Observable<any>) {
    /**
     * On errors, scroll to the top of forms
     */
    const userEditErrors$ = action$.pipe(
        ofType<Actions, TypeMap['Edit.Error']>('Edit.Error'),
        mapTo(scrollToSelector(`[data-test-id="InformationForm"]`)),
    );
    const pwErrors$ = action$.pipe(
        ofType<PWActions, PWTypeMap['Password.Error']>('Password.Error'),
        mapTo(scrollToSelector(`[data-test-id="ChangePasswordForm"]`)),
    );
    /**
     * Convert registration events into a marker that showed we signed
     */
    return merge(userEditErrors$, pwErrors$);
}
