import React from 'react';
import { AsyncSwitch } from '@wearejh/m2-pwa-engine/lib/router';
import { Route } from 'react-router';
import { DelayedRedirect } from '@wearejh/m2-pwa-engine/lib/router/index';
import { RegisterPage } from 'src/components/Customer/RegisterPage';

import { useDeps } from '../../hooks/useDeps';

import { SignInPage } from './SignInPage';
import { ResetPasswordConnected } from './ResetPassword';
import { Forgot } from './Forgot';
import { MagicLinkPage } from './MagicLinkPage';

export const GuestRouter: React.FC = React.memo(() => {
    const { paths } = useDeps();
    return (
        <AsyncSwitch base="/customer">
            <Route exact path={paths.customer.login}>
                <SignInPage />
            </Route>
            <Route exact path={paths.customer.forgotToken}>
                <ResetPasswordConnected />
            </Route>
            <Route exact path={paths.customer.forgot}>
                <Forgot />
            </Route>
            <Route exact path={paths.customer.register}>
                <RegisterPage />
            </Route>
            <Route exact path="/customer/account/passwordless">
                <MagicLinkPage />
            </Route>
            <DelayedRedirect to={paths.customer.login} />
        </AsyncSwitch>
    );
});
