import React from 'react';
import classnames from 'classnames';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { ApplicationDesc } from 'src/components/CustomisationPage/ApplicationDesc';

import { LazyImg } from '../../hooks/useLazyLoad';

import classes from './CustomCheckbox.scss';

type Props = {
    title: string;
    methodName?: string;
    image?: string;
    isBundle?: boolean;
    isDisabled?: boolean;
    availableMethods?: ApplicationMethod;
};

export const CustomisationLocation: React.FC<Props> = ({
    title,
    methodName,
    image,
    isBundle,
    isDisabled,
    availableMethods,
}) => {
    const getMethod = (availableMethods: ApplicationMethod) => {
        switch (availableMethods) {
            case ApplicationMethod.Both:
                return <ApplicationDesc isAvailableText={isBundle} types={['print', 'embroidery']} />;

            case ApplicationMethod.Embroidery:
                return <ApplicationDesc isAvailableText={isBundle} types={['embroidery']} />;

            case ApplicationMethod.Print:
                return <ApplicationDesc isAvailableText={isBundle} types={['print']} />;
        }
    };
    return (
        <div>
            <figure className={classes.imageContainer}>
                <LazyImg className={classes.image} src={image} alt={title} />
            </figure>
            <span className={classes.title}>
                {title}
                {isBundle && <small>(applied to all)</small>}
                {(availableMethods || methodName) && !isDisabled && (
                    <div className={classnames(classes.methodOptions, { [classes.methodNoBundleOptions]: !isBundle })}>
                        {availableMethods && getMethod(availableMethods)}
                        {methodName && methodName}
                    </div>
                )}
            </span>
        </div>
    );
};
