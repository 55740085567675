import React, { useCallback, useMemo, ReactNode } from 'react';
import { assign, doneInvoke, Machine } from 'xstate';
import { useMachine } from '@xstate/react/lib';
import { Button } from 'src/components/Button/Button';
import { ModalBody } from 'src/components/Modal/ModalBody';
import { Modal } from 'src/components/Modal/Modal';
import { map } from 'rxjs/operators';
import { useDeps } from 'src/hooks/useDeps';
import { useCustomerStatus } from '@wearejh/m2-pwa-user/lib/hooks/useCustomerStatus';
import { submitService } from 'src/components/BasketShared/services/submit.service';
import { Body } from 'swagger/ts/WorkwearExpressSharedBasketSharedBasketCreatorV1CreatePost';
import { useCspLoggedInToken } from 'src/components/CustomerServicePortal/hooks/useCspLogin';
import { UserKind } from 'src/components/BasketShared/ShareBasketViaEmailForm';

import { ShareForm } from './ShareForm';
import { ShareFormSuccess } from './ShareFormSuccess';

const initialState = {
    error: null,
    formValues: {
        name: '',
        creatorEmail: '',
        collaboratorEmail: '',
        message: '',
    },
};

const basketShareMachine = Machine(
    {
        id: 'BasketShareMachine',
        initial: 'idle',
        context: {
            ...initialState,
        },
        states: {
            idle: {
                entry: ['assignClearData'],
                on: {
                    OPEN_POPUP: 'showPopup',
                },
            },
            showPopup: {
                on: {
                    SUBMIT: 'submitting',
                    CLOSE_POPUP: 'idle',
                },
            },
            submitting: {
                entry: ['assignFormValue'],
                invoke: {
                    src: 'submitService',
                    onDone: 'success',
                    onError: { target: 'error', actions: 'assignError' },
                },
            },
            success: {
                on: {
                    CLOSE_POPUP: 'idle',
                },
            },
            error: {
                on: {
                    CLOSE_POPUP: 'idle',
                },
            },
        },
    },
    {
        actions: {
            assignFormValue: assign({
                formValues: (ctx, evt: any) => {
                    return evt.values;
                },
            }),
            assignClearData: assign({
                ...initialState,
            }),
            assignError: assign({
                error: (ctx, evt: any) => {
                    return evt.data.response.message;
                },
            }),
        },
        services: {
            submitService: unimplementedService('submitService'),
        },
    },
);

type Props = {
    cartId: string;
    display: 'inline' | 'modal';
    devTools?: boolean;
    shareButtonContent?: ReactNode;
};

export const BasketShareMachine: React.FC<Props> = ({ cartId, display, devTools, shareButtonContent }) => {
    const deps = useDeps();
    const { isSignedIn } = useCustomerStatus();
    const cspToken = useCspLoggedInToken();
    /**
     * The xState machine
     */
    const machine = useMemo(() => {
        return basketShareMachine.withConfig({
            actions: {},
            services: {
                submitService: (ctx, evt) => {
                    const body: Body = {
                        creationRequest: {
                            name: evt.values.name,
                            creator_email: cspToken ? undefined : evt.values.creatorEmail,
                            collaborator_email: evt.values.collaboratorEmail,
                            message: evt.values.message,
                            extension_attributes: {
                                mobile_number: cspToken ? evt.values.mobileNumber : undefined,
                            },
                            async: evt.isSharedEmail,
                        },
                    };
                    return submitService(isSignedIn, cspToken, body, cartId, deps).pipe(
                        map((resp) => {
                            return doneInvoke('submitService', resp);
                        }),
                    );
                },
            },
        });
    }, [deps, cspToken, isSignedIn, cartId]);

    /**
     * Run and interpret the machine
     */
    const [state, send] = useMachine(machine, { devTools: devTools });

    /**
     * Callbacks for events
     */
    const onShare = useCallback(() => {
        send({ type: 'OPEN_POPUP' });
    }, [send]);

    const onCancel = useCallback(() => {
        send({ type: 'CLOSE_POPUP' });
    }, [send]);

    const onSubmit = useCallback(
        (values, isSharedEmail) => {
            send({ type: 'SUBMIT', values, isSharedEmail });
        },
        [send],
    );

    const isPending = state.value === 'submitting';
    const popupOpen = ['showPopup', 'submitting', 'success', 'error'].some(state.matches);

    const button = (
        <Button disabled={isPending} width="full" variant="light" onClick={onShare} data-test-id="ShareBasketTrigger">
            {shareButtonContent}
        </Button>
    );
    const inner = (
        <>
            {state.value !== 'success' && (
                <ShareForm
                    onCancel={onCancel}
                    onSubmit={onSubmit}
                    isPending={isPending}
                    errors={state.context.error}
                    userKind={cspToken ? UserKind.Csp : UserKind.Customer}
                    cartId={cartId}
                />
            )}
            {state.value === 'success' && (
                <ShareFormSuccess
                    onCancel={onCancel}
                    recipientEmail={state.context.formValues.collaboratorEmail}
                    isSharedEmail={state.history?.event?.isSharedEmail}
                />
            )}
        </>
    );

    switch (display) {
        case 'inline':
            return (
                <>
                    {button}
                    {popupOpen && inner}
                </>
            );
        case 'modal':
            return (
                <>
                    {button}
                    <Modal type="narrow" isOpen={popupOpen}>
                        <ModalBody>{inner}</ModalBody>
                    </Modal>
                </>
            );
    }
};

function unimplementedService(name: string) {
    return function () {
        return Promise.reject(new Error(`${name} not implemented`));
    };
}
