import { object, string, array } from 'yup';
import { NOTES_MAX_LENGTH } from 'src/constants/customisation';

export const getValidationSchema = (step) => {
    switch (step) {
        case 1:
            return object({
                selectedPositions: array().min(1),
            });
        case 2:
            return object({
                selectedMethod: string().required(),
            });
        case 3:
            return object({
                selectedArtwork: string()
                    .matches(/^(?!no-state\b)/i)
                    .required(),
            });
        case 4:
            return object({
                selectedType: string()
                    .matches(/^logo$|^text$/)
                    .required(),
            });
        case 5:
            return object({
                selectedType: string()
                    .matches(/^logo$|^text$/)
                    .required(),
                selectedOptions: object()
                    .when('selectedType', {
                        is: (type) => type === 'logo',
                        then: object({
                            isContactRequested: string()
                                .required()
                                .matches(/^upload$|^contact$|^haveMyLogo/),
                            specialInstructions: string().max(NOTES_MAX_LENGTH),
                            image: string().when('isContactRequested', {
                                is: (type) => type === 'upload',
                                then: string().required(),
                            }),
                        }),
                    })
                    .when('selectedType', {
                        is: (type) => type === 'text',
                        then: object({
                            textLineOne: string().required(),
                            textLineTwo: string(),
                            textLineThree: string(),
                            color: string().required(),
                            font: string().required(),
                        }),
                    }),
            });
    }
};
