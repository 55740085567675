import React from 'react';

import { Button } from '../Button/Button';

import classes from './BasketCopySummary.scss';

type Props = {
    closeModal: () => void;
    disabled: boolean;
};

export const BasketCopySummary: React.FC<Props> = ({ closeModal, disabled }) => (
    <div className={classes.summary}>
        <h3 className={classes.summaryTitle}>Additional Costs</h3>
        <div className={classes.summaryActions}>
            <Button width="full" type="submit" disabled={disabled}>
                Copy Customisation
            </Button>
            <Button variant="light" width="full" onClick={closeModal}>
                Cancel
            </Button>
        </div>
    </div>
);
