import React from 'react';
import classnames from 'classnames';
import { getMethodNumber } from 'src/components/CustomisationPage/utils/getMethodNumber';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { Svg } from 'src/components/Svg/Svg';
import { Customisation } from 'src/components/BundleProduct/types';

import classes from './SelectMethod.scss';

type Props = {
    selectedMethod?: string;
    disabled?: boolean;
    method: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    recommendedApplication?: ApplicationMethod;
};

export const MethodOption: React.FC<Props> = (props) => {
    const { selectedMethod, disabled, method, handleCustomisationSet, recommendedApplication } = props;

    const isEmbroidery = method === 'embroidery';
    const isRecommended = isEmbroidery
        ? recommendedApplication === ApplicationMethod.Embroidery
        : recommendedApplication === ApplicationMethod.Print;

    const nameOfMethod = String(getMethodNumber(method));

    return (
        <div className={classes.container}>
            <label
                className={classnames(classes.label, {
                    [classes.labelBorder]: nameOfMethod === selectedMethod,
                    [classes.labelDisabled]: disabled,
                })}
            >
                {isRecommended && <div className={classes.informationRecommended}>Recommended</div>}
                <div className={classes.information}>
                    <img
                        className={classnames(classes.checkIcon, classes.checkIconMobile)}
                        src={require('../../../../svg/check-circle.svg')}
                        alt="check circle"
                    />
                    <input
                        className={classes.input}
                        type="radio"
                        disabled={disabled}
                        checked={selectedMethod == nameOfMethod}
                        name="method"
                        id={nameOfMethod}
                        onChange={(e) => {
                            e.preventDefault();
                            handleCustomisationSet({
                                selectedMethod: nameOfMethod,
                            });
                        }}
                    />

                    <div className={classes.informationWrapper}>
                        <strong className={classes.title}>
                            <span>
                                {isEmbroidery ? 'Embroidery' : 'Print'}
                                {isEmbroidery && <span className={classes.stitching}>{' (Stitching)'}</span>}
                            </span>
                            <Svg className={classes.icon} name={isEmbroidery ? 'embroidery' : 'print'} />
                        </strong>

                        <p className={classes.desc}>
                            {isEmbroidery
                                ? 'Embroidery (or stitching) is detailed and durable which is better suited to uniforms.'
                                : 'The print application method is both vivid and flexible, ideal for general use.'}
                        </p>
                    </div>
                </div>
                <img
                    src={require(`../../../../../static/img/new${isEmbroidery ? 'Embroidery' : 'Print'}.png`)}
                    className={classes.customRadioImg}
                />
                <img
                    className={classes.checkIcon}
                    src={require('../../../../svg/check-circle.svg')}
                    alt="check circle"
                />
            </label>
        </div>
    );
};
