import React from 'react';
import { Customisation } from 'src/components/BundleProduct/types';

import classes from './SelectArtwork.scss';

type AddNewLogoProps = {
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    description: string;
    id: string;
    setNewLogo: (state: boolean) => void;
};

export const AddNewLogo: React.FC<AddNewLogoProps> = ({ description, id, setNewLogo, handleCustomisationSet }) => (
    <button
        onClick={() => {
            handleCustomisationSet({
                selectedArtwork: id,
                selectedArtworkExisting: undefined,
                selectedType: 'logo',
                selectedOptions: {
                    isContactRequested: 'upload',
                },
                isArtworkTemporary: true,
            });
            setNewLogo(true);
        }}
        type="button"
        className={classes.addNewButton}
    >
        <p>{description}</p>
    </button>
);
