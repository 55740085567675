import React from 'react';

import classes from './ToggleSwitch.scss';

type Props = {
    checked: boolean;
    onChange: () => void;
};

export const ToggleSwitch: React.FC<Props> = ({ checked, onChange }) => (
    <label className={classes.switch}>
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className={classes.slider} />
    </label>
);
