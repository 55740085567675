import React from 'react';
import { Price } from 'src/components/Helpers/Price';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';

import classes from './OrderTotals.scss';

export type OrderTotalProps = {
    subtotal: string | number;
    delivery: string | number;
    vat?: string | number;
    total: string | number;
    className?: string;
    loading?: boolean;
};

export const OrderTotals: React.FC<OrderTotalProps> = (props) => {
    if (props.loading)
        return (
            <div className={props.className || ''}>
                <table className={['tablePlain', classes.table].join(' ')}>
                    <tr>
                        <td>Subtotal</td>
                        <td>
                            <SkeletonLine width={4} />
                        </td>
                    </tr>
                    <tr>
                        <td>Delivery</td>
                        <td>
                            <SkeletonLine width={4} />
                        </td>
                    </tr>
                    <tr>
                        <td>Total</td>
                        <td>
                            <strong>
                                <SkeletonLine width={5} />
                            </strong>
                        </td>
                    </tr>
                </table>
            </div>
        );
    return (
        <div className={props.className || ''}>
            <table className={['tablePlain', classes.table].join(' ')}>
                <tr>
                    <td>Subtotal</td>
                    <td>
                        <Price price={props.subtotal} priceIncl={props.subtotal} />
                    </td>
                </tr>
                <tr>
                    <td>Delivery</td>
                    <td>
                        <Price price={props.delivery} priceIncl={props.delivery} />
                    </td>
                </tr>
                {props.vat && (
                    <tr>
                        <td>VAT</td>
                        <td>
                            <Price price={props.vat} priceIncl={props.vat} />
                        </td>
                    </tr>
                )}
                <tr>
                    <td>Total</td>
                    <td>
                        <strong>
                            <Price price={props.total} priceIncl={props.total} />
                        </strong>
                    </td>
                </tr>
            </table>
        </div>
    );
};
