import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "Add/update the specified cart item.";
export const method = "POST";
export const operationId = "quoteCartItemRepositoryV1SavePost";
export const path = "/V1/carts/mine/items";
export interface Body {
    cartItem: Definitions.QuoteDataCartItemInterface;
}
export type Response = Response200 | Response400 | Response401 | ResponseDefault;
export type Response200 = Definitions.QuoteDataCartItemInterface;
export type RespKeys = (keyof Definitions.QuoteDataCartItemInterface)[];
export type Response400 = Definitions.ErrorResponse;
export type Response401 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "postJSON">;
export function execute<T extends RespKeys | undefined>(body: Body, effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.QuoteDataCartItemInterface, T[number]>> : Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, undefined, fields), body, effects.restHeaders({})) as any;
}
