import { usePCAPredict } from 'src/hooks/usePCAPredict';
import { useFormApi } from '@wearejh/rx-form/lib/hooks/useFormApi';
import { useDeps } from 'src/hooks/useDeps';
import { useCallback } from 'react';

export const PCAPredict: React.FC<{
    prefix: string;
    countryCode: string;
    showFields();
    onValueChange?: (property: string, value: string) => void;
}> = ({ prefix, countryCode, showFields, onValueChange }) => {
    const { PCA_FORM_KEY } = useDeps().env;
    const formApi = useFormApi();

    const setValues = useCallback(
        (address) => {
            showFields();
            if (onValueChange) {
                onValueChange('street1', address.FormattedLine1);
                onValueChange('street2', address.FormattedLine2);
                onValueChange('postcode', address.PostalCode);
                onValueChange('city', address.City);
                onValueChange('region', address.Province);
                onValueChange('countryId', address.CountryIso2);
            } else {
                formApi.setValues({
                    'address.street1': address.FormattedLine1,
                    'address.street2': address.FormattedLine2,
                    'address.postcode': address.PostalCode,
                    'address.city': address.City,
                    region: address.Province,
                    'address.country_id': address.CountryIso2,
                });
            }
        },

        [formApi, onValueChange, showFields],
    );

    /**
     * The field mappings
     * @param pca
     * @param prefix
     */
    const getFields = useCallback(
        (pca) => {
            return [
                {
                    element: `${prefix}street1`,
                    field: 'Line1',
                    mode: pca.fieldMode.SEARCH | pca.fieldMode.POPULATE,
                },
                {
                    element: `${prefix}street2`,
                    field: 'Line2',
                    mode: pca.fieldMode.POPULATE,
                },
                {
                    element: `${prefix}postcode`,
                    field: 'PostalCode',
                    mode: pca.fieldMode.POPULATE,
                },
                {
                    element: `${prefix}city`,
                    field: 'City',
                    mode: pca.fieldMode.POPULATE,
                },
                {
                    element: `${prefix}region`,
                    field: 'Province',
                    mode: pca.fieldMode.POPULATE,
                },
            ];
        },
        [prefix],
    );

    usePCAPredict(PCA_FORM_KEY, countryCode, prefix, setValues, getFields);

    return null;
};
