import { Svg } from 'src/components/Svg/Svg';
import React from 'react';
import { ArtworkStatus } from 'src/components/Orders/utils/order.utils';

import classes from './Status.scss';

export function Status(props) {
    const status = {
        pending: {
            icon: 'processing',
            text: 'Your order is being processed',
        },
        complete: {
            icon: 'check',
            text: 'Complete',
        },
        processing: {
            icon: 'processing',
            text: 'Your order is being processed',
        },
        released: {
            icon: 'processing',
            text: "We're beginning to prepare your order",
        },
        picking: {
            icon: 'processing',
            text: 'Your order is being picked by our warehouse team',
        },
        ship: {
            icon: 'processing',
            text: 'Your garments are being packaged for shipping',
        },
        invoicing: {
            icon: 'processing',
            text: 'Your package(s) are being sent to our dispatch area ready for shipping',
        },
        issue: {
            icon: 'delivery',
            text: 'Your package(s) have left us and are en-route!',
        },
        awaiting_stock: {
            icon: 'processing',
            text: "We're beginning to prepare your order",
        },
        new_art_print: {
            icon: 'processing',
            text: 'Your artwork proof is being created',
        },
        new_art_embroidery: {
            icon: 'processing',
            text: 'Your artwork proof is being created',
        },
        print: {
            icon: 'processing',
            text: 'Your garments are getting ready to be printed',
        },
        embroidery: {
            icon: 'processing',
            text: 'Your garments are getting ready to be embroidered',
        },
        transfer: {
            icon: 'processing',
            text: 'Your garments are getting ready to have transfer print applied',
        },
        dtg: {
            icon: 'processing',
            text: 'Your garments are being prepared for direct to garment printed',
        },
        processing_embroidery: {
            icon: 'processing',
            text: 'Your garments are being embroidered',
        },
        processing_print: {
            icon: 'processing',
            text: 'Your garments are being printed',
        },
        processing_transfer: {
            icon: 'processing',
            text: 'Your garments are being printed',
        },
        processing_dtg: {
            icon: 'processing',
            text: 'Your garments are being printed',
        },
        packing: {
            icon: 'processing',
            text: 'Your garments are being man packed into individual boxes',
        },
        pending_artwork_creation: {
            icon: 'processing',
            text: 'Your artwork proof is being created',
        },
        pending_artwork_approval: {
            icon: 'processing',
            text: 'Your artwork proof is ready for approval',
        },
        artwork_approved: {
            icon: 'check',
            text: "We're beginning to prepare your order",
        },
        [ArtworkStatus.ReviewPending]: {
            icon: 'alert',
            text: 'Proof ready for review',
        },
        [ArtworkStatus.RequestPending]: {
            icon: 'processing',
            text: 'Change request pending',
        },
        [ArtworkStatus.ArtworkApproved]: {
            icon: 'check',
            text: 'Artwork Approved',
        },
        [ArtworkStatus.Processing]: {
            icon: 'processing',
            text: 'Processing',
        },
    };

    return (
        <span data-status={props.status} className={classes.status}>
            {props.status && (
                <>
                    <Svg name={status[props.status].icon} />
                    {status[props.status].text}
                </>
            )}
        </span>
    );
}
