import React, { useState, useEffect } from 'react';
import { useRegionField } from 'src/hooks/useRegionField';
import { Fields } from '@wearejh/m2-pwa-addresses/lib/addresses.types';

import { Select } from '../Forms/Select';
import { Input } from '../Forms/Input';

const classes = {} as any;

type Props = {
    onChange?: (property: string, value) => void;
    countryId?: string;
    regionValue?: string;
    regionIdValue?: string;
};

export const RegionField: React.FC<Props> = ({ onChange, countryId, regionValue, regionIdValue }) => {
    const { hasRegionSelect, selectedCountry } = useRegionField(countryId || undefined);
    const [region, setRegion] = useState<string | undefined>(undefined);
    const [regionId, setRegionId] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (region) {
            onChange?.('region', region);
        }
        if (regionId) {
            onChange?.('regionId', regionId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [region, regionId]);

    return (
        <div className={classes.root}>
            {hasRegionSelect && (
                <Select
                    label="County"
                    field={Fields.region_id}
                    id={Fields.region_id}
                    options={
                        selectedCountry.available_regions?.map((region) => ({
                            label: region.name,
                            value: region.id,
                        })) as any
                    }
                    onChange={(value) => setRegionId(value.target.value)}
                    value={regionIdValue}
                    defaultValue={selectedCountry.available_regions?.[0].id}
                />
            )}
            <div hidden={hasRegionSelect}>
                <Input
                    field={Fields.region}
                    label="County"
                    onChange={(value) => setRegion(value.target.value)}
                    value={regionValue}
                />
            </div>
        </div>
    );
};
