export const statusMessages = {
    plain: 'Your order is being processed',
    'customised - as previous': 'Your order is being processed',
    'customised - new': 'Your order is being processed',
    'new artwork emb.': 'Your artwork proof is being created',
    'new artwork print': 'Your artwork proof is being created',
    'in progress': 'Your artwork proof is being created',
    sample: 'Your artwork proof is being created',
    done: 'Your artwork proof is being created',
    'proof sent': 'Your artwork proof is ready for approval',
    're-proof': 'Your artwork is being reproofed',
    'awaiting stock': 'Your items are being picked in one of our warehouses',
    released: 'Your garments are being picked in one of our warehouses',
    picking: 'Your garments are being picked by our warehouse',
    embroidery: 'Your garments are getting ready to be embroidered',
    'embroidery processing': ' Your garments are being embroidered',
    print: 'Your garments are getting ready to be printed',
    'print processing': 'Your garments are being printed',
    transfer: 'Your garments are getting ready to have transfer print applied',
    'transfer processing': 'Your garments are being printed',
    dtg: 'Your garments are being prepared to be printed',
    'dtg processing': 'Your garments are being printed',
    'man packing area': 'Your garments are being man packed into individual boxes',
    shipping: 'Your garments are being packaged for shipping',
    invoicing: 'Your package is being transferred to one of our delivery vans',
    issue: 'Your package has left our facility',
    issued: 'Your package has left our facility',
    'issued - current': 'Your package has left our facility',
    'orders - processing': 'Your garments are in the process of being customised',
    'orders - awaiting stock': 'Your items are being picked in one of our warehouses',
    'orders - released': 'Your garments are being picked in one of our warehouses',
    'orders - staging': 'Your garments are being picked by our warehouse',
    'orders - payment': 'Your order is being processed',
    'orders - picking': 'Your garments are being picked by our warehouse',
    'orders - shipping': 'Your garments are being packaged for shipping',
    'orders - invoicing': 'Your package is being transferred to one of our delivery vans',
    'orders - issue': 'Your package has left our facility',
    'aw. approval dtg': 'Your artwork proof is awaiting your approval',
    'aw. approval print': 'Your artwork proof is awaiting your approval',
    'aw. approval embroidery': 'Your artwork proof is awaiting your approval',
    'aw. approval transfer': 'Your artwork proof is awaiting your approval',
    'your garments are being prepared for direct to garment printed':
        'Your garments are being prepared for direct to garment printed',
    'emb processing': 'Your garments are being embroidered',
    'pre-press': 'Your garments are getting ready to be printed',
    damages: 'Your order is currently in progress',
    'orders - manual hold': 'Your order is currently in progress',
    cancelled: 'Your order has been cancelled',
    'new artwork double': 'Your artwork proof is being created',
    'new artwork emb text': 'Your artwork proof is being created',
    'production hold': 'Your order is currently in progress',
    'issued - archive q1': 'Your package has left our facility',
    'issued - archive q2': 'Your package has left our facility',
    'issued - archive q3': 'Your package has left our facility',
    'issued - archive q4': 'Your package has left our facility',
    'issued - q1': 'Your package has left our facility',
    'issued - q2': 'Your package has left our facility',
    'issued - q3': 'Your package has left our facility',
    'issued - q4': 'Your package has left our facility',
    partshipped: 'Part Shipped - please contact us for an update on the rest of your order',
};

export const statusMessagesPartshipped = {
    'orders - shipping': 'Your garments are being packaged for shipping',
    'orders - invoicing': 'Your package is being transferred to one of our delivery vans',
    'orders - issue': 'Your package has left our facility',
    shipping: 'Your garments are being packaged for shipping',
    invoicing: 'Your package is being transferred to one of our delivery vans',
    issue: 'Your package has left our facility',
    issued: 'Your package has left our facility',
    'issued - current': 'Your package has left our facility',
    'issued - archive q1': 'Your package has left our facility',
    'issued - archive q2': 'Your package has left our facility',
    'issued - archive q3': 'Your package has left our facility',
    'issued - archive q4': 'Your package has left our facility',
    'issued - q1': 'Your package has left our facility',
    'issued - q2': 'Your package has left our facility',
    'issued - q3': 'Your package has left our facility',
    'issued - q4': 'Your package has left our facility',
};

export const statusMessagePartshipped = 'Part Shipped - please contact us for an update on the rest of your order';
