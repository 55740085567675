import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "";
export const method = "GET";
export const operationId = "workwearExpressArtworkCustomerArtworkApprovalRepositoryV1GetForGuestGet";
export const path = "/V1/artwork_approval/guest/{token}";
export interface PathParams {
    token: string;
}
export type Response = Response200 | Response500 | ResponseDefault;
export type Response200 = Definitions.WorkwearExpressArtworkDataArtworkApprovalCompositeInterface;
export type RespKeys = (keyof Definitions.WorkwearExpressArtworkDataArtworkApprovalCompositeInterface)[];
export type Response500 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "getJSON">;
export function execute<T extends RespKeys | undefined>(pathParams: PathParams, effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.WorkwearExpressArtworkDataArtworkApprovalCompositeInterface, T[number]>> : Observable<Response200> {
    return effects.getJSON(effects.apiUrl(operationId)(path, pathParams, fields), effects.restHeaders({})) as any;
}
