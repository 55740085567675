import { Observable, Subject } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { pluck } from 'rxjs/operators';

import { UPLOAD_PATH } from '../feature/customisation.types';

export function uploadFileRx(file: File, id: number): [Observable<string>, Observable<ProgressEvent>] {
    const progressSubscriber = new Subject<ProgressEvent>();
    const formData = new FormData();
    formData.append('logo', file);
    formData.append('customer_id', id.toString());
    const ajax$ = ajax({
        method: 'POST',
        url: location.origin + UPLOAD_PATH,
        body: formData,
        progressSubscriber,
    } as any).pipe(pluck('response', 'url'));

    return [ajax$, progressSubscriber];
}
