import { execute, Body } from 'swagger/ts/WorkwearExpressSharedBasketSharedBasketCreatorV1CreatePost';
import { execute as guestExecute } from 'swagger/ts/WorkwearExpressSharedBasketSharedBasketCreatorV1CreateForGuestPost';
import { Deps } from 'src/types/global-types';
import { depsToDepsWithCspToken } from 'src/components/CustomerServicePortal/utils/utils';

export function submitService(
    isSignedIn: boolean,
    cspToken: string | null,
    body: Body,
    cartId: string,
    originalDeps: Deps,
) {
    const deps = cspToken ? depsToDepsWithCspToken(cspToken, originalDeps) : originalDeps;
    if (isSignedIn) {
        return execute(body, deps);
    } else {
        return guestExecute({ cartId: cartId }, body, deps);
    }
}
