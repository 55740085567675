import { Observable, concat, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { concatMap, map, catchError } from 'rxjs/operators';

import { execute as getOrders } from '../../../../../swagger/ts/JhOrderApiOrderListServiceV1GetOrdersGet';
import { Deps } from '../../../types/global-types';
import { Actions, Msg, TypeMap } from '../orders.register';

const action = 'Orders.FetchOrders';

export function getAccountOrdersEpic(action$: Observable<any>, _state$, deps: Deps) {
    return action$.pipe(
        ofType<Actions, TypeMap[typeof action]>(action),
        concatMap(() => {
            const ajax$ = getOrders(deps);
            return concat(
                ajax$.pipe(
                    map((resp) => Msg('Orders.FetchOrdersSuccess', resp.items.reverse())),
                    catchError((err) => {
                        return of(Msg('Orders.FetchOrdersError', err));
                    }),
                ),
            );
        }),
    );
}
