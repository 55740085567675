import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "";
export const method = "POST";
export const operationId = "workwearExpressCustomisationPricingEstimateCustomisationPriceV1EstimateGuestPricesPost";
export const path = "/V1/customisationPricing/{cartId}/estimate";
export interface PathParams {
    cartId: string;
}
export interface Body {
    estimateRequest: Definitions.WorkwearExpressCustomisationPricingDataEstimateRequestInterface;
}
export type Response = Response200 | ResponseDefault;
export type Response200 = Definitions.WorkwearExpressCustomisationPricingDataPriceEstimateInterface;
export type RespKeys = (keyof Definitions.WorkwearExpressCustomisationPricingDataPriceEstimateInterface)[];
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "postJSON">;
export function execute<T extends RespKeys | undefined>(pathParams: PathParams, body: Body, effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.WorkwearExpressCustomisationPricingDataPriceEstimateInterface, T[number]>> : Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, pathParams, fields), body, effects.restHeaders({})) as any;
}
