import Axios from 'axios';

export const getBasketLink = async (cartId: string) => {
    const requestURL = `/rest/V1/shared-baskets/${cartId}/shared-basket-no-notification`;

    try {
        const response = await Axios({
            method: 'post',
            url: requestURL,
        }).then((res) => {
            return res.data.conversion_token;
        });

        return `${window.location.origin}/shared-basket/convert/?token=${response}`;
    } catch (err) {
        console.error('err', err);
    }
};
