import React, { useState } from 'react';
import { Artwork, ArtworkValues } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import { Modal } from 'src/components/Modal/Modal';
import { ModalHeader } from 'src/components/ProductDetail/ModalHeader';
import { ArtworkApprovalProof } from 'src/components/ArtworkApproval/ArtworkApprovalProof';
import { ArtworkApprovalActions } from 'src/components/ArtworkApproval/ArtworkApprovalActions';
import { CircleLoader } from 'src/components/CircleLoader/CircleLoader';

import classes from './OrderItemArtwork.scss';

type Props = {
    artwork: Artwork;
    isOpen: boolean;
    onDismiss: (boolean) => void;
    onSubmit: (values: ArtworkValues, status: string) => void;
};

export const OrderItemArtworkReviewModal: React.FC<Props> = ({ artwork, isOpen, onDismiss, onSubmit }) => {
    const [isPendingAction, setIsPendingAction] = useState(false);

    return (
        <Modal className={classes.modalContainer} isOpen={isOpen} type="wide" onDismiss={() => onDismiss(false)}>
            <ModalHeader title="Artwork Review" closeModal={() => onDismiss(false)} />
            <div className={classes.modalContent}>
                {isPendingAction ? (
                    <div className={classes.proof}>
                        <div className={classes.loader}>
                            <CircleLoader />
                        </div>
                    </div>
                ) : (
                    <ArtworkApprovalProof artwork={artwork} isModalProof />
                )}

                <ArtworkApprovalActions
                    onSubmit={onSubmit}
                    artwork={artwork}
                    isPendingAction={isPendingAction}
                    setIsPendingAction={setIsPendingAction}
                />
            </div>
        </Modal>
    );
};
