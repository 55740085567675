import { CartItemJH } from 'src/features/analytics/utils/eventTypes';

export const determineCustomisedItems = (items: CartItemJH[]) => {
    return items.map((item) => {
        if (item.__typename === 'ConfigurableCartItem' || item.__typename === 'BundleCartItem') {
            const customisation = items.find((customisationItem) => customisationItem.product.name.includes(item.id));

            if (customisation) {
                return { ...item, customised: true };
            }

            return { ...item, customised: false };
        }

        return item;
    });
};
