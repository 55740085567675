import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { PriceSummary } from 'src/components/CustomisationPage/utils/priceEstimates';

import { Actions, EntryKind } from './customisation.actions';
import { ApplicationMethod, ApplicationType, AvailableLocation, Step } from './customisation.types';

export type CustomisationState = {
    id?: null | string | number;
    artwork_id?: null | string | number;
    status: DataStatus;
    messages: Message[];

    item_ids: number[];
    entry_kind: EntryKind;

    // where we are in the process
    step: Step;

    artwork: string;

    /**
     * Config submit params
     */
    application_method: ApplicationMethod;
    application_type: ApplicationType;
    textLineOne: string | null;
    textLineTwo: string | null;
    textLineThree: string | null;
    color: string | null;
    font: string | null;
    image: string | null;
    isContactRequested: boolean;
    customisationName: string | null;
    specialInstructions: string | null;
    locationNames: string[];

    summary: PriceSummary | null;

    availableLocations: AvailableLocation[] | null;

    takenLocations: string[];
    is_artwork_missing?: boolean;
    is_free?: boolean;
    one_method?: boolean;
};

const initialCustomisation = {
    application_method: ApplicationMethod.Both,
    application_type: ApplicationType.Logo,
    textLineOne: null,
    textLineTwo: null,
    textLineThree: null,
    color: null,
    font: null,
    image: null,
    isContactRequested: false,
    customisationName: null,
    specialInstructions: null,
    locationNames: [],
    availableLocations: null,
    takenLocations: [],
};

export const initialState: CustomisationState = {
    id: null,
    status: DataStatus.Pending,
    messages: [],

    item_ids: [],
    entry_kind: EntryKind.New,

    step: Step.Position,

    artwork: 'new',
    summary: null,

    ...initialCustomisation,
};

export function customisationReducer(state = initialState, action: Actions): CustomisationState {
    switch (action.type) {
        //Triggers Verify step
        case 'Customisation.LoadStep': {
            return {
                ...state,
                // summary: null,
            };
        }
        //Runs on all steps
        case 'Customisation.VerifyStep': {
            return {
                ...state,
                step: action.payload,
                status: DataStatus.Success,
            };
        }
        //Verify that the user is in a state where they can customise an item
        case 'Customisation.VerifyCustomisation': {
            const { availableLocations, entry, takenLocations } = action.payload;
            switch (entry.kind) {
                case EntryKind.New: {
                    return {
                        ...state,
                        step: Step.Position,
                        item_ids: entry.itemIds.map(Number),
                        entry_kind: EntryKind.New,
                        status: DataStatus.Success,
                        availableLocations: availableLocations,
                        artwork_id: null,
                        takenLocations: takenLocations,
                    };
                }
                case EntryKind.Edit: {
                    return {
                        ...state,
                        step: Step.Position,
                        item_ids: entry.itemIds.map(Number),
                        locationNames: entry.locationNames,
                        entry_kind: EntryKind.Edit,
                        status: DataStatus.Success,
                        availableLocations: availableLocations,
                        // these are duplicated here just to be explicit about them being important
                        id: entry.customisation.id ?? null,
                        artwork_id: entry.customisation.artwork_id ?? null,
                        takenLocations: takenLocations,
                        ...entry.customisation,
                    };
                }
                default: {
                    throw new Error('didnt expect to get here');
                }
            }
        }
        case 'Customisation.ArtworkSubmitNew': {
            return {
                ...state,
                artwork: 'new',
                is_artwork_missing: false,
                isContactRequested: false,
                status: DataStatus.Pending,
                // ...initialCustomisation,
                availableLocations: state.availableLocations,
            };
        }
        case 'Customisation.MethodSubmit': {
            return {
                ...state,
                application_method: action.payload.applicationMethod,
                one_method: action.payload.oneMethodAvailable,
                status: DataStatus.Pending,
            };
        }
        case 'Customisation.TypeSubmit': {
            return {
                ...state,
                application_type: action.payload,
                status: DataStatus.Pending,
            };
        }
        case 'Customisation.ConfigSubmit': {
            return {
                ...state,
                status: DataStatus.Pending,
                ...action.payload,
            };
        }
        case 'Customisation.PositionSubmit': {
            return {
                ...state,
                locationNames: action.payload,
                status: DataStatus.Pending,
            };
        }
        case 'Customisation.CustomisationSuccess': {
            return {
                ...initialState,
                status: DataStatus.Pending,
            };
        }
        case 'Customisation.FromArtwork': {
            return {
                ...state,
                ...action.payload,
                status: DataStatus.Pending,
            };
        }
        case 'Customisation.EstimatePricesSuccess': {
            return {
                ...state,
                summary: action.payload.summary,
            };
        }
        default:
            return state;
    }
}
