import React, { useCallback, useEffect, useState } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { Fields } from '@wearejh/m2-pwa-user/lib/user.types';
import { useCustomerStatus } from 'src/features/user/lib/useCustomerStatus';
import { RegisterParams } from 'src/features/user/lib/user.action';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import { Button } from 'src/components/Button/Button';
import { NOTES_MIN_LENGTH, NOTES_MAX_LENGTH, NOTES_MIN_LENGTH_LAST_NAME } from 'src/constants/forms';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { environments } from 'src/env';
import { useDeps } from 'src/hooks/useDeps';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { Input } from '../Forms/Input';
import { basicEmailValidation, basicLengthValidationWithText } from '../../util/forms';
import { Actions } from '../Forms/Actions';
import { ModalFooter } from '../Modal/ModalFooter';
import { Modal } from '../Modal/Modal';
import { Fieldset } from '../Forms/Fieldset';
import { Block } from '../Helpers/Block';
import { Messages } from '../Messages/Messages';

import classes from './RegisterModal.scss';
import { PasswordConfirm } from './PasswordConfirm';

export type RegisterModalProps = {
    close();
    login();
    forgot();
};

export const RegisterModal: React.FC<RegisterModalProps> = (props) => {
    return (
        <Modal type="popup" isOpen={true} onDismiss={props.close}>
            <ModalHeader title="Register" icon="account" onDismiss={props.close} />
            <ModalBody>
                <RegisterConnected showLogin={true} forgot={props.forgot} login={props.login} />
            </ModalBody>
            <ModalFooter onDismiss={props.close} />
        </Modal>
    );
};

export default RegisterModal;

type SignInProps = {
    register(params: RegisterParams);
    forgot();
    login?();
    messages: Message[];
    isPending: boolean;
    showLogin?: boolean;
};

type ReCaptchaProps = {
    setToken: (token: string) => void;
};

const ReCaptchaComponent: React.FC<ReCaptchaProps> = ({ setToken }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        const token = await executeRecaptcha('yourAction');

        setToken(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [executeRecaptcha]);

    useEffect(() => {
        handleReCaptchaVerify();
    }, [handleReCaptchaVerify]);

    return null;
};

const RegisterFields = { ...Fields, companyname: 'companyname' };

export const Register: React.FC<SignInProps> = React.memo((props) => {
    const deps = useDeps();
    const isProd = deps.env.RELEASE_STAGE === 'production';
    const recaptchaId = isProd ? environments['master'].RECAPTCHA_ID : environments['cypress'].RECAPTCHA_ID;

    const { register } = props;
    const [token, setToken] = useState<undefined | string>(undefined);

    const onSubmit = useCallback(
        (values) => {
            const companyName = values[RegisterFields.companyname];
            const extension_attributes = companyName ? { company_name: companyName } : undefined;

            if (token) {
                register({
                    firstname: values[RegisterFields.firstname],
                    lastname: values[RegisterFields.lastname],
                    email: values[RegisterFields.email],
                    password: values[RegisterFields.password],
                    extension_attributes,
                    token,
                });
            }
        },
        [register, token],
    );
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaId} useEnterprise={true}>
            <>
                <ReCaptchaComponent setToken={(token) => setToken(token)} />
                {props.messages.length > 0 && (
                    <Block>
                        <Messages messages={props.messages} />
                    </Block>
                )}
                <Form onSubmit={onSubmit} data-test-id="RegisterForm">
                    <Fieldset disabled={props.isPending}>
                        <Input
                            field={RegisterFields.companyname}
                            id={RegisterFields.companyname}
                            label="Company name"
                        />
                        <Input
                            field={RegisterFields.firstname}
                            id={RegisterFields.firstname}
                            label="First name"
                            validate={basicLengthValidationWithText(
                                NOTES_MIN_LENGTH,
                                NOTES_MAX_LENGTH,
                                'Please enter your First name',
                            )}
                            required={true}
                        />
                        <Input
                            field={RegisterFields.lastname}
                            id={RegisterFields.lastname}
                            label="Last name"
                            validate={basicLengthValidationWithText(
                                NOTES_MIN_LENGTH_LAST_NAME,
                                NOTES_MAX_LENGTH,
                                'Please enter your Last name',
                            )}
                            required={true}
                        />
                        <Input
                            field={RegisterFields.email}
                            id={RegisterFields.email}
                            label="Email"
                            validate={basicEmailValidation()}
                            required={true}
                        />
                        <PasswordConfirm />
                        <Actions primaryText="Register">
                            {props.showLogin && props.login && (
                                <Button variant="bold" onClick={props.login} data-test-id="AccountIcon_login">
                                    Login
                                </Button>
                            )}
                            <Button variant="reset" onClick={props.forgot}>
                                Forgot Your Password?
                            </Button>
                        </Actions>
                        <p className={classes.privacyNote}>
                            Our Privacy Notice and how we process personal data can be found at
                            <a target="_blank" rel="noreferrer" href="/privacy-policy-and-cookies">
                                {' '}
                                Privacy Policy | Workwear Express | Workwear Express
                            </a>
                        </p>
                    </Fieldset>
                </Form>
            </>
        </GoogleReCaptchaProvider>
    );
});

type RegisterConnectedProps = {
    forgot();
    login?();
    showLogin?: boolean;
};

export const RegisterConnected: React.FC<RegisterConnectedProps> = React.memo((props) => {
    const { register, createMessages, isPending } = useCustomerStatus();
    return (
        <Register
            messages={createMessages}
            register={register}
            isPending={isPending}
            login={props.login}
            showLogin={props.showLogin}
            forgot={props.forgot}
        />
    );
});
