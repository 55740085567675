import React, { useState } from 'react';
import classes from 'src/components/CustomisationPage/LogoUploader.scss';
import { LogoUploader } from 'src/components/CustomisationPage/LogoUploader';
import { Customisation } from 'src/components/BundleProduct/types';

export type Img = {
    url: string | undefined;
    fileName: string;
};

type Props = {
    url: string | undefined;
    fileName?: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
};

export const UploadLogo: React.FC<Props> = ({ url, fileName, handleCustomisationSet }) => {
    const [image, setImage] = useState({ url, fileName });

    const onSetImage = (img: Img) => {
        if (img.fileName) setImage(img);
        handleCustomisationSet({
            selectedOptions: {
                isContactRequested: 'upload',
                image: img.url,
            },
        });
    };

    const onRemoveImage = () => {
        setImage({ url: undefined, fileName: undefined });
        handleCustomisationSet({
            selectedOptions: {
                isContactRequested: 'upload',
                image: '',
            },
        });
    };

    return (
        <div className={classes.wrapper}>
            <LogoUploader setImage={onSetImage} fileName={image.fileName} url={image.url} removeImage={onRemoveImage} />

            <p className={classes.dropzoneAdvice}>
                Don’t worry how it looks, we will make it look great and send a proof before we add to your products!
            </p>
        </div>
    );
};
