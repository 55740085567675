import React from 'react';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router';
import { useIsSignedIn } from '@wearejh/m2-pwa-user/lib/hooks/useIsSignedIn';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib';
import { shallowEqual, useSelector } from 'react-redux';
import { ordersRegister } from 'src/features/app/orders.register';

import { AccountRouter } from '../../components/Customer/AccountRouter';
import { GuestRouter } from '../../components/Customer/GuestRouter';
import { Container, Wrapper } from '../../components/Layout';
import { StoreState } from '../../types/global-types';
import { registerCustomer } from '../../components/Customer/feature/customer.register';

import '../../scss/_root-component.scss';

const renderEnvSelector = (x: StoreState) => x.app.renderEnv;

export const CustomerRoot: React.FC = () => {
    useSetResolved();
    const readyOnOrders = useLazyRegister(ordersRegister, true, 'LazyRegister');
    const renderEnv = useSelector(renderEnvSelector, shallowEqual);
    const ready = useLazyRegister(registerCustomer, renderEnv === 'browser', 'CustomerRoot');
    const isSignedIn = useIsSignedIn();
    /**
     * This allows the features to register and prevent
     * early access to redux state that may not exist yet
     */
    if (!ready || !readyOnOrders) return null;

    const inner = isSignedIn ? <AccountRouter /> : <GuestRouter />;
    return (
        <Wrapper variant="page-inner">
            <Container>{inner}</Container>
        </Wrapper>
    );
};

export default CustomerRoot;
