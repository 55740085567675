import React from 'react';
import { ArtworkItem } from 'src/components/Artwork/artwork.types';
import { maybePdf } from 'src/util/formatUrl';
import { ApplicationMethod, ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { generateFontImage } from 'src/components/ArtworkApproval/utils/utils';
import { NEW_ARTWORK_ID } from 'src/constants/artwork';
import { filterCustomisations } from 'src/components/Artwork/utils/filterCustomisations';

import { ArtworkType } from './ArtworkSelect';
import { ArtworkGridState } from './feature/artwork.register';
import classes from './RadioGrid.scss';
import { ArtworkGridItem } from './ArtworkGridItem';

type Props = {
    customisations: ArtworkItem[];
    applicationMethod: ApplicationMethod;
    fieldIndex?: number;
    description?: string;
    artworkGrid?: ArtworkGridState;
    hide?: boolean;
    existingArtworkId?: number;
    type: ArtworkType;
};

export const ArtworkGrid: React.FC<Props> = ({
    customisations,
    applicationMethod,
    fieldIndex,
    type,
    description,
    hide,
}) => {
    const sortedCustomisations = filterCustomisations(customisations, Number(applicationMethod));

    return (
        <div className={classes.radioGrid} data-test-id="ArtworkGrid">
            {sortedCustomisations.map((item, index) => {
                const displayImage = item.image;
                const previewImage = maybePdf(item.image);
                const isImageAI = item.image.includes('.ai');
                const textImage = item.application_type === ApplicationType.Text && generateFontImage(item);
                const logoImage = item.application_type === ApplicationType.Logo && (
                    <img src={previewImage} alt={item.title} />
                );
                const imageUrl = item.image.split('/').pop();
                const imageId = imageUrl?.includes('%') ? `View ${imageUrl.split('%')[0]}` : 'View artwork';

                return (
                    <ArtworkGridItem
                        key={item.id}
                        fieldIndex={fieldIndex}
                        id={item.id}
                        displayImage={displayImage}
                        imageId={imageId}
                        title={item.title}
                        application_type={item.application_type}
                        application_method={item.application_method}
                        is_artwork_missing={item.is_artwork_missing}
                        textImage={textImage}
                        logoImage={!isImageAI ? logoImage : <div className={classes.aiSpan}>AI</div>}
                        hide={hide}
                        index={index}
                        value={item.id}
                        addNew={false}
                        type={type}
                    />
                );
            })}
            {type === ArtworkType.Bundle && (
                <ArtworkGridItem
                    fieldIndex={fieldIndex}
                    id={`${NEW_ARTWORK_ID}-`}
                    addNew={true}
                    description={description}
                    application_type={ApplicationType.None}
                    application_method={ApplicationMethod.None}
                    is_artwork_missing={false}
                    value={NEW_ARTWORK_ID}
                    type={type}
                />
            )}
        </div>
    );
};
