import React from 'react';
import { Fieldset } from 'src/components/Forms/Fieldset';
import { Fields } from 'src/components/CustomisationPage/feature/customisation.types';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';
import { TextArea } from 'src/components/Forms/TextArea';
import { Customisation, SelectedOptions } from 'src/components/BundleProduct/types';

import classes from './AdditionalNotes.scss';

type Props = {
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedOptions: SelectedOptions;
};

export const AdditionalNotes: React.FC<Props> = ({ backgroundStyles, handleCustomisationSet, selectedOptions }) => {
    return (
        <Fieldset>
            <div className={classes.wrapper} style={backgroundStyles}>
                <TextArea
                    label="Notes"
                    field={Fields.specialInstructions}
                    id={Fields.specialInstructions}
                    placeholder="Please let us know if you have any special requirements"
                    onChange={(e) => {
                        handleCustomisationSet({
                            selectedOptions: {
                                ...selectedOptions,
                                specialInstructions: e.target.value,
                            },
                        });
                    }}
                />
            </div>
        </Fieldset>
    );
};
