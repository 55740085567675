import React, { useState } from 'react';
import { InlineIcons } from 'src/components/Artwork/InlineIcons';
import { displayTypeNames, getIcons } from 'src/components/Artwork/utils/artwork';
import { ItemCustomisationMap } from 'src/components/Basket/utils/basket-customisations';
import { WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface } from 'swagger/ts/Definitions';
import { generateFontImage } from 'src/components/ArtworkApproval/utils/utils';
import { BasketItemType } from 'src/components/Basket/BasketContext';
import classnames from 'classnames';
import { useDeps } from 'src/hooks/useDeps';
import { removeCustomisationUser, removeCustomisationGuest } from 'src/util/api/customisation';
import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { CopyCustomisationModal } from 'src/components/Basket/CopyCustomisationModal';
import Bugsnag from '@bugsnag/js';
import { useDispatch } from 'react-redux';
import { MultipleStepCustomisation } from 'src/components/BundleProduct/MultipleStepCustomisation/MultipleStepCustomisation';
import { handleCreateCustomisationCamel } from 'src/components/Basket/utils/handleCreateCustomisation';
import { Price } from 'src/components/Helpers/Price';
import { useBundleCustomisationData } from 'src/components/BundleProduct/hooks/useBundleCustomisationData';
import { BundleCustomisationOptions } from 'src/components/BundleProduct/utils/BundleCustomisationOptions';

import { Button } from '../Button/Button';
import {
    applicationMethodFromInt,
    ApplicationType,
    BasketItemCustomisation,
    CustomisationWithIdAndLocation,
    Targets,
} from '../CustomisationPage/feature/customisation.types';

import { ItemCustomisation } from './ItemCustomisation';
import classes from './ItemCustomisations.scss';

type Props = {
    groupedCustomisations: ItemCustomisationMap;
    itemId: string;
    isPending: boolean;
    allowActions?: boolean;
    item: BasketItemType;
    canCopyCustomisation: (customisationId: number, itemId: string) => boolean;
    getCopyParams: (itemId: string, customisation: CustomisationWithIdAndLocation) => Targets;
};

export const ItemCustomisations: React.FC<Props> = ({
    itemId,
    groupedCustomisations,
    isPending,
    allowActions,
    item,
    canCopyCustomisation,
    getCopyParams,
}) => (
    <div className={classes.customisationsContainer}>
        <ul className={classes.customisations}>
            {Object.keys(groupedCustomisations).map((id) => (
                <ItemCustomisation
                    key={id}
                    groupedCustomisation={groupedCustomisations[id]}
                    itemId={itemId}
                    isPending={isPending}
                    allowActions={allowActions}
                    item={item}
                    canCopyCustomisation={canCopyCustomisation}
                    getCopyParams={getCopyParams}
                />
            ))}
        </ul>
        {item.customisationsCost > 0 && (
            <div className={classes.customisationItemPrice}>
                <span className={classes.customisationItemPriceTitle}> Customisation Cost:</span>
                <p className={classes.basketItemCostSummary__block__price}>
                    <Price
                        price={
                            Boolean(item.cartItem.customisationDiscount)
                                ? item.customisationsCost - item.cartItem.customisationDiscount
                                : item.customisationsCost
                        }
                        priceIncl={item.customisationsCost}
                    />
                </p>
            </div>
        )}
    </div>
);

ItemCustomisations.defaultProps = { allowActions: true };

type ItemRowCheckoutProps = {
    items: BasketItemCustomisation[];
    itemId: string;
    getCopyParams?: (itemId: string, customisation: CustomisationWithIdAndLocation) => Targets;
    isPending: boolean;
    groupedCustomisation: BasketItemCustomisation[];
    item: BasketItemType;
    canCopyCustomisation?: (customisationId: number, itemId: string) => boolean;
    allowActions?: boolean;
    firstItem: BasketItemCustomisation;
};
export const ItemRowCheckout: React.FC<ItemRowCheckoutProps> = ({
    items,
    itemId,
    getCopyParams,
    isPending,
    groupedCustomisation,
    item,
    canCopyCustomisation,
    allowActions,
    firstItem,
}) => {
    const customisationOption = useBundleCustomisationData(item?.cartItem?.product?.logo_policy?.toString());

    const [loadingItem, setLoadingItem] = useState(false);
    const [targets, setTargets] = useState<Targets>({
        validTargets: [],
        invalidTargets: [],
    });
    const [openCopyModal, setOpenCopyModal] = useState(false);
    const [openCustomisationModal, setOpenCustomisationModal] = useState(false);

    const [firstCustomisationItem] = groupedCustomisation;
    const customisationWithIdAndLocation = {
        id: firstCustomisationItem.id,
        location_names: groupedCustomisation.map((customisation) => customisation.location_name),
        customisation: { ...firstCustomisationItem.customisation, id: firstCustomisationItem.id },
    };

    const customisationId = customisationWithIdAndLocation.id;
    const customisationLocationNames = customisationWithIdAndLocation.location_names;
    const customisation = customisationWithIdAndLocation.customisation;

    const alreadySelectedPositions = item.customisations.map((customisationItem) =>
        customisationId !== customisationItem.id ? customisationItem.location_name : '',
    );

    const deps = useDeps();
    const dispatch = useDispatch();

    const placeholder = require('../../../static/img/placeholder.jpg');
    const epsPlaceholder = require('../../../static/img/epsPlaceholder.png');
    const isEpsFile = firstItem.customisation.image.includes('.eps');
    const method = applicationMethodFromInt(firstItem.customisation.type);
    const image = !isEpsFile ? firstItem.customisation.image : epsPlaceholder;
    const isContact = firstItem.customisation.is_contact_requested;
    const type = image ? ApplicationType.Logo : ApplicationType.Text;
    const location_names = items.map((x) => x.location_name);
    const artworkExistInDB = firstItem.customisation.is_artwork_missing;
    const imagePDForAI = image.includes('.pdf') ? 'PDF' : image.includes('.ai') ? 'AI' : null;

    const deleteCustomisation = async (items: BasketItemCustomisation[]) => {
        const output = {
            removeCustomisations: items.map((item) => ({
                itemId: Number(itemId),
                customisationId: item.id,
                locationName: item.location_name,
            })),
        };
        setLoadingItem(true);

        try {
            window.localStorage['store.user']
                ? await removeCustomisationUser(output, deps)
                : await removeCustomisationGuest(output, deps, {
                      cartId: JSON.parse(window.localStorage['store.cart']).cartId,
                  });
            //when formik will be added to the main basket, no need for cart.refresh anymore as ‘item’ (which include customisations) will be coming from formik
            dispatch(CartMsg('Cart.Refresh', { force: true }));
        } catch (e) {
            Bugsnag.notify(e);
        }

        setLoadingItem(false);
    };

    const onCopy = () => {
        if (getCopyParams) {
            setTargets(getCopyParams(itemId, customisationWithIdAndLocation));
            setOpenCopyModal(true);
        }
    };

    const customisationActions = () => (
        <div className={classes.customisationActionsContaier}>
            <div className={classes.customisationsActionsRight} hidden={!allowActions}>
                <Button
                    icon="left-of-text"
                    variant="reset"
                    disabled={isPending || loadingItem}
                    onClick={() => setOpenCustomisationModal(true)}
                >
                    <span>Edit</span>
                </Button>
                {getCopyParams && canCopyCustomisation && canCopyCustomisation(customisationId, itemId) && (
                    <Button onClick={onCopy} icon="left-of-text" variant="reset" disabled={isPending || loadingItem}>
                        <span>Copy</span>
                    </Button>
                )}
            </div>

            {(customisationOption !== BundleCustomisationOptions.Required ||
                item?.cartItem?.product?.__typename !== 'BundleProduct') && (
                <Button
                    variant="reset"
                    onClick={() => deleteCustomisation(groupedCustomisation)}
                    className={classnames(classes.customisationsRemove, classes.customisationsRemoveBigScreen)}
                    disabled={isPending || loadingItem}
                >
                    <span>Remove</span>
                </Button>
            )}
        </div>
    );

    return (
        <>
            <div
                className={classnames(classes.itemRow, {
                    [classes.itemRowDisabled]: loadingItem,
                })}
            >
                <figure className={classes.customisationsImage}>
                    {isContact ? (
                        <img
                            src={require('../../../static/img/placeholder-logo.jpg')}
                            alt="We will contact you"
                            className={classes.img}
                        />
                    ) : artworkExistInDB ? (
                        <img src={image && !imagePDForAI ? image : placeholder} alt="" className={classes.img} />
                    ) : (
                        <>
                            {type === 'Logo' && imagePDForAI && (
                                <div className={classes.pdfPlaceholder}>{imagePDForAI}</div>
                            )}
                            {type === 'Logo' && !imagePDForAI && (
                                <img src={image ? image : placeholder} alt="" className={classes.img} />
                            )}
                            {type === 'Text' && generateFontImage(customisationKeysUpperCase(firstItem.customisation))}
                        </>
                    )}
                </figure>
                <div className={classes.customisationsApplication}>
                    <div className={classes.customisationsInlineIconsAndMethodWrapper}>
                        <div>
                            <InlineIcons icons={getIcons(method, type)} />
                        </div>
                        <div>{displayTypeNames(method, type, isContact, artworkExistInDB)}</div>
                    </div>
                    {firstItem.customisation.customisation_name && (
                        <div className={classes.customisationsName}>
                            <p>
                                <b>Name:</b> {firstItem.customisation.customisation_name}
                            </p>
                        </div>
                    )}
                    <div className={classes.customisationsLocation}>
                        {location_names.join(', ')}
                        <div className={classes.customisationActions}>{customisationActions()}</div>
                    </div>
                </div>
            </div>
            <div className={classes.customisationActionsAndPrice}>
                <div className={classes.customisationActionsMobile}>{customisationActions()}</div>
                {firstItem.customisation.is_free && <b className={classes.customisationsFree}>Free</b>}
            </div>

            {openCopyModal && (
                <CopyCustomisationModal
                    isOpenModal
                    closeModal={() => setOpenCopyModal(false)}
                    customisation={customisation}
                    locationNames={customisationLocationNames}
                    validTargets={targets.validTargets}
                    invalidTargets={targets.invalidTargets}
                />
            )}
            {openCustomisationModal && (
                <MultipleStepCustomisation
                    locations={item.availableLocations}
                    isOpenModal
                    closeModal={() => setOpenCustomisationModal(false)}
                    onSubmitCustomisation={(value) =>
                        handleCreateCustomisationCamel(
                            value,
                            [Number(item.cartItem.id)],
                            deps,
                            dispatch,
                            customisationId,
                            true,
                        )
                    }
                    customisation={{
                        selectedPositions: customisationLocationNames,
                    }}
                    alreadySelectedPositions={alreadySelectedPositions}
                    recommendedApplication={0}
                    multiplePositions={!customisation.is_free}
                />
            )}
        </>
    );
};

const customisationKeysUpperCase = (
    customisation: WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface,
) => {
    const customisationToArray: { key: string; value: string }[] = [];
    const formattedCustomisation: any = {};

    for (const [key, value] of Object.entries(customisation)) {
        customisationToArray.push({ key, value });
    }

    const splitElements = customisationToArray.map((el) => ({ key: el.key.split('_'), value: el.value }));
    const upperCaseFirstLetter = splitElements.map((el, i) => {
        const upperCaseAllWords = el.key.map((k) => k.charAt(0).toUpperCase() + k.slice(1)).join('');
        const firstWordLowerCase = upperCaseAllWords.charAt(0).toLowerCase() + upperCaseAllWords.slice(1);
        return { key: firstWordLowerCase, value: el.value };
    });
    upperCaseFirstLetter.map((el) => (formattedCustomisation[el.key] = el.value));
    return formattedCustomisation;
};

export const ItemRow: React.FC<{ items: BasketItemCustomisation[]; firstItem: BasketItemCustomisation }> = ({
    items,
    firstItem,
}) => {
    const placeholder = require('../../../static/img/placeholder.jpg');
    const epsPlaceholder = require('../../../static/img/epsPlaceholder.png');
    const isEpsFile = firstItem.customisation.image.includes('.eps');
    const method = applicationMethodFromInt(firstItem.customisation.type);
    const image = !isEpsFile ? firstItem.customisation.image : epsPlaceholder;
    const isContact = firstItem.customisation.is_contact_requested;
    const type = image ? ApplicationType.Logo : ApplicationType.Text;
    const location_names = items.map((x) => x.location_name);
    const artworkExistInDB = firstItem.customisation.is_artwork_missing;
    const imagePDForAI = image.includes('.pdf') ? 'PDF' : image.includes('.ai') ? 'AI' : null;

    return (
        <div className={classes.itemRow}>
            <figure className={classes.customisationsImage}>
                {artworkExistInDB ? (
                    <img src={image && !imagePDForAI ? image : placeholder} alt="" className={classes.img} />
                ) : (
                    <>
                        {type === 'Logo' && imagePDForAI && (
                            <div className={classes.pdfPlaceholder}>{imagePDForAI}</div>
                        )}
                        {type === 'Logo' && !imagePDForAI && (
                            <img src={image ? image : placeholder} alt="" className={classes.img} />
                        )}
                        {type === 'Text' && generateFontImage(customisationKeysUpperCase(firstItem.customisation))}
                    </>
                )}
            </figure>
            <div className={classes.customisationsApplication}>
                <div className={classes.customisationsInlineIconsAndMethodWrapper}>
                    <div>
                        <InlineIcons icons={getIcons(method, type)} />
                    </div>
                    <div>{displayTypeNames(method, type, isContact, artworkExistInDB)}</div>
                </div>
                {firstItem.customisation.customisation_name && (
                    <div className={classes.customisationsName}>
                        <p>
                            <b>Name:</b> {firstItem.customisation.customisation_name}
                        </p>
                    </div>
                )}
                <div className={classes.customisationsLocation}>
                    {location_names.join(', ')}
                    {firstItem.customisation.is_free && <b className={classes.customisationsFree}>FREE</b>}
                </div>
            </div>
        </div>
    );
};
