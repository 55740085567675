import { Observable, concat, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { concatMap, mergeMap, catchError } from 'rxjs/operators';

import { execute as getOrder } from '../../../../../swagger/ts/JhOrderApiOrderListServiceV1GetOrderGet';
import { Deps } from '../../../types/global-types';
import { Actions, Msg, TypeMap } from '../orders.register';

const action = 'Orders.FetchOrder';

export function getAccountOrderEpic(action$: Observable<any>, _state$, deps: Deps) {
    return action$.pipe(
        ofType<Actions, TypeMap[typeof action]>(action),
        concatMap(({ payload }) => {
            const ajax$ = getOrder({ orderId: payload.orderId }, deps);
            return concat(
                ajax$.pipe(
                    mergeMap((resp) => of(Msg('Orders.FetchOrderSuccess', resp))),
                    catchError((err) => {
                        return of(Msg('Orders.FetchOrderError', err));
                    }),
                ),
            );
        }),
    );
}
