import classes from 'src/components/BasketShared/BasketShared.scss';
import { Svg } from 'src/components/Svg/Svg';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { Button } from 'src/components/Button/Button';
import { formTypes } from 'src/components/BasketShared/ShareForm';

enum LinkStates {
    linkCreated = 'linkCreated',
    linkPending = 'linkPending',
    linkCopied = 'linkCopied',
}

type Props = {
    setFormType: Dispatch<SetStateAction<formTypes>>;
    basketLink?: string;
};

const copyLinkToClipboard = (link, setIsLinkCreated) => {
    navigator.clipboard.writeText(link);
    setIsLinkCreated(LinkStates.linkCopied);
};

export const CopyBasketLink: React.FC<Props> = ({ setFormType, basketLink }) => {
    const [isLinkCreated, setIsLinkCreated] = useState(basketLink ? LinkStates.linkCreated : LinkStates.linkPending);

    return (
        <div className={classes.container}>
            <Content />
            <div className={classes.buttonContainer}>
                <Button onClick={() => setFormType(formTypes.chooseForm)} variant="reset">
                    <b>Back</b>
                </Button>

                <Button
                    variant={isLinkCreated === LinkStates.linkCopied ? 'secondary' : 'primary'}
                    onClick={() => basketLink && copyLinkToClipboard(basketLink, setIsLinkCreated)}
                    disabled={isLinkCreated !== LinkStates.linkCreated}
                >
                    {isLinkCreated === LinkStates.linkCreated && <b>Copy Link</b>}
                    {isLinkCreated === LinkStates.linkPending && <b>Loading...</b>}
                    {isLinkCreated === LinkStates.linkCopied && <b>Link Copied</b>}
                </Button>
            </div>
        </div>
    );
};

const Content = () => (
    <>
        <p>
            <b>Click on the button to generate the link to the copy of your basket.</b>
        </p>
        <ul className={classes.list}>
            <li className={classes.listItem}>
                <Svg name="alert" />
                <span>
                    Anyone who will receive this link will get a copy of your basket and any changes made will only
                    appear on their basket.
                </span>
            </li>
            <li className={classes.listItem}>
                <Svg name="alert" />
                <span>The recipient will be able to view and use any customisations your have added.</span>
            </li>
            <li className={classes.listItem}>
                <Svg name="alert" /> <span>The basket link will remain active for 5 days.</span>
            </li>
        </ul>
    </>
);
