import React from 'react';
import { SalesDataOrderInterface } from 'swagger/ts/Definitions';
import { DispatchProp } from 'react-redux';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/index';
import classnames from 'classnames';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { NavLink } from 'react-router-dom';
import { DefaultMeta } from 'src/components/AppShell/Head';
import { CustomerOrdersRoot } from 'src/components/Orders/CustomerOrdersRoot';
import { BasketContextProvider } from 'src/components/Basket/BasketContext';
import { BasketCspContextProvider } from 'src/components/Basket/BasketCspContext';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { basketRegister } from 'src/components/Basket/feature/basket.register';

import { useDeps } from '../../hooks/useDeps';
import { OrdersConnected } from '../Orders/OrdersConnected';

import classes from './Dashboard.scss';
import { CustomerAccountPage } from './CustomerAccountPage';
import meta from './customer.json';

export type DashboardInnerProps = {
    orders: SalesDataOrderInterface[];
    ordersStatus: DataStatus;
} & DispatchProp;
const DashboardInner: React.FC<DashboardInnerProps> = React.memo((props) => {
    const { paths } = useDeps();
    useSetResolved();
    const ready = useLazyRegister(basketRegister, true, 'Basket');
    if (!ready) return null;
    return (
        <>
            <DefaultMeta data={meta.helmet.dashboard} />
            <BasketContextProvider>
                <BasketCspContextProvider>
                    <div>
                        <CustomerOrdersRoot sliced />
                        <NavLink className={classnames(classes.navLink, 'button')} to={paths.customer.orders}>
                            View all orders
                        </NavLink>
                    </div>
                </BasketCspContextProvider>
            </BasketContextProvider>
        </>
    );
});

export const Dashboard: React.FC = () => {
    return <CustomerAccountPage name="My Account" child={<OrdersConnected component={DashboardInner} />} />;
};
