import { formValuesToString } from 'src/components/CustomisationPage/utils/fontPreview';
import { LazyImg } from 'src/hooks/useLazyLoad';
import React from 'react';
import { FONT_PREVIEW_URL } from 'src/constants/customisation';

export function generateFontImage(query) {
    const placeholder = require('../../../../static/img/placeholder.jpg');
    let fullURL = `${FONT_PREVIEW_URL}?${formValuesToString(query)}`;
    if (!query.font || !query.color) {
        fullURL = placeholder;
    }
    return <img src={fullURL} alt="Preview" />;
}

export function generateTextPreview(artworkSummary, sample: string) {
    if (sample) {
        return <LazyImg src={sample} alt="Preview" />;
    } else {
        return generateFontImage(artworkSummary);
    }
}

export function generateLogoPreview(image, sample: string) {
    if (sample) {
        return <LazyImg src={sample} alt="Preview" />;
    } else {
        return <LazyImg src={image} alt="Preview" />;
    }
}

export function getLastSample(samples: string[]) {
    const samplesImages = getAllImages(samples);
    return samplesImages[samplesImages.length - 1];
}

export function getAllImages(samples: string[]) {
    return samples.filter((x) => !x.endsWith('.pdf'));
}
