import { concat, Observable, of, race } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, mergeMap, pluck, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { BundleActions, BundleMsg, TypeMap } from 'src/components/BundleProduct/feature/bundle.register';
import { catchableUnauthenticated } from '@wearejh/m2-pwa-user/lib/utils/user.utils';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { CartVariant } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { CartMsg, CartActions, TypeMap as CartTypeMap } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { execute as guestItemAddAjax } from 'swagger/ts/QuoteGuestCartItemRepositoryV1SavePost';
import { execute as mineItemAddAjax } from 'swagger/ts/QuoteCartItemRepositoryV1SavePost2';
import { QuoteDataCartItemInterface } from 'swagger/ts/Definitions';
import { assertUnreachable, cheapClone } from '@wearejh/m2-pwa-engine/lib/utils/general';
import { Deps } from 'src/types/global-types';
import { execute } from 'swagger/ts/QuoteCartManagementV1GetCartForCustomerGet';
import { refreshCartAndRetry } from '@wearejh/m2-pwa-cart-gql/lib/utils/refreshCartAndRetry';
import Bugsnag from '@bugsnag/js';

export function bundleAdd(action$: Observable<any>, state$: Observable<any>, deps: Deps): Observable<any> {
    const cartItem$ = state$.pipe(pluck('cart', 'items'));
    return action$.pipe(
        ofType<BundleActions, TypeMap['Bundle.Add']>('Bundle.Add'),
        withLatestFrom(cartVariantAndId(state$), cartItem$),
        switchMap(([action, [variant, cartId], cartItems]) => {
            if (!cartId) {
                return refreshCartAndRetry(
                    action$,
                    action,
                    BundleMsg('Bundle.AddError', 'could not create cart prior to adding'),
                );
            }

            const cartItem = { ...action.payload, quote_id: cartId! };
            const ajax$ = getAjaxMethod(cartItem, variant, cartId!, deps);
            const prevItems = cheapClone(cartItems)!.filter((cartItem) => cartItem.__typename === 'BundleCartItem');

            return ajax$.pipe(
                mergeMap(() => {
                    return concat(
                        of(CartMsg('Cart.Refresh', { force: true })),
                        race(
                            action$.pipe(
                                ofType<CartActions>('Cart.FetchSuccess'),
                                withLatestFrom(state$.pipe(pluck('cart', 'items'))),
                                map(([, items]) => {
                                    const changed: string[] = [];
                                    items.forEach((curr) => {
                                        // does it exist in prev?
                                        if (curr.__typename !== 'BundleCartItem') return;
                                        const prev = prevItems.find((prev) => String(prev.id) === String(curr.id));
                                        if (!prev) {
                                            changed.push(String(curr.id));
                                            return;
                                        }
                                        if (curr.quantity > prev.quantity) {
                                            changed.push(String(curr.id));
                                        }
                                    });
                                    return BundleMsg('Bundle.AddSuccess', changed);
                                }),
                            ),
                            action$.pipe(
                                ofType<CartActions, CartTypeMap['Cart.FetchError']>('Cart.FetchError'),
                                map(({ payload }) => BundleMsg('Bundle.AddError', payload)),
                            ),
                        ).pipe(take(1)),
                    );
                }),
                catchableUnauthenticated((e) => {
                    debugger;
                    Bugsnag.notify(e.error);
                    return of(BundleMsg('Bundle.AddError', e.error));
                }),
            );
        }),
    );
}

function getAjaxMethod(
    cartItem: QuoteDataCartItemInterface,
    variant: CartVariant,
    cartId: string | number,
    deps: Deps,
) {
    switch (variant) {
        case CartVariant.Guest: {
            return guestItemAddAjax(
                { cartId: String(cartId) },
                {
                    cartItem,
                },
                deps,
            );
        }
        case CartVariant.Account: {
            /**
             * This is needed to extract a request quote ID
             * since the rest API does not support the hashed ID used in the GQL queries
             */
            const cartId$ = execute(deps).pipe(map((resp) => resp.id));
            return cartId$.pipe(
                mergeMap((cartId) => {
                    return mineItemAddAjax(
                        {
                            cartItem: { ...cartItem, quote_id: String(cartId) },
                        },
                        deps,
                    );
                }),
            );
        }
        default:
            return assertUnreachable();
    }
}
