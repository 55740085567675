import React from 'react';
import { Customisation } from 'src/components/BundleProduct/types';
import { SelectType } from 'src/components/CustomisationPage/steps/SelectType/SelectType';

import { CustomisationModalWrapper } from './CustomisationModalWrapper';

type Props = {
    closeModal: () => void;
    image: string;
    selectedType?: 'logo' | 'text';
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
};

export const CustomisationStepTypeFieldset: React.FC<Props> = ({
    closeModal,
    image,
    selectedType,
    handleCustomisationSet,
}) => {
    const backgroundStyles = {
        background: 'none',
        padding: '15px 0px 25px 0px',
    };

    return (
        <CustomisationModalWrapper
            image={image}
            titleModal="4. Choose application type"
            closeModal={closeModal}
            stepName="Type"
        >
            <SelectType
                backgroundStyles={backgroundStyles}
                selectedType={selectedType}
                handleCustomisationSet={handleCustomisationSet}
            />
        </CustomisationModalWrapper>
    );
};
