import React, { useState } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';

import classes from './Searcher.scss';

type Props = {
    onSubmit: (searchResults: string) => void;
    filterResults: string;
};

export const Searcher: React.FC<Props> = ({ onSubmit, filterResults }) => {
    const [searchResults, setSearchResults] = useState(filterResults);

    return (
        <Form onSubmit={() => onSubmit(searchResults)}>
            <div className={classes.wrapper}>
                <input
                    value={searchResults}
                    type="text"
                    onChange={(event) => setSearchResults(event.target.value)}
                    placeholder="Search"
                />
                <button type="submit">
                    <img className={classes.icon} src="../../../static/icons/search-orange.svg" />
                </button>
            </div>
        </Form>
    );
};
