import React from 'react';
import { ProductLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { Text } from '@wearejh/rx-form/lib/RxText';
import { CartState } from 'src/util/test-utils';
import { CustomisationState } from 'src/components/CustomisationPage/feature/customisation.reducer';
import { ARTWORK_EXISTING } from 'src/components/Artwork/artwork.types';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { ArtworkItem } from 'src/components/Artwork/artwork.types';

import { ArtworkGridState } from './feature/artwork.register';
import { ArtworkGrid } from './ArtworkGrid';

export const cartIdSelector = (s: {
    cart: CartState;
    artworkGrid: ArtworkGridState;
    customisation: CustomisationState;
}) => {
    const EMPTY_OBJ = {};

    return {
        artworkGrid: s.artworkGrid || EMPTY_OBJ,
        customisation: s.customisation,
    };
};

export enum ArtworkType {
    Bundle = 'bundle',
    Regular = 'regular',
}

type ArtworkGridWrapperProps = {
    locations?: ProductLocation[];
    fieldIndex?: number;
    description?: string;
    artworkGrid?: ArtworkGridState;
    hide?: boolean;
    existingArtworkId?: number;
    applicationMethod: ApplicationMethod;
    processed: ArtworkItem[];
    type: ArtworkType;
};

export const ArtworkGridWrapper: React.FC<ArtworkGridWrapperProps> = React.memo((props) => {
    const { fieldIndex, applicationMethod, processed, description, artworkGrid, hide, existingArtworkId, type } = props;

    return (
        <>
            <ArtworkGrid
                customisations={processed}
                applicationMethod={applicationMethod}
                fieldIndex={fieldIndex}
                description={description}
                artworkGrid={artworkGrid}
                hide={hide}
                existingArtworkId={existingArtworkId}
                type={type}
            />
            <Text field={fieldIndex ? `${fieldIndex}.${ARTWORK_EXISTING}` : ARTWORK_EXISTING} hidden />
        </>
    );
});
