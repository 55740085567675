import {
    getDataFromCustomisation,
    getDataFromCustomisationCamel,
} from 'src/components/Basket/utils/getDataFromCustomisation';
import {
    newCustomisationUser,
    newCustomisationGuest,
    editCustomisationUser,
    editCustomisationGuest,
} from 'src/util/api/customisation';
import { Customisation } from 'src/components/BundleProduct/types';
import { Deps } from 'src/types/global-types';
import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import Bugsnag from '@bugsnag/js';

export const handleCreateCustomisation = (
    customisation,
    itemIds: string[],
    deps: Deps,
    dispatch: React.Dispatch<any>,
    edit: boolean,
) => {
    const computedCustomisation = getDataFromCustomisation(customisation, itemIds);

    handleCustomisation(deps, dispatch, edit, computedCustomisation);
};

export const handleCreateCustomisationCamel = (
    customisation: Partial<Customisation>,
    itemIds: number[],
    deps: Deps,
    dispatch: React.Dispatch<any>,
    id: number | undefined,
    edit: boolean,
) => {
    const computedCustomisation = getDataFromCustomisationCamel(customisation, itemIds, id);

    handleCustomisation(deps, dispatch, edit, computedCustomisation);
};

const handleCustomisation = async (deps: Deps, dispatch: React.Dispatch<any>, edit: boolean, computedCustomisation) => {
    try {
        if (window.localStorage['store.user']) {
            if (edit) {
                await editCustomisationUser(computedCustomisation, deps);
            } else {
                await newCustomisationUser(computedCustomisation, deps);
            }
        } else {
            if (edit) {
                await editCustomisationGuest(computedCustomisation, deps, {
                    cartId: JSON.parse(window.localStorage['store.cart']).cartId,
                });
            } else {
                await newCustomisationGuest(computedCustomisation, deps, {
                    cartId: JSON.parse(window.localStorage['store.cart']).cartId,
                });
            }
        }
        dispatch(CartMsg('Cart.Refresh', { force: true }));
    } catch (e) {
        Bugsnag.notify(e);
    }
};
