import React from 'react';
import { PageTitle } from 'src/components/Layout/PageTitle';
import { RegisterConnected } from 'src/components/SignIn/RegisterModal';
import { Container } from 'src/components/Layout';
import { useDeps } from 'src/hooks/useDeps';
import { useDispatch } from 'react-redux';

export const RegisterPage: React.FC = React.memo(() => {
    const { paths, push } = useDeps();
    const dispatch = useDispatch();
    const login = () => {
        dispatch(push(paths.customer.login));
    };
    const forgot = () => {
        dispatch(push(paths.customer.forgot));
    };
    return (
        <Container variant="modal-narrow">
            <PageTitle title="Register" />
            <div className="card">
                <RegisterConnected login={login} forgot={forgot} showLogin={true} />
            </div>
        </Container>
    );
});
