import React from 'react';
import { ArtworkItem } from 'src/components/Artwork/artwork.types';
import { maybePdf } from 'src/util/formatUrl';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { generateFontImage } from 'src/components/ArtworkApproval/utils/utils';
import { filterCustomisations } from 'src/components/Artwork/utils/filterCustomisations';
import { Customisation } from 'src/components/BundleProduct/types';
import { WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface } from 'swagger/ts/Definitions';

import { ArtworkItem as ArtworkItemComponent } from './ArtworkItem';
import classes from './SelectArtwork.scss';

type Props = {
    customisations: ArtworkItem[];
    existingArtworkId?: number;
    freeCustomisation?: boolean;
    selectedArtwork?: string;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedMethod?: string;
    artworks?: WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface[];
};

export const ArtworkGrid: React.FC<Props> = ({
    customisations,
    selectedMethod,
    freeCustomisation,
    selectedArtwork,
    handleCustomisationSet,
    artworks,
}) => {
    const sortedCustomisations = filterCustomisations(customisations, Number(selectedMethod));
    return (
        <div className={classes.radioGrid} data-test-id="ArtworkGrid">
            {sortedCustomisations.map((item) => {
                const previewImage = maybePdf(item.image);
                const textImage = item.application_type === ApplicationType.Text && generateFontImage(item);
                const logoImage = item.application_type === ApplicationType.Logo && (
                    <img src={previewImage} alt={item.title} />
                );
                const imageUrl = item.image.split('/').pop();
                const imageId = imageUrl?.includes('%') ? `View ${imageUrl.split('%')[0]}` : 'View artwork';
                const computedArtwork = artworks?.find(
                    (artwork) => artwork.artwork_id === Number(item.id) || artwork.id === Number(item.id),
                );

                return (
                    <ArtworkItemComponent
                        key={item.id}
                        imageId={imageId}
                        textImage={textImage}
                        logoImage={logoImage}
                        artwork={item}
                        computedArtwork={computedArtwork}
                        freeCustomisation={freeCustomisation}
                        selectedArtwork={selectedArtwork}
                        handleCustomisationSet={handleCustomisationSet}
                    />
                );
            })}
        </div>
    );
};
