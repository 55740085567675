import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import { Actions, TypeMap } from '../customisation.actions';
import { Deps } from '../../../../types/global-types';

export function methodSubmit(action$: Observable<any>, _state$: Observable<any>, deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.MethodSubmit']>('Customisation.MethodSubmit'),
        switchMap(() => {
            return of(deps.push(deps.paths.customisation.artwork));
        }),
    );
}
