import React from 'react';
import { BasketProduct } from 'src/components/Basket/BasketProduct';
import { Dropdown } from 'src/components/Dropdown/Dropdown';
import {
    CustomisationFull,
    createStateFromCustomisation,
    Targets,
} from 'src/components/CustomisationPage/feature/customisation.types';
import { Messages } from 'src/components/Messages/Messages';
import { errorMessage } from 'src/util/state-helpers';

import { CustomisationSummary } from '../CustomisationPage/CustomisationSummary';

import classes from './BasketCopy.scss';

type Props = {
    customisation: CustomisationFull;
    locationNames: string[];
    handleItemCheck: (itemId: string) => void;
} & Targets;

export const BasketCopy: React.FC<Props> = ({
    customisation,
    validTargets,
    invalidTargets,
    handleItemCheck,
    locationNames,
}) => (
    <>
        <div className={classes.summary}>
            <CustomisationSummary
                customisation={createStateFromCustomisation(customisation)}
                locationNames={locationNames}
            />
        </div>

        <div className={classes.compatible}>
            <h2 className={classes.compatibleTitle}>Choose Garment</h2>
            {validTargets.length === 0 && (
                <Messages
                    messages={[
                        errorMessage(`None of the remaining items in your basket can receive this customisation`),
                    ]}
                />
            )}
            {validTargets.map((cartItem) => (
                <label className={classes.product} key={cartItem.id} data-testid="validTargetCheckbox">
                    <input
                        type="checkbox"
                        onChange={(e) => {
                            handleItemCheck(cartItem.id);
                        }}
                    />
                    <BasketProduct cartItem={cartItem} />
                </label>
            ))}
        </div>

        {invalidTargets.length > 0 && (
            <Dropdown
                buttonText={`${invalidTargets.length} garment${invalidTargets.length === 1 ? '' : 's'} not supported`}
                isError={true}
            >
                {invalidTargets.map((cartItem) => (
                    <div key={cartItem.id} className={[classes.product, classes.productDisabled].join(' ')}>
                        <BasketProduct cartItem={cartItem} />
                    </div>
                ))}
            </Dropdown>
        )}
    </>
);
