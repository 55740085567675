import React from 'react';
import { CustomerDataAddressInterface } from 'swagger/ts/Definitions';
import { Svg } from 'src/components/Svg/Svg';

import { Button } from '../Button/Button';

import { Address } from './Address';
import classes from './Address.scss';

type Props = {
    addresses: CustomerDataAddressInterface[];
    edit(address: CustomerDataAddressInterface);
    delete(address: CustomerDataAddressInterface);
};

export const OtherAddresses: React.FC<Props> = (props) => {
    const { addresses } = props;
    return (
        <>
            <h2 className="underline">Other Addresses</h2>
            <div className="flex-layout" data-test-id="OtherAddresses">
                {addresses.map((address) => {
                    return (
                        <div className={classes.address} key={address.id} data-test-id="OtherAddressEntry">
                            <Button
                                icon="left-of-text"
                                variant="reset"
                                data-test-id="DeleteAddress"
                                onClick={() => props.delete(address)}
                                className={classes.remove}
                            >
                                <Svg name="remove" />
                            </Button>
                            <Address address={address} />
                            <Button
                                icon="left-of-text"
                                variant="reset"
                                data-test-id="EditAddress"
                                onClick={() => props.edit(address)}
                                className={classes.edit}
                            >
                                <Svg name="edit" />
                                <span>Edit</span>
                            </Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
