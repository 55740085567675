import { Deps } from 'src/types/global-types';
import { Observable, concat, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { concatMap, mergeMap, catchError } from 'rxjs/operators';
import { execute } from 'swagger/ts/WorkwearExpressArtworkCustomerArtworkApprovalRepositoryV1GetForGuestGet';

import { Actions, Msg, TypeMap } from '../artworkApproval.register';

const action = 'ArtworkGuest.Fetch';

export function getArtworkGuestApprovalEpic(action$: Observable<any>, _state$, deps: Deps) {
    return action$.pipe(
        ofType<Actions, TypeMap[typeof action]>(action),
        concatMap(({ payload }) => {
            const ajax$ = execute({ token: payload.token }, deps);
            return concat(
                ajax$.pipe(
                    mergeMap((resp) => of(Msg('Artwork.Success', resp))),
                    catchError((err) => {
                        return of(Msg('Artwork.Error', err));
                    }),
                ),
            );
        }),
    );
}
