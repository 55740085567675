import { ActionMap, createMsg } from 'action-typed';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';

import { PriceSummary } from '../utils/priceEstimates';

import { CustomisationState } from './customisation.reducer';
import { ApplicationMethod, ApplicationType, AvailableLocation, CustomisationFull, Step } from './customisation.types';

export enum EntryKind {
    New = 'New',
    Edit = 'Edit',
}

export type CustomisationEntry =
    | {
          kind: EntryKind.New;
          itemIds: (number | string)[];
      }
    | {
          kind: EntryKind.Edit;
          itemIds: (number | string)[];
          customisation: FromCustomisation;
          locationNames: string[];
      };

export type ConfigSubmit = {
    textLineOne: string | null;
    textLineTwo: string | null;
    textLineThree: string | null;
    color: string | null;
    font: string | null;
    image: string | null;
    customisationName: string | null;
    specialInstructions: string | null;

    isContactRequested: boolean;
    is_free?: boolean;
    is_artwork_missing?: boolean;
};

/**
 * This allows converting from an existing artwork piece into a pre-filled
 * customisation
 */
export type FromCustomisation = ConfigSubmit & {
    application_method: ApplicationMethod;
    application_type: ApplicationType;
    id?: string | number;
    artwork_id?: string | number;
};

type MethodSubmit = {
    applicationMethod: ApplicationMethod;
    oneMethodAvailable?: boolean;
};

export type Messages = {
    ['Customisation.LoadStep']: { step: Step; search?: string };
    ['Customisation.VerifyCustomisation']: {
        entry: CustomisationEntry;
        availableLocations: AvailableLocation[];
        takenLocations: string[];
    };
    ['Customisation.VerifyStep']: Step;

    ['Customisation.ArtworkSubmitNew']: undefined;
    ['Customisation.ArtworkSubmitExisting']: CustomisationFull;
    ['Customisation.MethodSubmit']: MethodSubmit;
    ['Customisation.TypeSubmit']: ApplicationType;
    ['Customisation.ConfigSubmit']: ConfigSubmit;
    ['Customisation.PositionSubmit']: string[];
    ['Customisation.FromArtwork']: FromCustomisation;

    ['Customisation.SetStatus']: DataStatus;
    ['Customisation.CustomisationSubmit']: boolean;
    ['Customisation.CustomisationSuccess']: boolean;
    ['Customisation.CustomisationError']: string;

    ['Customisation.EstimatePrices']: { locations: string[]; isLogoMissing?: boolean };
    ['Customisation.EstimatePricesSuccess']: { summary: PriceSummary | null };
    ['Customisation.EstimatePricesError']: string;
};

export const Msg = createMsg<Messages>();
export const CustomisationMsg = Msg;
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
export type AppendState<T = Record<string, unknown>> = T & { customisation: CustomisationState };
