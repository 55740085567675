import * as Definitions from "./Definitions";
import { Observable } from "rxjs";
import { Effects } from "../";
export const description = "";
export const method = "POST";
export const operationId = "workwearExpressSharedBasketSharedBasketCreatorV1CreatePost";
export const path = "/V1/shared-baskets/mine/shared-basket";
export interface Body {
    creationRequest: Definitions.WorkwearExpressSharedBasketDataSharedBasketCreationRequestInterface;
}
export type Response = Response200 | Response401 | ResponseDefault;
export type Response200 = Definitions.WorkwearExpressSharedBasketDataSharedBasketInterface;
export type RespKeys = (keyof Definitions.WorkwearExpressSharedBasketDataSharedBasketInterface)[];
export type Response401 = Definitions.ErrorResponse;
export type ResponseDefault = Definitions.ErrorResponse;
type MinimalEffects = Pick<Effects, "apiUrl" | "restHeaders" | "postJSON">;
export function execute<T extends RespKeys | undefined>(body: Body, effects: MinimalEffects, fields?: T): T extends RespKeys ? Observable<Pick<Definitions.WorkwearExpressSharedBasketDataSharedBasketInterface, T[number]>> : Observable<Response200> {
    return effects.postJSON(effects.apiUrl(operationId)(path, undefined, fields), body, effects.restHeaders({})) as any;
}
