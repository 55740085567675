import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import { Actions, CustomisationMsg, TypeMap } from '../customisation.actions';
import { Deps } from '../../../../types/global-types';

export function configSubmit(action$: Observable<any>, _state$: Observable<any>, _deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.ConfigSubmit']>('Customisation.ConfigSubmit'),
        switchMap(() => {
            return of(CustomisationMsg('Customisation.CustomisationSubmit', true));
        }),
    );
}
