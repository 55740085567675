import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { catchable } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { CurrencyActions } from '@wearejh/m2-pwa-currency/lib/browser';
import { Step } from 'src/components/Basket/feature/basket.types';
import { refreshBasketCustomisations } from 'src/components/Basket/utils/refreshBasket';
import { UserActions } from '@wearejh/m2-pwa-user/lib/user.actions';

import { Actions, BasketMsg } from '../basket.actions';
import { Deps } from '../../../../types/global-types';

export function basketRefreshEpic(action$: Observable<any>, state$: Observable<any>, deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<Actions>('Basket.Refresh'),
        withLatestFrom(cartVariantAndId(state$)),
        switchMap(([, [variant, cartId]]) => {
            return refreshBasketCustomisations(variant, cartId!, deps).pipe(
                map(({ updatedAt, customisations, parsedCardId }) => {
                    return BasketMsg('Basket.RefreshSuccess', { updatedAt, customisations, parsedCardId });
                }),
                catchable(({ error }) => {
                    return of(BasketMsg('Basket.RefreshError', error));
                }),
            );
        }),
    );
}

export function basketRefreshOnCurrencyChange(action$: Observable<any>) {
    return action$.pipe(
        ofType<CurrencyActions | UserActions>('Currency.Set', 'User.SignInSuccess'),
        mapTo(BasketMsg('Basket.LoadStep', Step.List)),
    );
}
