import dlv from 'dlv';
import {
    SalesDataOrderInterface,
    SalesDataOrderPaymentInterface,
    WorkwearExpressArtworkDataArtworkApprovalCompositeInterface,
} from 'swagger/ts/Definitions';
import { parseAndFormatDate } from 'src/util/formatDate';
import { BRAINTREE_NAME, PAYPAL_EXPRESS_NAME, CARD_NAMES } from 'src/components/Checkout/feature/wwe-checkout.types';
import { ListOrder } from 'src/features/app/orders.register';

import { OrderDetailItem } from '../OrderDetailItem';
import { OrderDetailInfoAddress } from '../OrderDetailAddress';
import { OrderTotalProps } from '../OrderTotals';

export type ParsedOrder = {
    orderDetails: OrderDetailProps;
    shippingAddress: OrderDetailInfoAddress;
    billingAddress: OrderDetailInfoAddress;
    shipping: string;
    orderDetailItems: OrderDetailItem[];
    payment: {
        paymentInfo: string;
        cardNumber?: string;
        cardBrand?: string;
    };
    orderTotal: OrderTotalProps;
    artworkApprovals?: WorkwearExpressArtworkDataArtworkApprovalCompositeInterface[];
};

type OrderDetailProps = {
    orderNumber: string;
    placed: string;
    total: string | number;
    status: string;
    loading?: boolean;
    className?: string;
    orderId?: number;
    orderStatus?: string;
    estimatedShippingDate?: string;
    shippingDate?: string;
    maskedQuoteId?: string;
};

const emptyOrder: ParsedOrder = {
    orderDetails: {
        orderNumber: '',
        placed: '',
        status: '',
        total: 0,
        orderId: 111,
    },
    shippingAddress: {
        address_line1: '',
        address_line2: '',
        city: '',
        firstname: '',
        lastname: '',
        postcode: '',
        telephone: '',
    },
    billingAddress: {
        telephone: '',
        postcode: '',
        lastname: '',
        firstname: '',
        city: '',
    },
    shipping: '',
    orderDetailItems: [],
    payment: {
        paymentInfo: '',
        cardNumber: '',
    },
    orderTotal: {
        total: '',
        subtotal: '',
        delivery: '',
    },
};

const emptyAddress: OrderDetailInfoAddress = {
    firstname: '',
    lastname: '',
    city: '',
    postcode: '',
    telephone: '',
};

/**
 * @param order
 */
export function processOrderInfo(order?: SalesDataOrderInterface | null, id?: string): ParsedOrder {
    if (!order) return emptyOrder;

    /**
     * Shipping address is deeply nests in the `shipping_assignments`
     */
    const shippingAddress: OrderDetailInfoAddress = dlv(
        order,
        'extension_attributes.shipping_assignments.0.shipping.address',
        undefined,
    );

    const billingAddress: OrderDetailInfoAddress = (order.billing_address && order.billing_address) || emptyAddress;

    const OrderDetailItems: OrderDetailItem[] = order.items
        .filter((item) => item.parent_item === undefined && item.product_type !== 'customisation')
        .map((item) => {
            const options = item.extension_attributes?.options || [];
            const thumbnail = item.extension_attributes?.product_small_image || '';
            const link = item.extension_attributes?.product_url_key;
            const meta =
                item.product_type === 'configurable' && options.length > 0
                    ? options
                    : [
                          {
                              attribute_label: item.sku,
                              attribute_value: item.sku,
                          },
                      ];

            return {
                sku: item.sku,
                name: item.name || '',
                meta,
                price: item.price || 0,
                qty_ordered: item.qty_ordered || 0,
                link: link || '',
                product_thumbnail: thumbnail,
                product_type: item.product_type,
                additional_data: item.additional_data,
                quote_id: String(item.quote_item_id),
                product_option: item.product_option,
                customisations: item.extension_attributes?.customisations,
            };
        });

    const grandTotal = order.base_grand_total || order.grand_total || 0;

    const reformatID = (id) => `SO${id.slice(3)}`;

    const IdToUse = order.extension_attributes?.khaos_id
        ? order.extension_attributes?.khaos_id
        : id
        ? reformatID(id)
        : 'ID pending';

    const orderDetail: OrderDetailProps = {
        orderNumber: IdToUse,
        placed: parseAndFormatDate(order.created_at || ''),
        total: grandTotal,
        status: order.status || '',
        orderStatus: order.state,
        orderId: order.entity_id,
        estimatedShippingDate: order.extension_attributes?.shipping_date,
        shippingDate:
            order.extension_attributes?.last_ship_date &&
            parseAndFormatDate(order.extension_attributes?.last_ship_date),
        maskedQuoteId: order.extension_attributes?.masked_quote_id,
    };

    const paymentInfo = (order.payment && cardType(order.payment)) || '';

    const orderTotal: OrderTotalProps = {
        delivery: (order.shipping_amount && order.shipping_amount) || '',
        subtotal: (order.base_subtotal && order.base_subtotal) || '',
        total: grandTotal,
        vat: (order.base_tax_amount && order.base_tax_amount) || undefined,
    };

    return {
        orderDetails: orderDetail,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        shipping: order.shipping_description || '',
        orderDetailItems: OrderDetailItems,
        payment: {
            paymentInfo: paymentInfo,
            cardNumber: order.payment?.cc_last4,
            cardBrand: order.payment?.extension_attributes?.cc_brand,
        },
        orderTotal: orderTotal,
        artworkApprovals: order.extension_attributes?.artwork_approvals,
    };
}

function cardType(payment: SalesDataOrderPaymentInterface): string {
    switch (payment.method) {
        case 'checkmo': {
            return `Credit or Money Order`;
        }
        case BRAINTREE_NAME: {
            const maybe = (() => {
                return {
                    cc_type: payment.cc_type?.toLowerCase(),
                    cc_last4: payment.cc_last4,
                    cc_exp_year: payment.cc_exp_year,
                    cc_exp_month: payment.cc_exp_month,
                };
            })();
            return `${(maybe.cc_type && CARD_NAMES[maybe.cc_type]) || 'Card'} ending ${maybe.cc_last4}`;
        }
        case PAYPAL_EXPRESS_NAME: {
            return `Paypal Express`;
        }
        default: {
            return `${payment.method} not supported`;
        }
    }
}

export enum ArtworkStatus {
    Processing = 'Processing',
    ReviewPending = 'ReviewPending',
    RequestPending = 'RequestPending',
    ArtworkApproved = 'ArtworkApproved',
}

export function getArtworkStatus(status) {
    switch (status) {
        case ArtworkStatus.ReviewPending:
            return 'Artwork approval required';
        case ArtworkStatus.RequestPending:
            return 'Change request pending';
        case ArtworkStatus.ArtworkApproved:
            return 'Artwork Approved';
        default:
            return 'View Order';
    }
}

function approvalPending(status): ArtworkStatus {
    if (status && status.length === 0) {
        return ArtworkStatus.Processing;
    } else if (status && status.some((e) => ['proof_sent', 'chase'].includes(e.status))) {
        return ArtworkStatus.ReviewPending;
    } else if (status && status.some((e) => e.status === 'reproof')) {
        return ArtworkStatus.RequestPending;
    } else if (status && status.every((e) => e.status === 'approved')) {
        return ArtworkStatus.ArtworkApproved;
    } else {
        return ArtworkStatus.Processing;
    }
}

export function cleanLatestOrders(orders: SalesDataOrderInterface[]): ListOrder[] {
    return orders.map((order) => {
        const artworkApproval = order.extension_attributes?.artwork_approvals;
        const artworkStatus = approvalPending(artworkApproval);
        const orderStatus = order.status || 'pending';
        const khaosId = order.extension_attributes?.khaos_id ?? 'ID pending';

        return {
            orderId: (order.entity_id && order.entity_id.toString()) || '',
            entityId: khaosId,
            date: parseAndFormatDate(order.created_at || ''),
            shipTo: (order.billing_address?.street || []).join(',') || '',
            orderTotal: order.base_grand_total ?? 0,
            status: orderStatus,
            artworkStatus: artworkStatus,
            itemsCount: order.items.filter(
                ({ product_type }) =>
                    product_type && ['configurable', 'wearer_bundle', 'bundle'].includes(product_type),
            ).length,
        };
    });
}
