import { useEffect } from 'react';
import { concat, defer, Observable, forkJoin } from 'rxjs';
import { ignoreElements } from 'rxjs/operators';
import { loadCssAsObservable } from 'src/util/loadCssAsObservable';

const CSS_FILE = 'https://services.postcodeanywhere.co.uk/css/address-3.70.min.css';
const JS_FILE = 'https://services.postcodeanywhere.co.uk/js/address-3.70.min.js';

/**
 * The hook that can be used in a component with an address field
 */
export function usePCAPredict(
    apiKey: string,
    countryCodes: string,
    prefix: string,
    setValues: (values: Record<string, any>) => void,
    getFields: (pca: any) => void,
) {
    useEffect(() => {
        const PCA_OPTIONS = {
            key: apiKey,
            countries: { codesList: countryCodes },
            suppressAutocomplete: false,
            list: {
                inlineMessages: true,
                onlyDown: true,
            },
        };

        const obs$ = concat(loadPCAAssets(), initPCA(PCA_OPTIONS, getFields));

        const sub$ = obs$.subscribe({
            next: (address) => {
                setValues(address);
            },
        });
        return () => sub$.unsubscribe();
    }, [apiKey, countryCodes, getFields, prefix, setValues]);
}

/**
 * Load the PCA
 * @param options
 * @param getFields
 */
function initPCA(options, getFields): Observable<PCAAddress> {
    return Observable.create((observer) => {
        const pca = (window as any).pca;
        const fields = getFields(pca);
        const control = new pca.Address(fields, options);
        control.listen('populate', function (fields: PCAAddress) {
            observer.next(fields);
        });
        return () => control.destroy();
    });
}

/**
 * This will handle the loading of the JS/CSS files needed
 */
function loadPCAAssets() {
    return forkJoin([loadCssAsObservable(CSS_FILE), defer(() => require('load-js/src/load-js.js')(JS_FILE))]).pipe(
        ignoreElements(),
    );
}

interface PCAAddress {
    Id: string;
    DomesticId: string;
    Language: string;
    LanguageAlternatives: string;
    Department: string;
    Company: string;
    SubBuilding: string;
    BuildingNumber: string;
    BuildingName: string;
    SecondaryStreet: string;
    Street: string;
    Block: string;
    Neighbourhood: string;
    District: string;
    City: string;
    Line1: string;
    Line2: string;
    Line3: string;
    Line4: string;
    Line5: string;
    AdminAreaName: string;
    AdminAreaCode: string;
    Province: string;
    ProvinceName: string;
    ProvinceCode: string;
    PostalCode: string;
    CountryName: string;
    CountryIso2: string;
    CountryIso3: string;
    CountryIsoNumber: number;
    SortingNumber1: string;
    SortingNumber2: string;
    Barcode: string;
    POBoxNumber: string;
    Label: string;
    Type: string;
    DataLevel: string;
    Field1: string;
    Field2: string;
    Field3: string;
    Field4: string;
    Field5: string;
    Field6: string;
    Field7: string;
    Field8: string;
    Field9: string;
    Field10: string;
    Field11: string;
    Field12: string;
    Field13: string;
    Field14: string;
    Field15: string;
    Field16: string;
    Field17: string;
    Field18: string;
    Field19: string;
    Field20: string;
    FormattedLine1: string;
    FormattedLine2: string;
    FormattedLine3: string;
    FormattedLine4: string;
    FormattedLine5: string;
}
