import { assertUnreachable } from '@wearejh/m2-pwa-engine/lib/index';
import { useCallback, useEffect, useReducer } from 'react';
import { UseEditAddressesApi } from '@wearejh/m2-pwa-addresses/lib/hooks/useEditAddresses';
import { CustomerDataAddressInterface } from 'swagger/ts/Definitions';

type State = {
    operation: Operation;
    id?: number;
};

export enum Operation {
    None = 'None',
    Add = 'Add',
    Edit = 'Edit',
}

const initialState: State = {
    operation: Operation.None,
};

type Events =
    | {
          type: Operation.Add;
      }
    | {
          type: Operation.Edit;
          id: number;
      }
    | {
          type: Operation.None;
      };

function reducer(state: State = initialState, action: Events): State {
    switch (action.type) {
        case Operation.Add: {
            return { ...state, operation: Operation.Add, id: undefined };
        }
        case Operation.Edit: {
            return { ...state, operation: Operation.Edit, id: action.id };
        }
        case Operation.None: {
            return { ...state, operation: Operation.None, id: undefined };
        }
        default:
            return assertUnreachable('Cannot get here');
    }
}

export function useEditAddressesPopup(addresses: CustomerDataAddressInterface[], edit: UseEditAddressesApi) {
    const { createAddress, editAddress, deleteAddress, isPending, onSuccess } = edit;
    const [{ id, operation }, dispatch] = useReducer(reducer, initialState);
    const newAddress = () => dispatch({ type: Operation.Add });
    const openEditAddress = (address) => dispatch({ type: Operation.Edit, id: address.id });
    const isOpen = operation === Operation.Edit || operation === Operation.Add;

    /**
     * If the id is set, grab the selected address;
     */
    const selected = (operation === Operation.Edit && id && addresses.find((add) => add.id === id)) || undefined;

    /**
     * Handle popup being closed
     */
    const closeModal = useCallback(() => {
        dispatch({ type: Operation.None });
    }, []);

    useEffect(() => {
        const sub = onSuccess.subscribe(() => closeModal());
        return () => {
            sub.unsubscribe();
        };
    }, [closeModal, onSuccess]);

    /**
     * Handle when popup tries to save
     */
    const onSave = useCallback(
        (values) => {
            switch (operation) {
                case Operation.Add:
                    return createAddress(values);
                case Operation.Edit:
                    return editAddress({ ...values, id: selected && selected.id });
            }
        },
        [createAddress, editAddress, operation, selected],
    );

    return {
        operation,
        deleteAddress,
        isPending,
        selected,
        closeModal,
        onSave,
        newAddress,
        openEditAddress,
        isOpen,
    };
}
