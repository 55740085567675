import React, { useState } from 'react';
import { useArtwork } from 'src/hooks/useArtwork';
import { SubmitButton } from 'src/components/Artwork/SubmitButton';
import { NEW_ARTWORK_ID, EMPTY_ARTWORK_ID } from 'src/constants/artwork';
import classnames from 'classnames';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';
import { Customisation } from 'src/components/BundleProduct/types';

import { AddNewLogo } from './AddNewLogo';
import { ArtworkGrid } from './ArtworkGrid';
import classes from './SelectArtwork.scss';

type SelectArtworkProps = {
    selectedApplicationMethod?: number;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    onNextButtonClick: () => void;
    freeCustomisation?: boolean;
    selectedArtwork?: string;
    selectedMethod?: string;
    backgroundStyles?: BackgroundStyles;
};

export const SelectArtwork: React.FC<SelectArtworkProps> = ({
    selectedApplicationMethod,
    handleCustomisationSet,
    onNextButtonClick,
    freeCustomisation,
    selectedArtwork,
    selectedMethod,
    backgroundStyles,
}) => {
    const [newLogo, setNewLogo] = useState(false);
    const { deduplicatedCustomisations, processed, price, isPending } = useArtwork();
    const matchingCustomisations = deduplicatedCustomisations.filter(
        (object) => object.type === Number(selectedApplicationMethod),
    );

    const onNewCustomisationSubmit = () => {
        handleCustomisationSet({
            selectedArtwork: NEW_ARTWORK_ID,
            selectedArtworkExisting: undefined,
            selectedType: 'logo',
            selectedOptions: {
                isContactRequested: 'upload',
            },
            isArtworkTemporary: true,
        });
        onNextButtonClick();
    };

    if (!isPending && matchingCustomisations.length === 0) {
        return (
            <fieldset className={classes.wrapperNoCustomisations}>
                <div className={classes.radioGridAddNew}>
                    <div className={classes.addNewContainer}>
                        <SubmitButton
                            onNewCustomisationSubmit={onNewCustomisationSubmit}
                            price={price}
                            freeCustomisation={freeCustomisation}
                        />
                    </div>
                </div>
            </fieldset>
        );
    }

    if (!isPending) {
        return (
            <fieldset className={classes.wrapper} style={backgroundStyles}>
                <div className={classes.wrapperInfo}>
                    {deduplicatedCustomisations?.length > 0 && (
                        <p className={classes.subHeader}>
                            <strong>Choose</strong> from your existing artwork
                        </p>
                    )}
                    <div className={classes.artworkCheckboxDesktop}>
                        <AddNewLogo
                            id={NEW_ARTWORK_ID}
                            description="+ Add a new logo"
                            setNewLogo={setNewLogo}
                            handleCustomisationSet={handleCustomisationSet}
                        />
                    </div>
                </div>
                <div className={classes.radioGridAddNew}>
                    <ArtworkGrid
                        artworks={deduplicatedCustomisations}
                        customisations={processed}
                        selectedMethod={selectedMethod}
                        freeCustomisation={freeCustomisation}
                        selectedArtwork={selectedArtwork}
                        handleCustomisationSet={handleCustomisationSet}
                    />
                    <div
                        className={classnames(classes.addNewContainer, {
                            [classes.addNewContainerVisible]: newLogo,
                        })}
                    >
                        <button
                            className={classes.closeAddNew}
                            type="button"
                            data-testid="closeAddNewButton"
                            onClick={() => {
                                setNewLogo(false);
                                handleCustomisationSet({
                                    selectedArtwork: EMPTY_ARTWORK_ID,
                                    selectedArtworkExisting: undefined,
                                    selectedType: 'logo',
                                    selectedOptions: {
                                        isContactRequested: 'upload',
                                    },
                                    isArtworkTemporary: true,
                                });
                            }}
                        ></button>
                        <SubmitButton
                            onNewCustomisationSubmit={onNewCustomisationSubmit}
                            price={price}
                            freeCustomisation={freeCustomisation}
                        />
                    </div>
                </div>
                <div className={classes.artworkCheckboxMobile}>
                    <AddNewLogo
                        id={NEW_ARTWORK_ID}
                        description="+ Add a new logo"
                        setNewLogo={setNewLogo}
                        handleCustomisationSet={handleCustomisationSet}
                    />
                </div>
            </fieldset>
        );
    }

    return <div className={classes.loader}>Looking for saved logos...</div>;
};
