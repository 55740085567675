import React, { useState, useContext, useEffect } from 'react';
import { getCardBrand } from 'src/components/Orders/utils/getCardBrand';
import { OrderDetailInfoAddress } from 'src/components/Orders/OrderDetailAddress';
import { formatDate } from 'src/util/formatDate';
import { Price } from 'src/components/Helpers/Price';
import { OrderItem } from 'src/components/OrderItemFromMagento/OrderItemFromMagento';
import { parse } from 'date-fns';
import { ModalOrderTracking } from 'src/components/Orders/ModalOrderTracking';
import { useMutation, useQuery } from '@apollo/react-hooks';
import mergeCarts from 'src/queries/mergeCarts.graphql';
import { useDeps } from 'src/hooks/useDeps';
import { useDispatch } from 'react-redux';
import { BasketContext } from 'src/components/Basket/BasketContext';
import productList from 'src/queries/products.graphql';
import { Modal } from 'src/components/Modal/Modal';
import { CloseButton } from 'src/components/BundleProduct/CloseButton';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { basketRegister } from 'src/components/Basket/feature/basket.register';
import { BasketContextProvider } from 'src/components/Basket/BasketContext';
import { BasketCspContextProvider } from 'src/components/Basket/BasketCspContext';
import { ItemDetailsLoading } from 'src/components/Orders/ItemDetailsLoading';
import { useTreatments } from '@splitsoftware/splitio-react';
import { OrderItemArtwork } from 'src/components/Orders/OrderItemArtwork';
import axios from 'axios';
import Bugsnag from '@bugsnag/js';
import { ArtworkValues } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import ReactTooltip from 'react-tooltip';
import { Button } from 'src/components/Button/Button';
import { ModalHeader } from 'src/components/ProductDetail/ModalHeader';
import customerOrder from 'src/queries/customerOrder.graphql';
import { camelizeKeys } from 'humps';
import { OrderStatusWidget } from 'src/components/Orders/OrderStatusWidget';
import { useHistory } from 'react-router-dom';
import { ExpectedDeliveryDate } from 'src/components/Orders/ExpectedDeliveryDate';
import { mergeCarts as mergeCartsUtil } from 'src/components/Orders/utils/mergeCarts';
import { processOrderGraphqlMagento } from 'src/components/Orders/utils/processOrderGraphqlMagento';
import classes from 'src/components/Orders/ItemDetails.scss';
import statusMessageFromKhaos from 'src/queries/statusMessageFromKhaos.graphql';

export type Item = {
    sku: string;
    name: string;
    meta: {
        attributeLabel: string;
        attributeValue: string;
    }[];
    price: number;
    qtyOrdered: number;
    link: string;
    productThumbnail: string;
    productType: string;
    additionalData?: string;
};

type Props = {
    order: {
        orderDetails: {
            orderNumber: string;
            placed: string;
            total: number;
            status: string;
            statusMessage: string;
            estimatedShippingDate?: string;
            shippingDate?: string;
            maskedQuoteId?: string;
        };
        shippingAddress: {
            countryCode: string;
            city: string;
            firstname: string;
            lastname: string;
            postcode: string;
            street: string[];
            telephone: string;
        };
        billingAddress: {
            countryCode: string;
            city: string;
            firstname: string;
            lastname: string;
            postcode: string;
            street: string[];
            telephone: string;
        };
        orderDetailItems: Item[];
        payment: {
            cardNumber: string;
            cardBrand: string;
        };
        artworkApprovals?: {
            id: number;
        }[];
    };
    id: string;
    soNumber: string;
};

const AddressContent = (address: OrderDetailInfoAddress) => (
    <span className={classes.address}>
        <p>
            <span>{address.firstname} </span>
            <span>{address.lastname}</span>
        </p>
        <p className={classes.address}>
            <span>{address.street?.[1] && address.street?.[1]}</span>
            <span>{address.street?.[0] && address.street?.[0]}</span>
            <span>{address.city && address.city}</span>
            <span>{address.postcode && address.postcode}</span>
        </p>
    </span>
);

export const ItemDetailsFromMagento: React.FC<{ soNumber: string; id: string }> = ({ id, soNumber }) => {
    const { data } = useQuery(customerOrder, {
        variables: {
            orderFilters: {
                number: { eq: id },
            },
        },
        fetchPolicy: 'network-only',
    });

    const ready = useLazyRegister(basketRegister, true, 'Basket');
    if (!ready) return null;

    if (!data?.customer?.orders?.items) {
        return <ItemDetailsLoading />;
    }

    return (
        <BasketContextProvider>
            <BasketCspContextProvider>
                <ItemDetails
                    soNumber={soNumber}
                    order={processOrderGraphqlMagento(camelizeKeys(data.customer.orders.items[0]))}
                    id={id}
                />
            </BasketCspContextProvider>
        </BasketContextProvider>
    );
};

export const ItemDetails: React.FC<Props> = ({ order, id, soNumber }) => {
    const { cartId } = useContext(BasketContext);
    const deps = useDeps();
    const dispatch = useDispatch();
    const [mergeCartsMutation, { data: mergeCartsData }] = useMutation(mergeCarts);
    const history = useHistory();
    const goToPreviousPath = () => {
        history.goBack();
    };

    const { data: statusMessageData, loading } = useQuery(statusMessageFromKhaos, {
        variables: {
            id: soNumber,
        },
        fetchPolicy: 'network-only',
    });

    const statusMessage = statusMessageData?.customerInvoice?.khaos_invoices?.[0]?.status_message;

    const { payment, orderDetails, billingAddress, shippingAddress, artworkApprovals } = order;
    const [isViewTrackingModalOpen, setIsViewTrackingModalOpen] = useState(false);
    const [isArtworksModal, setIsArtworksModal] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [orderShipped, setOrderShipped] = useState(false);
    const [isArtworkEnabled, setIsArtworkEnabled] = useState(false);
    const artworksIds = artworkApprovals && artworkApprovals.map((approval) => approval.id);
    const hasArtwork = artworksIds && artworksIds.length > 0;
    const artworkButtonActive = hasArtwork && isArtworkEnabled;

    const featureName = 'Account_Reorder';
    const treatments = useTreatments([featureName]);

    const { treatment } = treatments[featureName];

    const { data } = useQuery(productList, {
        variables: { productFilters: { url_key: { in: order.orderDetailItems.map((item) => item.link) } } },
    });

    const isMaskedQuoteId = Boolean(orderDetails?.maskedQuoteId);

    useEffect(() => {
        if (!Boolean(mergeCartsData)) return;
        setError(mergeCartsData.addToCartFromOrder.errors.join('\r\n'));
    }, [mergeCartsData]);
    const handleReorder = () => {
        if (!isMaskedQuoteId) {
            setError(`Can't find basket ID`);
        }

        mergeCartsUtil(mergeCartsMutation, orderDetails?.maskedQuoteId || '', cartId || '', deps, dispatch, setError);
    };

    const handleSubmit = async (id: number, values: ArtworkValues, status: string, deps) => {
        try {
            await axios({
                method: 'POST',
                url: `/rest/V1/artwork_approval/${id}/comment`,
                data: {
                    ...values,
                    status: status,
                },
                headers: { Authorization: deps.restHeaders({}).Authorization },
            });
        } catch (err) {
            Bugsnag.notify(err);
        }
    };

    const isOfflineOrder = orderDetails.status === '';

    const itemsQty = order.orderDetailItems
        .map((item) => (item.price && item.price > 0 ? item.qtyOrdered || 0 : 0))
        .reduce((partialSum, current) => partialSum + current);

    const showButtonContainer = artworkButtonActive || (treatment === 'on' && isMaskedQuoteId);

    const ArtworkModal = ({ isOpen, onDismiss }) => (
        <Modal isOpen={isOpen} type="popup" onDismiss={onDismiss} className={classes.artworkModal}>
            <ModalHeader title="Artwork Approval" closeModal={onDismiss} />
            <div className={classes.artworksContainer}>
                {artworksIds && (
                    <div className={classes.artworksContainer}>
                        {artworksIds.map((artworkId) => (
                            <OrderItemArtwork key={artworkId} artworkId={artworkId} onSubmit={handleSubmit} />
                        ))}
                    </div>
                )}
            </div>
        </Modal>
    );
    return (
        <>
            <div className={classes.wrapper}>
                <div className={classes.header}>
                    <div className={classes.orderTitle}>
                        <Button onClick={goToPreviousPath}>
                            <img className={classes.icon} src="../../../static/icons/arrow-orange-left-thin.svg" />
                        </Button>
                        <p className={classes.title}>
                            <span className={classes.titleOrder}>Order </span>
                            {orderDetails.orderNumber}
                        </p>
                    </div>
                    {orderShipped ? (
                        <div className={classes.trackingInfoButtonContainer}>
                            <button
                                className={classes.trackingInfoButtonDesktop}
                                type="button"
                                onClick={() => setIsViewTrackingModalOpen(true)}
                            >
                                View Tracking Info
                                <img className={classes.icon} src="../../../static/icons/v-right_orange.svg" />
                            </button>
                        </div>
                    ) : orderDetails?.estimatedShippingDate ? (
                        <div className={classes.estimatedShippingDate}>
                            Est. dispatch date:{' '}
                            {formatDate(String(parse(orderDetails.estimatedShippingDate, 'dd/MM/yyyy', new Date())))}
                        </div>
                    ) : (
                        <ExpectedDeliveryDate id={id} />
                    )}
                </div>
                <div className={classes.orderHeaderDesktop}>
                    <div className={classes.orderHeaderTitles}>
                        <span>Date placed</span>
                        <span>Order ID</span>
                        <span>Items</span>
                        <span>Type</span>
                        <span>Total</span>
                        {payment.cardNumber && <span>Method</span>}
                    </div>
                    <div className={classes.orderHeaderInfo}>
                        <span>{formatDate(String(parse(orderDetails.placed, 'dd/MM/yy', new Date())))}</span>
                        <span>{orderDetails.orderNumber}</span>
                        <span>{itemsQty}</span>
                        <span className={classes.type}>
                            <img
                                src={`../../../static/icons/${
                                    isOfflineOrder ? 'offline-orders.svg' : 'online-orders.svg'
                                }`}
                                alt="online-orders"
                                data-tip={isOfflineOrder ? 'Offline order' : 'Online order'}
                            />
                            <ReactTooltip type="dark" effect="solid" />
                        </span>
                        <Price
                            price={orderDetails.total}
                            priceIncl={orderDetails.total}
                            className={classes.headerPrice}
                        />

                        {payment.cardNumber && (
                            <span className={classes.payment}>
                                <img
                                    className={classes.iconBrand}
                                    src={getCardBrand(payment.cardBrand || '')}
                                    alt={payment.cardBrand}
                                />
                                <span>{payment.cardNumber}</span>
                            </span>
                        )}
                    </div>
                </div>
                <div className={classes.orderHeaderMobile}>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Date placed</span>
                        <span>{formatDate(String(parse(orderDetails.placed, 'dd/MM/yy', new Date())))}</span>
                    </div>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Items</span>
                        <span>{itemsQty}</span>
                    </div>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Type</span>
                        <span className={classes.type}>
                            <img
                                src={`../../../static/icons/${
                                    isOfflineOrder ? 'offline-orders.svg' : 'online-orders.svg'
                                }`}
                                alt="online-orders"
                                data-tip={isOfflineOrder ? 'Offline order' : 'Online order'}
                            />
                            <ReactTooltip type="dark" effect="solid" />
                        </span>
                    </div>
                    <div>
                        <span className={classes.orderHeaderMobileTitle}>Total</span>
                        <Price
                            price={orderDetails.total}
                            priceIncl={orderDetails.total}
                            className={classes.headerPrice}
                        />
                    </div>
                    {payment.cardNumber && (
                        <span className={classes.payment}>
                            <img
                                className={classes.iconBrand}
                                src={getCardBrand(payment.cardBrand || '')}
                                alt={payment.cardBrand}
                            />
                            <span>{payment.cardNumber}</span>
                        </span>
                    )}
                </div>
                <div className={classes.itemsContainer}>
                    <div>
                        {order.orderDetailItems.map((item, key) => {
                            if (item.sku !== 'default_customisation' && item.sku !== 'setup_charge')
                                return (
                                    <OrderItem
                                        key={key}
                                        item={item}
                                        product={data?.productList?.items.find(
                                            (product) => product.url_key === item.link,
                                        )}
                                        cartId={cartId}
                                        treatment={treatment}
                                        shippingDate={orderDetails.shippingDate}
                                        isOfflineOrder
                                    />
                                );
                        })}
                    </div>
                </div>

                {showButtonContainer && (
                    <div className={classes.buttonsContainer}>
                        {artworkButtonActive && (
                            <Button className={classes.button} type="button" onClick={() => setIsArtworksModal(true)}>
                                Check artwork
                            </Button>
                        )}
                        {treatment === 'on' && isMaskedQuoteId && (
                            <Button className={classes.button} type="button" onClick={handleReorder}>
                                Reorder
                            </Button>
                        )}
                    </div>
                )}

                <OrderStatusWidget
                    orderStatus={orderDetails.status}
                    setOrderShipped={setOrderShipped}
                    setIsArtworkEnabled={setIsArtworkEnabled}
                    hasArtwork={hasArtwork}
                    statusMessage={statusMessage}
                    loading={loading}
                />

                <div className={classes.details}>
                    <div className={classes.billing}>
                        <p>Billing Address</p>
                        {AddressContent(billingAddress)}
                    </div>
                    <div className={classes.delivery}>
                        <p>Delivery Address</p>
                        {AddressContent(shippingAddress)}
                    </div>
                </div>
            </div>
            <ArtworkModal isOpen={isArtworksModal} onDismiss={() => setIsArtworksModal(false)} />
            <ModalOrderTracking
                footprint={Math.round(Math.random() * 1e6)}
                onDismiss={() => setIsViewTrackingModalOpen(false)}
                isModalOpen={isViewTrackingModalOpen}
                orderInfo={{ orderId: orderDetails.orderNumber, postcode: shippingAddress.postcode }}
            />
            <Modal className={classes.errorModal} type="popup" isOpen={Boolean(error)} onDismiss={() => setError(null)}>
                <div className={classes.modalHeader}>
                    <CloseButton onClick={() => setError(null)} background="gray" />
                </div>
                <div className={classes.errorText}>
                    <p>Some items cannot be added:</p>
                    <p>{error}</p>
                </div>
            </Modal>
        </>
    );
};
