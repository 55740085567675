import { Observable, timer, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import Bugsnag from '@bugsnag/js';

export function arrayToObjectByKey<T>(items: T[], key: string): { [index: string]: T } {
    return items.reduce((acc, item) => {
        acc[item[key]] = item;
        return acc;
    }, {});
}

export function cheapClone<T>(input: T): T | undefined {
    try {
        return JSON.parse(JSON.stringify(input));
    } catch (e) {
        Bugsnag.notify(e);
        return undefined;
    }
}

/**
 * Cause a stream to be delayed by a minimum amount of time.
 * This is useful to ensure spinners/loading texts have time to show/animate
 * @param input$
 * @param delay
 */
export function minDelay<T>(input$: Observable<T>, delay = 500): Observable<T> {
    return zip(timer(delay), input$).pipe(map(([, res]) => res));
}

export function appendReferrer(targetPath: string, referrer: string): string {
    return [targetPath, `referrer=${encodeURIComponent(referrer)}`].join('?');
}

export function extractReferrer(url: string): string {
    const [, search] = url.split('?');
    return new URLSearchParams(search || '').get('referrer') || '';
}

export function titleCaseString(str: string): string {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match) {
        if (+match === 0) return '';
        return match.toUpperCase();
    });
}

export function createArray<T>(input: T | T[]): T[] {
    return [].concat(input as any).filter(Boolean);
}
