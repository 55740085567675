import React from 'react';
import { maybePdf } from 'src/util/formatUrl';
import { FromCustomisation } from 'src/components/CustomisationPage/feature/customisation.actions';
import { InlineIcons } from 'src/components/Artwork/InlineIcons';
import { displayTypeNames, getIcons } from 'src/components/Artwork/utils/artwork';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { generateLogoPreview, generateTextPreview, getLastSample } from 'src/components/ArtworkApproval/utils/utils';

import classes from './CustomisationSummary.scss';

type Props = {
    customisation: FromCustomisation;
    locationNames: string[];
    samples?: string[];
};

export const CustomisationSummary: React.FC<Props> = (props) => {
    const { customisation, locationNames, samples } = props;
    const hasSamples = samples && samples.length > 0 ? samples : [];
    const epsPlaceholder = require('../../../static/img/epsPlaceholder.png');

    const {
        application_method: method,
        application_type: type,
        image,
        customisationName,
        isContactRequested,
    } = customisation;

    const isEpsFile = image?.includes('.eps');
    const isAIFile = image?.includes('.ai');

    return (
        <div className={classes.customisationSummary}>
            <figure className={classes.image}>
                {isAIFile && <span className={classes.aiPlaceholder}>AI</span>}
                {isEpsFile && <img src={epsPlaceholder} alt="Eps file has been uploaded" />}
                {customisation.application_type === ApplicationType.Text &&
                    !isEpsFile &&
                    !isAIFile &&
                    generateTextPreview(customisation, getLastSample(hasSamples))}
                {customisation.application_type === ApplicationType.Logo &&
                    !isEpsFile &&
                    !isAIFile &&
                    generateLogoPreview(maybePdf(image), getLastSample(hasSamples))}
            </figure>
            <div className={classes.info}>
                <span className={classes.application}>
                    <InlineIcons icons={getIcons(method, type)} />
                    <b>
                        {displayTypeNames(method, type, isContactRequested, customisation.is_artwork_missing || false)}
                    </b>
                </span>
                <span className={classes.meta}>
                    {customisationName && (
                        <span className={classes.name}>
                            Name: <b>{customisationName}</b>
                        </span>
                    )}
                    <span className={classes.locations}>
                        Locations: <b>{locationNames.join(', ')}</b>
                    </span>
                </span>
            </div>
        </div>
    );
};
