import { Observable } from 'rxjs';
import { ignoreElements, pluck, tap, withLatestFrom } from 'rxjs/operators';
import { CartActions, CartState, TypeMap as CartTypeMap } from '@wearejh/m2-pwa-cart-gql/lib/index';
import { ofType } from 'redux-observable';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';

export function gtmRemoveFromCart(action$: Observable<any>, state$: Observable<any>): Observable<never> {
    const cartItems$: Observable<CartState['items']> = state$.pipe(pluck('cart', 'items'));

    return action$.pipe(
        ofType<CartActions, CartTypeMap['Cart.ItemDelete']>('Cart.ItemDelete'),
        withLatestFrom(cartItems$),
        tap(([{ payload }, items]) => {
            const product = items.find((item) => item.id === payload.itemId.toString());
            fireGtm({
                event: 'eec.remove',
                eecEventName: 'Remove from Cart',
                ecommerce: {
                    remove: {
                        actionField: {
                            list: '',
                        },
                        products:
                            (product && [
                                {
                                    id: product.product.sku,
                                    name: product.product.name,
                                    price: product.prices.price,
                                },
                            ]) ||
                            [],
                    },
                },
            });
        }),
        ignoreElements(),
    );
}
