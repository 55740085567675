import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { CustomerAccountPage } from 'src/components/Customer/CustomerAccountPage';
import { useParams } from 'react-router-dom';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { basketRegister } from 'src/components/Basket/feature/basket.register';
import { BasketContextProvider } from 'src/components/Basket/BasketContext';
import { BasketCspContextProvider } from 'src/components/Basket/BasketCspContext';
import { ItemDetailsLoading } from 'src/components/Orders/ItemDetailsLoading';
import { ItemDetailsFromKhaos } from 'src/components/ItemDetailsFromKhaos/ItemDetailsFromKhaos';
import { ItemDetailsFromMagento } from 'src/components/ItemDetailsFromMagento/ItemDetailsFromMagento';
import { orderStatusFromMagento } from 'src/queries/orderStatusFromMagento.graphql';
import { useTreatments } from '@splitsoftware/splitio-react';

const featureName = 'Use_Invoices_In_My_Account';

const ItemDetailsRoot = () => {
    const { id } = useParams<{ id: string }>();

    const treatments = useTreatments([featureName]);
    const { treatment } = treatments[featureName];

    const { data: dataFromMagento, loading: lodaingFromMagento } = useQuery(orderStatusFromMagento, {
        variables: {
            orderFilters: {
                khaos_id: { eq: id },
            },
        },
        fetchPolicy: 'network-only',
        skip: !id,
    });

    const ready = useLazyRegister(basketRegister, true, 'Basket');
    if (!ready) return null;

    if (lodaingFromMagento) {
        return <ItemDetailsLoading />;
    }

    const isCompleteStatus = dataFromMagento.customer.orders.items[0].khaos_status === 'Complete';

    return (
        <BasketContextProvider>
            <BasketCspContextProvider>
                {Boolean(isCompleteStatus && treatment === 'on') ? (
                    <ItemDetailsFromKhaos id={id} />
                ) : (
                    <ItemDetailsFromMagento
                        soNumber={id}
                        id={dataFromMagento.customer.orders.items[0].increment_id || ''}
                    />
                )}
            </BasketCspContextProvider>
        </BasketContextProvider>
    );
};

export const Order: React.FC = () => {
    return <CustomerAccountPage name="Orders" child={<ItemDetailsRoot />} />;
};
