import React from 'react';
import { formValuesToString } from 'src/components/CustomisationPage/utils/fontPreview';
import { Field } from 'src/components/Forms/Field';
import { Spinner } from 'src/components/Layout/Spinner';
import { Font } from 'src/components/CustomisationPage/utils/fontPreview';
import { SelectedOptions } from 'src/components/BundleProduct/types';
import { FONT_PREVIEW_URL } from 'src/constants/customisation';

import classes from './SelectText.scss';

type TextPreviewProps = {
    selectedOptions: SelectedOptions;
    fonts: Font[];
    noTitle?: boolean;
};

export const TextPreview: React.FC<TextPreviewProps> = ({ selectedOptions, fonts, noTitle = false }) => {
    const query = formValuesToString(
        selectedOptions as {
            [index: string]: string | undefined;
        },
        fonts,
    );

    return (
        <Field>
            {!noTitle && <p>Text Preview</p>}
            <div className={classes.imagePreview}>
                {query.includes('&') ? <img src={`${FONT_PREVIEW_URL}?${query}`} /> : <Spinner />}
            </div>
        </Field>
    );
};
