export const text = (path: string) => {
    const text = require('../config.json').text;
    return text[path];
};

export const toUpperCase = (text) => {
    const upperCase = text.charAt(0).toUpperCase() + text.slice(1);
    return upperCase;
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};
