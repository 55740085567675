import React, { useState } from 'react';
import classnames from 'classnames';
import { bundleRegister } from 'src/components/BundleProduct/feature/bundle.register';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { Modal } from 'src/components/Modal/Modal';
import { CloseButton } from 'src/components/BundleProduct/CloseButton';
import { Price } from 'src/components/Helpers/Price';
import { Item } from 'src/components/ItemDetailsFromKhaos/ItemDetailsFromKhaos';

import classes from './ItemDetails.scss';

type Props = {
    item: Item;
};

export const OrderItem: React.FC<Props> = ({ item }) => {
    const [error, setError] = useState<string | null>(null);
    useLazyRegister(bundleRegister, true, 'BundleSelectionProvider');

    const productImage = item.productThumbnail !== '' ? item.productThumbnail : '../../../static/img/placeholder.jpg';

    return (
        <div className={classes.itemContainer}>
            <div className={classes.item}>
                <div className={classes.itemDataContainer}>
                    <div className={classes.itemInfoContainer}>
                        <div className={classes.itemImage}>
                            <img src={productImage} alt={productImage} />
                            <span className={classes.imageQty}>{item.qtyOrdered}</span>
                        </div>
                        <div className={classes.itemInfo}>
                            <p className={classes.itemName}>{item.name}</p>
                            <p className={classes.itemDetails}>
                                <span>{item.sku}</span>
                            </p>
                        </div>
                    </div>
                    {item.price && (
                        <>
                            <p className={classes.itemPriceContainer}>
                                <Price price={item.price} priceIncl={item.price} className={classes.singleItemPrice} />
                                <span>each (exc. VAT)</span>
                            </p>
                            {item.qtyOrdered && (
                                <p className={classnames(classes.itemPriceContainer, classes.totalItemPriceContainer)}>
                                    <Price
                                        price={item.price * item.qtyOrdered}
                                        priceIncl={item.price * item.qtyOrdered}
                                        className={classes.totalItemPrice}
                                    />
                                    <span className={classes.totalItemPriceText}>Total</span>
                                </p>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Modal className={classes.errorModal} type="popup" isOpen={Boolean(error)} onDismiss={() => setError(null)}>
                <div className={classes.modalHeader}>
                    <CloseButton onClick={() => setError(null)} background="gray" />
                </div>
                <div className={classes.errorText}>{error}</div>
            </Modal>
        </div>
    );
};
