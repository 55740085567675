import {
    ApplicationMethod,
    ApplicationType,
    CustomisationFull,
} from 'src/components/CustomisationPage/feature/customisation.types';

import { ArtworkItem } from '../artwork.types';
import { SvgIconNames } from '../../Svg/icons-types';

const icons: { [index: string]: SvgIconNames } = {
    [ApplicationMethod.Embroidery]: 'embroidery',
    [ApplicationMethod.Print]: 'print',
    [ApplicationType.Logo]: 'logo',
    [ApplicationType.Text]: 'text',
};

export function displayTypeNames(
    method: ApplicationMethod,
    type: ApplicationType,
    isContact: boolean,
    artworkExistInDB: boolean,
): string {
    if (artworkExistInDB) {
        return `Logo: We'll Find It & Confirm With You`;
    }

    if (isContact) {
        return `Logo: We'll contact you`;
    }
    const method_text = method === ApplicationMethod.Embroidery ? 'Embroidered' : 'Printed';
    const type_text = type === ApplicationType.Logo ? 'Logo' : 'Text';
    return `${method_text} ${type_text}`;
}

export function getIcons(method: ApplicationMethod, type: ApplicationType): SvgIconNames[] {
    const metod_icon = icons[method];
    const typeIcon = icons[type];
    return [metod_icon, typeIcon] as SvgIconNames[];
}

export function customisationToArtwork(input: CustomisationFull): ArtworkItem {
    return {
        id: String(input.id || input.artwork_id),
        value: String(input.id || input.artwork_id),
        title: input.customisation_name,
        application_method: input.type,
        application_type: input.image ? ApplicationType.Logo : ApplicationType.Text,
        image: input.image, // the image path will be absolute
        textLineOne: input.text_line_one,
        textLineTwo: input.text_line_two,
        textLineThree: input.text_line_three,
        color: input.color,
        font: input.font,
        is_artwork_missing: input.is_artwork_missing,
    };
}
