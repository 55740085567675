import React, { useContext, useState } from 'react';
import { DefaultMeta } from 'src/components/AppShell/Head';
import { ArtworkApprovalContext } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import { ArtworkApprovalSkeleton } from 'src/components/ArtworkApproval/ArtworkApprovalSkeleton';
import { PageTitle } from 'src/components/Layout/PageTitle';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import classes from 'src/components/ArtworkApproval/ArtworkApproval.scss';
import { Messages } from 'src/components/Messages/Messages';
import { ArtworkApprovalSummary } from 'src/components/ArtworkApproval/ArtworkApprovalSummary';
import { ArtworkApprovalProof } from 'src/components/ArtworkApproval/ArtworkApprovalProof';
import { ArtworkApprovalActions } from 'src/components/ArtworkApproval/ArtworkApprovalActions';
import { CircleLoader } from 'src/components/CircleLoader/CircleLoader';

import meta from './artworkApproval.json';

export const ArtworkApprovalConnected: React.FC = React.memo(() => {
    const { isPending, state, artwork, onSubmit, onGuestSubmit, isGuest } = useContext(ArtworkApprovalContext);
    const [isPendingAction, setIsPendingAction] = useState(false);

    if (isPending) return <ArtworkApprovalSkeleton />;

    return (
        <>
            <DefaultMeta data={meta.helmet} />

            <PageTitle title="Artwork Details" />
            {state.status === DataStatus.Error && state.messages.length > 0 && (
                <div className={classes.actionsFormMsg}>
                    <Messages messages={state.messages} />
                </div>
            )}

            <div className={classes.root}>
                <ArtworkApprovalSummary artwork={artwork} />
                {isPendingAction ? (
                    <div className={classes.proof}>
                        <div className={classes.loader}>
                            <CircleLoader />
                        </div>
                    </div>
                ) : (
                    <ArtworkApprovalProof artwork={artwork} />
                )}

                <ArtworkApprovalActions
                    onSubmit={onSubmit}
                    state={state}
                    onGuestSubmit={onGuestSubmit}
                    isGuest={isGuest}
                    artwork={artwork}
                    setIsPendingAction={setIsPendingAction}
                    isPendingAction={isPendingAction}
                />
            </div>
        </>
    );
});
