import { ActionMap, createMsg } from 'action-typed';
import { SalesDataOrderInterface } from 'swagger/ts/Definitions';
import { DataStatus, Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';
import { ArtworkStatus } from 'src/components/Orders/utils/order.utils';

import { getAccountOrdersEpic } from './epics/getAccountOrders.epic';
import { getAccountOrderEpic } from './epics/getAccountOrder.epic';

export type Messages = {
    ['Orders.SetStatus']: DataStatus;
    ['Orders.FetchOrders']: undefined;
    ['Orders.FetchOrdersSuccess']: SalesDataOrderInterface[];
    ['Orders.FetchOrdersError']: string;

    ['Orders.FetchOrder']: { orderId: number };
    ['Orders.FetchOrderSuccess']: SalesDataOrderInterface;
    ['Orders.FetchOrderError']: string;
};

export type OrdersState = {
    status: DataStatus;
    orders: SalesDataOrderInterface[];
    order: SalesDataOrderInterface | null;
    messages: Message[];
};

export type Order = {
    createdAt: string;
    grandTotal: number;
    number: number;
    khaosId: string;
    status: string;
    totalQtyOrdered: number;
    items?: {
        productImage: string;
    }[];
    shipped: boolean | null;
};

export type ListOrder = {
    orderId: string;
    entityId: string;
    date: string;
    shipTo: string;
    orderTotal: number;
    status: string;
    artworkStatus: ArtworkStatus;
    itemsCount?: number;
};

const initialState = {
    status: DataStatus.Idle,
    messages: [],
    orders: [],
    order: null,
};

export function ordersRegister() {
    return {
        epics: [getAccountOrdersEpic, getAccountOrderEpic],
        reducers: {
            orders: function (state: OrdersState = initialState, action: Actions): OrdersState {
                switch (action.type) {
                    case 'Orders.SetStatus': {
                        return {
                            ...state,
                            status: action.payload,
                        };
                    }
                    case 'Orders.FetchOrder':
                    case 'Orders.FetchOrders': {
                        return {
                            ...state,
                            messages: [],
                            status: DataStatus.Pending,
                        };
                    }
                    case 'Orders.FetchOrdersSuccess': {
                        return {
                            ...state,
                            orders: action.payload,
                            status: DataStatus.Success,
                        };
                    }
                    case 'Orders.FetchOrderError':
                    case 'Orders.FetchOrdersError': {
                        return {
                            ...state,
                            messages: [{ type: MessageType.Error, text: action.payload }],
                            status: DataStatus.Error,
                        };
                    }
                    case 'Orders.FetchOrderSuccess': {
                        return {
                            ...state,
                            order: action.payload,
                            status: DataStatus.Success,
                        };
                    }
                    default:
                        return state;
                }
            },
        },
        name: 'orders',
    };
}

export const Msg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
export type AppendState<T> = T & { orders: OrdersState };
