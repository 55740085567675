import { OrderDetailItem } from 'src/components/Orders/OrderDetailItem';

export const getProductPayload = (item: OrderDetailItem, sku: string, taxFree: boolean) => ({
    items: [
        {
            data: {
                quantity: item.qty_ordered || 0,
                sku: item.sku,
                tax_free: taxFree,
            },
            parent_sku: sku,
            variant_sku: item.sku,
        },
    ],
});
