import { CartVariant } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { execute } from 'swagger/ts/QuoteCartManagementV1GetCartForCustomerGet';
import { execute as guestBasket } from 'swagger/ts/QuoteGuestCartRepositoryV1GetGet';
import { map, mergeMap } from 'rxjs/operators';
import {
    BasketCustomisationsMapping,
    BasketCustomisationItem,
    BasketCustomisationsOnRefresh,
} from 'src/components/Basket/feature/basket.reducer';
import { ApplicationMethod, AvailableLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { Deps } from 'src/types/global-types';
import { EMPTY, Observable, of } from 'rxjs';
import { WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface } from 'swagger/cypress/Definitions';
import Bugsnag from '@bugsnag/js';

export type CustomisationsProps = {
    id: number;
    location_name: string;
    customisation: WorkwearExpressCustomisationPlatformFrontendDataFullCustomisationInterface;
    customisationPrice: number;
};

/**
 * This is a stand-along way of fetching data about customisations
 * @param variant
 * @param cartId
 * @param deps
 */
export function refreshBasketCustomisations(
    variant: CartVariant,
    cartId: number | string,
    deps: Deps,
): Observable<BasketCustomisationsOnRefresh> {
    const ajax$ = variant === CartVariant.Account ? execute(deps) : guestBasket({ cartId: String(cartId) }, deps);
    return ajax$.pipe(
        map((resp: any) => {
            const customisations: BasketCustomisationsMapping = {};
            const products = resp.items?.filter(
                (item) => item.product_type === 'configurable' || item.product_type === 'bundle',
            );

            const setupCharge = resp.items?.find((item) => item.name === 'Setup charge')?.price;

            products?.map((item) => {
                const itemId = String(item.item_id!);
                const itemCustomisations = item.extension_attributes?.customisations || [];
                const itemAvailableLocations = item.extension_attributes?.available_locations || [];

                const customisationsArray: CustomisationsProps[] = [];

                if (itemCustomisations.length) {
                    itemCustomisations.map((el) => {
                        const obj = {
                            id: el.id,
                            location_name: el.location_name,
                            customisation: el.customisation,
                            customisationPrice: item.price,
                        };
                        return customisationsArray.push(obj);
                    });
                }
                customisations[itemId] = {
                    customisations: customisationsArray,
                    availableLocations: [],
                    setupCharge: setupCharge,
                };

                if (itemAvailableLocations.length) {
                    const availableLocations: AvailableLocation[] = itemAvailableLocations.map((loc) => {
                        return {
                            method: loc.type as ApplicationMethod,
                            name: loc.location_name,
                            image_url: loc.image_url,
                        };
                    });
                    if (!customisations[itemId]) {
                        customisations[itemId] = { customisations: [], availableLocations: [], setupCharge: 0 };
                    }
                    customisations[itemId].availableLocations = availableLocations;
                } else {
                    customisations[itemId] = { customisations: [], availableLocations: [], setupCharge: 0 };
                }
            });
            const updatedAt = resp.updated_at;
            const parsedCardId = String(resp.id);
            return { updatedAt, customisations, parsedCardId };
        }),
    );
}

interface LocationsForBasketItemsParams {
    itemIds: (number | string)[];
    variant: CartVariant;
    cartId: number | string;
    deps: Deps;
}

/**
 * Given some basket ids, load the items and then
 *
 * @param itemIds
 * @param variant
 * @param cartId
 * @param deps
 */
export function locationsForBasketItems(params: LocationsForBasketItemsParams): Observable<BasketCustomisationItem> {
    const { itemIds, variant, cartId, deps } = params;
    return refreshBasketCustomisations(variant, cartId, deps).pipe(
        mergeMap((resp) => {
            const keys = Object.keys(resp.customisations || {});
            if (!resp || keys.length === 0) {
                Bugsnag.notify('no basket items were found', (event) => {
                    event.addMetadata('basketData', {
                        resp: resp,
                        itemIds: itemIds,
                    });
                });
                return EMPTY;
            }

            const firstMatchId = itemIds.find((itemId) => keys.some((key) => String(itemId) === String(key)));

            if (!firstMatchId) {
                Bugsnag.notify('no matching basket items were found', (event) => {
                    event.addMetadata('basketData', {
                        resp: resp,
                        itemIds: itemIds,
                    });
                });
                return EMPTY;
            }
            const match = resp.customisations[firstMatchId];
            return of(match);
        }),
    );
}
