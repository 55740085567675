import React from 'react';
import { Customisation } from 'src/components/BundleProduct/types';
import { SvgIconNames } from 'src/components/Svg/icons-types';
import { Svg } from 'src/components/Svg/Svg';

import classes from './SelectType.scss';

type ApplicationTypeProps = {
    id: 'logo' | 'text';
    title?: string;
    icon: SvgIconNames;
    selectedType?: 'logo' | 'text';
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
};

export const ApplicationType: React.FC<ApplicationTypeProps> = ({
    id,
    title,
    icon,
    selectedType,
    handleCustomisationSet,
}) => {
    return (
        <div className={classes.container}>
            <input
                className={classes.input}
                type="radio"
                checked={selectedType === id}
                name="type"
                id={id}
                onChange={(e) => {
                    e.preventDefault();
                    if (id === 'logo') {
                        handleCustomisationSet({
                            selectedType: id,
                            selectedOptions: { isContactRequested: 'upload' },
                        });
                    } else {
                        handleCustomisationSet({
                            selectedType: id,
                            selectedOptions: {
                                textLineOne: '',
                                color: 'White',
                                font: '',
                            },
                        });
                    }
                }}
            />

            <label className={classes.label} htmlFor={id} data-test-id="CustomRadio_label">
                <img
                    className={classes.checkIcon}
                    src={require('../../../../svg/check-circle.svg')}
                    alt="check circle"
                />
                <div className={classes.emptyCircle}></div>
                <div className={classes.information}>
                    <div className={classes.informationWrapper}>
                        <span className={classes.content}>
                            <strong className={classes.title}>{title}</strong>
                        </span>
                    </div>
                    <Svg className={classes.icon} name={icon} />
                </div>
            </label>
        </div>
    );
};
