import React from 'react';
import { SwatchCss } from 'src/components/ProductDetail/utils/swatchData';
import classnames from 'classnames';

import { CloseButton } from '../BundleProduct/CloseButton';

import classes from './ModalHeader.scss';

type Props = {
    title?: string;
    titleBig?: boolean;
    closeModal: () => void;
    color?: string;
    imageUrl?: string;
    swatchCss?: SwatchCss;
    titleMobile?: string;
    counter?: string;
    predictedQty?: number;
    maxQty?: number;
    iconSrc?: string;
};

export const ModalHeader: React.FC<Props> = ({
    title,
    titleBig,
    closeModal,
    color,
    imageUrl,
    swatchCss,
    titleMobile,
    counter,
    predictedQty,
    maxQty,
    iconSrc,
}) => (
    <div className={classes.modalHeader}>
        <div
            className={classnames(classes.titleCircle, {
                [classes.titleCircleWithIcon]: iconSrc,
            })}
        >
            {iconSrc && (
                <div className={classes.iconContainer}>
                    <img src={iconSrc} />
                </div>
            )}
            {(imageUrl || color) && (
                <div className={classes.contentCircle} style={{ background: color }} data-testid="colorSwatch">
                    {imageUrl && <img src={imageUrl} alt={imageUrl} />}
                </div>
            )}
            {swatchCss && <span className={classes.swatchesItem} style={swatchCss} data-testid="swatchCss"></span>}

            {titleMobile ? (
                <>
                    <p
                        className={classnames(classes.titleDesktop, {
                            [classes.titleBig]: titleBig,
                        })}
                    >
                        {title}
                    </p>
                    <p className={classes.titleMobile}>{titleMobile}</p>
                </>
            ) : (
                <p
                    className={classnames(classes.title, {
                        [classes.titleBig]: titleBig,
                    })}
                >
                    {title}
                </p>
            )}
        </div>
        <div className={classes.actions}>
            {counter && (
                <div
                    className={classnames({
                        [classes.correct]: predictedQty === maxQty,
                        [classes.incorrect]: predictedQty && maxQty && predictedQty > maxQty,
                    })}
                >
                    <span className={classes.quantity}>Quantity:</span>
                    <span className={classes.counter}>{counter}</span>
                </div>
            )}
            <CloseButton onClick={closeModal} background="gray" className={classes.button} />
        </div>
    </div>
);
