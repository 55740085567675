import React from 'react';
import classes from 'src/components/ArtworkApproval/ArtworkApproval.scss';
import { CustomisationSummary } from 'src/components/CustomisationPage/CustomisationSummary';
import { Status } from 'src/components/Orders/Status';
import { Artwork } from 'src/components/ArtworkApproval/ArtworkApprovalContext';

type Props = {
    artwork: Artwork;
    status?: string;
};

export const ArtworkSummary: React.FC<Props> = ({ artwork, status }) => (
    <div className={classes.summaryContent}>
        <CustomisationSummary
            locationNames={artwork.location_name}
            customisation={artwork.artwork}
            samples={artwork.samples}
        />
        {(status || artwork.status) && (
            <div className={classes.summaryStatus}>
                <Status status={status || artwork.status} />
            </div>
        )}
    </div>
);
