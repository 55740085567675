import React, { FC } from 'react';
import classnames from 'classnames';

import { BundleButton } from '../BundleProduct/BundleButton';

import classes from './QtyPicker.scss';

type Props = {
    id: number;
    quantity: number;
    handleChange: (quantity: number, id: number) => void;
    colorId?: number;
    onDecreaseQuantity: (quantity: number, id: number) => void;
    onIncreaseQuantity: (quantity: number, id: number, maxQty: number | null) => void;
    disabled?: boolean;
    maxQty?: number | null;
};

export const QtyPicker: FC<Props> = ({
    id,
    quantity,
    handleChange,
    colorId,
    onIncreaseQuantity,
    onDecreaseQuantity,
    maxQty = 0,
    disabled = false,
}) => (
    <div
        className={classnames(classes.qty, {
            [classes.disabled]: disabled,
        })}
    >
        <BundleButton
            type="button"
            background="white"
            onClick={() => onDecreaseQuantity(quantity, id)}
            size="mini"
            disabled={disabled}
        >
            -
        </BundleButton>
        <input
            id={id.toString()}
            className={classnames(classes.input, {
                [classes.warning]: maxQty && quantity > maxQty && maxQty > 0,
            })}
            pattern="\d*"
            type="number"
            onChange={(event) => handleChange(parseInt(event.target.value) || 0, id)}
            value={quantity}
            name={`index:${colorId}.size:${id}`}
            data-test-id="QtySelect_input"
            disabled={disabled}
            max={maxQty || undefined}
        />
        <BundleButton
            type="button"
            background="white"
            onClick={() => onIncreaseQuantity(quantity, id, maxQty)}
            size="mini"
            disabled={disabled}
        >
            +
        </BundleButton>
    </div>
);
