import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { BasketCustomisationsMapping } from 'src/components/Basket/feature/basket.reducer';
import {
    BasketCustomisations,
    BasketItemRemoveCustomisation,
    createStateFromCustomisation,
} from 'src/components/CustomisationPage/feature/customisation.types';
import { artWorkEntryUrl } from 'src/components/CustomisationPage/utils/customisationsUrls';
import { EntryKind } from 'src/components/CustomisationPage/feature/customisation.actions';
import { useDeps } from 'src/hooks/useDeps';

import { Step } from '../feature/basket.types';
import { AppendBasketState, BasketMsg } from '../feature/basket.actions';

const selector = (s: AppendBasketState) => s.basket;

export interface BasketApi {
    loadStep(step: Step);
    status: DataStatus;
    messages: Message[];
    customisations: BasketCustomisationsMapping;
    itemsPending: string[];
    isPending: boolean;
}

export function useCustomisations(): BasketApi {
    const dispatch = useDispatch();
    const { status, messages, customisations, itemsPending } = useSelector(selector, shallowEqual);

    const loadStep = useCallback(
        (step: Step) => {
            dispatch(BasketMsg('Basket.LoadStep', step));
        },
        [dispatch],
    );

    return {
        loadStep,
        status,
        messages,
        customisations,
        itemsPending: itemsPending.map(String),
        isPending: status === DataStatus.Pending || status === DataStatus.Idle,
    };
}

export function useRemoveCustomisation(): (params: BasketItemRemoveCustomisation[]) => void {
    const dispatch = useDispatch();
    return useCallback(
        (params: BasketItemRemoveCustomisation[]) => {
            dispatch(BasketMsg('Basket.RemoveCustomisations', params));
        },
        [dispatch],
    );
}

export function useEditCustomisation(): (params: BasketCustomisations) => void {
    const dispatch = useDispatch();
    const { push } = useDeps();
    return useCallback(
        (params: BasketCustomisations) => {
            const asState = createStateFromCustomisation(params.customisation);
            const nextUrl = artWorkEntryUrl({
                kind: EntryKind.Edit,
                itemIds: params.item_ids,
                locationNames: params.location_names,
                customisation: { ...asState, id: params.customisation.id },
            });
            dispatch(push(nextUrl));
        },
        [dispatch, push],
    );
}

export function useCopyCustomisation(): (params: BasketCustomisations) => void {
    const dispatch = useDispatch();
    return useCallback(
        (params: BasketCustomisations) => {
            dispatch(BasketMsg('Basket.CopyCustomisations', params));
        },
        [dispatch],
    );
}
