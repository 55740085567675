import { asyncScheduler, concat, Observable, of, scheduled } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { Deps } from 'src/types/global-types';
import { getNewAjax } from 'src/components/CustomisationPage/feature/epics/customisationSubmit.epic';
import { catchable } from '@wearejh/swagger-rxjs/utils/ajax-helpers';

import { Actions, BasketMsg, TypeMap } from '../basket.actions';

export function copyCustomisationEpic(action$: Observable<any>, state$: Observable<any>, deps: Deps): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Basket.CopyCustomisations']>('Basket.CopyCustomisations'),
        withLatestFrom(cartVariantAndId(state$)),
        switchMap(([{ payload }, [variant, cartId]]) => {
            const outgoing = {
                itemIds: payload.item_ids,
                locationNames: payload.location_names,
                customisation: {
                    customisationName: payload.customisation.customisation_name,
                    image: payload.customisation.image || '',
                    type: payload.customisation.type,
                    isContactRequested: payload.customisation.is_contact_requested,
                    specialInstructions: payload.customisation.special_instructions || '',
                    textLineOne: payload.customisation.text_line_one || '',
                    textLineTwo: payload.customisation.text_line_two || '',
                    textLineThree: payload.customisation.text_line_three || '',
                    color: payload.customisation.color || '',
                    font: payload.customisation.font || '',
                    id: payload.customisation.id,
                },
            };
            const ajax$ = getNewAjax(variant, cartId!, [outgoing], deps);

            return ajax$.pipe(
                mergeMap(() => {
                    return concat(
                        of(BasketMsg('Basket.CopyCustomisationsSuccess')),
                        scheduled(of(deps.replace(deps.paths.basket.basket)), asyncScheduler),
                    );
                }),
                catchable(({ error }) => {
                    return of(BasketMsg('Basket.CopyCustomisationsError', error));
                }),
            );
        }),
    );
}
