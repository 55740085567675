import React from 'react';
import classnames from 'classnames';

import { BundleButton } from './BundleButton';
import classes from './CloseButton.scss';

type Props = React.ComponentProps<'button'> & {
    background: 'orange' | 'green' | 'white' | 'gray' | 'transparent';
    icon?: string;
    iconOnRight?: boolean;
    className?: string;
};

export const CloseButton: React.FC<Props> = ({
    background,
    icon,
    iconOnRight,
    children,
    className,
    ...buttonProps
}) => (
    <BundleButton
        background={background}
        icon={icon}
        iconOnRight={iconOnRight}
        size="mini"
        {...buttonProps}
        classProps={classnames(classes.closeButton, className)}
    >
        <div className={classes.iconContainer}>{children}</div>
    </BundleButton>
);
