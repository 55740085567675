import React, { HTMLAttributes } from 'react';

import classes from './TwoColSplit.scss';

type TwoColSplitProps = {
    first?: React.ReactNode;
    main: React.ReactNode;
    last: React.ReactNode;
} & HTMLAttributes<any>;

export const TwoColSplit: React.FC<TwoColSplitProps> = React.memo((props) => {
    const { first, main, last, title, ...rest } = props;
    return (
        <section className={classes.root} {...rest}>
            <div className={classes.main}>
                <div className={classes.mainFirst}>{props.first}</div>
                {props.main}
            </div>
            <div className={classes.last}>
                <div className={classes.lastFirst}>{props.first}</div> {props.last}
            </div>
        </section>
    );
});
