import React from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'src/components/Layout';
import { PageTitle } from 'src/components/Layout/PageTitle';

import { SignInConnected } from '../SignIn/SignInModal';
import { useDeps } from '../../hooks/useDeps';
import { useRouterMessages } from '../../hooks/useRouterMessages';

export const SignInPage: React.FC = React.memo(() => {
    const routerMessages = useRouterMessages();
    const { paths, push } = useDeps();
    const dispatch = useDispatch();
    const forgot = () => {
        dispatch(push(paths.customer.forgot));
    };
    const register = () => {
        dispatch(push(paths.customer.register));
    };
    return (
        <Container variant="modal-narrow">
            <PageTitle title="Customer Login" />
            <div className="card">
                <SignInConnected
                    showRegister={true}
                    forgot={forgot}
                    register={register}
                    routerMessages={routerMessages}
                    magicLink={() => dispatch({ kind: 'magicLink' })}
                />
            </div>
        </Container>
    );
});
