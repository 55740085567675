import React from 'react';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { Field } from 'src/components/Forms/Field';
import classnames from 'classnames';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';
import { Customisation, SelectedOptions } from 'src/components/BundleProduct/types';

import { SelectText } from './SelectText';
import { AdditionalNotes } from './AdditionalNotes';
import { UploadLogo } from './UploadLogo';
import classes from './ConfigureCustomisation.scss';

type Props = {
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    isActive: boolean;
    description: string;
};

const DefaultButton: React.FC<Props> = ({ backgroundStyles, handleCustomisationSet, isActive, description }) => (
    <button
        className={classes.contactRadio}
        style={backgroundStyles}
        onClick={() => {
            handleCustomisationSet({
                selectedOptions: {
                    isContactRequested: 'contact',
                },
            });
        }}
        type="button"
    >
        {isActive ? (
            <img src={require('../../../../svg/check-circle.svg')} alt="check circle" />
        ) : (
            <div className={classes.emptyCircle}></div>
        )}
        <p>{description}</p>
    </button>
);

type ConfigureCustomisationProps = {
    applicationType: ApplicationType;
    backgroundStyles?: BackgroundStyles;
    handleCustomisationSet: (customisation: Partial<Customisation>) => void;
    selectedOptions: SelectedOptions;
};
export const ConfigureCustomisation: React.FC<ConfigureCustomisationProps> = ({
    applicationType,
    backgroundStyles,
    handleCustomisationSet,
    selectedOptions,
}) => {
    if (applicationType === ApplicationType.Logo && 'isContactRequested' in selectedOptions) {
        const showUploader = selectedOptions?.isContactRequested === 'upload';
        const showContactLater = selectedOptions?.isContactRequested === 'contact';
        const showHaveMyLogo = selectedOptions?.isContactRequested === 'haveMyLogo';
        return (
            <>
                <Field class={classes.noOverflow}>
                    <button
                        className={classnames(classes.wrapper, {
                            [classes.isActive]: showUploader,
                        })}
                        style={backgroundStyles}
                        type="button"
                        onClick={() => {
                            handleCustomisationSet({
                                selectedOptions: {
                                    isContactRequested: 'upload',
                                },
                            });
                        }}
                    >
                        <div className={classes.upload}>
                            {showUploader ? (
                                <img src={require('../../../../svg/check-circle.svg')} alt="check circle" />
                            ) : (
                                <div className={classes.emptyCircle}></div>
                            )}
                            <p>Upload your own logo</p>
                        </div>
                        {showUploader && (
                            <UploadLogo url={selectedOptions?.image} handleCustomisationSet={handleCustomisationSet} />
                        )}
                    </button>
                </Field>
                <Field class={classes.noOverflow}>
                    <div
                        className={classnames(classes.contactWrapper, {
                            [classes.isActive]: showContactLater,
                        })}
                    >
                        <p className={classes.alternatively}>alternatively...</p>
                        <DefaultButton
                            backgroundStyles={backgroundStyles}
                            handleCustomisationSet={handleCustomisationSet}
                            isActive={showContactLater}
                            description="Don't have your logo to hand? Don't worry, select here and we will contact after you
                                place your order."
                        />
                        <DefaultButton
                            backgroundStyles={backgroundStyles}
                            handleCustomisationSet={handleCustomisationSet}
                            isActive={showHaveMyLogo}
                            description="You already have my logo, it’s just not in my account (no setup fee will be charged)"
                        />
                    </div>
                </Field>
                <AdditionalNotes
                    backgroundStyles={backgroundStyles}
                    handleCustomisationSet={handleCustomisationSet}
                    selectedOptions={selectedOptions}
                />
            </>
        );
    }

    return (
        <SelectText
            backgroundStyles={backgroundStyles}
            handleCustomisationSet={handleCustomisationSet}
            selectedOptions={selectedOptions}
        />
    );
};
