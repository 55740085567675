import React from 'react';
import { DispatchProp, shallowEqual, useSelector } from 'react-redux';
import { useNextTick } from 'src/hooks/useNextTick';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/useSetResolved';
import { StoreState } from 'src/types/global-types';
import { escapeHtml } from 'src/util/escapeHtml';
import { Wrapper, Container } from 'src/components/Layout';
import { DefaultMeta } from 'src/components/AppShell/Head';

import '../../scss/_root-component.scss';

type CatalogsearchRootProps = Record<string, unknown> & DispatchProp;

const SearchResultsPageLoader = React.lazy(() => {
    return import(
        /* webpackChunkName:"__lazy__search-results-page-loader" */ '../../components/Algolia/SearchPage/SearchResultsPageLoader'
    );
});

export const CatalogSearchRoot: React.FC<CatalogsearchRootProps> = () => {
    const ready = useNextTick();
    useSetResolved();
    return (
        <>
            <Title />
            <Wrapper variant="page-inner">
                <Container>
                    <noscript>
                        <p>This search page requires Javascript to work.</p>
                    </noscript>
                    {ready && (
                        <React.Suspense fallback={<p>Please wait...</p>}>
                            <SearchResultsPageLoader />
                        </React.Suspense>
                    )}
                </Container>
            </Wrapper>
        </>
    );
};

export default CatalogSearchRoot;

/**
 * The following components (title, page title + breadcrumbs)
 * are extracted here to prevent them causing a re-render when the url changes.
 * @constructor
 */
function Title() {
    const title = useTitleFromSearch();
    return (
        <DefaultMeta
            data={{
                description: title,
                title,
            }}
        />
    );
}

// function SearchBreadcrumb() {
//     const title = useTitleFromSearch();
//     return <Breadcrumbs breadcrumbs={[]} current={title} />;
// }

const searchSelector = (s: StoreState) => s.router.location.search;

export function useSearch() {
    return useSelector(searchSelector, shallowEqual);
}

/**
 * Support both q= & query= for search queries
 */
export function useSearchQuery() {
    const search = useSearch();
    const query = new URLSearchParams(search);
    return query.get('query') || query.get('q');
}

export function useTitleFromSearch(): string {
    const s = useSelector(searchSelector, shallowEqual);
    return titleFromSearch(s);
}

export function titleFromSearch(search: string): string {
    const query = new URLSearchParams(search);
    const queryString = query.get('query') || query.get('q');
    if (queryString) {
        return `Search results for: '${escapeHtml(queryString)}'`;
    }
    return 'Search results';
}
