import React, { useState } from 'react';
import { Button } from 'src/components/Button/Button';
import { Modal } from 'src/components/Modal/Modal';
import { ModalHeader } from 'src/components/Modal/ModalHeader';
import { ModalBody } from 'src/components/Modal/ModalBody';
import { ModalFooter } from 'src/components/Modal/ModalFooter';
import { Svg } from 'src/components/Svg/Svg';

import classes from './ArtworkApproval.scss';

export interface Comment {
    id?: number;
    artwork_approval_id: number;
    from_customer: boolean;
    status: string;
    comment: string;
    created_at: string;
}

type Props = {
    comments: Comment[];
};

export const ArtworkApprovalCommentsWithPopup: React.FC<Props> = (props) => {
    const { comments } = props;
    const [showDialog, setShowDialog] = useState(false);
    const open = () => setShowDialog(true);
    const close = () => setShowDialog(false);

    return (
        <div className={classes.actionsNotes}>
            <Button width="full" variant="light" icon="left-of-text" onClick={open}>
                <Svg name="edit" /> View Notes
            </Button>
            <Modal type="popup" isOpen={showDialog} onDismiss={close}>
                <ModalHeader title="Notes" icon="edit" type="success" onDismiss={close} />
                <ModalBody>
                    {comments.length === 0 && <p>There are no comments</p>}
                    {comments.reverse().map((comment) => {
                        const fromCustomer = comment.from_customer ? 'Me:' : 'WE:';
                        return (
                            <p key={comment.id} className={classes.actionsNote}>
                                <b>{fromCustomer}</b> {comment.comment}
                            </p>
                        );
                    })}
                </ModalBody>
                <ModalFooter onDismiss={close} />
            </Modal>
        </div>
    );
};

export const ArtworkApprovalCommentsWithoutPopup: React.FC<Props> = ({ comments }) => {
    return (
        <div className={classes.commentsWrapper}>
            {comments.length === 0 && <p>There are no comments</p>}
            {comments.reverse().map((comment) => {
                const fromCustomer = comment.from_customer ? 'Me:' : 'WE:';
                return (
                    <p key={comment.id} className={classes.actionsNote}>
                        <b>{fromCustomer}</b> {comment.comment}
                    </p>
                );
            })}
        </div>
    );
};
