import { ofType } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { Deps } from 'src/types/global-types';
import { mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { CartVariant, AppendState as AppendCartState } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { execute } from 'swagger/ts/WorkwearExpressCustomisationPricingEstimateCustomisationPriceV1EstimateGuestPricesPost';
import { execute as executeMine } from 'swagger/ts/WorkwearExpressCustomisationPricingEstimateCustomisationPriceV1EstimatePricesPost';
import { estimateToSummary } from 'src/components/CustomisationPage/utils/priceEstimates';
import { catchableUnauthenticated } from '@wearejh/m2-pwa-user/lib/utils/user.utils';
import { AppendState as AppendUserState } from '@wearejh/m2-pwa-user/lib/user.reducer';

import { Actions, AppendState, CustomisationMsg, TypeMap } from '../customisation.actions';

/**
 * Listen for location selections and use them to estimate the
 * price summaries
 *
 * @param action$
 * @param state$
 * @param deps
 */

type State = Observable<AppendState<AppendCartState<AppendUserState>>>;

export function priceEstimatesEpic(action$: Observable<any>, state$: State, deps: Deps) {
    const customisation$ = state$.pipe(pluck('customisation'));
    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.EstimatePrices']>('Customisation.EstimatePrices'),
        withLatestFrom(customisation$, cartVariantAndId(state$)),
        switchMap(([{ payload }, customisation, [variant, cartId]]) => {
            if (payload?.locations?.length === 0) {
                return of(CustomisationMsg('Customisation.EstimatePricesSuccess', { summary: null }));
            }
            const is_image = customisation.application_type === 'Logo';

            const output = {
                estimateRequest: {
                    quote_item_ids: customisation.item_ids,
                    type: customisation.application_method,
                    location_names: payload?.locations,
                    is_image: is_image,
                    artwork_id: customisation.artwork_id ? Number(customisation.artwork_id) : undefined,
                    is_logo_missing: payload?.isLogoMissing,
                },
            };
            const ajax$ =
                variant === CartVariant.Guest ? execute({ cartId: cartId! }, output, deps) : executeMine(output, deps);
            return ajax$.pipe(
                mergeMap((resp) => {
                    const summary = estimateToSummary(resp, Boolean(customisation.artwork_id));
                    return of(CustomisationMsg('Customisation.EstimatePricesSuccess', { summary }));
                }),
                catchableUnauthenticated(({ error }) => {
                    return of(CustomisationMsg('Customisation.EstimatePricesError', error));
                }),
            );
        }),
    );
}
