import React from 'react';

import classes from './ItemDetailsLoading.scss';

export const ItemDetailsLoading: React.FC = () => (
    <div className={classes.wrapper}>
        <div className={classes.spinner} />
        <p className={classes.loadingText}>
            Order details loading. <span>This may take a little time, please bear with us.</span>
        </p>
    </div>
);
