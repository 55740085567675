import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { execute as removeMine } from '@wearejh/swagger-rxjs/lib/QuoteCouponManagementV1RemoveDelete2';
import { execute as removeGuest } from '@wearejh/swagger-rxjs/lib/QuoteGuestCouponManagementV1RemoveDelete';
import { CartVariant, CartMsg } from '@wearejh/m2-pwa-cart';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { extractErrorAndCode } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart/lib/utils/stateObservables';
import Bugsnag from '@bugsnag/js';

import { Actions, CouponMsg } from './coupon.register';

export function removeCouponEpic(action$: Observable<any>, state$: Observable<any>, deps: EpicDeps) {
    return action$.pipe(
        ofType<Actions>('Coupon.Delete'),
        withLatestFrom(cartVariantAndId(state$)),
        concatMap(([, [variant, cartId]]) => {
            const payload = { cartId: cartId as any };
            const ajax$ = variant === CartVariant.Account ? removeMine(deps) : removeGuest(payload, deps);
            return ajax$.pipe(
                mergeMap(() => of(CartMsg('Cart.Refresh', { force: true }), CouponMsg('Coupon.DeleteSuccess'))),
                catchError((err) => {
                    const { error, code } = extractErrorAndCode(err);
                    Bugsnag.notify(error, (event) => {
                        event.addMetadata('code', { code: code });
                    });
                    return of(CouponMsg('Coupon.DeleteError', error), CartMsg('Cart.Refresh', { force: true }));
                }),
            );
        }),
    );
}
