import React from 'react';
import { Fieldset } from 'src/components/Forms/Fieldset';
import { TextArea } from 'src/components/Forms/TextArea';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { basicPresenceValidation } from 'src/util/forms';

import { Button } from '../Button/Button';

import classes from './ArtworkApproval.scss';

type Props = {
    onSubmit(values, status);
};

export const ArtworkApprovalRequest: React.FC<Props> = (props) => {
    return (
        <>
            <h3 className={classes.actionsHeader}>Request Changes</h3>
            <Form onSubmit={(values) => props.onSubmit(values, 'reproof')} className={classes.actionsForm}>
                <Fieldset disabled={false}>
                    <TextArea
                        field="comment"
                        id="comment"
                        label="Description of changes required"
                        validate={basicPresenceValidation()}
                        required={true}
                    />
                </Fieldset>
                <Button type="submit" width="full">
                    Send change requests
                </Button>
            </Form>
        </>
    );
};
