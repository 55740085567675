import React, { useState } from 'react';
import { Svg } from 'src/components/Svg/Svg';
import classnames from 'classnames';

import classes from './Dropddown.scss';

type Props = {
    buttonText: string;
    children: React.ReactNode;
    isError?: boolean;
};

export const Dropdown: React.FC<Props> = (props) => {
    const { buttonText, children, isError } = props;
    const [showDropdown, setShowDropdown] = useState('isClosed');
    return (
        <div className={classes.dropdown} data-active={showDropdown}>
            <button
                className={classnames({
                    [classes.dropdownButton]: true,
                    [classes.dropdownButtonError]: isError,
                })}
                type="button"
                onClick={() => setShowDropdown(showDropdown === 'isClosed' ? 'isOpen' : 'isClosed')}
            >
                {buttonText} <Svg name="arrow-down" />
            </button>
            <div className={classes.dropdownContent}>{children}</div>
        </div>
    );
};

Dropdown.defaultProps = {
    isError: false,
};
