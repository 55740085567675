import React, { useMemo } from 'react';
import { LazyRedux } from '@wearejh/m2-pwa-engine/lib/components/index';
import { useParams } from 'react-router';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { PageTitle } from 'src/components/Layout/PageTitle';
import { TwoColSplit } from 'src/components/Layout/TwoColSplit';
import { OrderDetails } from 'src/components/Orders/OrderDetails';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { useDeps } from 'src/hooks/useDeps';
import { useSetResolved } from '@wearejh/m2-pwa-engine/lib/router/index';
import { SkeletonLine } from 'src/components/Helpers/SkeletonLine';
import { DefaultMeta } from 'src/components/AppShell/Head';
import meta from 'src/components/Customer/customer.json';
import { ModalOrderTracking } from 'src/components/Orders/ModalOrderTracking';

import { AppendState, Msg, ordersRegister, OrdersState } from '../../features/app/orders.register';
import { StoreState } from '../../types/global-types';

import { processOrderInfo } from './utils/order.utils';
import { OrderDetailAddress } from './OrderDetailAddress';
import { OrderItems } from './OrderItems';
import { OrderTotals } from './OrderTotals';
import classes from './OrderDetail.scss';
import { OrderApprovals } from './OrderApprovals';

type OrderDetailProps = {
    order: OrdersState['order'];
    isPending: boolean;
};

export const OrderDetailInner: React.FC<OrderDetailProps> = React.memo((props) => {
    const { paths } = useDeps();
    useSetResolved();

    const { order, isPending } = props;

    const parsedOrder = processOrderInfo(order);

    return (
        <TwoColSplit
            first={<OrderDetails className={classes.orderDetails} loading={isPending} {...parsedOrder.orderDetails} />}
            main={
                <>
                    {order &&
                        order.extension_attributes?.artwork_approvals &&
                        order.extension_attributes?.artwork_approvals.length > 0 && (
                            <OrderApprovals
                                loading={props.isPending}
                                artwork_approvals={order.extension_attributes.artwork_approvals}
                            />
                        )}
                    <OrderItems items={parsedOrder.orderDetailItems} loading={props.isPending} />
                    <OrderTotals className={classes.orderTotals} {...parsedOrder.orderTotal} />
                </>
            }
            last={
                <>
                    <div className={classes.sideBlock}>
                        <h3>Payment Details</h3>
                        <p>
                            {isPending && <SkeletonLine width={8} />}
                            {!isPending && parsedOrder.payment.paymentInfo}
                        </p>
                        <OrderDetailAddress address={parsedOrder.billingAddress} loading={isPending} />
                    </div>
                    <div className={classes.sideBlock}>
                        <h3>Delivery Details</h3>
                        <p>
                            {isPending && <SkeletonLine width={8} />}
                            {!isPending && parsedOrder.shipping}
                        </p>
                        <OrderDetailAddress address={parsedOrder.shippingAddress} loading={isPending} />
                    </div>
                    <Link
                        to={paths.customer.account}
                        className={classnames({
                            [classes.link]: true,
                            ['button']: true,
                            ['button-link']: true,
                            ['width--100']: true,
                        })}
                    >
                        Back to Account
                    </Link>
                    <a
                        className={[classes.link, 'button', 'button--light', 'button--width-full'].join(' ')}
                        href="javascript:window.print()"
                    >
                        Print order
                    </a>
                </>
            }
        />
    );
});

function stateSelector(state: AppendState<StoreState>) {
    return {
        order: state.orders.order,
        isPending: state.orders.status === DataStatus.Idle || state.orders.status === DataStatus.Pending,
    };
}

const EMPTY_OBJ = {};
export const OrderConnected: React.FC = React.memo(() => {
    const params = useParams() as Record<string, any>;
    const INIT = useMemo(() => {
        return Msg('Orders.FetchOrder', { orderId: Number(params.id) });
    }, [params.id]);
    return (
        <LazyRedux
            register={ordersRegister}
            initial={INIT}
            selector={stateSelector}
            Component={OrderDetailInner}
            initialProps={EMPTY_OBJ}
        />
    );
});

export const OrderDetail: React.FC = () => {
    return (
        <>
            <DefaultMeta data={meta.helmet.orderDetail} />
            <PageTitle title="Order Details" />
            <ModalOrderTracking
                orderInfo={{ orderId: 'SO1026590', postcode: 'DH1 1TF' }}
                isModalOpen={true}
                footprint={Math.round(Math.random() * 1e6)}
                onDismiss={() => ''}
            />
            <OrderConnected />
        </>
    );
};
