export const Selectors = {
    ShippingMethods_item: 'ShippingMethods_item',
    AddressSelect_Items: 'AddressSelect_Items',
    AddressSelect_Item: 'AddressSelect_Item',
    AddressSelect_New: 'AddressSelect_New',
    Providers_Accordion: 'Providers_Accordion',
    ReachAccordionItem: 'ReachAccordionItem',
    ReachAccordionButton: 'ReachAccordionButton',
};

export const TEST_NONCE = '__nonce__123__';
export const BRAINTREE_NAME = 'braintree';
export const PAYPAL_EXPRESS_NAME = 'paypal_express';
export const CHECK_MO = 'checkmo';
export const APPLE_PAY_NAME = 'braintree_applepay';
export const BANK_TRANSFER = 'banktransfer';

export const CARD_NAMES = {
    mc: 'Mastercard',
    visa: 'Visa',
    amex: 'American Express',
};
