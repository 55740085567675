import { HAVE_MY_LOGO } from 'src/constants/artwork';

export const getDataFromCustomisation = (value, itemIds: string[]) => {
    const customisation = {
        customisationName: value.customisation_name,
        image: value.image || '',
        type: value.type,
        isContactRequested: value.is_contact_requested,
        specialInstructions: value.special_instructions || '',
        textLineOne: value.text_line_one || '',
        textLineTwo: value.text_line_two || '',
        textLineThree: value.text_line_three || '',
        color: value.color || '',
        font: value.font || '',
        id: value.id,
    };

    const locationNames = value.location_names;

    return {
        itemCustomisations: [
            {
                itemIds,
                locationNames,
                customisation,
            },
        ],
    };
};

export const getDataFromCustomisationCamel = (value, itemIds: number[], id?: number) => {
    const idToBeSent = value.isArtworkTemporary
        ? { id: Number(value.selectedArtwork) }
        : { artworkId: Number(value.selectedArtwork) };

    const defaultCustomisation = {
        customisationName: '',
        image: value.selectedOptions?.image || '',
        type: Number(value.selectedMethod),
        isContactRequested: ['contact', HAVE_MY_LOGO].includes(value.selectedOptions?.isContactRequested),
        specialInstructions: value.selectedOptions?.specialInstructions || '',
        textLineOne: value.selectedOptions?.textLineOne || '',
        textLineTwo: value.selectedOptions?.textLineTwo || '',
        textLineThree: value.selectedOptions?.textLineThree || '',
        color: value.selectedOptions?.color || '',
        font: value.selectedOptions?.font || '',
        is_artwork_missing:
            value.selectedOptions?.isArtworkMissing ||
            value.selectedOptions?.isContactRequested === HAVE_MY_LOGO ||
            false,
        is_free: value.selectedOptions?.isFree || false,
        ...(id && { id }),
        ...(Number(value.selectedArtwork) && idToBeSent),
    };

    return {
        itemCustomisations: [
            {
                itemIds,
                locationNames: value.selectedPositions,
                customisation: defaultCustomisation,
            },
        ],
    };
};
