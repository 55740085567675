import { ActionMap, createMsg } from 'action-typed';
import { QuoteDataCartItemInterface } from 'swagger/ts/Definitions';
import { DataStatus, Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';
import { bundleAdd } from 'src/components/BundleProduct/feature/epics/bundleAdd.epic';
import { Observable } from 'rxjs';
import { ofType } from 'redux-observable';
import { LOCATION_CHANGE } from '@wearejh/m2-pwa-engine/lib/router/router.register';
import { mapTo } from 'rxjs/operators';

export const NAME = 'bundle';

export type Messages = {
    ['Bundle.Add']: QuoteDataCartItemInterface;
    ['Bundle.AddSuccess']: string[];
    ['Bundle.AddError']: string;

    ['Bundle.Reset']: undefined;
};

export type BundleState = {
    status: DataStatus;
    messages: Message[];
    itemIds: string[];
};

const initialState = {
    status: DataStatus.Idle,
    messages: [],
    itemIds: [],
};

export function bundleRegister() {
    return {
        epics: [
            bundleAdd,
            /**
             * Reset the state on page nav
             * @param action$
             */
            (action$: Observable<any>) => action$.pipe(ofType(LOCATION_CHANGE), mapTo(BundleMsg('Bundle.Reset'))),
        ],
        reducers: {
            [NAME]: function (state: BundleState = initialState, action: BundleActions): BundleState {
                switch (action.type) {
                    case 'Bundle.Reset': {
                        return { ...initialState };
                    }
                    case 'Bundle.Add': {
                        return {
                            ...state,
                            messages: [],
                            itemIds: [],
                            status: DataStatus.Pending,
                        };
                    }
                    case 'Bundle.AddError': {
                        return {
                            ...state,
                            messages: [{ type: MessageType.Error, text: action.payload }],
                            itemIds: [],
                            status: DataStatus.Error,
                        };
                    }
                    case 'Bundle.AddSuccess': {
                        return {
                            ...state,
                            messages: [],
                            itemIds: action.payload,
                            status: DataStatus.Success,
                        };
                    }
                    default:
                        return state;
                }
            },
        },
        name: NAME,
    };
}

export const BundleMsg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type BundleActions = TypeMap[keyof TypeMap];
export type AppendState<T = Record<string, unknown>> = T & { bundle: BundleState };
