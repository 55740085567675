import React, { useCallback, useState } from 'react';
import { Fields } from '@wearejh/m2-pwa-addresses/lib/addresses.types';
import { Field } from 'src/components/Forms/Field';
import { useDeps } from 'src/hooks/useDeps';
import { NOTES_MIN_LENGTH, NOTES_MAX_LENGTH, NOTES_MIN_LENGTH_LAST_NAME } from 'src/constants/forms';
import { useCountries } from '@wearejh/m2-pwa-addresses/lib/hooks/useCountries';

import { Input } from '../Forms/Input';
import {
    makeCountryPostcodeValidation,
    telephoneValidation,
    basicEmailValidation,
    basicMaxLengthValidation,
    basicLengthValidationWithText,
} from '../../util/forms';
import { PCAPredict } from '../PCAPredict/PCAPredict';

import classes from './AddressFields.scss';
import { RegionField } from './RegionField';
import { CountryField } from './CountryField';

type InputProps = {
    onChange?: () => void;
    countryCode?: string;
};

export const FirstNameField: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.firstname}
        label="First name"
        validate={basicLengthValidationWithText(NOTES_MIN_LENGTH, NOTES_MAX_LENGTH, 'Please enter your First name')}
        required={true}
        onChange={onChange}
    />
);
export const LastNameField: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.lastname}
        label="Last name"
        validate={basicLengthValidationWithText(
            NOTES_MIN_LENGTH_LAST_NAME,
            NOTES_MAX_LENGTH,
            'Please enter your Last name',
        )}
        required={true}
        onChange={onChange}
    />
);
export const Street1Field: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.street1}
        label="Street Line 1"
        validate={basicLengthValidationWithText(NOTES_MIN_LENGTH, NOTES_MAX_LENGTH, 'Please enter your Street Address')}
        required={true}
        placeholder="Street address or Postcode"
        disableAutoComplete={true}
        onChange={onChange}
    />
);
export const Street2Field: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.street2}
        label="Street Line 2"
        validate={basicMaxLengthValidation(NOTES_MAX_LENGTH)}
        disableAutoComplete={true}
        onChange={onChange}
    />
);

export const EmailField: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.email}
        label="Email Address"
        validate={basicEmailValidation()}
        required={true}
        onChange={onChange}
    />
);

export const CityField: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.city}
        label="City"
        validate={basicLengthValidationWithText(
            NOTES_MIN_LENGTH,
            NOTES_MAX_LENGTH,
            'Please enter the name of the City',
        )}
        required={true}
        disableAutoComplete={true}
        onChange={onChange}
    />
);

export const PostcodeField: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.postcode}
        label="Postcode"
        disableAutoComplete={true}
        validate={basicLengthValidationWithText(NOTES_MIN_LENGTH, NOTES_MAX_LENGTH, 'Please enter the Postcode')}
        required={true}
        onChange={onChange}
    />
);
export const SearchByPostCode: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.street1}
        required={true}
        placeholder="Post Code"
        disableAutoComplete={true}
        onChange={onChange}
    />
);

/**
 * Makes a postcode field that will be validated against the given country.
 */
export const MakeCountryPostcodeField: React.FC<InputProps> = ({ countryCode, onChange }) => {
    return (
        <Input
            field={Fields.postcode}
            label="Postcode"
            validate={makeCountryPostcodeValidation(countryCode)()}
            required={true}
            disableAutoComplete={true}
            onChange={onChange}
        />
    );
};

export const TelephoneField: React.FC<InputProps> = ({ onChange }) => (
    <Input
        field={Fields.telephone}
        label="Phone Number"
        validate={telephoneValidation()}
        required={true}
        onChange={onChange}
    />
);

export const AddressFields: React.FC<{
    after?: React.ReactNode;
    before?: React.ReactNode;
    showFieldsInit?: boolean;
    showFields?: boolean;
    setshowFields?: (state: boolean) => void;
    onChange?: () => void;
}> = ({ after, before, showFields, setshowFields, onChange }) => {
    const { countries } = useCountries();

    const [countryState, setCountryState] = useState(countries[0].id);

    const { PCA_ENABLED } = useDeps().env;

    const onCountryChange = useCallback((value) => {
        setCountryState(value);
    }, []);

    const onPcaSelect = () => {
        setshowFields?.(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    return (
        <>
            {showFields && <p>Search by Post Code</p>}
            <div hidden={!showFields}>
                <Field>
                    <CountryField onCountryChange={onCountryChange} noLabel showFields />
                </Field>
            </div>
            {showFields && (
                <Field>
                    <SearchByPostCode onChange={onChange} />
                </Field>
            )}
            <div hidden={showFields}>
                {before}
                <Field>
                    <Street1Field onChange={onChange} />
                </Field>
                <Field>
                    <Street2Field onChange={onChange} />
                </Field>
                <Field>
                    <CityField onChange={onChange} />
                </Field>
                <Field>
                    <FirstNameField onChange={onChange} />
                </Field>
                <Field>
                    <LastNameField onChange={onChange} />
                </Field>
                <div className={classes.oneLine}>
                    <Field>
                        <RegionField onChange={onChange} />
                    </Field>
                    {countryState === 'IE' ? (
                        <Field>
                            <PostcodeField onChange={onChange} />
                        </Field>
                    ) : (
                        <Field>
                            <MakeCountryPostcodeField countryCode={countryState} onChange={onChange} />
                        </Field>
                    )}
                </div>
                <Field>
                    <CountryField onCountryChange={onCountryChange} showFields onChange={onChange} />
                </Field>
                <Field>
                    <TelephoneField onChange={onChange} />
                </Field>
                {after}
            </div>

            {PCA_ENABLED && <PCAPredict prefix="address." countryCode={countryState} showFields={onPcaSelect} />}
        </>
    );
};
