import React from 'react';
import classnames from 'classnames';

import classes from './BundleButton.scss';

type Props = React.ComponentProps<'button'> & {
    background: 'orange' | 'green' | 'white' | 'gray' | 'transparent';
    size: 'small' | 'medium' | 'big' | 'close' | 'mini';
    icon?: string;
    iconOnRight?: boolean;
    classProps?: string;
    bold?: boolean;
};

export const BundleButton: React.FC<Props> = ({
    background,
    icon,
    iconOnRight,
    size,
    children,
    classProps,
    bold,
    ...buttonProps
}) => (
    // eslint-disable-next-line react/button-has-type
    <button
        {...buttonProps}
        className={classnames(classes.button, classProps, {
            [classes[background]]: background,
            [classes[size]]: size,
        })}
    >
        {icon && !iconOnRight && <img className={classes.icon} src={`../../../static/icons/${icon}`} />}
        {children && <span className={bold ? classes.boldSpan : undefined}>{children}</span>}
        {icon && iconOnRight && <img className={classes.iconOnRight} src={`../../../static/icons/${icon}`} />}
    </button>
);
