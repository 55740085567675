import React, { LabelHTMLAttributes } from 'react';
import { Radio } from '@wearejh/rx-form/lib/RxRadioGroup';
import classnames from 'classnames';
import { ApplicationMethod, ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';

import { Svg } from '../Svg/Svg';

import classes from './ArtworkGridItem.scss';
import { ArtworkType } from './ArtworkSelect';
import { displayTypeNames, getIcons } from './utils/artwork';

type ArtworkGridItemProps = {
    id: string;
    title?: string;
    displayImage?: string;
    fieldIndex?: number;
    application_type: ApplicationType;
    imageId?: string;
    application_method: ApplicationMethod;
    is_artwork_missing: boolean;
    textImage?: React.ReactNode;
    logoImage?: React.ReactNode;
    hide?: boolean;
    index?: number;
    addNew?: boolean;
    icon?: string;
    description?: string;
    meta?: string;
    value: string;
    type: ArtworkType;
} & LabelHTMLAttributes<any>;

export const ArtworkGridItem: React.FC<ArtworkGridItemProps> = (props) => {
    const {
        fieldIndex,
        id,
        title,
        displayImage,
        application_type,
        is_artwork_missing,
        application_method,
        textImage,
        logoImage,
        meta,
        description,
        imageId,
        hide,
        index,
        addNew,
        value,
        type,
    } = props;

    return (
        <div
            className={classnames(classes.container, {
                [classes.regularContainer]: type !== ArtworkType.Bundle,
                [classes.container_hide]: type === ArtworkType.Bundle && hide && index && index >= 6,
            })}
        >
            <Radio className={classes.input} value={value} id={fieldIndex ? fieldIndex + id : id} />

            <label
                className={classnames(classes.label, {
                    [classes.addNew_label]: addNew,
                })}
                htmlFor={fieldIndex ? fieldIndex + id : id}
            >
                {addNew ? (
                    <div className={classes.addNew}>
                        <div className={classes.addNew_icon}>
                            <AddSvgIcon />
                        </div>
                        <p className={classes.addNew_desc}>{description}</p>
                        <p className={classes.addNew_meta}>{meta}</p>
                    </div>
                ) : (
                    <>
                        <div className={classes.wrapper}>
                            {application_type === ApplicationType.Logo ? (
                                <a href={displayImage} target="_blank" rel="noreferrer">
                                    <SvgIcon /> {imageId}
                                </a>
                            ) : (
                                <span>Artwork</span>
                            )}
                        </div>

                        <div>
                            <div className={classes.image}>
                                {application_type === ApplicationType.Logo && (
                                    <div className={classes.logoImage}>{logoImage}</div>
                                )}
                                {application_type === ApplicationType.Text && (
                                    <div className={classes.textImage}>{textImage}</div>
                                )}
                            </div>
                            <span className={classes.title}>{title ? title : ''}</span>
                            {type === ArtworkType.Bundle ? (
                                <div className={classes.details}>
                                    <div className={classes.icons}>
                                        {getIcons(application_method, application_type).map((icon) => (
                                            <Svg name={icon} key={icon} />
                                        ))}
                                    </div>
                                    <span className={classes.type}>
                                        {displayTypeNames(
                                            application_method,
                                            application_type,
                                            false,
                                            is_artwork_missing,
                                        )}
                                    </span>
                                </div>
                            ) : (
                                <img
                                    className={classes.checkIcon}
                                    src={require('../../svg/check-circle.svg')}
                                    alt="check circle"
                                />
                            )}
                        </div>
                    </>
                )}
            </label>
        </div>
    );
};

const SvgIcon = () => {
    return (
        <svg
            width="13px"
            height="9px"
            viewBox="0 0 13 9"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="Workwear-Express---Step-3"
                    transform="translate(-268.000000, -410.000000)"
                    fill="#000000"
                    fillRule="nonzero"
                >
                    <g id="eye-close-up" transform="translate(268.000000, 410.000000)">
                        <path
                            d="M6.5,0 C3.63265032,0 1.16137424,1.6902859 0.0261492249,4.1294856 C-0.0087164083,4.20340074 -0.0087164083,4.28986751 0.0261492249,4.36517728 C1.16137424,6.80436303 3.63266427,8.49466288 6.5,8.49466288 C9.36733573,8.49466288 11.8386258,6.80437698 12.9738508,4.36517728 C13.0087164,4.29126214 13.0087164,4.20479537 12.9738508,4.1294856 C11.8386258,1.6902859 9.36734968,0 6.5,0 Z M6.5,7.17815051 C4.88083999,7.17815051 3.56989218,5.86579413 3.56989218,4.24802875 C3.56989218,2.62886874 4.88223462,1.31792094 6.5,1.31792094 C8.11916001,1.31792094 9.43010782,2.63026337 9.43010782,4.24802875 C9.43010782,5.86579413 8.11776538,7.17815051 6.5,7.17815051 Z"
                            id="Shape"
                        ></path>
                        <circle id="Oval" cx="6.5" cy="4.24663413" r="1.87577107"></circle>
                    </g>
                </g>
            </g>
        </svg>
    );
};

const AddSvgIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            version="1.1"
            id="Capa_1"
            x="0px"
            y="0px"
            viewBox="0 0 512 512"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <path d="M256,0C114.833,0,0,114.833,0,256s114.833,256,256,256s256-114.853,256-256S397.167,0,256,0z M256,472.341    c-119.275,0-216.341-97.046-216.341-216.341S136.725,39.659,256,39.659S472.341,136.705,472.341,256S375.295,472.341,256,472.341z    " />
                </g>
            </g>
            <g>
                <g>
                    <path d="M355.148,234.386H275.83v-79.318c0-10.946-8.864-19.83-19.83-19.83s-19.83,8.884-19.83,19.83v79.318h-79.318    c-10.966,0-19.83,8.884-19.83,19.83s8.864,19.83,19.83,19.83h79.318v79.318c0,10.946,8.864,19.83,19.83,19.83    s19.83-8.884,19.83-19.83v-79.318h79.318c10.966,0,19.83-8.884,19.83-19.83S366.114,234.386,355.148,234.386z" />
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    );
};
