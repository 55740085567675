import { ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { Actions } from '@wearejh/m2-pwa-user/lib/user.actions';
import { Deps } from 'src/types/global-types';
import { mapTo } from 'rxjs/operators';

/**
 * Listen for the user signing out - when they do so on any page
 * under /customisation then we just throw them back to the homepage
 *
 * @param action$
 * @param _state$
 * @param deps
 */
export function userSignOutReaction(action$: Observable<any>, _state$: Observable<any>, deps: Deps) {
    return action$.pipe(ofType<Actions>('User.SignOut'), mapTo(deps.push('/')));
}
