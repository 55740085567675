import React, { useState, useEffect } from 'react';
import { Modal } from 'src/components/Modal/Modal';
import { PositionValidationSingle } from 'src/components/CustomisationPage/steps/PositionFieldSet';
import { BundleButton } from 'src/components/BundleProduct/BundleButton';
import { Customisation } from 'src/components/BundleProduct/types';
import { ProductLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { ApplicationMethod } from 'src/components/CustomisationPage/feature/customisation.types';
import { useFormik } from 'formik';
import { EMPTY_ARTWORK_ID } from 'src/constants/artwork';

import classes from './MultipleStepCustomisation.scss';
import { CustomisationStepPositionSelect } from './CustomisationStepPositionSelect';
import { CustomisationStepMethodFieldset } from './CustomisationStepMethodFieldset';
import { CustomisationStepArtworkSelect } from './CustomisationStepArtworkSelect';
import { CustomisationStepTypeFieldset } from './CustomisationStepTypeFieldset';
import { CustomisationStepConfigureSelector } from './CustomisationStepConfigureSelector';
import { NUMBER_OF_STEPS } from './CustomisationUtils';
import { getValidationSchema } from './MultipleStepCustomisation.schema';

type Props = {
    locations: ProductLocation[];
    isOpenModal: boolean;
    closeModal: () => void;
    onSubmitCustomisation: (state: Customisation) => void;
    customisation?: Customisation;
    freeCustomisation?: boolean;
    alreadySelectedPositions?: string[];
    recommendedApplication?: ApplicationMethod;
    avoidSelectingPosition?: boolean;
    multiplePositions?: boolean;
};

export const MultipleStepCustomisation: React.FC<Props> = ({
    locations,
    isOpenModal,
    closeModal,
    onSubmitCustomisation,
    customisation,
    freeCustomisation,
    alreadySelectedPositions,
    recommendedApplication,
    avoidSelectingPosition,
    multiplePositions,
}) => {
    const firstAvailableLocation = locations.find((location) => !alreadySelectedPositions?.includes(location.name));
    const [step, setStep] = useState(1);
    const methodKey = Boolean(firstAvailableLocation?.type) ? 'type' : 'method';

    const formik = useFormik<Customisation>({
        initialValues: customisation
            ? { ...customisation, availableMethodNumber: firstAvailableLocation?.[methodKey] }
            : {
                  selectedArtwork: EMPTY_ARTWORK_ID,
                  isArtworkTemporary: undefined,
                  selectedMethod: recommendedApplication
                      ? String(recommendedApplication)
                      : firstAvailableLocation?.[methodKey] === ApplicationMethod.Both
                      ? String(ApplicationMethod.Print)
                      : String(firstAvailableLocation?.[methodKey]),
                  selectedOptions: {
                      isContactRequested: 'upload',
                  },
                  selectedPositions: [],
                  availableMethodNumber: firstAvailableLocation?.[methodKey],
                  selectedType: 'logo',
              },
        onSubmit: () => {
            closeModal();
            setStep(1);
            onSubmitCustomisation(formik.values);
        },
        validationSchema: getValidationSchema(step),
    });

    useEffect(() => {
        formik.validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step]);

    const someLocationHasBothPositions = Boolean(
        locations.find((location) => location[methodKey] !== Number(formik.values.selectedMethod)),
    );

    const productHasOnlyOnePosition = Boolean(
        !locations.find((location) => location[methodKey] !== locations[0][methodKey]) && !someLocationHasBothPositions,
    );

    const handleCustomisationSet = (customisation: Partial<Customisation>) => {
        formik.setValues({ ...formik.values, ...customisation });
    };

    const usingNew = formik.values.selectedArtwork === 'new';
    const isFinalStep = usingNew ? step === NUMBER_OF_STEPS : step === NUMBER_OF_STEPS - 2;

    const selectedLocations = locations.filter((location) => formik.values.selectedPositions?.includes(location.name));
    const oneMethodAvailable =
        selectedLocations.findIndex((location) => location[methodKey] !== ApplicationMethod.Both) > -1;

    const handleNextButtonClick = () => {
        let stepFactor = 1;

        //if there is step 1 and no free customisation or there is only one method available
        //shoud move two steps away
        if (step === 1 && (oneMethodAvailable || avoidSelectingPosition)) stepFactor += 1;

        setStep(step + stepFactor);
    };

    const handleBackButtonClick = () => {
        let stepFactor = 1;

        //if there is step 3 and no free customisation or there is only one method available
        //shoud move two steps back
        if ((step === 3 && avoidSelectingPosition) || oneMethodAvailable) stepFactor = 2;

        setStep(step - stepFactor);
    };

    const logoImageSrc = freeCustomisation ? require('../../../../static/img/free-logo.png') : null;

    const getNextButtonTitle = () => {
        switch (step) {
            case 1:
                return avoidSelectingPosition ? 'Select Artwork' : 'Application Method';
            case 2:
                return 'Select Artwork';
            case 3:
                return 'Application Type';
            case 4:
                return 'Artwork';
        }
    };

    const renderStepContent = (step) => {
        switch (step) {
            case 1:
                return (
                    <CustomisationStepPositionSelect
                        locations={locations}
                        closeModal={closeModal}
                        selectedPositions={formik.values.selectedPositions}
                        handleCustomisationSet={handleCustomisationSet}
                        image={logoImageSrc}
                        alreadySelectedPositions={alreadySelectedPositions}
                        multiplePositions={multiplePositions}
                    />
                );
            case 2:
                return (
                    <CustomisationStepMethodFieldset
                        closeModal={closeModal}
                        image={logoImageSrc}
                        recommendedApplication={recommendedApplication}
                        selectedMethod={formik.values.selectedMethod}
                        handleCustomisationSet={handleCustomisationSet}
                    />
                );
            case 3:
                return (
                    <CustomisationStepArtworkSelect
                        closeModal={closeModal}
                        onNextButtonClick={handleNextButtonClick}
                        image={logoImageSrc}
                        applicationMethod={formik.values.selectedMethod}
                        isFinalStep={isFinalStep}
                        freeCustomisation={freeCustomisation}
                        oneMethodAvailable={oneMethodAvailable}
                        handleCustomisationSet={handleCustomisationSet}
                        selectedArtwork={formik.values.selectedArtwork}
                        selectedMethod={formik.values.selectedMethod}
                        someLocationHasBothPositions={someLocationHasBothPositions}
                        productHasOnlyOnePosition={productHasOnlyOnePosition}
                    />
                );
            case 4:
                return (
                    <CustomisationStepTypeFieldset
                        closeModal={closeModal}
                        image={logoImageSrc}
                        selectedType={formik.values.selectedType}
                        handleCustomisationSet={handleCustomisationSet}
                    />
                );
            case 5:
                return formik.values.selectedOptions ? (
                    <CustomisationStepConfigureSelector
                        selectedType={formik.values.selectedType}
                        selectedOptions={formik.values.selectedOptions}
                        handleCustomisationSet={handleCustomisationSet}
                        closeModal={closeModal}
                        image={logoImageSrc}
                    />
                ) : (
                    <div>Not Found</div>
                );
            default:
                return <div>Not Found</div>;
        }
    };

    return (
        <Modal className={classes.modal} type="popup-wide" isOpen={isOpenModal} bottomSpacing>
            <form onSubmit={formik.handleSubmit}>
                {renderStepContent(step)}
                {isFinalStep && <PositionValidationSingle />}
                <div className={classes.actionButtons}>
                    {step !== 1 && (
                        <div className={classes.prevButtonContainer}>
                            <div className={classes.prevButtonDesktop}>
                                <button type="button" onClick={handleBackButtonClick} className={classes.prevButton}>
                                    <span>{'<'}</span>
                                    <span className={classes.prevButtonText}>Back</span>
                                </button>
                            </div>
                            <div className={classes.prevButtonMobile}>
                                <BundleButton
                                    disabled={step === 1}
                                    background="orange"
                                    size="big"
                                    onClick={handleBackButtonClick}
                                    icon="v-left-white.svg"
                                    type="button"
                                    bold
                                >
                                    Back
                                </BundleButton>
                            </div>
                        </div>
                    )}
                    <div className={classes.nextButton}>
                        <BundleButton
                            disabled={!formik.isValid}
                            background="orange"
                            size="big"
                            onClick={isFinalStep ? () => formik.handleSubmit() : handleNextButtonClick}
                            icon="v-right.svg"
                            iconOnRight
                            type="button"
                            bold
                        >
                            {isFinalStep ? 'Confirm' : getNextButtonTitle() || 'Next Step'}
                        </BundleButton>
                    </div>
                    {step !== 1 && <div className={classes.nextButtonRight}></div>}
                </div>
            </form>
        </Modal>
    );
};
