import React, { useCallback, useEffect, useReducer } from 'react';
import { useDropzone } from 'react-dropzone';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import { useConstant } from '@wearejh/react-hooks/lib/useConstant';
import { concat, merge, Observable, of, race, Subject, throwError } from 'rxjs';
import { catchError, map, mapTo, switchMap, tap } from 'rxjs/operators';
import { STORAGE_KEY } from '@wearejh/m2-pwa-user/lib/user.actions';
import { useDeps } from 'src/hooks/useDeps';
import classnames from 'classnames';

import { uploadFileRx } from '../CustomisationPage/utils/uploadFile';
import { errorMessage } from '../../util/state-helpers';
import { Button } from '../Button/Button';
import { Messages } from '../Messages/Messages';
import { Block } from '../Helpers/Block';

import classes from './DropZone.scss';

interface Props {
    dispatch(evt: Events);
    messages: Message[];
    value: Value;
    fileName: string | undefined;
    setImage(img: { fileName: string; url: string });
    percentage: number;
    isBundleView?: boolean;
}

export const DropZone: React.FC<Props> = ({
    dispatch,
    messages,
    percentage,
    fileName,
    value,
    setImage,
    isBundleView,
}) => {
    const accepted$ = useConstant(() => new Subject<File>());
    const cancel$ = useConstant(() => new Subject<any>());
    const onCancel = useCallback(() => cancel$.next(), [cancel$]);
    const { storage } = useDeps();

    useEffect(() => {
        const acc$: Observable<Events> = accepted$.pipe(
            /**
             * Now take the file and perform the upload
             */
            switchMap((file) => {
                const userData = storage.get(STORAGE_KEY);
                let id = 0;
                if (userData && userData.data && userData.data.id) {
                    id = userData.data.id;
                }
                const [uploader, progress] = uploadFileRx(file, id);

                const isTest = (file) => file.name.indexOf('__jh_test') === 0;

                /**
                 * Handle the upload complete
                 */
                const uploader$: Observable<Events> = isTest(file)
                    ? throwError(new Error('oops!'))
                    : uploader.pipe(
                          tap((url) => setImage({ fileName: file.name, url })),
                          map((url) => ({ kind: 'upload-complete', url })),
                      );

                /**
                 * Progress indication
                 */
                const progress$: Observable<Events> = progress.pipe(
                    map((progress) => (progress.loaded * 100) / progress.total),
                    map((percentage) => ({ kind: 'progress', percentage })),
                );

                /**
                 * group the final upload with the end of the progress events
                 */
                const completion$: Observable<Events> = merge(uploader$, progress$).pipe(
                    catchError((e) => {
                        // dispatch({ kind: 'upload-error', error: e.message });
                        return of({ kind: 'upload-error', error: e.message } as const);
                    }),
                );

                /**
                 * Now race the upload against a click on 'cancel'
                 */
                return concat(
                    of({ kind: 'file-accepted', fileName: file.name } as const),
                    race(completion$, cancel$.pipe(mapTo({ kind: 'cancel' } as const))),
                );
            }),
        );

        /**
         * Subscribe to start listening & pump the events
         * into the react useReducer
         */
        const sub = acc$.subscribe((evt) => dispatch(evt));
        return () => {
            /**
             * This will cancel the uploads and any listeners
             */
            sub.unsubscribe();
        };
    }, [accepted$, cancel$, dispatch, setImage, storage]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDropAccepted: (files) => {
            accepted$.next(files[0]);
        },
        accept: [
            'image/png',
            'image/jpg',
            'image/jpeg',
            'image/PNG',
            'image/JPG',
            'image/JPEG',
            'image/x-citrix-jpeg',
            'image/pjpeg',
            'image/x-citrix-png',
            'image/x-png',
            'application/pdf',
            'image/eps',
            'application/postscript',
        ],
        onDropRejected: () => {
            dispatch({ kind: 'file-rejected', error: 'File not accepted' });
        },
    });

    return (
        <div className={classnames(classes.root, { [classes.wrapper]: !isBundleView })}>
            {value === Value.Error && (
                <Block>
                    <Messages messages={messages} />
                </Block>
            )}
            <div {...getRootProps()} className={classes.dropzone} data-test-id="DropZone">
                <input {...getInputProps()} disabled={value === Value.Uploading} />
                <div className={classes.dropzoneInner}>
                    <div className={classes.dropzoneContent} data-dropzone-value={value}>
                        <p className={classes.dragDrop}>
                            {isDragActive ? (
                                <span>Drop the files here ...</span>
                            ) : (
                                <span>Drag 'n' drop some files here, or click to select files</span>
                            )}
                        </p>
                        <div className={classes.choose}>
                            {!isBundleView && <img src={require('../../svg/upload.svg')} alt="upload" />}
                            <span className="button button--light">Choose file</span>
                        </div>
                        <div className={classes.info}>
                            <p>JPG, PNG, EPS, AI, PDF</p>
                            <small>Max size: 8MB</small>
                        </div>
                    </div>
                </div>
            </div>
            {value === Value.Uploading && (
                <div className={classes.progress}>
                    <div>
                        <p>{fileName}</p>
                        <p>
                            <progress max="100" value={Math.ceil(percentage).toString()}>
                                {percentage.toFixed(2)}
                            </progress>
                        </p>
                        <p>
                            <Button variant="reset" onClick={onCancel}>
                                Cancel
                            </Button>
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

type Events =
    | {
          kind: 'file-accepted';
          fileName: string;
      }
    | {
          kind: 'file-rejected';
          error: string;
      }
    | {
          kind: 'upload-complete';
          url: string;
      }
    | {
          kind: 'upload-error';
          error: string;
      }
    | {
          kind: 'progress';
          percentage: number;
      }
    | {
          kind: 'cancel';
      };

export enum Value {
    Idle = 'Idle',
    Uploading = 'Uploading',
    Error = 'Error',
}

type State = {
    value: Value;
    messages: Message[];
    percentage: number;
    fileName: string | undefined;
};

function dropzoneReducer(state: State, action: Events): State {
    switch (action.kind) {
        case 'file-accepted':
            return { ...state, value: Value.Uploading, fileName: action.fileName, messages: [] };
        case 'file-rejected':
        case 'upload-error': {
            return { ...state, value: Value.Error, messages: [errorMessage(action.error)] };
        }
        case 'upload-complete':
            return state;
        case 'progress':
            return { ...state, percentage: action.percentage };
        case 'cancel':
            return {
                value: Value.Idle,
                messages: [],
                percentage: 0,
                fileName: undefined,
            };
        default:
            return unreachable();
    }
}

export const DropZoneState: React.FC<{ setImage(img: { fileName: string; url: string }); isBundleView?: boolean }> = ({
    setImage,
    isBundleView,
}) => {
    const [state, dispatch] = useReducer(dropzoneReducer, {
        value: Value.Idle,
        messages: [],
        percentage: 0,
        fileName: undefined,
    });
    return (
        <DropZone
            dispatch={dispatch}
            percentage={state.percentage}
            messages={state.messages}
            value={state.value}
            fileName={state.fileName}
            setImage={setImage}
            isBundleView={isBundleView}
        />
    );
};

function unreachable(msg = "didn't expect to get here"): never {
    throw new Error(msg);
}
