import { Observable } from 'rxjs';
import loadCSS from 'loadcss';

export function loadCssAsObservable(cssFile) {
    return Observable.create((observer) => {
        const prev = document.getElementsByTagName('LINK');
        let exists = false;
        [].forEach.call(prev, function (elem: any) {
            if (elem && elem.href === cssFile) {
                exists = true;
            }
        });
        if (!exists) {
            loadCSS(cssFile, () => {
                observer.next(cssFile);
                observer.complete();
            });
        } else {
            observer.next(cssFile);
            observer.complete();
        }
    });
}
