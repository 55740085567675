import { ApplicationMethod, ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';

export interface ArtworkItem {
    id: string;
    value: string;
    title: string;
    application_method: ApplicationMethod;
    application_type: ApplicationType;
    image: string;
    textLineOne: string;
    textLineTwo: string;
    textLineThree: string;
    color: string;
    font: string;
    is_artwork_missing: boolean;
}

export const ARTWORK_EXISTING = 'artwork-existing';
export const ARTWORK = 'artwork';
