import { format } from 'date-fns';
import Bugsnag from '@bugsnag/js';

import { DateFormat } from '../types/global-types';

export function parseAndFormatDate(input: string | undefined, formatString?: DateFormat): string {
    if (!input) return '';
    try {
        const formatDate = new Date(input.replace(' ', 'T'));
        return format(formatDate, formatString || 'd/MM/yy');
    } catch (e) {
        Bugsnag.notify('Date could not be parsed', (event) => {
            event.addMetadata('errorData', {
                error: e,
                input: input,
            });
        });
        return '';
    }
}

export const formatDate = (date: string) => {
    const initialDate = new Date(date);

    return format(initialDate, 'do MMMM, yyyy');
};

export const nth = (d: number) => {
    if (d >= 11 && d < 14) return 'th';
    switch (d % 10) {
        case 1:
            return 'st';
        case 2:
            return 'nd';
        case 3:
            return 'rd';
        default:
            return 'th';
    }
};

export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const daysNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
