import React, { useState, Dispatch } from 'react';
import classnames from 'classnames';
import { getAllImages } from 'src/components/ArtworkApproval/utils/utils';
import classes from 'src/components/ArtworkApproval/ArtworkApproval.scss';
import { Svg } from 'src/components/Svg/Svg';
import { Artwork } from 'src/components/ArtworkApproval/ArtworkApprovalContext';

const handleNextButtonClick = (
    index: number,
    setPickedImageIndex: Dispatch<React.SetStateAction<number>>,
    images: string[],
) => {
    const indexToSet = (index + 1) % images.length;
    setPickedImageIndex(indexToSet);
};

const handlePrevButtonClick = (
    index: number,
    setPickedImageIndex: Dispatch<React.SetStateAction<number>>,
    images: string[],
) => {
    const indexToSet = (index - 1 + images.length) % images.length;
    setPickedImageIndex(indexToSet);
};

export const ProofGalleryModal: React.FC<{ artwork: Artwork }> = ({ artwork }) => {
    const [pickedImageIndex, setPickedImageIndex] = useState<number>(0);
    const images = getAllImages(artwork.samples);
    const showImagesPagination = images.length > 1;

    return (
        <div className={classes.modalProofGalleryContainer}>
            <b>{`${pickedImageIndex + 1}  of ${images.length}`}</b>
            <div className={classes.modalProofImageContainer}>
                {showImagesPagination && (
                    <button
                        type="button"
                        className={classnames(classes.mobileButton, classes.buttonPrev)}
                        onClick={() => handlePrevButtonClick(pickedImageIndex, setPickedImageIndex, images)}
                    >
                        <Svg name="arrow-left" />
                    </button>
                )}

                <img className={classes.modalProofImage} src={images[pickedImageIndex]} />

                {showImagesPagination && (
                    <button
                        type="button"
                        className={classnames(classes.mobileButton, classes.buttonNext)}
                        onClick={() => handleNextButtonClick(pickedImageIndex, setPickedImageIndex, images)}
                    >
                        <Svg name="arrow-right" />
                    </button>
                )}
            </div>
            {showImagesPagination && (
                <div className={classes.paginationContainer}>
                    {images.map((image) => {
                        const index = images.indexOf(image);
                        return (
                            <button
                                className={classnames(classes.paginationButton, {
                                    [classes.selectedPaginationButton]: pickedImageIndex === index,
                                })}
                                key={index}
                                type="button"
                                onClick={() => setPickedImageIndex(index)}
                            >
                                {index + 1}
                            </button>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
