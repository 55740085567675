import React from 'react';
import classnames from 'classnames';

import { CUSTOMISATION_STEPS, FINAL_STEP_INDEX, NUMBER_OF_STEPS } from './CustomisationUtils';
import classes from './MultipleStepCustomisation.scss';

type Props = {
    name: string;
    isFinalStep?: boolean;
    titleModal: string;
};

export const CustomisationModalMenu: React.FC<Props> = ({ name, isFinalStep, titleModal }) => {
    const currentStepIndex = CUSTOMISATION_STEPS.findIndex((step) => step === name);

    return (
        <>
            <div className={classes.customisationMenu}>
                {CUSTOMISATION_STEPS.map((step, index) => (
                    <div
                        key={index}
                        className={classnames(classes.customisationMenuElement, {
                            [classes.stepUnactive]: isFinalStep && index + 1 > FINAL_STEP_INDEX,
                        })}
                    >
                        {index < currentStepIndex && (
                            <span className={classes.checkSign}>
                                <div className={classes.checkSignStem}></div>
                                <div className={classes.checkSignKick}></div>
                            </span>
                        )}
                        <span className={classnames({ [classes.stepBold]: index === currentStepIndex })}>
                            {`${index + 1}. ${step}`}
                        </span>
                        <span className={classes.nextStepSign}>{'>'}</span>
                    </div>
                ))}
            </div>
            <div className={classes.customisationMenuMobile}>
                <div className={classes.customisationMenuMobileTitle}>{titleModal}</div>
                <div className={classes.customisationMenuMobileCounter}>{`(${currentStepIndex + 1}/${
                    isFinalStep ? FINAL_STEP_INDEX : NUMBER_OF_STEPS
                })`}</div>
            </div>
        </>
    );
};
