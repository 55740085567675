import { Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { catchError, concatMap, mergeMap, withLatestFrom } from 'rxjs/operators';
import { execute as couponMine } from '@wearejh/swagger-rxjs/lib/QuoteCouponManagementV1SetPut2';
import { execute as couponGuest } from '@wearejh/swagger-rxjs/lib/QuoteGuestCouponManagementV1SetPut';
import { EpicDeps } from '@wearejh/m2-pwa-engine/lib/types';
import { CartMsg, CartVariant } from '@wearejh/m2-pwa-cart';
import { extractErrorAndCode } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart/lib/utils/stateObservables';
import Bugsnag from '@bugsnag/js';

import { Actions, CouponMsg, TypeMap } from './coupon.register';

type Action = TypeMap['Coupon.Apply'];

export function applyCouponEpic(action$: Observable<any>, state$: Observable<any>, deps: EpicDeps) {
    return action$.pipe(
        ofType<Actions, Action>('Coupon.Apply'),
        withLatestFrom(cartVariantAndId(state$)),
        concatMap(([{ payload }, [variant, cartId]]) => {
            const ajaxPayload = {
                cartId: cartId as any,
                couponCode: payload,
            };
            const fn = variant === CartVariant.Account ? couponMine : couponGuest;
            const ajax$ = fn(ajaxPayload, deps);
            return ajax$.pipe(
                mergeMap(() => of(CartMsg('Cart.Refresh', { force: true }), CouponMsg('Coupon.ApplySuccess'))),
                catchError((err) => {
                    const { error, code } = extractErrorAndCode(err);
                    let newError = error;
                    Bugsnag.notify(error, (event) => {
                        event.addMetadata('code', { code: code });
                    });
                    if (newError.includes('No such entity with cartId')) {
                        newError = 'Cart ID not found. Please refresh the page.';
                    }
                    return of(CouponMsg('Coupon.ApplyError', newError), CartMsg('Cart.Refresh', { force: true }));
                }),
            );
        }),
    );
}
