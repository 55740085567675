import {
    artworkSubmitExisting,
    artworkSubmitNew,
} from 'src/components/CustomisationPage/feature/epics/artworkSubmit.epic';
import { registerLogrocket } from 'src/features/logrocket/logrocket.register';

import { userSignOutReaction } from './epics/userSignedInReaction.epic';
import { priceEstimatesEpic } from './epics/priceEstimates.epic';
import { customisationReducer } from './customisation.reducer';
import { loadStep } from './epics/loadStep.epic';
import { methodSubmit } from './epics/methodSubmit.epic';
import { typeSubmit } from './epics/typeSubmit.epic';
import { configSubmit } from './epics/configSubmit.epic';
import { positionSubmit } from './epics/positionSubmit.epic';
import { customisationSubmit } from './epics/customisationSubmit.epic';

export function customisationRegister() {
    return [
        {
            epics: [
                loadStep,
                methodSubmit,
                artworkSubmitNew,
                artworkSubmitExisting,
                typeSubmit,
                configSubmit,
                positionSubmit,
                customisationSubmit,
                userSignOutReaction,
                priceEstimatesEpic,
            ],
            reducers: {
                customisation: customisationReducer,
            },
            name: 'customisation',
        },
        registerLogrocket(),
    ];
}
