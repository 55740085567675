import React from 'react';

import classes from './RemoveItemBtn.scss';

type Props = {
    deleteItem: () => void;
};

export const RemoveItemBtn: React.FC<Props> = ({ deleteItem }) => (
    <button className={classes.removeItemBtn} type="button" onClick={deleteItem} data-testid="removeItemBtn">
        <img className={classes.icon} src="../../../static/img/delete.svg" />
    </button>
);
