import {
    CustomisationEntry,
    EntryKind,
    FromCustomisation,
} from 'src/components/CustomisationPage/feature/customisation.actions';
import { paths } from 'src/config.json';
import Bugsnag from '@bugsnag/js';

export function entryToSearch(entry: CustomisationEntry): string {
    return encodeURIComponent(JSON.stringify(entry));
}

export function artWorkEntryUrl(entry: CustomisationEntry): string {
    const asJson = entryToSearch(entry);
    const path = paths.customisation.position;
    const joined = path + `?input=${asJson}`;
    return joined;
}

/**
 * @param search
 */
export function searchToEntry(search: string): CustomisationEntry | undefined {
    const params = new URLSearchParams(search || '');
    const input = params.get('input');
    if (!input) {
        Bugsnag.notify('input param missing');
        return undefined;
    }

    try {
        const parsed = JSON.parse(input);
        if (parsed.kind === EntryKind.New) {
            if (allValidIds(parsed.itemIds)) {
                return { itemIds: parsed.itemIds, kind: EntryKind.New };
            }
        }
        if (parsed.kind === EntryKind.Edit) {
            if (allValidIds(parsed.itemIds)) {
                return {
                    itemIds: parsed.itemIds,
                    kind: EntryKind.Edit,
                    customisation: parsed.customisation as FromCustomisation,
                    locationNames: parsed.locationNames as string[],
                };
            }
        }
    } catch (e) {
        Bugsnag.notify('Could not parse the JSON input from the url param', (event) => {
            event.addMetadata('errorObject', e);
        });
        return undefined;
    }

    return undefined;
}

function allValidIds(maybeArray): boolean {
    return Array.isArray(maybeArray) && maybeArray.length > 0 && maybeArray.every((id) => typeof id === 'number');
}
