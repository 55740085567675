import React from 'react';
import { Select as RxSelect } from '@wearejh/rx-form';
import { RxValidateFn } from '@wearejh/rx-form/lib/types';

import { ErrorFor } from './ErrorFor';

type SelectProps = {
    id?: string;
    field: string;
    label: string;
    validate?: RxValidateFn;
    options: { label: string; value: string; disabled?: boolean }[];
    required?: boolean;
    onChange?: (value) => void;
    value?: string;
    defaultValue?: string;
};

export const Select: React.FC<SelectProps> = ({
    label,
    id,
    field,
    validate,
    options,
    required,
    onChange,
    value,
    defaultValue,
}) => {
    return (
        <>
            <label htmlFor={id || field}>
                {label}
                {required && ' *'}
            </label>
            <RxSelect
                field={field}
                id={id || field}
                validate={validate}
                options={options}
                onChange={onChange}
                value={value}
                defaultValue={defaultValue}
            />
            <ErrorFor field={field} />
        </>
    );
};

Select.defaultProps = {
    required: false,
};
