import { from, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { catchable } from '@wearejh/swagger-rxjs/utils/ajax-helpers';
import { CartVariant } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { execute } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1DeleteCustomisationsPost';
import { execute as guestRemove } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendItemCustomisationRepositoryV1DeleteGuestCustomisationsPost';
import { Deps } from 'src/types/global-types';
import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';

import { Actions, BasketMsg, TypeMap } from '../basket.actions';

export function removeCustomisationEpic(
    action$: Observable<any>,
    state$: Observable<any>,
    deps: Deps,
): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Basket.RemoveCustomisations']>('Basket.RemoveCustomisations'),
        withLatestFrom(cartVariantAndId(state$)),
        switchMap(([{ payload }, [variant, cartId]]) => {
            const output = {
                removeCustomisations: payload.map((item) => {
                    return {
                        customisationId: item.customisation_id,
                        itemId: item.item_id,
                        locationName: item.location_name,
                    } as any;
                }),
            };

            const ajax$ =
                variant === CartVariant.Account
                    ? execute(output, deps)
                    : guestRemove({ cartId: String(cartId) }, output, deps);

            return ajax$.pipe(
                mergeMap(() => {
                    return from([
                        BasketMsg('Basket.RemoveCustomisationsSuccess'),
                        CartMsg('Cart.Refresh', { force: true }),
                        BasketMsg('Basket.Refresh'),
                    ]);
                }),
                catchable(({ error }) => {
                    return of(BasketMsg('Basket.RemoveCustomisationsError', error));
                }),
            );
        }),
    );
}
