import React from 'react';
import { Helmet } from 'react-helmet-async';
import { PageTitle } from 'src/components/Layout/PageTitle';
import { AccountNav } from 'src/components/Customer/AccountNav';

import classes from './CustomerAccountPage.scss';

type CustomerAccountPageProps = {
    name: string;
    child: React.ReactNode;
};

export const CustomerAccountPage: React.FC<CustomerAccountPageProps> = ({ name, child }) => {
    return (
        <>
            <Helmet>
                <title>{name}</title>
            </Helmet>
            <div className={classes.container}>
                <div className={classes.navContent}>
                    <PageTitle title="My Account" />
                    <AccountNav />
                </div>
                <div className={classes.mainContent}>{child}</div>
            </div>
        </>
    );
};
