import React, { HtmlHTMLAttributes, useContext } from 'react';
import { ConfigContext } from 'src/components/AppShell/ConfigProvider';

import classes from './SimplePrice.scss';

type SimplePriceProps = {
    price: string | number;
    priceIncl: string | number;
    priceHighest?: number;
    priceHighestIncl?: number;
    vat?: boolean;
} & HtmlHTMLAttributes<any>;

export const Price: React.FC<SimplePriceProps> = (props) => {
    const { price, priceIncl, priceHighest, priceHighestIncl, vat = false, ...rest } = props;
    const { amount, prefix, symbol, high, currencyCode } = usePrice(
        price,
        priceIncl ?? price,
        vat,
        priceHighest,
        priceHighestIncl,
    );

    return (
        <span {...rest}>
            {prefix}
            {symbol}
            <span itemProp="price">{amount} </span>
            {(priceHighest || priceHighestIncl) && high !== amount ? `- ${symbol}${high}` : ''}
            <meta itemProp="priceCurrency" content={currencyCode} />
        </span>
    );
};

type DisplayPrice = {
    prefix: string;
    symbol: string;
    amount: string;
    high: string;
    currencyCode: string;
};

export function usePrice(
    price: number | string,
    priceIncl: number | string = price,
    vat: boolean,
    priceHighest?: number,
    priceHighestIncl?: number,
): DisplayPrice {
    const roundNumber = (num: number) => {
        return Math.round((num + Number.EPSILON) * 100) / 100;
    };

    const { currentCurrency } = useContext(ConfigContext);
    const subjectPrice = vat ? priceIncl : price;
    const symbol = currentCurrency.symbol;
    const float = parseFloat(String(subjectPrice));
    const prefix = float < 0 ? '-' : '';
    const amount = Math.abs(roundNumber(float)).toLocaleString('en-EN', {
        minimumFractionDigits: 2,
    });
    const currencyCode = currentCurrency.code;

    const high = Math.abs(vat ? roundNumber(priceHighestIncl!) : roundNumber(priceHighest!)).toLocaleString('en-EN', {
        minimumFractionDigits: 2,
    });
    return { symbol, prefix, amount, high, currencyCode };
}

export function usePriceString(price: number | string, vat: boolean): string {
    const { symbol, prefix, amount } = usePrice(price, price, vat);
    return `${prefix}${symbol}${amount}`;
}

export const StyledPrice: React.FC<SimplePriceProps> = (props) => {
    return <Price className={classes.root} price={props.price} vat={props.vat} priceIncl={props.priceIncl} />;
};

export const StyledText: React.FC = (props) => {
    return <span className={classes.root}>{props.children}</span>;
};
