import React from 'react';
import { ApplicationType } from 'src/components/CustomisationPage/feature/customisation.types';
import { maybePdf } from 'src/util/formatUrl';
import { Artwork } from 'src/components/ArtworkApproval/ArtworkApprovalContext';
import {
    generateLogoPreview,
    generateTextPreview,
    getAllImages,
    getLastSample,
} from 'src/components/ArtworkApproval/utils/utils';
import { ProofGallery } from 'src/components/ArtworkApproval/ProofGallery';
import { ProofGalleryModal } from 'src/components/ArtworkApproval/ProofGalleryModal';

import classes from './ArtworkApproval.scss';

type Props = {
    artwork: Artwork;
    isModalProof?: boolean;
};

export const ArtworkApprovalProof: React.FC<Props> = ({ artwork, isModalProof }) => {
    const artworkImages = getAllImages(artwork.samples);

    const Component = isModalProof ? ProofGalleryModal : ProofGallery;

    return (
        <div className={classes.proof}>
            <h2 className={classes.proofHeader}>Artwork Proof</h2>
            <p>Preview</p>

            {artworkImages.length === 0 && (
                <>
                    <span>
                        <b>1 of 1</b>
                    </span>
                    <figure className={classes.figure}>
                        {artwork.artwork.application_type === ApplicationType.Text &&
                            generateTextPreview(artwork.artwork, getLastSample(artwork.samples))}
                        {artwork.artwork.application_type === ApplicationType.Logo &&
                            generateLogoPreview(maybePdf(artwork.artwork.image), getLastSample(artwork.samples))}
                    </figure>
                </>
            )}

            <Component artwork={artwork} />
        </div>
    );
};
