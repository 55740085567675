import React, { useCallback, useEffect } from 'react';
import { Form } from '@wearejh/rx-form/lib';
import { DataStatus, Message } from '@wearejh/m2-pwa-engine/lib/types';
import { useChangePassword } from '@wearejh/m2-pwa-user-edit/lib/hooks/useChangePassword';
import { PasswordParams } from '@wearejh/m2-pwa-user-edit/lib/user-password.register';
import { Link } from 'react-router-dom';
import { Actions } from 'src/components/Forms/Actions';
import { Messages } from 'src/components/Messages/Messages';
import { useDeps } from 'src/hooks/useDeps';
import { Field } from 'src/components/Forms/Field';
import { DefaultMeta } from 'src/components/AppShell/Head';

import { Input } from '../Forms/Input';
import { Block } from '../Helpers/Block';
import { basicPresenceValidation } from '../../util/forms';
import { PasswordConfirm } from '../SignIn/PasswordConfirm';

import { CustomerAccountPage } from './CustomerAccountPage';
import meta from './customer.json';

type Props = {
    changePassword(values: PasswordParams);
    messages: Message[];
    status: DataStatus;
};

export const ChangePasswordPage: React.FC<Props> = (props) => {
    const { paths } = useDeps();
    const { changePassword, status, messages } = props;
    const isPending = status === DataStatus.Pending;
    const isSuccess = status === DataStatus.Success;
    const submit = useCallback(
        (values) => {
            const payload: PasswordParams = {
                currentPassword: values.currentPassword,
                newPassword: values.password,
            };
            changePassword(payload);
        },
        [changePassword],
    );

    return (
        <>
            <DefaultMeta data={meta.helmet.password} />
            {messages.length > 0 && (
                <Block>
                    {messages.map((msg) => {
                        return <Messages key={msg.text} messages={[{ type: msg.type, text: msg.text }]} />;
                    })}
                </Block>
            )}
            <Form data-test-id="ChangePasswordForm" onSubmit={submit} className="card">
                <fieldset disabled={isPending || isSuccess}>
                    <Field>
                        <Input
                            field="currentPassword"
                            label="Current Password"
                            validate={basicPresenceValidation()}
                            type="password"
                        />
                    </Field>
                    <PasswordConfirm required={false} />
                    <Actions primaryText="Submit">
                        <Link className="button button--reset" to={paths.customer.accountSettings}>
                            Change Account information
                        </Link>
                    </Actions>
                </fieldset>
            </Form>
        </>
    );
};

export const ChangePasswordPageConnected: React.FC = () => {
    const { messages, status, reset, changePassword } = useChangePassword();
    useEffect(() => {
        reset();
    }, [reset]);
    return (
        <CustomerAccountPage
            name="Change Password"
            child={<ChangePasswordPage changePassword={changePassword} messages={messages} status={status} />}
        />
    );
};
