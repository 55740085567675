import { get } from 'lodash';
import { format } from 'date-fns';
import {
    statusMessagePartshipped,
    statusMessages,
    statusMessagesPartshipped,
} from 'src/components/Orders/utils/statusMessages';
import { UNKNOWN_VALUE } from 'src/components/Orders/utils/orderTrackingStaticValues';

export const useTrackingFoundDetails = (data, orderId) => {
    const dateFormat = 'dd MMMM, yyyy';

    const {
        orderStatus: {
            created_at: orderDateCreated = null,
            dispatch_date: orderDateDispatch = null,
            quantity: orderQuantity = UNKNOWN_VALUE,
            invoices: invoices = [],
            status: orderStatus = UNKNOWN_VALUE,
            consignment_ref: orderConsignmentNumber = UNKNOWN_VALUE,
        } = {},
    } = data || {};

    const isDuplicateInvoice =
        invoices.filter(
            ({ dispatch_date, quantity }) => dispatch_date && dispatch_date.length && quantity === orderQuantity,
        ).length > 0;

    let displayOrderStatus = statusMessages[orderStatus.toLowerCase()] || orderStatus || UNKNOWN_VALUE;
    if (orderConsignmentNumber !== UNKNOWN_VALUE && !statusMessagesPartshipped[orderStatus]) {
        displayOrderStatus = statusMessagePartshipped;
    }

    const orderDetails = {
        number: orderId,
        dateCreated: orderDateCreated ? format(new Date(orderDateCreated), dateFormat) : UNKNOWN_VALUE,
        dateDispatchExpected: orderDateDispatch ? format(new Date(orderDateDispatch), dateFormat) : UNKNOWN_VALUE,
        value: get(data, 'orderStatus.grand_total', 0).toFixed(2),
        status: displayOrderStatus,
        consignmentNumber: orderConsignmentNumber,
        quantity:
            orderQuantity === UNKNOWN_VALUE || isDuplicateInvoice
                ? orderQuantity
                : orderQuantity -
                  invoices.reduce((acc, { dispatch_date, quantity }) => {
                      if (dispatch_date && dispatch_date.length) {
                          return acc + quantity;
                      } else {
                          return acc;
                      }
                  }, 0),
    };

    const displayValue = (value: string) => {
        return value.length ? value : UNKNOWN_VALUE;
    };

    const pastShipmentsDetails = invoices
        .filter(({ dispatch_date }) => dispatch_date && dispatch_date.length)
        .map(({ dispatch_date, status, quantity }) => {
            const invoiceStatus = status !== null ? status : UNKNOWN_VALUE;
            return {
                dispatchDate:
                    dispatch_date && dispatch_date.length ? format(new Date(dispatch_date), dateFormat) : UNKNOWN_VALUE,
                status: statusMessages[invoiceStatus.toLowerCase()] || invoiceStatus || UNKNOWN_VALUE,
                quantity: quantity && quantity.length ? quantity : UNKNOWN_VALUE,
                dpdStatus: '',
            };
        });

    const isPastShipments = pastShipmentsDetails.length > 0 && !isDuplicateInvoice;

    return {
        orderDetails,
        isPastShipments,
        pastShipmentsDetails,
        displayValue,
        isDuplicateInvoice,
    };
};
