import { CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { BasketClearCartFn } from 'src/components/Basket/BasketContext';
import Bugsnag from '@bugsnag/js';

export const deleteCart = async (
    clearCartFn: BasketClearCartFn,
    cartId: string,
    deps,
    dispatch: React.Dispatch<any>,
) => {
    try {
        await clearCartFn({
            context: {
                headers: {
                    Authorization: `Bearer ${deps.restHeaders({}).Authorization}`,
                },
            },
            variables: { cartId },
        });
        dispatch(CartMsg('Cart.Refresh', { force: true }));
    } catch (error) {
        Bugsnag.notify(error);
    }
};
