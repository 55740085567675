import { ActionMap, createMsg } from 'action-typed';
import { DataStatus, Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';

import { removeCouponEpic } from './removeCoupon.epic';
import { applyCouponEpic } from './applyCoupon.epic';

export interface CouponState {
    status: DataStatus;
    messages: Message[];
}

const initialState: CouponState = {
    status: DataStatus.Idle,
    messages: [],
};

type Messages = {
    ['Coupon.Apply']: string;
    ['Coupon.ApplySuccess']: undefined;
    ['Coupon.ApplyError']: string;
    ['Coupon.Delete']: undefined;
    ['Coupon.DeleteSuccess']: undefined;
    ['Coupon.DeleteError']: string;
    ['Coupon.Reset']: undefined;
};

export type AppendState<T> = T & { coupon: CouponState };

function couponReducer(state = initialState, action: Actions): CouponState {
    switch (action.type) {
        case 'Coupon.Apply': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'Coupon.ApplySuccess': {
            return {
                ...state,
                status: DataStatus.Idle,
            };
        }
        case 'Coupon.ApplyError': {
            return {
                ...state,
                status: DataStatus.Idle,
                messages: [
                    {
                        type: MessageType.Error,
                        text: action.payload,
                    },
                ],
            };
        }
        case 'Coupon.Delete': {
            return {
                ...state,
                status: DataStatus.Pending,
                messages: [],
            };
        }
        case 'Coupon.DeleteSuccess': {
            return {
                ...state,
                status: DataStatus.Idle,
            };
        }
        case 'Coupon.DeleteError': {
            return {
                ...state,
                status: DataStatus.Idle,
                messages: [
                    {
                        type: MessageType.Error,
                        text: action.payload,
                    },
                ],
            };
        }
        default:
            return state;
    }
}

export function couponRegister() {
    return {
        epics: [removeCouponEpic, applyCouponEpic],
        name: 'coupon',
        reducers: { coupon: couponReducer },
    };
}

export const CouponMsg = createMsg<Messages>();
export type TypeMap = ActionMap<Messages>;
export type Actions = TypeMap[keyof TypeMap];
