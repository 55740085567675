import React from 'react';

import classes from './TwoUp.scss';

type Props = {
    left: React.ReactNode;
    right: React.ReactNode;
    className?: string;
};

export const TwoUp: React.FC<Props> = React.memo((props) => {
    const { className, left, right, ...propsRest } = props;
    return (
        <section className={[classes.root, className || ''].join(' ')} {...propsRest}>
            <div className={classes.left}>{left}</div>
            <div className={classes.right}>{right}</div>
        </section>
    );
});
