import { Observable } from 'rxjs';
import { ignoreElements, pluck, tap, withLatestFrom } from 'rxjs/operators';
import { CartState } from '@wearejh/m2-pwa-cart-gql/lib/index';
import { ofType } from 'redux-observable';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';
import { CartActions } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { v4 as uuidv4 } from 'uuid';
import { sendFbConversionEvent } from 'src/features/analytics/utils/sendFbConversionEvent';
import Bugsnag from '@bugsnag/js';
import { determineCustomisedItems } from 'src/features/analytics/utils/determineCustomisedItems';

import { upsellItems } from '../utils/upsellItems';

export function gtmBasket(action$: Observable<any>, state$: Observable<any>): Observable<never> {
    const $currentProduct: Observable<CartState['items']> = state$.pipe(pluck('cart', 'items'));
    const eventId = uuidv4();

    return action$.pipe(
        ofType<CartActions>('Cart.FetchSuccess'),
        withLatestFrom($currentProduct),
        tap(([_action, rawItems]) => {
            const items = determineCustomisedItems(rawItems);

            sendFbConversionEvent({
                data: [
                    {
                        event_name: 'InitiateCheckout',
                        event_time: Math.floor(Date.now() / 1000),
                        action_source: 'website',
                        event_id: eventId,
                        event_source_url: window.location.href,
                        user_data: {
                            external_id: [eventId],
                        },
                        custom_data: {
                            contents:
                                items &&
                                items.map((item) => {
                                    return {
                                        id: item.product.sku,
                                        quantity: item.quantity.toString(),
                                        customised: item.customised,
                                    };
                                }),

                            num_items: items
                                .map((item) => item.quantity)
                                .reduce((prev, next) => prev + next, 0)
                                .toString(),
                            value: items
                                .map((item) => item.prices.price * item.quantity)
                                .reduce((prev, next) => prev + next, 0)
                                .toString(),
                            content_type: 'product',
                        },
                    },
                ],
            }).catch((error) => {
                Bugsnag.notify(error);
            });

            fireGtm({
                event: 'eec.checkout',
                eecEventName: 'Checkout Basket',
                ecommerce: {
                    checkout: {
                        eventId: eventId,
                        actionField: {
                            step: 1,
                        },
                        products:
                            (items &&
                                items.map((item) => {
                                    return {
                                        id: item.product.sku,
                                        name: item.product.name,
                                        quantity: item.quantity.toString(),
                                        price: item.prices.price,
                                        upsell: upsellItems().includes(item.product.sku),
                                        customised: item.customised,
                                    };
                                })) ||
                            [],
                    },
                },
            });
        }),
        ignoreElements(),
    );
}
