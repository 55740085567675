import React, { useContext } from 'react';
import { BasketContext } from 'src/components/Basket/BasketContext';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { customisationRegister } from 'src/components/CustomisationPage/feature/customisation.register';
import { Price } from 'src/components/Helpers/Price';
import { BasketShareMachine } from 'src/components/BasketShared/BasketShareMachine';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { AppendBasketState } from 'src/components/Basket/feature/basket.actions';
import { formatDate } from 'src/util/formatDate';
import { BasketItem } from 'src/components/SavedBasket/BasketItem';
import { useMutation } from '@apollo/react-hooks';
import clearCart from 'src/queries/clearCart.graphql';
import { useDeps } from 'src/hooks/useDeps';

import { deleteCart } from './helpers/deleteCart';
import classes from './Basket.scss';

const selector = (s: AppendBasketState) => s.basket;

export const Basket: React.FC = React.memo(() => {
    const { basketItems, totals, cartId } = useContext(BasketContext);

    const { updatedAt } = useSelector(selector, shallowEqual);
    const deps = useDeps();
    const dispatch = useDispatch();

    const ready = useLazyRegister(customisationRegister, true, 'Customisation');

    const [clearCartFn] = useMutation(clearCart);

    if (!ready) return null;

    if (basketItems.length === 0)
        return (
            <div>
                <h3>Your basket is empty.</h3>
            </div>
        );

    return (
        <>
            <div className={classes.header}>
                <div className={classes.itemDetails}>
                    <div>
                        <p className={classes.medium}>Date saved</p>
                        <p>{updatedAt && formatDate(updatedAt)}</p>
                    </div>
                    <div>
                        <p className={classes.medium}>Total amount</p>
                        <span className={classes.price}>
                            <Price price={totals?.grand_total || 0} priceIncl={totals?.grand_total || 0} />
                        </span>
                    </div>
                </div>
                <div className={classes.buttons}>
                    {cartId && (
                        <>
                            <BasketShareMachine
                                cartId={cartId}
                                display="modal"
                                shareButtonContent={
                                    <>
                                        <img src="../../../static/img/share.svg" />
                                        Share
                                    </>
                                }
                            />
                            <button type="button" onClick={() => deleteCart(clearCartFn, cartId, deps, dispatch)}>
                                <img className={classes.icon} src="../../../static/img/delete.svg" />
                                Delete
                            </button>
                        </>
                    )}
                </div>
            </div>
            <div className={classes.items}>
                {basketItems.map((item, key) => (
                    <BasketItem key={key} item={item} />
                ))}
            </div>
            <div className={classes.separator} />
            <div className={classes.checkout}>
                <a href="/checkout">
                    <img className={classes.icon} src="../../../static/icons/basket.svg" />
                    Checkout now
                </a>
            </div>
        </>
    );
});
