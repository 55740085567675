import { EMPTY, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, pluck, switchMap, withLatestFrom } from 'rxjs/operators';
import { Deps } from 'src/types/global-types';
import { CartState } from 'src/util/test-utils';
import { searchToEntry } from 'src/components/CustomisationPage/utils/customisationsUrls';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { locationsForBasketItems } from 'src/components/Basket/utils/refreshBasket';
import { catchableUnauthenticated } from '@wearejh/m2-pwa-user/lib/utils/user.utils';
import Bugsnag from '@bugsnag/js';

import { Actions, AppendState, CustomisationMsg, EntryKind, TypeMap } from '../customisation.actions';
import { getCartItems } from '../../utils/cart';
import { Step } from '../customisation.types';

export function loadStep(action$: Observable<any>, state$: Observable<any>, deps: Deps): Observable<any> {
    const customisation$ = state$.pipe(pluck<AppendState, 'customisation'>('customisation'));
    const RETURN_TO_BASKET = of(deps.push(deps.paths.basket.basket));

    return action$.pipe(
        ofType<Actions, TypeMap['Customisation.LoadStep']>('Customisation.LoadStep'),
        withLatestFrom(getCartItems(state$), cartVariantAndId(state$), customisation$),
        switchMap(([{ payload }, cartItems, [variant, cartId], customisation]) => {
            /**
             *
             */
            if (!cartItems) {
                Bugsnag.notify('missing cart items');
                return RETURN_TO_BASKET;
            }

            switch (payload.step) {
                case Step.Position: {
                    /**
                     * Try to parse the input
                     */
                    const input = searchToEntry(payload.search || '');

                    if (!input) {
                        Bugsnag.notify('search param could not be parsed');
                        return RETURN_TO_BASKET;
                    }

                    if (!input.itemIds.every((id) => existsInCart(id, cartItems))) {
                        Bugsnag.notify('item id did not exist in the current cart');
                        return RETURN_TO_BASKET;
                    }

                    /**
                     * First, get fresh data about the customisations
                     */
                    return locationsForBasketItems({
                        itemIds: input.itemIds,
                        variant,
                        cartId: cartId!,
                        deps,
                    }).pipe(
                        mergeMap((match) => {
                            const takenLocations = match.customisations.map((el) => el.location_name);
                            switch (input.kind) {
                                case EntryKind.New: {
                                    const output = CustomisationMsg('Customisation.VerifyCustomisation', {
                                        entry: {
                                            itemIds: input.itemIds as number[],
                                            kind: EntryKind.New,
                                        },
                                        availableLocations: match.availableLocations,
                                        takenLocations: takenLocations,
                                    });
                                    return of(output);
                                }
                                case EntryKind.Edit: {
                                    const output = CustomisationMsg('Customisation.VerifyCustomisation', {
                                        entry: {
                                            itemIds: input.itemIds as number[],
                                            kind: EntryKind.Edit,
                                            customisation: input.customisation,
                                            locationNames: input.locationNames,
                                        },
                                        availableLocations: match.availableLocations,
                                        takenLocations: takenLocations,
                                    });
                                    return of(output);
                                }
                                default: {
                                    Bugsnag.notify("Didn't expect to get here!");
                                    return EMPTY;
                                }
                            }
                        }),
                        catchableUnauthenticated(({ error }) => {
                            Bugsnag.notify('could not load information for location', (event) => {
                                event.addMetadata('errorMsg', { errorMsg: error });
                            });
                            return of(deps.push(deps.paths.basket.basket));
                        }),
                    );
                }

                case Step.ApplicationType:
                case Step.ApplicationMethod:
                case Step.Config:
                case Step.Artwork: {
                    return verifyStep(customisation.item_ids, cartItems, payload.step, deps);
                }
                default: {
                    Bugsnag.notify('Could not match step');
                    return RETURN_TO_BASKET;
                }
            }
        }),
    );
}

function verifyStep(ids: number[] | null, cartItems: CartState['items'], step: Step, deps: Deps) {
    // check if item_id in STATE, matches a cart item
    if (ids === null || ids.length === 0 || !ids.every((id) => existsInCart(id, cartItems))) {
        Bugsnag.notify('id === null');
        return of(deps.push(deps.paths.basket.basket));
    }
    // Sets the status to success if the above passes
    const output = CustomisationMsg('Customisation.VerifyStep', step);

    return of(output);
}

export function existsInCart(id: string | number, cartItems: CartState['items']): boolean {
    return cartItems.some((item) => String(item.id) === String(id));
}
