import React from 'react';
import classnames from 'classnames';
import classes from 'src/components/Orders/OrderTracking.scss';

export const TrackingNotFound: React.FC = ({ children }) => (
    <div className={classnames(classes.orderNotFound, classes.modalSectionCard)}>
        <img className={classes.orderNotFoundIcon} src="../../../static/img/search-x.svg" alt="notFoundIcon" />
        <span className={classes.orderNotFoundMessage}>
            Oops, We can't seem to find any order matching the details you entered
        </span>
        {children}
    </div>
);
