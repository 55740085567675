import { useMemo } from 'react';
import { DirectoryDataCountryInformationInterface } from '@wearejh/swagger-rxjs/lib/Definitions';
import { useFieldValue } from '@wearejh/rx-form/lib/hooks/useFieldValue';
import { Fields } from '@wearejh/m2-pwa-addresses/lib/addresses.types';
import { useCountries } from '@wearejh/m2-pwa-addresses/lib/hooks/useCountries';

export const useRegionField = (countryId?: string) => {
    const countryField = useFieldValue(Fields.country_id);

    const computedCountryField = countryId ? countryId : countryField;

    const { countries } = useCountries();

    const countryMap = useMemo(() => {
        const obj = {};
        countries.forEach((c) => {
            obj[c.id] = c;
        });
        return obj;
    }, [countries]);

    const hasCountrySelected = typeof computedCountryField === 'string';
    const selectedCountry: DirectoryDataCountryInformationInterface =
        hasCountrySelected && countryMap[computedCountryField];

    const hasRegionSelect =
        selectedCountry &&
        Array.isArray(selectedCountry.available_regions) &&
        selectedCountry.available_regions.length > 0;

    return {
        hasRegionSelect,
        selectedCountry,
    };
};
