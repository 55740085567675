import { switchMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, Observable, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { cartVariantAndId } from '@wearejh/m2-pwa-cart-gql/lib/utils/stateObservables';
import { CartVariant } from '@wearejh/m2-pwa-cart-gql/lib/cart.reducer';
import { execute as fetchMine } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendExistingCustomisationsRepositoryV1GetExistingCustomisationsWithCartGet';
import { execute as fetchGuest } from 'swagger/ts/WorkwearExpressCustomisationPlatformFrontendExistingCustomisationsRepositoryV1GetExistingCustomisationsWithCartForGuestGet';
import { Deps } from 'src/types/global-types';
import { catchableUnauthenticated } from '@wearejh/m2-pwa-user/lib/utils/user.utils';
import Bugsnag from '@bugsnag/js';

import { ArtworkActions, ArtworkMsg, TypeMap } from '../artwork.register';

export function artworkFetch(action$: Observable<any>, state$: Observable<any>, deps: Deps) {
    return action$.pipe(
        ofType<ArtworkActions, TypeMap['ArtworkGrid.Fetch']>('ArtworkGrid.Fetch'),
        withLatestFrom(cartVariantAndId(state$), (_action, variantAndId) => variantAndId),
        switchMap(([variant, cartId]) => {
            if (!cartId) {
                Bugsnag.notify('cartId was missing');
                return EMPTY;
            }

            const ajax =
                variant === CartVariant.Account ? fetchMine(deps) : fetchGuest({ cartId: String(cartId) }, deps);

            return ajax.pipe(
                switchMap((resp) => {
                    return of(ArtworkMsg('ArtworkGrid.FetchSuccess', resp));
                }),
                catchableUnauthenticated(({ error }) => {
                    return of(ArtworkMsg('ArtworkGrid.FetchError', error));
                }),
            );
        }),
    );
}
