import { AppendState, Msg, ordersRegister } from 'src/features/app/orders.register';
import React from 'react';
import { LazyRedux } from '@wearejh/m2-pwa-engine/lib/components/index';
import { StoreState } from 'src/types/global-types';

function stateSelector(state: AppendState<StoreState>) {
    return {
        orders: state.orders.orders,
        ordersStatus: state.orders.status,
    };
}

const INIT = Msg('Orders.FetchOrders');
const EMPTY_OBJ = {};
export const OrdersConnected: React.FC<{ component: React.FC<any> }> = React.memo((props) => {
    return (
        <LazyRedux
            register={ordersRegister}
            initial={INIT}
            selector={stateSelector}
            Component={props.component}
            initialProps={EMPTY_OBJ}
        />
    );
});
