import React, { useCallback, useMemo, useEffect } from 'react';
import { Form } from '@wearejh/rx-form/lib/RxForm';
import { useCustomerStatus } from '@wearejh/m2-pwa-user/lib/hooks/useCustomerStatus';
import { Fields } from '@wearejh/m2-pwa-user/lib/user.types';
import { Message } from '@wearejh/m2-pwa-engine/lib/types';
import { basicPresenceValidation } from 'src/util/forms';
import { useLazyRegister } from '@wearejh/m2-pwa-engine/lib/hooks/useRegisterFeature';
import { gtmLogin } from 'src/features/analytics/epics/gtmLogin';
import { errorMessage, successMessage } from 'src/util/state-helpers';
import { useQuery } from '@apollo/react-hooks';
import { useDeps } from 'src/hooks/useDeps';
import { getMagicLinkFlag } from 'src/queries/__generated__/getMagicLinkFlag';
import { get } from 'lodash';
import Bugsnag from '@bugsnag/js';
import { fireGtm } from 'src/features/analytics/hooks/useGtm';
import { shallowEqual, useSelector } from 'react-redux';
import { AppendState as UserAppendState } from '@wearejh/m2-pwa-user';
import { AppendState } from '@wearejh/m2-pwa-user-edit/lib/user-edit.register';

import { ModalHeader } from '../Modal/ModalHeader';
import { ModalBody } from '../Modal/ModalBody';
import { Input } from '../Forms/Input';
import { Actions } from '../Forms/Actions';
import { ModalFooter } from '../Modal/ModalFooter';
import { Modal } from '../Modal/Modal';
import { Fieldset } from '../Forms/Fieldset';
import { Messages } from '../Messages/Messages';
import { Block } from '../Helpers/Block';
import { Button, ButtonProps } from '../Button/Button';
import getMagicLinkFlagQuery from '../../queries/getMagicLinkFlag.graphql';

import classes from './SignInModal.scss';

export type SignInModalProps = {
    close();
    forgot();
    register();
    magicLink();
};

export const SignInModal: React.FC<SignInModalProps> = (props) => {
    return (
        <Modal type="popup" isOpen={true} onDismiss={props.close}>
            <ModalHeader title="Log in" icon="account" onDismiss={props.close} />
            <ModalBody>
                <SignInConnected
                    forgot={props.forgot}
                    register={props.register}
                    magicLink={props.magicLink}
                    showRegister={true}
                    routerMessages={[]}
                />
            </ModalBody>
            <ModalFooter onDismiss={props.close} />
        </Modal>
    );
};

export default SignInModal;

type SignInProps = {
    signIn(params: { username: string; password: string; allowRedirects?: boolean });
    forgot();
    register();
    magicLink();
    messages: Message[];
    isPending: boolean;
    showRegister?: boolean;
};

const selector = (s: AppendState<UserAppendState>) => {
    return {
        id: s.user.data?.id,
    };
};

export const SignIn: React.FC<SignInProps> = (props) => {
    const { id } = useSelector(selector, shallowEqual);
    const { loading, error, data } = useQuery<getMagicLinkFlag>(getMagicLinkFlagQuery, {
        ssr: useDeps().env.SSR_GQL,
    });
    const enabledMagicLink = get(data, 'isPasswordlessLoginEnabled.isEnabled', false);

    const { signIn } = props;
    const onSubmit = useCallback(
        (values) => {
            signIn({
                password: values[Fields.password],
                username: values[Fields.email],
                allowRedirects: false,
            });
        },
        [signIn],
    );

    useEffect(() => {
        fireGtm({ event: 'login', userId: String(id) || 'null' });
    }, [id]);

    const postInfo = () => {
        const postInfo: {
            text: string;
            type: string;
        }[] = [];

        if (props.messages.length > 0) {
            props.messages.map((msg) => {
                if (msg.type === 'Error') {
                    postInfo.push({
                        text: `We don't recognise this email address or password. Please check your details and try again`,
                        type: 'Error',
                    });
                }

                if (msg.type === 'Success') {
                    postInfo.push({
                        text: msg.text,
                        type: 'Success',
                    });
                }
            });
        }
        return postInfo;
    };

    return (
        <div className={classes.signInModal}>
            <div className={classes.border} />
            {postInfo().length > 0 &&
                postInfo().map((msg) => {
                    const msgType = msg.type === 'Error' ? errorMessage(msg.text) : successMessage(msg.text);
                    return (
                        <Block key={msg.text}>
                            <Messages messages={[msgType]} />
                        </Block>
                    );
                })}
            <Form onSubmit={onSubmit} data-test-id="SignInForm">
                <Fieldset disabled={props.isPending}>
                    <Input
                        label="Email Address"
                        field={Fields.email}
                        id={`modal-${Fields.email}`}
                        validate={basicPresenceValidation()}
                        required={true}
                    />
                    <Input
                        label="Password"
                        field={Fields.password}
                        id={Fields.password}
                        type="password"
                        validate={basicPresenceValidation()}
                        required={true}
                    />
                    <div className={classes.actions}>
                        <div className={classes.passwordActions}>
                            <Button variant="reset" onClick={props.forgot}>
                                Forgot Password?
                            </Button>
                            <MagicLinkButtonRender
                                loading={loading}
                                error={error}
                                enabledMagicLink={enabledMagicLink}
                                magicLink={props.magicLink}
                            />
                        </div>
                        <Actions primaryText="Login">
                            {props.showRegister && (
                                <Button variant="bold" onClick={props.register} data-test-id="AccountIcon_register">
                                    Register
                                </Button>
                            )}
                        </Actions>
                    </div>
                </Fieldset>
            </Form>
        </div>
    );
};

type redicrectProps = Pick<ButtonProps, 'element' | 'to' | 'onClick'>;

const MagicLinkButtonRender = ({ error, loading, enabledMagicLink, magicLink }) => {
    if (error) {
        Bugsnag.notify(error);
    }
    if (loading) {
        <p>...</p>;
    }
    if (enabledMagicLink) {
        const isLoginpage = window.location.pathname.includes('login');
        let redirectProps: redicrectProps = {
            element: 'a',
            to: '/customer/account/passwordless',
        };
        if (!isLoginpage) {
            redirectProps = {
                onClick: magicLink,
            };
        }
        return (
            <div className={classes.magicLink}>
                <Button variant="reset" {...redirectProps}>
                    Passwordless Login
                </Button>
            </div>
        );
    }
    return <div></div>;
};

type SignInConnectedProps = {
    forgot();
    register();
    magicLink();
    showRegister?: boolean;
    routerMessages: Message[];
};

const EMPTY_ARR = [];

export const SignInConnected: React.FC<SignInConnectedProps> = React.memo((props) => {
    useLazyRegister(signInRegister, true, 'SignInConnected');
    const { signIn, messages, isPending } = useCustomerStatus();
    const displayMessages = messages.length > 0 ? messages : props.routerMessages || EMPTY_ARR;
    const validMessages = useMemo(() => {
        return displayMessages.filter((msg) => Boolean(msg.text));
    }, [displayMessages]);
    return (
        <SignIn
            messages={validMessages}
            signIn={signIn}
            forgot={props.forgot}
            magicLink={props.magicLink}
            register={props.register}
            showRegister={props.showRegister}
            isPending={isPending}
        />
    );
});

function signInRegister() {
    return {
        name: 'signInConnected',
        epics: [gtmLogin],
        reducers: {
            signInConnected: (s = {}) => s,
        },
    };
}
