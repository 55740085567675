import React, { useState } from 'react';
import { ShareBasketViaEmailForm } from 'src/components/BasketShared/ShareBasketViaEmailForm';
import { CopyBasketLink } from 'src/components/BasketShared/CopyBasketLink';
import { UserKind } from 'src/components/BasketShared/ShareBasketViaEmailForm';
import classes from 'src/components/BasketShared/BasketShared.scss';
import { Svg } from 'src/components/Svg/Svg';
import { Button } from 'src/components/Button/Button';
import { CloseButton } from 'src/components/BundleProduct/CloseButton';
import { getBasketLink } from 'src/components/BasketShared/utils/getBasketLink';
import { Modal } from 'src/components/Modal/Modal';

export type BasketShareMachineValues = {
    name: string;
    creatorEmail: string;
    collaboratorEmail: string;
    message: string;
};

type Props = {
    onCancel: () => void;
    onSubmit: (values: BasketShareMachineValues, isSharedEmail?: boolean) => void;
    isPending: boolean;
    errors: string | null;
    userKind: UserKind;
    cartId: string;
};

export enum formTypes {
    chooseForm = 'choose',
    sendLink = 'send',
    copyLink = 'copy',
}

const generateLink = async (cartId, setBasketLink, setFormType) => {
    const basketLink = await getBasketLink(cartId);

    if (basketLink) {
        setBasketLink(basketLink);
        setFormType(formTypes.copyLink);
    }
};

const WWE_TEAM_EMAIL = 'sales@workwearexpress.com';

export const ShareForm: React.FC<Props> = ({ onCancel, onSubmit, isPending, errors, userKind, cartId }) => {
    const [formType, setFormType] = useState(formTypes.chooseForm);
    const [basketLink, setBasketLink] = useState();
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [initialValue, setInitialValue] = useState<string | undefined>(undefined);

    return (
        <div className={classes.shareFormContainer}>
            <CloseButton
                onClick={onCancel}
                background="gray"
                className={classes.cancelButton}
                data-testid="cancelButton"
            />
            <h2 className={classes.title}>
                <Svg name="save-cart" /> Share your basket
            </h2>

            {formType === formTypes.chooseForm && (
                <div className={classes.formContainer}>
                    <p>
                        <b>Choose a way to share your basket:</b>
                    </p>
                    <div className={classes.initialButtonsContainer}>
                        <div className={classes.shareButton}>
                            <Button onClick={() => setIsOpenModal(true)} variant="primary">
                                Share with our sales team
                            </Button>
                        </div>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setFormType(formTypes.sendLink);
                                setInitialValue(undefined);
                            }}
                        >
                            Send Link via Email
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => {
                                generateLink(cartId, setBasketLink, setFormType);
                                setInitialValue(undefined);
                            }}
                        >
                            Generate Link to Copy
                        </Button>
                    </div>
                </div>
            )}

            {formType === formTypes.sendLink && (
                <ShareBasketViaEmailForm
                    onSubmit={onSubmit}
                    isPending={isPending}
                    errors={errors}
                    userKind={userKind}
                    setFormType={setFormType}
                    initialValue={initialValue}
                />
            )}

            {formType === formTypes.copyLink && <CopyBasketLink setFormType={setFormType} basketLink={basketLink} />}

            <Modal
                className={classes.sharePopup}
                type="popup"
                isOpen={isOpenModal}
                onDismiss={() => setIsOpenModal(false)}
            >
                <p>Are you sure that you want to share your basket with our sales team?</p>
                <div className={classes.sharePopupButtons}>
                    <Button
                        onClick={() => {
                            setInitialValue(WWE_TEAM_EMAIL);
                            setIsOpenModal(false);
                            setFormType(formTypes.sendLink);
                        }}
                        variant="primary"
                    >
                        Yes
                    </Button>
                    <Button onClick={() => setIsOpenModal(false)} variant="primary">
                        No
                    </Button>
                </div>
            </Modal>
        </div>
    );
};
