import React, { useCallback, useState } from 'react';
import { Text } from '@wearejh/rx-form';
import { useFormApi } from '@wearejh/rx-form/lib/hooks/useFormApi';
import classnames from 'classnames';

import { DropZoneState } from '../Forms/DropZone';
import { Button } from '../Button/Button';
import { Svg } from '../Svg/Svg';
import { ErrorFor } from '../Forms/ErrorFor';
import { basicPresenceValidation } from '../../util/forms';

import classes from './LogoUploader.scss';

export type Img = {
    url: string | undefined;
    fileName: string | undefined;
    fieldPrefix?: string;
    isBundleView?: boolean;
};

type Props = {
    fileName: string | undefined;
    url: string | undefined;
    setImage(img: Img);
    removeImage();
    isBundleView?: boolean;
};

export const LogoUploader: React.FC<Props> = ({ fileName, url, setImage, removeImage, isBundleView }) => {
    if (!fileName && !url) {
        return <DropZoneState setImage={setImage} isBundleView={isBundleView} />;
    }

    const isEpsFile = url && url.includes('.eps');
    const epsPlaceholderUrl = '/static/img/epsPlaceholder.png';

    return (
        <div className={classes.preview}>
            {url && url.indexOf('.pdf') === -1 && url.indexOf('.ai') === -1 && (
                <div className={classes.imgWrapper}>
                    {' '}
                    <img src={isEpsFile ? epsPlaceholderUrl : url} />
                </div>
            )}
            <div className={classes.info} data-test-id="LogoUploader_filename">
                {fileName && fileName.indexOf('http') === -1 && <span>{fileName}</span>}
            </div>
            <div className={classes.cta}>
                <Button icon="left-of-text" variant="reset-warn" onClick={removeImage}>
                    <Svg name="remove" />
                    <span>Delete Logo</span>
                </Button>
            </div>
        </div>
    );
};

export const LogoUploaderFormConnector: React.FC<Img> = (props) => {
    const formApi = useFormApi();
    const fieldName = `${props.fieldPrefix}image`;
    const [image, setImage] = useState<Img>(props);

    const onSetImage = useCallback(
        (img: Img) => {
            setImage(img);
            formApi.setValue(fieldName, img.url);
        },
        [fieldName, formApi],
    );
    const onRemoveImage = useCallback(() => {
        setImage({ url: undefined, fileName: undefined });
        formApi.setValue(fieldName, '');
    }, [fieldName, formApi]);

    return (
        <div className={classnames({ [classes.wrapper]: !props.isBundleView })}>
            <LogoUploader
                setImage={onSetImage}
                fileName={image.fileName}
                url={image.url}
                removeImage={onRemoveImage}
                isBundleView={props.isBundleView}
            />

            <p className={classes.dropzoneAdvice}>
                Don’t worry how it looks, we will make it look great and send a proof before we add to your products!
            </p>
            <Text field={fieldName} validate={basicPresenceValidation('Please upload your logo')} hidden />
            <ErrorFor field={fieldName} />
        </div>
    );
};

LogoUploaderFormConnector.defaultProps = { fieldPrefix: '' };
