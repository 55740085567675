import React from 'react';

import { OrderDetailItem, OrderDetailItemRow, OrderDetailItemRowLoading } from './OrderDetailItem';
import classes from './OrderItems.scss';

type OrderItemsProps = {
    items: OrderDetailItem[];
    loading?: boolean;
};

export const OrderItems: React.FC<OrderItemsProps> = (props) => {
    return (
        <div data-test-id="OrderDetailTable">
            <h2>Your Items</h2>
            <ul className={classes.orders}>
                {props.loading &&
                    [...Array(4).keys()].map((i) => {
                        return <OrderDetailItemRowLoading key={i} />;
                    })}
                {!props.loading &&
                    props.items.map((item) => {
                        return <OrderDetailItemRow {...item} key={item.sku} />;
                    })}
            </ul>
        </div>
    );
};
