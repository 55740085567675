import React, { useCallback, useEffect, useMemo } from 'react';
import classes from 'src/components/CustomisationPage/pages/Pages.scss';
import { CustomCheckbox } from 'src/components/CustomisationPage/CustomCheckbox';
import { RadioGroup, Text } from '@wearejh/rx-form';
import { useFormState } from '@wearejh/rx-form/lib/hooks/useFormState';
import { ARTWORK_FORM_LOCATION_PREFIX } from 'src/components/BundleProduct/bundle-product.types';
import { ErrorFor } from 'src/components/Forms/ErrorFor';
import { useFormApi } from '@wearejh/rx-form/lib/hooks/useFormApi';
import { useFieldValue } from '@wearejh/rx-form/lib/hooks/useFieldValue';
import { ApplicationMethod, ProductLocation } from 'src/components/CustomisationPage/feature/customisation.types';
import { usePositions } from 'src/components/CustomisationPage/hooks/usePositions';
import { BackgroundStyles } from 'src/components/BundleProduct/MultipleStepCustomisation/customisations.types';

type PositionFieldsetProps = {
    locations: ProductLocation[];
    isBundle?: boolean;
    alreadyUsedLocations: string[];
    fieldIndex?: number;
    selectedNr?: number;
    oneOptionSelect?: boolean;
    backgroundStyles?: BackgroundStyles;
    avoidedSelectedPosition?: string;
    freeCustomisation?: boolean;
};
export const PositionFieldset: React.FC<PositionFieldsetProps> = ({
    fieldIndex,
    locations,
    isBundle,
    selectedNr,
    alreadyUsedLocations,
    oneOptionSelect,
    backgroundStyles,
    avoidedSelectedPosition,
    freeCustomisation,
}) => {
    const state = useFormState();
    const methodType = usePositions(locations, state);
    let computedLocations = locations;

    const logoLocations = locations.map((position) => {
        const isDisabled = () => {
            // If a position is used in other logo, make this position disabled
            if (alreadyUsedLocations.some((el) => el === position.name)) {
                computedLocations = computedLocations.filter((location) => location.name !== position.name);
                return true;
            }
            if (!freeCustomisation && position.name === avoidedSelectedPosition) {
                computedLocations = computedLocations.filter((location) => location.name !== position.name);
                return true;
            }

            if (methodType === ApplicationMethod.Print) {
                if (position.method === ApplicationMethod.Both) {
                    return false;
                }
                if (position.method === ApplicationMethod.Print) {
                    return false;
                }
                if (position.method === ApplicationMethod.Embroidery) {
                    computedLocations = computedLocations.filter((location) => location.method !== position.method);
                    return true;
                }
            } else if (methodType === ApplicationMethod.Embroidery) {
                if (position.method === ApplicationMethod.Both) {
                    return false;
                }
                if (position.method === ApplicationMethod.Print) {
                    computedLocations = computedLocations.filter((location) => location.method !== position.method);
                    return true;
                }
                if (position.method === ApplicationMethod.Embroidery) {
                    return false;
                }
            } else {
                return false;
            }
        };

        return (
            <CustomCheckbox
                fieldIndex={fieldIndex}
                key={position.name}
                field={position.name}
                id={position.name}
                image={position.image_url}
                title={position.name}
                availableMethods={position.method}
                isBundle={isBundle}
                oneOptionSelect={oneOptionSelect}
                isDisabled={isDisabled()}
                data-test-id="Position_item"
            />
        );
    });

    if (isBundle) {
        return (
            <div className={classes.contentBundle}>
                <RadioGroup
                    field={fieldIndex ? `${fieldIndex}.${ARTWORK_FORM_LOCATION_PREFIX}` : ARTWORK_FORM_LOCATION_PREFIX}
                    initialValue={computedLocations[0].name}
                >
                    {logoLocations}
                </RadioGroup>
            </div>
        );
    }

    return (
        <div className={classes.positionBackground} style={backgroundStyles}>
            {oneOptionSelect ? (
                <RadioGroup
                    field={fieldIndex ? `${fieldIndex}.${ARTWORK_FORM_LOCATION_PREFIX}` : ARTWORK_FORM_LOCATION_PREFIX}
                    initialValue={computedLocations[0].name}
                >
                    <div className={classes.content}>{logoLocations}</div>
                </RadioGroup>
            ) : (
                <>
                    <span
                        className={classes.positionSubtitle}
                    >{`${selectedNr} position(s) selected (applied to all)`}</span>
                    <div className={classes.content}>{logoLocations}</div>
                </>
            )}
        </div>
    );
};

const EMPTY_OBJ = {};
const MIRROR_NAME = 'locations-mirror';
/**
 * This will validate when only a single location can be used
 * @constructor
 */
export const PositionValidationSingle: React.FC<{ fieldIndex?: number }> = ({ fieldIndex }) => {
    const value = useFieldValue(`${fieldIndex}.${ARTWORK_FORM_LOCATION_PREFIX}`);
    const api = useFormApi();

    /**
     * Mirror the changes from 'names' into this field
     */
    useEffect(() => {
        api.setValue(fieldIndex ? `${fieldIndex}.${MIRROR_NAME}` : MIRROR_NAME, value);
    }, [api, value, fieldIndex]);

    /**
     * The validate fn
     */
    const validate = useCallback((value) => {
        if (!value) return 'Select a location';
        return undefined;
    }, []);

    return (
        <>
            <Text
                field={fieldIndex ? `${fieldIndex}.${MIRROR_NAME}` : MIRROR_NAME}
                validate={validate}
                validateOnChange={true}
                hidden
            />
            <ErrorFor field={fieldIndex ? `${fieldIndex}.${MIRROR_NAME}` : MIRROR_NAME} />
        </>
    );
};

export const PositionValidation: React.FC<{ fieldIndex?: number }> = ({ fieldIndex }) => {
    const v = useFormState();
    const api = useFormApi();

    /**
     * The locations-sub-key
     */
    const locations = (v && v.values[`${fieldIndex}.${ARTWORK_FORM_LOCATION_PREFIX}`.slice(0, -1)]) || EMPTY_OBJ;

    /**
     * A list of names, like ["Left Sleeve", "Right Breast"]
     */
    const names = useMemo(() => {
        return Object.keys(locations).filter((key) => locations[key] === true);
    }, [locations]);

    /**
     * Mirror the changes from 'names' into this field
     */
    useEffect(() => {
        api.setValue(`${fieldIndex}.${MIRROR_NAME}`, JSON.stringify(names));
    }, [api, names, fieldIndex]);

    /**
     * The validate fn
     */
    const validate = useCallback((value) => {
        if (!value) return 'Select at least 1 location';
        if (value === '[]') return 'Select at least 1 location';
        return undefined;
    }, []);
    return (
        <>
            <Text field={`${fieldIndex}.${MIRROR_NAME}`} validate={validate} validateOnChange={true} hidden />
            <ErrorFor field={`${fieldIndex}.${MIRROR_NAME}`} />
        </>
    );
};
