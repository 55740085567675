import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { LazyImg } from 'src/hooks/useLazyLoad';
import { imagePath } from 'src/util/formatUrl';
import { CartItem } from 'src/types/global-types';
import { Button } from 'src/components/Button/Button';
import { BasketContext } from 'src/components/Basket/BasketContext';

import classes from './BasketProduct.scss';

type Props = {
    cartItem: CartItem;
    isPending?: boolean;
    isBundle?: boolean;
};

export const BasketProduct: React.FC<Props> = ({ cartItem, isPending, isBundle }) => {
    const { pathname, small_image, sku, name } = cartItem.product;
    const { deleteItem } = useContext(BasketContext);

    const getProductOptions = (cartItem: CartItem) => {
        if (cartItem.__typename === 'ConfigurableCartItem') {
            return cartItem.configurable_options
                .map((option) => {
                    if (option.option_label === 'Sizing') {
                        return option.value_label;
                    } else {
                        return option.value_label.replace(/\//g, ',');
                    }
                })
                .join(', ');
        } else if (cartItem.__typename === 'BundleCartItem') {
            return cartItem.bundle_options
                .map((option) =>
                    option.values
                        .map(
                            (value) =>
                                `${value.details?.color.value}, ${value.details?.size}${
                                    value.quantity && `x${value.quantity}`
                                }`,
                        )
                        .join(' | '),
                )
                .join(', ');
        }
    };

    const getProductOptionsMobile = (cartItem: CartItem) => {
        if (cartItem.__typename === 'ConfigurableCartItem') {
            return cartItem.configurable_options.map((option) => {
                if (option.option_label === 'Sizing') {
                    return <p>{option.value_label}</p>;
                } else {
                    return <p>{option.value_label.replace(/\//g, ',')}</p>;
                }
            });
        } else if (cartItem.__typename === 'BundleCartItem') {
            return cartItem.bundle_options.map((option) =>
                option.values.map((value) => (
                    <p key={value.id}>{`${value.details?.color.value}, ${value.details?.size}${
                        value.quantity && `x${value.quantity}`
                    }`}</p>
                )),
            );
        }
    };

    return (
        <div className={classes.basketProductContainer}>
            <div className={classes.basketProduct}>
                <figure className={classes.image}>
                    <LazyImg src={imagePath(small_image.url)} alt={small_image.label} />
                </figure>
                <div className={classes.info}>
                    <h3 className={classes.title}>
                        <Link to={'/' + pathname}>{name}</Link>
                    </h3>
                    <div className={classes.meta}>
                        <span>{sku}</span>
                        {!isBundle && getProductOptions(cartItem) && (
                            <>
                                <span className={classes.qtyAndSize}>{getProductOptions(cartItem)}</span>
                                <span className={classes.qtyAndSizeMobile}>{getProductOptionsMobile(cartItem)}</span>
                            </>
                        )}
                        <Button
                            type="button"
                            variant="reset"
                            disabled={isPending}
                            onClick={() => deleteItem({ itemId: Number(cartItem.id)! })}
                            className={classes.basketItemRemove}
                        >
                            Remove
                        </Button>
                    </div>
                </div>
            </div>
            <Button
                type="button"
                variant="reset"
                disabled={isPending}
                onClick={() => deleteItem({ itemId: Number(cartItem.id)! })}
                className={classes.basketItemRemoveMobile}
            >
                Remove
            </Button>
        </div>
    );
};
