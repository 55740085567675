import React from 'react';
import { CustomerDataAddressInterface } from 'swagger/ts/Definitions';
import { Svg } from 'src/components/Svg/Svg';

import { Button } from '../Button/Button';

import { Address } from './Address';
import classes from './Address.scss';

type Props = {
    title: string;
    address: CustomerDataAddressInterface;
    edit(address: CustomerDataAddressInterface);
};

export const AddressHeading: React.FC<Props> = (props) => {
    return (
        <>
            <h3 className="label-heading">{props.title}</h3>
            <div className={classes.address}>
                <Address address={props.address} />

                <Button
                    icon="left-of-text"
                    variant="reset"
                    data-test-id="EditAddress"
                    className={classes.edit}
                    onClick={() => props.edit(props.address)}
                >
                    <Svg name="edit" />
                    <span>Edit</span>
                </Button>
            </div>
        </>
    );
};
