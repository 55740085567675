import { MediaEntry } from '@wearejh/m2-pwa-cart-gql/lib';
import {
    getCart_getCart,
    getCart_getCart_items,
    getCart_getCart_items_ConfigurableCartItem,
} from 'src/queries/__generated__/getCart';
import { imagePath } from 'src/util/formatUrl';

export type CartItemImages = Map<string, MediaEntry>;
const MAP_IMAGES: CartItemImages = new Map();
export const EMPTY_IMAGE: MediaEntry = { label: 'unknown', url: 'https://placehold.it/200' };

export function itemImagesFromQuery(raw: getCart_getCart | null): CartItemImages {
    if (!raw) return MAP_IMAGES;
    if (!raw?.items) return MAP_IMAGES;
    if (raw.items.length === 0) return MAP_IMAGES;
    return new Map(
        raw.items.map((item) => {
            if (!item) throw new Error('null found in cart item');
            return [item!.id, getImageForItem(item)];
        }),
    );
}

export function getImageForItem<T extends getCart_getCart_items = getCart_getCart_items>(item: T): MediaEntry {
    if (!item) throw new Error('null found in cart item');

    if (item.__typename !== 'ConfigurableCartItem') {
        return (item.product.small_image as MediaEntry) || EMPTY_IMAGE;
    }

    const entries = (item as getCart_getCart_items_ConfigurableCartItem)?.configured_variant?.media_gallery;

    if (entries && entries.length > 0) {
        return {
            url: imagePath(entries[0]!.small_url) || 'unknnown',
            label: item.product.small_image?.label || 'unknown',
        };
    }
    return (item.product.small_image as MediaEntry) || EMPTY_IMAGE;
}
