import React, { useCallback, useEffect, useState } from 'react';
import { Form } from '@wearejh/rx-form/lib';
import { DataStatus, Message, MessageType } from '@wearejh/m2-pwa-engine/lib/types';
import { Link } from 'react-router-dom';
import { Actions } from 'src/components/Forms/Actions';
import { useDeps } from 'src/hooks/useDeps';
import { CustomerAccountPage } from 'src/components/Customer/CustomerAccountPage';
import { Field } from 'src/components/Forms/Field';
import { DefaultMeta } from 'src/components/AppShell/Head';
import { ToggleSwitch } from 'src/components/ToggleSwitch/ToggleSwitch';

import { Messages } from '../Messages/Messages';
import { Block } from '../Helpers/Block';

import { useEditUser } from './utils/useEditUser';
import { EmailField, FirstNameField, LastNameField } from './AddressFields';
import meta from './customer.json';
import classes from './AccountInformationPage.scss';

type EditFields = {
    firstname?: string;
    lastname?: string;
    email?: string;
    extensionAttributes?: { is_subscribed?: boolean; customer_order_statuses_notification: boolean };
};

type Props = {
    edit(params: EditParams);
    initialValues: EditFields;
    messages: Message[];
    status: DataStatus;
};

export const AccountInformationPage: React.FC<Props> = ({ initialValues, messages, status, edit }) => (
    <>
        <DefaultMeta data={meta.helmet.information} />
        <InformationForm initialValues={initialValues} messages={messages} status={status} onSubmit={edit} />
    </>
);

type EditParams = {
    firstname?: string;
    lastname?: string;
    email?: string;
    extension_attributes?: { is_subscribed?: boolean; customer_order_statuses_notification: boolean };
};

type InformationFormProps = {
    onSubmit(params: EditParams);
    initialValues: EditFields;
    messages: Message[];
    status: DataStatus;
};

const InformationForm: React.FC<InformationFormProps> = ({ initialValues, messages, status, onSubmit }) => {
    const isStatusEmailDisabled = initialValues.extensionAttributes?.customer_order_statuses_notification || false;
    const [isEmailChecked, setIsEmailChecked] = useState(!isStatusEmailDisabled);
    const { paths, restHeaders } = useDeps();
    const isPending = status === DataStatus.Pending;
    const isSuccess = status === DataStatus.Success;
    const submit = useCallback(
        (values) => {
            const { email, firstname, lastname } = values.address;
            onSubmit({
                email,
                firstname,
                lastname,
                extension_attributes: {
                    ...initialValues.extensionAttributes,
                    customer_order_statuses_notification: !isEmailChecked,
                },
            });
        },
        [initialValues.extensionAttributes, isEmailChecked, onSubmit],
    );

    return (
        <>
            {messages.length > 0 && (
                <Block>
                    {messages.map((msg) => {
                        const type = msg.type === MessageType.Success ? MessageType.Success : MessageType.Error;
                        return <Messages key={msg.text} messages={[{ type: type, text: msg.text }]} />;
                    })}
                </Block>
            )}
            <Form
                data-test-id="InformationForm"
                initialValues={{ address: initialValues }}
                onSubmit={submit}
                className="card"
            >
                <fieldset disabled={isPending || isSuccess}>
                    <Field>
                        <FirstNameField />
                    </Field>
                    <Field>
                        <LastNameField />
                    </Field>
                    <Field>
                        <EmailField />
                    </Field>
                    <Field>
                        <div className={classes.permissions}>
                            <label>Order Status Email Updates</label>
                            <ToggleSwitch
                                checked={isEmailChecked}
                                onChange={() => setIsEmailChecked(!isEmailChecked)}
                            />
                        </div>
                    </Field>
                    <Actions primaryText="Submit">
                        <Link className="button button--reset" to={paths.customer.changePassword}>
                            Change Password
                        </Link>
                    </Actions>
                </fieldset>
            </Form>
        </>
    );
};

export const AccountInformationPageConnected: React.FC = () => {
    const { data, edit, messages, status, reset } = useEditUser();

    useEffect(() => {
        reset();
    }, [reset]);

    return (
        <CustomerAccountPage
            name="Profile"
            child={<AccountInformationPage initialValues={data} edit={edit} messages={messages} status={status} />}
        />
    );
};
