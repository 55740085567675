import { concat, Observable, of, race } from 'rxjs';
import { ofType } from 'redux-observable';
import { mapTo, pluck, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { CartActions, CartMsg } from '@wearejh/m2-pwa-cart-gql/lib/cart.actions';
import { AppendCartState } from '@wearejh/m2-pwa-cart-gql/lib';
import { DataStatus } from '@wearejh/m2-pwa-engine/lib/types';
import { AppendUserState } from '@wearejh/m2-pwa-user/lib';

import { Actions, BasketMsg, TypeMap } from '../basket.actions';

export function loadStepBasket(
    action$: Observable<any>,
    state$: Observable<AppendCartState<AppendUserState>>,
): Observable<any> {
    return action$.pipe(
        ofType<Actions, TypeMap['Basket.LoadStep']>('Basket.LoadStep'),
        withLatestFrom(state$.pipe(pluck('cart', 'status')), state$.pipe(pluck('user', 'status'))),
        switchMap(([, cartStatus, userStatus]) => {
            const cartRefresh = CartMsg('Cart.Refresh', { force: true });
            // const refreshMsg = BasketMsg('Basket.Refresh');
            /**
             * Listen for a fetchSuccess and follow up with a Basket refresh
             */
            // const good = action$.pipe(ofType<CartActions>('Cart.FetchSuccess'), mapTo(refreshMsg));
            const good = action$.pipe(ofType<CartActions>('Cart.FetchSuccess'));
            /**
             * Listen for when a refresh failed.
             */
            const bad = action$.pipe(
                ofType<CartActions>('Cart.FetchError'),
                mapTo(BasketMsg('Basket.Noop', 'Not refreshing since the cart had issues')),
            );
            /**
             * Listen to either exclusively
             */
            const eitherOr = race(good, bad).pipe(take(1));
            /**
             * If we're already pending, just wait
             */
            if (cartStatus === DataStatus.Pending || userStatus === DataStatus.Pending) {
                return eitherOr;
            }
            /**
             * Otherwise trigger a refresh and _then_ wait
             */
            return concat(of(cartRefresh), eitherOr);
        }),
    );
}
