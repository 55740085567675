import classes from 'src/components/Orders/OrderTracking.scss';
import React from 'react';
import { useTrackingFoundDetails } from 'src/components/Orders/hooks/useTrackingFoundDetails';

export const ModalTrackingFound = ({ data, orderId, invoiceStatusesFromDPD, statusFromDPD }) => {
    const { orderDetails, displayValue, isDuplicateInvoice } = useTrackingFoundDetails(data, orderId);

    const correctOrderStatus = isDuplicateInvoice
        ? invoiceStatusesFromDPD[0] || ''
        : statusFromDPD || displayValue(orderDetails.status);

    const statusToDisplay =
        correctOrderStatus === 'direct to film' ? 'Your order is currently being printed' : correctOrderStatus;

    return (
        <div className={classes.modalSectionCard}>
            <span className={classes.orderTrackingTitle}>Order Status - #{orderDetails.number}</span>
            <span className={classes.modalOrderStatus}>{statusToDisplay}</span>

            <span className={classes.modalEstimatedDispatchDate}>
                Estimated dispatch date:{' '}
                <span className={classes.modalEstimatedDispatchDateBold}>
                    {displayValue(orderDetails.dateDispatchExpected)}
                </span>
            </span>
            {data?.orderStatus?.consignment_ref !== '' && (
                <div className={classes.trackPackage}>
                    <a
                        href={`https://www.fedex.com/fedextrack/?trknbr=${data?.orderStatus?.consignment_ref}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Track Package
                    </a>
                </div>
            )}
        </div>
    );
};
