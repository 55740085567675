import { useQuery } from '@apollo/react-hooks';
import plainBundleQuery from 'src/queries/plainBundleQuery.graphql';
import { useDeps } from 'src/hooks/useDeps';
import { BundleCustomisationOptions } from 'src/components/BundleProduct/utils/BundleCustomisationOptions';

export const useBundleCustomisationData = (logoPolicy?: string) => {
    const { data } = useQuery(plainBundleQuery, {
        ssr: useDeps().env.SSR_GQL,
    });

    return (
        data?.customAttributeMetadata?.items[0]?.attribute_options.find((option) => option.value === logoPolicy)
            ?.label || BundleCustomisationOptions.Required
    );
};
